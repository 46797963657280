import React from 'react'

import { Account } from '../../components/Widgets/Account'
import { AppSettings } from '../../components/Widgets/AppSettings'
import { SettingsWrapper } from './styles'

export function Settings(): JSX.Element {
  return (
    <SettingsWrapper>
      <Account />
      <AppSettings />
    </SettingsWrapper>
  )
}
