import dayjs from 'dayjs'
import { ChartAggregationLevel } from '../../stores/analytics'

export function formatDateCategory(timestamp: string, aggregationLevel: ChartAggregationLevel): string {
  if (aggregationLevel === 'year') return dayjs(timestamp).format('YYYY')
  if (aggregationLevel === 'month') return dayjs(timestamp).format('MMM')
  return dayjs(timestamp).format('D')
}

export function formatDateLabel(timestamp: string, aggregationLevel: ChartAggregationLevel): string {
  if (aggregationLevel === 'year') return dayjs(timestamp).format('YYYY')
  if (aggregationLevel === 'month') return dayjs(timestamp).format('MMMM YYYY')
  return dayjs(timestamp).format('LL')
}
