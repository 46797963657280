import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Button, Checkbox, Input, InputRef, Radio, Space } from 'antd'
import { FilterDropdownProps, Key } from 'antd/lib/table/interface'

const DropdownContent = styled.div`
  padding: 12px;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  > button {
    width: 92px;
  }
`

const FilterSearch = styled(Input)`
  margin-bottom: 12px;
`

const FilterScrollWrapper = styled.div`
  max-height: 300px;
  overflow: scroll;
`

export function SearchDropdown({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  visible,
}: FilterDropdownProps): ReactNode {
  const { t } = useTranslation()
  const ref = useRef<InputRef>(null)
  useEffect(() => {
    if (!visible) return
    window.setTimeout(() => {
      ref.current?.select()
    }, 100)
  }, [visible])
  return (
    <DropdownContent>
      <Input
        ref={ref}
        allowClear
        onReset={clearFilters}
        onPressEnter={() => confirm()}
        placeholder={t('placeholders.search')}
        value={selectedKeys[0] as string | number}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      />
      <ButtonWrap>
        <Button
          type="link"
          onClick={() => {
            if (clearFilters) clearFilters()
            confirm()
          }}
          size="small"
        >
          {selectedKeys.length === 0 ? t('actions.cancel') : t('actions.reset')}
        </Button>
        <Button type="primary" onClick={() => confirm()} size="small">
          {t('actions.search')}
        </Button>
      </ButtonWrap>
    </DropdownContent>
  )
}

export function FilterDropdown({
  setSelectedKeys,
  selectedKeys,
  confirm,
  filters,
  clearFilters,
  visible,
}: FilterDropdownProps): ReactNode {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const ref = useRef<InputRef>(null)

  useEffect(() => {
    if (!visible) {
      window.setTimeout(() => setQuery(''), 250)
      return
    }
    window.setTimeout(() => ref.current?.select(), 100)
  }, [visible])

  function toggleFilter(key: string): void {
    const set = !selectedKeys.includes(key)
    setSelectedKeys(set ? [...selectedKeys, key] : selectedKeys.filter((selectedKey: Key) => key !== selectedKey))
  }

  function handleEnter(): void {
    const firstFilter = filters?.find(
      ({ value, text }) =>
        (typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())) ||
        (typeof text === 'string' && text.toLowerCase().includes(query.toLowerCase())),
    )
    const key = firstFilter?.value
    if (typeof key === 'string') toggleFilter(key)
  }

  function handleKeyPress(e: KeyboardEvent<HTMLInputElement>): void {
    if (e.key === 'Escape') confirm()
  }

  if (!filters?.length) return

  const searchedFilters = filters?.filter(({ value, text }) => {
    return (
      typeof value !== 'string' ||
      value.toLowerCase().includes(query.toLowerCase()) ||
      typeof text !== 'string' ||
      text.toLowerCase().includes(query.toLowerCase())
    )
  })

  return (
    <DropdownContent>
      {filters && filters.length > 5 && (
        <FilterSearch
          ref={ref}
          type="text"
          size="small"
          value={query}
          prefix={<FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />}
          placeholder={t('placeholders.searchFilter')}
          onChange={(e) => setQuery(e.target.value)}
          allowClear
          onPressEnter={handleEnter}
          onKeyDown={handleKeyPress}
          onReset={() => setQuery('')}
        />
      )}
      <FilterScrollWrapper>
        {'asRadio' in filters && filters.asRadio ? (
          <Radio.Group value={selectedKeys[0]} onChange={({ target: { value } }) => setSelectedKeys([value])}>
            <Space direction="vertical">
              {searchedFilters.map(({ value, text }) => (
                <Radio key={value.toString()} value={value}>
                  {text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        ) : (
          <Space direction="vertical">
            {searchedFilters.map((filterItem) => {
              const key: Key = filterItem.value.toString()
              const isChecked = selectedKeys.includes(key)
              return (
                <Checkbox key={key} checked={isChecked} onChange={() => toggleFilter(key)}>
                  {filterItem.text}
                </Checkbox>
              )
            })}
          </Space>
        )}
      </FilterScrollWrapper>
      <ButtonWrap>
        <Button
          type="link"
          onClick={() => {
            if (clearFilters) clearFilters()
            confirm()
          }}
          size="small"
        >
          {selectedKeys.length === 0 ? t('actions.cancel') : t('actions.reset')}
        </Button>
        <Button type="primary" onClick={() => confirm()} size="small">
          {t('actions.filter')}
        </Button>
      </ButtonWrap>
    </DropdownContent>
  )
}

export function searchIcon(filtered: boolean): ReactNode {
  return <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" color={filtered ? Colors.mauve600 : undefined} />
}
