import { Popconfirm, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  gap: 8px;
`

export const Actions = ({
  isEditing,
  isDisabled,
  onSave,
  onCancel,
  onEdit,
  onDelete,
}: {
  isEditing: boolean
  isDisabled: boolean
  editingIndex?: number
  onSave: () => void
  onCancel: () => void
  onEdit: () => void
  onDelete: () => void
}) => {
  const { t } = useTranslation()
  return isEditing ? (
    <Flex>
      <Typography.Link onClick={onSave}>{t('actions.save')}</Typography.Link>
      <Typography.Link onClick={onCancel}>{t('actions.cancel')}</Typography.Link>
    </Flex>
  ) : (
    <Flex>
      <Typography.Link disabled={isDisabled} onClick={onEdit}>
        {t('actions.edit')}
      </Typography.Link>

      <Popconfirm title={t('actions.confirm')} okText={t('form.yes')} cancelText={t('form.no')} onConfirm={onDelete}>
        <Typography.Link type="danger" disabled={isDisabled}>
          {t('actions.delete')}
        </Typography.Link>
      </Popconfirm>
    </Flex>
  )
}
