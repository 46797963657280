import { ProgramStartType } from '@vetahealth/tuna-can-api'
import { Button, DatePicker, Form, Modal, Select, Typography, message } from 'antd'
import dayjs from 'dayjs'
import { isEqual, omit } from 'lodash-es'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { usePatientStore } from '../../../../stores/patient'
import { useSitesStore } from '../../../../stores/sites'
import { FormKeys, PersonalFormValues } from '../../../Forms'

export function EditProgram({
  isVisible,
  onCancel,
  warning,
}: {
  isVisible: boolean
  onCancel: () => void
  warning?: string
}): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [patient, setProgram] = usePatientStore((state) => [state.patient, state.setProgram])
  const sites = useSitesStore((state) => state.sites)
  const [isLoading, withLoading] = useLoading()
  const program = patient?.program

  const referenceDateInputIsDisabled =
    Form.useWatch(FormKeys.PROGRAM_START_TYPE, form) === ProgramStartType.NextMeasurement

  const patientSiteProgramTemplates = sites?.find((site) => site.key === patient?.site)?.programs || []

  const initialValues =
    sites && program
      ? {
          [FormKeys.PROGRAM_TEMPLATE_ID]: program.templateId,
          [FormKeys.PROGRAM_START]: dayjs(program.referenceDate),
          [FormKeys.PROGRAM_START_TYPE]: program.startType,
        }
      : undefined

  useEffect(() => {
    if (sites && program) {
      form.setFieldsValue({
        [FormKeys.PROGRAM_TEMPLATE_ID]: program.templateId,
        [FormKeys.PROGRAM_START]: dayjs(program.referenceDate),
        [FormKeys.PROGRAM_START_TYPE]: program.startType,
      })
    }
  }, [sites, program])

  function handleCancel(): void {
    form.resetFields()
    onCancel()
  }

  async function handleSubmit(
    values: Pick<
      PersonalFormValues,
      FormKeys.PROGRAM_TEMPLATE_ID | FormKeys.PROGRAM_START | FormKeys.PROGRAM_START_TYPE
    >,
  ): Promise<void> {
    if (
      isEqual(omit(values, [FormKeys.PROGRAM_START]), omit(initialValues, [FormKeys.PROGRAM_START])) &&
      values.programStart?.format('YYYY-MM-DD') === initialValues?.programStart?.format('YYYY-MM-DD')
    ) {
      return onCancel()
    }
    trackEvent(TrackingEvent.programChangeClicked)

    const success = await withLoading(
      setProgram({
        // currently we support just one program at a time - existing programs are automatically finished by backend
        id: values.programTemplateId,
        startDate:
          values.programStart && values.programStartType === ProgramStartType.ReferenceDate
            ? values.programStart.format('YYYY-MM-DD')
            : undefined,
        startType: values.programStartType,
      }),
    )

    onCancel()

    if (success) {
      message.success({ content: t('message.patientUpdate') })
    } else if (program) {
      form.setFieldsValue({
        program: { label: program.name, value: program.id },
        startDate: dayjs(program.referenceDate),
        startType: program.startType === 'nextMeasurement',
      })
    }
  }

  return (
    <Modal
      title={t('widgets.programs.edit')}
      open={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      centered
      footer={
        <Button form="editProgram" htmlType="submit" type="primary" loading={isLoading}>
          {t('actions.save')}
        </Button>
      }
    >
      {warning && (
        <Typography.Paragraph>
          <Typography.Text>{warning}</Typography.Text>
        </Typography.Paragraph>
      )}
      <Form
        name="editProgram"
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        validateTrigger="onSubmit"
        initialValues={initialValues}
      >
        <Form.Item
          name={FormKeys.PROGRAM_TEMPLATE_ID}
          label={t('form.program')}
          rules={[{ required: true, message: t('validations.selectProgram') }]}
        >
          <Select
            labelInValue={false}
            style={{ minWidth: '200px' }}
            options={patientSiteProgramTemplates.map((programTemplate) => ({
              label: programTemplate.name,
              value: programTemplate.id,
            }))}
            placeholder={t('validations.selectProgram')}
          />
        </Form.Item>
        <Form.Item name={FormKeys.PROGRAM_START_TYPE} label={t('form.startOn')} rules={[{ required: true }]}>
          <Select
            options={[
              { value: ProgramStartType.NextMeasurement, label: t('form.startOnNextMeasurement') },
              { value: ProgramStartType.ReferenceDate, label: t('form.startOnReferenceDate') },
            ]}
          />
        </Form.Item>
        {!referenceDateInputIsDisabled && (
          <Form.Item
            name={FormKeys.PROGRAM_START}
            label={t('form.programStart')}
            rules={[{ required: !referenceDateInputIsDisabled, message: t('validations.enterProgramStart') }]}
            dependencies={[FormKeys.PROGRAM_START_TYPE]}
          >
            <DatePicker
              disabled={referenceDateInputIsDisabled}
              allowClear={false}
              style={{ width: '100%' }}
              placeholder={dayjs().format('L')}
              format="L"
              showNow
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
