import { faRotateRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Button } from 'antd'

import { usePatientStore } from '../../../stores/patient'
import { useSitesStore } from '../../../stores/sites'
import { CareTasks } from '../../CareTasks'
import { OpenChat } from '../../Chat'
import { Spinner } from '../../Spinner'
import { Subscribe } from '../../Subscribe'
import { Card } from '../styles'
import { CareState } from './CareState'
import { Deviations } from './Deviations'
import { Name } from './Name'
import { QuickInfo } from './QuickInfo'
import { EditQuickInfo } from './QuickInfo/EditQuickInfo'
import { QuickNotes } from './QuickNotes'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 6px 40px;
`
const Actions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  align-self: flex-start;
  flex: 1;
`
const NameWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 5px;
`
const PatientInfo = styled.div`
  margin-bottom: 6px;
  padding-bottom: 12px;
  grid-column: 1 / span 2;
  gap: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBackground};
`
const StateAndAlerts = styled.div`
  display: flex;
  grid-column: 1 / span 2;
  gap: 5px;
`
const Alerts = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
`

export function PatientHeader(): JSX.Element {
  const { t } = useTranslation()
  const [lastUpdated, setLastUpdated] = useState<Dayjs>(dayjs())
  const [patient] = usePatientStore((state) => [state.patient])
  const [sites, getSites] = useSitesStore((state) => [state.sites, state.getSites])

  void getSites()

  const { hasMessaging = false } = sites?.find(({ key }) => key === patient?.site) || {}

  async function handleRefresh(): Promise<void> {
    if (patient) {
      window.location.reload()
    }
  }

  useEffect(() => {
    setLastUpdated(dayjs())
  }, [patient])

  return (
    <Card isSticky gridColumn="1 / span 2">
      {patient ? (
        <Grid>
          <NameWrap>
            <Name patient={patient} />
          </NameWrap>
          <Actions>
            <Button
              onClick={handleRefresh}
              icon={<FontAwesomeIcon icon={faRotateRight} style={{ marginRight: 10 }} />}
              type="text"
              style={{ fontSize: 12, fontWeight: 500, color: Colors.gray600 }}
            >
              {t('widgets.patient.lastUpdated', { date: lastUpdated.format(t('dateFormats.time')) })}
            </Button>

            <Subscribe />
            <EditQuickInfo />
            {hasMessaging && <OpenChat />}
          </Actions>
          <PatientInfo>
            <QuickInfo />
            <QuickNotes />
          </PatientInfo>
          <StateAndAlerts>
            <CareState />
            <Alerts>
              <CareTasks careTasks={patient.careState?.careTasks} />
              <Deviations />
            </Alerts>
          </StateAndAlerts>
        </Grid>
      ) : (
        <Spinner />
      )}
    </Card>
  )
}
