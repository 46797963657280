import { ArchiveSearchResult } from '@vetahealth/tuna-can-api'
import { Button, Form, Input, Table } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { API } from '../../../lib/api'

import styled from 'styled-components'
import { routes } from '../../../Router/routes'
import { PatientDetailsTab } from '../../../Router/routes/PatientDetails'
import { useLoading } from '../../../lib/hooks/useLoading'
import { FormKeys } from '../../Forms'
import { getTableData } from '../../helpers'
import { Card } from '../styles'
import { getFields } from './helpers'

const ClickableMultilineTable = styled(Table)`
  cursor: pointer;
  white-space: pre-line;
`

const SearchForm = styled(Form)`
  padding: 24px 0;
`

const SearchInputWrap = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
  .ant-form-item {
    width: 320px;
    margin-bottom: 0;
  }
`

export function Archive(): JSX.Element {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const [results, setResults] = useState<ArchiveSearchResult[]>([])
  const navigate = useNavigate()
  const location = useLocation()

  const query = new URLSearchParams(location.search).get('query')
  const [queryInput, setQueryInput] = useState(query ?? '')

  useEffect(() => {
    if (!query) {
      setResults([])
      return
    }
    ;(async () => {
      const searchResult = await withLoading(API.quickSearchArchive(query))
      if (searchResult) setResults(searchResult)
    })()
  }, [query])

  async function handleSubmit(values: { search: string }) {
    navigate({
      pathname: routes.archive(),
      search: `query=${values.search}`,
    })
  }

  async function handlePatientSelection(item: ArchiveSearchResult) {
    navigate({
      pathname: routes.patientDetails(item.id, PatientDetailsTab.program),
    })
  }

  const { dataSource, columns } = getTableData({
    fields: getFields(),
    data: results,
  })

  return (
    <Card title={t('widgets.archive.title')}>
      <SearchForm onFinish={handleSubmit} layout="vertical" initialValues={{ search: query }}>
        <SearchInputWrap>
          <FormItem name={FormKeys.SEARCH}>
            <Input
              placeholder={t('placeholders.searchTerms')}
              value={queryInput}
              onChange={(e) => setQueryInput(e.target.value)}
              autoComplete="off"
            />
          </FormItem>
          <Button htmlType="submit" type="primary" disabled={query === queryInput}>
            {t('placeholders.search')}
          </Button>
        </SearchInputWrap>
      </SearchForm>
      <ClickableMultilineTable<typeof Table<ArchiveSearchResult>>
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 5 }}
        onRow={(record: ArchiveSearchResult) => ({ onClick: () => handlePatientSelection(record) })}
      />
    </Card>
  )
}
