import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs, { Dayjs } from 'dayjs'
import i18next from 'i18next'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Radio, RadioChangeEvent } from 'antd'

import { getIntervalDates } from './helpers'
import { IntervalDates, IntervalType } from './types'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const RadioWrapper = styled.div`
  margin-right: 20px;
`

const DateIntervalWrapper = styled.div`
  font-weight: 500;
  margin-left: 20px;
`

const AggregationLabel = styled.div`
  margin-left: 20px;
  font-weight: 700;
`

function getIntervalDateLabel({
  intervalType,
  intervalDates: [intervalStart, intervalEnd],
}: {
  intervalType: IntervalType
  intervalDates: IntervalDates
}): string {
  const intervalDays = intervalEnd.startOf('day').diff(intervalStart.startOf('day'), 'day')

  if (intervalType === 'all' || intervalType === 'trend') {
    if (intervalDays === 0) {
      intervalStart.format(i18next.t('dateFormats.dayAndWeekDayAndMonth'))
    }

    const format = intervalEnd.isSame(intervalStart, 'year')
      ? i18next.t('dateFormats.dayAndMonth')
      : i18next.t('dateFormats.dayAndMonthAndYear')

    return `${intervalStart.format(format)} - ${intervalEnd.format(format)}`
  }

  const isCurrent = intervalStart.isSame(dayjs(), intervalType)

  if (intervalType === 'day') {
    if (isCurrent) return i18next.t('dateFormats.today')
    return intervalStart.format(i18next.t('dateFormats.dayAndWeekDayAndMonth'))
  }

  if (intervalType === 'week') {
    if (isCurrent) return i18next.t('dateFormats.thisWeek')
    if (!intervalEnd.isSame(intervalStart, 'month')) {
      return `${intervalStart.format(i18next.t('dateFormats.dayAndMonth'))} - ${intervalEnd.format(
        i18next.t('dateFormats.dayAndMonth'),
      )}`
    }
    return `${intervalStart.format('D')} - ${intervalEnd.format(i18next.t('dateFormats.dayAndMonth'))}`
  }

  if (intervalType === 'month') {
    if (isCurrent) return i18next.t('dateFormats.thisMonth')
    if (!intervalStart.isSame(dayjs(), 'year')) {
      return intervalStart.format('MMMM YYYY')
    }

    return intervalStart.format('MMMM')
  }

  return intervalStart.format('YYYY')
}

type ControlsProps = {
  intervalType: IntervalType
  intervalDates: IntervalDates
  minDate: Dayjs
  maxDate: Dayjs
  aggregationLabel?: string
  intervals?: IntervalType[]
  onChange: (intervalType: IntervalType, intervalDates: IntervalDates) => void
}

export function Controls({
  intervalType,
  intervalDates,
  minDate,
  maxDate,
  aggregationLabel,
  intervals,
  onChange,
}: ControlsProps): JSX.Element {
  const { t } = useTranslation()

  const intervalsWithLabel: Array<{ value: IntervalType; label: string }> = [
    { value: 'day', label: t('dateFormats.day') },
    { value: 'week', label: t('dateFormats.week') },
    { value: 'month', label: t('dateFormats.month') },
    { value: 'all', label: t('dateFormats.allTime') },
    { value: 'trend', label: t('dateFormats.trend') },
  ]

  const selectableIntervals = intervals
    ? intervalsWithLabel.filter(({ value }) => intervals.includes(value))
    : intervalsWithLabel

  function handleIntervalChange(type: IntervalType): void {
    onChange(type, getIntervalDates({ intervalType: type, intervalDates, minDate, maxDate }))
  }

  const handleIntervalOffsetChange = useCallback(
    (offset: number): void => {
      onChange(intervalType, getIntervalDates({ intervalType, intervalDates, offset, minDate, maxDate }))
    },
    [intervalType, intervalDates],
  )

  const isAllOrTrend = intervalType === 'all' || intervalType === 'trend'

  return (
    <Wrapper>
      <RadioWrapper>
        <Radio.Group
          value={intervalType}
          onChange={(e: RadioChangeEvent) => handleIntervalChange(e.target.value)}
          options={selectableIntervals}
          optionType="button"
          buttonStyle="outline"
          size="middle"
        />
      </RadioWrapper>
      <Button
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={() => handleIntervalOffsetChange(1)}
        disabled={isAllOrTrend || intervalDates[0].isBefore(minDate)}
        style={{ marginRight: '8px' }}
      />
      <Button
        icon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={() => handleIntervalOffsetChange(-1)}
        disabled={isAllOrTrend || intervalDates[1].isSameOrAfter(dayjs(), intervalType)}
      />
      <DateIntervalWrapper>{getIntervalDateLabel({ intervalType, intervalDates })}</DateIntervalWrapper>
      {aggregationLabel && <AggregationLabel>{aggregationLabel}</AggregationLabel>}
    </Wrapper>
  )
}
