import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Site } from '@vetahealth/tuna-can-api'
import { Button, Collapse } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AddProgramTemplate } from './AddProgramTemplate'
import { ProgramTemplate } from './ProgramTemplate'

const Wrap = styled.div`
  .ant-collapse {
    background-color: ${({ theme }) => theme.badgeBackground};
    .ant-collapse-header {
      font-weight: 600;
    }
  }
`

const ProgramTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
`

const ProgramDescription = styled.div`
  opacity: 0.6;
  font-weight: 400;
`

export function Programs({ site, isEditable }: { site?: Site; isEditable: boolean }): JSX.Element {
  const { t } = useTranslation()
  const [isAddProgramVisible, setIsAddProgramVisible] = useState(false)

  if (!site) return <div />

  return (
    <Wrap>
      {isEditable && (
        <Button
          type="primary"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setIsAddProgramVisible(true)}
          style={{ marginBottom: 20 }}
        >
          {t('widgets.siteManagement.programs.addProgramTemplate')}
        </Button>
      )}

      {site.programs.length > 0 && (
        <Collapse
          key={site.programs[0]?.id}
          defaultActiveKey={site.programs[0]?.id}
          items={site.programs.map((program) => ({
            key: program.id,
            label: (
              <span>
                <ProgramTitle>{program.name}</ProgramTitle>
                <ProgramDescription>{program.description}</ProgramDescription>
              </span>
            ),
            children: <ProgramTemplate key={program.id} program={program} siteKey={site.key} isEditable={isEditable} />,
          }))}
        />
      )}

      <AddProgramTemplate
        isVisible={isAddProgramVisible}
        onCancel={() => setIsAddProgramVisible(false)}
        siteKey={site.key}
      />
    </Wrap>
  )
}
