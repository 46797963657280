import { faPen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Button, Tooltip } from 'antd'

import { AlertLevel } from './views'

interface ViewSelectorProps {
  alertLevel?: AlertLevel
  label: string
  tooltip?: string
  count: number | string
  isModified: boolean
  isActive?: boolean
  onClick?: () => void
  onEdit?: () => void
  onUpdate?: () => void
}

const ViewWrapper = styled.div<{ $isActive: boolean }>`
  border: 1px solid ${({ theme }) => theme.tertiaryBackground};
  padding: 1px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    border-color: ${({ theme }) => theme.primary};
  }
  ${({ $isActive, theme }) =>
    $isActive &&
    `
      border: 2px solid ${theme.primary};
      padding: 0;
  `}
`

const TooltipWrapper = styled(Tooltip)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 4px;
`

const Badge = styled.div`
  color: ${({ theme }) => theme.tagViewSelector};
  background-color: ${({ theme }) => theme.secondaryBackground};
  border-radius: 6px;
  min-width: 28px;
  padding: 0 4px;
  height: 28px;
  flex-shrink: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

const Label = styled.span`
  font-weight: 500;
  padding: 0 4px;
`

const Indicator = styled.div<{ $level: AlertLevel }>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-top: 2px;
  background-color: ${({ $level }) => ($level === AlertLevel.medium ? Colors.canary200 : Colors.ruby600)};
  margin-right: 4px;
`

export function ViewSelector({
  label,
  tooltip,
  isModified,
  alertLevel = AlertLevel.default,
  count,
  onClick,
  onEdit,
  onUpdate,
  isActive = false,
}: ViewSelectorProps): JSX.Element {
  const { t } = useTranslation()

  function handleClickEdit(e?: React.MouseEvent<HTMLElement, MouseEvent>): void {
    e?.stopPropagation()
    onEdit?.()
  }

  function handleUpdate(e?: React.MouseEvent<HTMLElement, MouseEvent>): void {
    e?.stopPropagation()
    onUpdate?.()
  }

  return (
    <ViewWrapper $isActive={isActive} onClick={onClick}>
      <TooltipWrapper title={tooltip} mouseEnterDelay={1}>
        <Badge>{count}</Badge>
        {alertLevel !== AlertLevel.default && <Indicator $level={alertLevel} />}
        <Label>{label}</Label>
      </TooltipWrapper>
      {onEdit && isActive && !isModified && (
        <Button shape="circle" type="text" icon={<FontAwesomeIcon icon={faPen} />} onClick={handleClickEdit} />
      )}

      {onUpdate && isActive && isModified && (
        <>
          <Button size="small">{t('actions.cancel')}</Button>
          <Button style={{ margin: '0 6px' }} size="small" onClick={handleUpdate} type="primary">
            {t('actions.update')}
          </Button>
        </>
      )}
    </ViewWrapper>
  )
}
