import i18next from 'i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { usePatientStore } from '../../../../../stores/patient'
import { formatInterval } from '../../../helpers'
import { BarChart } from '../Chart/BarChart'
import { createAlertField } from '../Table/helpers'
import { ResultsConfig } from './types'

export function getMeasurementCountConfig(): ResultsConfig {
  const patient = usePatientStore.getState().patient

  return {
    key: 'measurementCount',
    title: i18next.t('tracking.measurementCount.title'),
    category: 'statistics',
    format: (event) => `${event.value ?? '-'}`,
    card: {
      formatTime: () => (patient?.billingPeriod ? formatInterval(patient.billingPeriod) : ''),
    },
    chart: {
      component: BarChart,
      intervals: ['week', 'month', 'all'],
      dataKeys: [{ key: 'value', color: Colors.ink400 }],
      getData: (data: CardDetails[]) => data,
    },
    table: { defaultFields: true, additionalFields: [createAlertField()], timeFormat: 'll' },
  }
}
