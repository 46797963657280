import { IconDefinition, faStethoscope, faUser } from '@fortawesome/pro-regular-svg-icons'
import { DefaultTheme } from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

type Avatar = {
  color: Colors
  background: Colors
  icon: IconDefinition
}

export function getAvatarProps({
  isCareManager,
  isMe,
  theme,
}: {
  isCareManager: boolean
  isMe: boolean
  theme: DefaultTheme
}): Avatar {
  if (isMe)
    return {
      color: theme.chatAvatarTertiary,
      background: theme.chatAvatarBackground,
      icon: faStethoscope,
    }
  if (isCareManager)
    return {
      color: theme.chatAvatarSecondary,
      background: theme.chatAvatarBackground,
      icon: faStethoscope,
    }
  return {
    color: theme.quinaryBackground,
    background: theme.primary,
    icon: faUser,
  }
}
