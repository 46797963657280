import React, { useEffect } from 'react'

import { useAppStore } from '../../stores/app'
import { ModalName, useModalStore } from '../../stores/modal'
import { useUserStore } from '../../stores/user'
import { AddPatient } from './AddPatient'
import { EmailSupport } from './EmailSupport'
import { EpicImport } from './EpicImport'
import { Preview } from './Preview'
import { ReleaseInfo } from './ReleaseInfo'
import { ReportWizard } from './ReportWizard'
import { ResetDemoPatients } from './ResetDemoPatients'
import { ResetUserSettings } from './ResetUserSettings'

export function Modals(): JSX.Element {
  const [modal, openModal, closeModal] = useModalStore((state) => [state.modal, state.openModal, state.closeModal])
  const [releaseInfo] = useAppStore((state) => [state.releaseInfo])
  const [viewedReleaseInfo] = useUserStore((state) => [state.viewedReleaseInfo])

  useEffect(
    function autoOpen() {
      if (releaseInfo.length && !viewedReleaseInfo.includes(releaseInfo[0].id)) {
        openModal(ModalName.releaseInfo)
      }
    },
    [releaseInfo, viewedReleaseInfo],
  )

  return (
    <>
      <AddPatient isVisible={modal === ModalName.addPatient} onCancel={() => closeModal(ModalName.addPatient)} />
      <EmailSupport isVisible={modal === ModalName.emailSupport} onCancel={() => closeModal(ModalName.emailSupport)} />
      <EpicImport isVisible={modal === ModalName.epicImport} onCancel={() => closeModal(ModalName.epicImport)} />
      <ReportWizard isVisible={modal === ModalName.reportWizard} onCancel={() => closeModal(ModalName.reportWizard)} />
      <ReleaseInfo isVisible={modal === ModalName.releaseInfo} onCancel={() => closeModal(ModalName.releaseInfo)} />
      <ResetDemoPatients
        isVisible={modal === ModalName.resetDemoPatients}
        onCancel={() => closeModal(ModalName.resetDemoPatients)}
      />
      <ResetUserSettings
        isVisible={modal === ModalName.resetUserSettings}
        onCancel={() => closeModal(ModalName.resetUserSettings)}
      />
      <Preview isVisible={modal === ModalName.preview} onCancel={() => closeModal(ModalName.preview)} />
    </>
  )
}
