import { Button, Form, Modal } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import jsDownload from 'js-file-download'
import { debounce } from 'lodash-es'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { API } from '../../../../../lib/api'
import { useLoading } from '../../../../../lib/hooks/useLoading'
import { normalizeVitalsBody, usePatientStore } from '../../../../../stores/patient'
import { DateRange, SetPasswordFormValues, SetTimeRangeFormValues } from '../../../../Forms'
import { SetDateRange } from '../../../../Forms/SetDateRange'
import { SetPdfPassword } from '../../../../Forms/SetPdfPassword'
import { getDocumentFilename } from '../../Documents'

type DownloadVitalsFormValues = SetTimeRangeFormValues & SetPasswordFormValues

export type CreateVitalsReportParams = {
  password: string
  from: Dayjs
  until: Dayjs
}

export function CreateVitalsReport(): JSX.Element {
  const { t } = useTranslation()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [downloadVitalsForm] = Form.useForm<DownloadVitalsFormValues>()
  const [patient] = usePatientStore((state) => [state.patient])
  const [isLoading, withLoading] = useLoading()

  const handleCreateVitalsReport = useCallback(
    debounce(async (values: DownloadVitalsFormValues): Promise<void> => {
      const baseParams: CreateVitalsReportParams = {
        password: values.password,
        from: dayjs(),
        until: dayjs(),
      }

      const dateRangeParams: Partial<CreateVitalsReportParams> = {
        [DateRange.ONE_WEEK]: { from: dayjs().subtract(7, 'days') },
        [DateRange.ONE_MONTH]: { from: dayjs().subtract(30, 'days') },
        [DateRange.CUSTOM]: { from: values.from, until: values.until },
      }[values.dateRange]

      if (patient) {
        const vitalsPDF = await withLoading(
          API.getVitalsPDF(patient.id, normalizeVitalsBody({ ...baseParams, ...dateRangeParams })),
        )

        if (vitalsPDF) {
          jsDownload(
            vitalsPDF,
            getDocumentFilename(t('widgets.documents.vitals'), patient.site, patient.lastName, dayjs().toString()),
          )
          setModalVisibility(false)
        }
      }
    }, 300),
    [patient],
  )

  return (
    <>
      <Button onClick={() => setModalVisibility(true)}>{t('actions.downloadVitalsReport')}</Button>
      <Modal
        title={t('actions.downloadVitalsReport')}
        open={isModalVisible}
        style={{ maxWidth: '600px' }}
        width="46%"
        onCancel={() => setModalVisibility(false)}
        destroyOnClose
        centered
        footer={null}
      >
        <Form
          layout="vertical"
          form={downloadVitalsForm}
          onFinish={handleCreateVitalsReport}
          validateTrigger="onSubmit"
          preserve={false}
        >
          <SetDateRange />
          <SetPdfPassword isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}
