import { Button, Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { NoteActions } from '../Widgets/Notes'
import { getNoteTypeName, noteTypes } from '../helpers'
import { InlineForm } from './styles'
import { FormKeys, NoteFormValues } from './types'

const TextAreaWrap = styled.div`
  position: relative;
  padding-bottom: 1%; /* to keep the position of floating buttons when text area errors occur */
`

export function CreateNote({
  loading,
  form,
}: {
  form: FormInstance<NoteFormValues>
  loading: boolean
}): JSX.Element {
  const { t } = useTranslation()
  const [isEnhanceTouched, setIsEnhanceTouched] = useState<boolean>(false)

  return (
    <>
      <Form.Item
        style={{ marginBottom: '10px' }}
        name={FormKeys.NOTE_TYPE}
        rules={[{ required: true, message: t('validations.enterNoteType') }]}
      >
        <Select
          showSearch
          options={noteTypes.map((noteTypeKey) => ({
            value: noteTypeKey,
            label: getNoteTypeName(noteTypeKey),
          }))}
          placeholder={t('placeholders.noteType')}
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: '10px' }} name={FormKeys.PATIENT_INTERACTION} valuePropName="checked">
        <Checkbox>{t('form.patientCommunication')}</Checkbox>
      </Form.Item>
      <TextAreaWrap>
        <Form.Item name={FormKeys.TEXT} rules={[{ required: true, message: t('validations.enterNote') }]}>
          <Input.TextArea
            style={{ paddingBottom: '40px' }}
            placeholder={t('placeholders.note')}
            autoSize={{ minRows: 5, maxRows: 20 }}
            onChange={() => setIsEnhanceTouched(false)}
          />
        </Form.Item>
        {/* Needs a child to remove Ant warning */}
        <Form.Item name={FormKeys.TEXT_ORIGINAL} hidden>
          <div />
        </Form.Item>
        <Form.Item name={FormKeys.TEXT_ENHANCED} hidden>
          <div />
        </Form.Item>
        <NoteActions form={form} isEnhanceTouched={isEnhanceTouched} onEnhanceTouch={setIsEnhanceTouched} />
      </TextAreaWrap>
      <InlineForm>
        <Form.Item
          style={{ flex: 1 }}
          name={FormKeys.TIMESTAMP}
          validateFirst
          rules={[
            { required: true, message: t('validations.enterDate') },
            {
              type: 'date',
              validator: (_, value: Dayjs) => (dayjs(value) < dayjs() ? Promise.resolve() : Promise.reject()),
              message: t('validations.noFutureDate'),
            },
          ]}
        >
          <DatePicker style={{ width: '100%' }} format="lll" showTime={{ format: t('dateFormats.time') }} showNow />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          name={FormKeys.TIME_SPENT_IN_MIN}
          validateFirst
          rules={[{ required: true, message: t('validations.enterTimeSpent') }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={99}
            formatter={(i) => (i ? Number(i).toFixed() : '')}
            placeholder={t('placeholders.timeSpent')}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            onClick={() => trackEvent(TrackingEvent.notesSaveClicked)}
          >
            {t('actions.save')}
          </Button>
        </Form.Item>
      </InlineForm>
    </>
  )
}
