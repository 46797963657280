import { ReactNode } from 'react'

export interface TabProps {
  tab?: string
  onTabChange: (tab: string) => void
}

export interface CardProps {
  children: ReactNode
  action?: ReactNode
  gridColumn?: string
  title?: string
  isSticky?: boolean
  fullHeight?: boolean
}

export enum CareStateCategoryEnum {
  disqualified = 'disqualified',
  monitoring = 'monitoring',
  notInterested = 'notInterested',
}
