import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`
const Text = styled.div`
  font-size: 13px;
  font-weight: 600;
`
const SectionLine = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.secondaryBackground};
`

const SectionTextWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.quinaryBackground};
`

export function Section({ day }: { day?: number }): JSX.Element {
  const { t } = useTranslation()

  return (
    <SectionWrapper>
      <SectionLine />
      <SectionTextWrapper>
        <Text>{day === dayjs().startOf('day').valueOf() ? t('dateFormats.today') : dayjs(day).format('LL')}</Text>
      </SectionTextWrapper>
    </SectionWrapper>
  )
}

export const Badge = styled.div<{ $color: Colors }>`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(10%, -20%);
  box-shadow:
    0 1px 2px rgb(0 0 0 / 5%),
    0 1px 6px -1px rgb(0 0 0 / 3%),
    0 2px 4px rgb(0 0 0 / 3%);
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${({ $color }) => $color};
`
