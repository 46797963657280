/* eslint-disable @typescript-eslint/no-explicit-any */
export function takeLeading<T extends (...params: any[]) => Promise<any>>(
  method: T,
): (...params: Parameters<T>) => ReturnType<T> {
  // consider hashing the params to differentiate requests
  let promise: any

  return (...params) => {
    if (promise) return promise

    promise = new Promise((resolve, reject) => {
      method(...params)
        .then((data) => {
          promise = null
          resolve(data)
        })
        .catch((error) => {
          promise = null
          reject(error)
        })
    })

    return promise
  }
}
