import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DatePicker, Form, Input } from 'antd'

import { TwoColumns } from './styles'
import { FormKeys } from './types'

export function Billing(): JSX.Element {
  const { t } = useTranslation()

  return (
    <TwoColumns>
      <Form.Item
        style={{ width: '100%' }}
        name={['billingInfo', FormKeys.ORDER_DATE]}
        label={t('form.orderDate')}
        tooltip={t('form.orderDateInfo')}
        rules={[{ type: 'date', max: dayjs().endOf('day').valueOf(), message: t('validations.noFutureDate') }]}
      >
        <DatePicker
          style={{ width: '100%' }}
          placeholder={dayjs().format('L')}
          format="L"
          showNow={false}
          disabledDate={(current) => current && current > dayjs()}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name={['billingInfo', FormKeys.ONBOARDING_DATE]}
        label={t('form.onboardingDate')}
        rules={[{ type: 'date', max: dayjs().endOf('day').valueOf(), message: t('validations.noFutureDate') }]}
      >
        <DatePicker
          style={{ width: '100%' }}
          placeholder={dayjs().format('L')}
          format="L"
          showNow={false}
          disabledDate={(current) => current && current > dayjs()}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name={['billingInfo', FormKeys.DELIVERY_DATE]}
        label={t('form.deliveryDate')}
        rules={[{ type: 'date', max: dayjs().endOf('day').valueOf(), message: t('validations.noFutureDate') }]}
      >
        <DatePicker
          style={{ width: '100%' }}
          placeholder={dayjs().format('L')}
          format="L"
          showNow={false}
          disabledDate={(current) => current && current > dayjs()}
        />
      </Form.Item>
      <Form.Item
        name={['billingInfo', FormKeys.PROVIDER]}
        label={t('form.provider')}
        rules={[{ type: 'string', min: 1 }]}
      >
        <Input placeholder={t('placeholders.provider')} />
      </Form.Item>
    </TwoColumns>
  )
}
