import { Note } from '@vetahealth/tuna-can-api'
import { Button, Dropdown, Form, Modal } from 'antd'
import dayjs from 'dayjs'
import jsDownload from 'js-file-download'
import { debounce, orderBy } from 'lodash-es'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { API } from '../../../lib/api'
import { useLoading } from '../../../lib/hooks/useLoading'
import { useValidatedPatient } from '../../../lib/hooks/useValidatedPatient'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { useNotesStore } from '../../../stores/notes'
import { usePatientStore } from '../../../stores/patient'
import { SetPasswordFormValues } from '../../Forms'
import { SetPdfPassword } from '../../Forms/SetPdfPassword'
import { Section, SubTitle } from '../styles'

export const getDocumentFilename = (title: string, site: string, lastName: string, timestamp: string): string =>
  `${title}_${site}_${lastName}_${dayjs(timestamp).format('YYYY-MM-DD')}.pdf`

export function Documents(): JSX.Element {
  const { t } = useTranslation()
  const [passwordForm] = Form.useForm<SetPasswordFormValues>()
  const validPatientId = useValidatedPatient()
  const notes = useNotesStore((state) => state.notes)
  const [selectedEscalationNote, setEscalationNote] = useState<Note | undefined>()
  const [loading, withLoading] = useLoading()
  const [patient, consent, getConsent] = usePatientStore((state) => [state.patient, state.consent, state.getConsent])
  const hasEscalationNotes = notes.some((note) => note.hasEscalationReport)

  useEffect(() => {
    if (!patient?.id) return
    void getConsent()
  }, [patient?.id])

  const handleConsentDownload = useCallback(
    debounce(async (): Promise<void> => {
      if (!patient || !consent) return
      trackEvent(TrackingEvent.documentsConsentDownloadClicked)

      const consentPDF = await withLoading(API.getPatientConsentPDF(patient.id))

      if (consentPDF) {
        jsDownload(
          consentPDF,
          getDocumentFilename(t('widgets.documents.consent'), patient.site, patient.lastName, consent.consentedAt),
        )
      }
    }, 300),
    [patient, consent],
  )

  const handleEscalationReportDownload = useCallback(
    debounce(async (values: SetPasswordFormValues, note?: Note): Promise<void> => {
      if (!patient || !note) return
      trackEvent(TrackingEvent.documentsEscalationDownloadClicked)

      const escalationPDF = await withLoading(API.getPatientEscalationPDF(patient.id, note.id, values.password))
      if (escalationPDF) {
        jsDownload(
          escalationPDF,
          getDocumentFilename(t('widgets.documents.escalation'), patient.site, patient.lastName, note.timestamp),
        )
        setEscalationNote(undefined)
      }
    }, 300),
    [patient],
  )

  if (!validPatientId) return <div />

  return (
    <>
      <Section>
        <SubTitle>{t('widgets.documents.escalation')}</SubTitle>
        <Dropdown
          trigger={['click']}
          disabled={!hasEscalationNotes}
          menu={{
            items: orderBy(notes, ['createdAt'], ['desc'])
              .filter((note) => note.hasEscalationReport)
              .map((note) => ({
                key: note.id,
                label: (
                  <a key={note.id} onClick={() => setEscalationNote(note)}>
                    {dayjs(note.createdAt).format('lll')}
                  </a>
                ),
              })),
          }}
        >
          <Button>{t('widgets.documents.selectReport')}</Button>
        </Dropdown>
        <Modal
          title={t('form.password')}
          open={Boolean(selectedEscalationNote)}
          style={{ maxWidth: '600px' }}
          width="46%"
          onCancel={() => setEscalationNote(undefined)}
          destroyOnClose
          centered
          footer={null}
        >
          <Form
            layout="vertical"
            form={passwordForm}
            onFinish={(values) => handleEscalationReportDownload(values, selectedEscalationNote)}
            validateTrigger="onSubmit"
            preserve={false}
          >
            <SetPdfPassword isLoading={loading} />
          </Form>
        </Modal>
      </Section>
      <Section>
        <SubTitle>{t('widgets.documents.consent')}</SubTitle>
        <Button disabled={!consent || loading} loading={loading} type="primary" onClick={handleConsentDownload}>
          {t('actions.download')}
        </Button>
      </Section>
    </>
  )
}
