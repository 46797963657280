import { PatientOverviewItem, PopulationView } from '@vetahealth/tuna-can-api'

import { PopulationViewState } from '../../../lib/PopulationViewState'

interface EvaluatedView {
  label: string
  tooltip?: string
  queryString: string
  count: number
  alertLevel: AlertLevel
}

export enum AlertLevel {
  default = 'default',
  medium = 'medium',
  high = 'high',
}

export function evaluateView(
  patients: PatientOverviewItem[],
  { name, description, queryString }: PopulationView,
): EvaluatedView {
  const viewState = new PopulationViewState(queryString)
  const count = patients.filter((patient) => viewState.matches(patient)).length
  viewState.concerning = true
  const hasUnreviewedAlerts = patients.filter((patient) => viewState.matches(patient)).length > 0

  return {
    label: name,
    tooltip: description,
    queryString,
    count,
    alertLevel: hasUnreviewedAlerts ? AlertLevel.high : AlertLevel.default,
  }
}
