import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'antd'

import { useUserStore } from '../../../stores/user'
import { Card, SubTitle } from '../styles'
import { ChangePassword } from './ChangePassword'
import { DisableTwoFactor } from './DisableTwoFactor'
import { EnableTwoFactor } from './EnableTwoFactor'

export function Account(): JSX.Element {
  const { t } = useTranslation()
  const [userId, firstName, lastName, credentials, email, hasTwoFactorEnabled] = useUserStore((state) => [
    state.userId,
    state.firstName,
    state.lastName,
    state.credentials,
    state.email,
    state.hasTwoFactorEnabled,
  ])
  const [visibleModal, setModalVisibility] = useState<
    'change-password' | 'enable-two-factor' | 'disable-two-factor' | undefined
  >()

  function handleModalClose(): void {
    setModalVisibility(undefined)
  }

  return (
    <Card title={t('widgets.account.title')}>
      {!!userId && (
        <>
          <SubTitle>{t('widgets.account.info')}</SubTitle>
          <Typography.Text>{`${firstName} ${lastName}`}</Typography.Text>
          {credentials && <Typography.Text>{` ${credentials}`}</Typography.Text>}
          <br />
          <Typography.Text>{email}</Typography.Text>
          <SubTitle>{t('widgets.account.password')}</SubTitle>
          <Typography.Link onClick={() => setModalVisibility('change-password')}>
            {t('widgets.account.changePassword')}
          </Typography.Link>
          <SubTitle>{t('widgets.account.twoFactor.title')}</SubTitle>
          {hasTwoFactorEnabled ? (
            <Typography.Link onClick={() => setModalVisibility('disable-two-factor')}>
              {t('widgets.account.twoFactor.disable.title')}
            </Typography.Link>
          ) : (
            <Typography.Link onClick={() => setModalVisibility('enable-two-factor')}>
              {t('widgets.account.twoFactor.enable.title')}
            </Typography.Link>
          )}
        </>
      )}
      <ChangePassword isVisible={visibleModal === 'change-password'} onCancel={handleModalClose} />
      <EnableTwoFactor isVisible={visibleModal === 'enable-two-factor'} onCancel={handleModalClose} />
      <DisableTwoFactor isVisible={visibleModal === 'disable-two-factor'} onCancel={handleModalClose} />
    </Card>
  )
}
