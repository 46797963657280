import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { CareTaskTemplate as ICareTaskTemplate } from '@vetahealth/tuna-can-api'
import { Button, Input, InputNumber, Modal, Popconfirm, Select, Typography } from 'antd'
import { isEqual, isNumber, omit } from 'lodash-es'
import React, { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLoading } from '../../lib/hooks/useLoading'
import { convertEmptyStringToUndefined, trimDeep } from '../../lib/normalizers'
import { useSitesStore } from '../../stores/sites'
import { WizardSection } from '../Wizard'
import { CareTaskTemplate } from './CareTaskTemplate'

type CareTaskState = {
  isOpen: boolean
  careTask?: ICareTaskTemplate & { id: number }
}
const initialState: CareTaskState = {
  isOpen: false,
  careTask: undefined,
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
`
const CareTaskWrapper = styled.div`
  margin-bottom: 20px;
`
const ScheduleWrapper = styled.div`
  display: flex;
  gap: 5px;
`

type CareTaskTemplateWizardProps = {
  editCareTask?: ICareTaskTemplate & { id: number }
  onCreate?: (careTaskTemplate: ICareTaskTemplate) => Promise<boolean>
  onUpdate?: (index: number, updatedCareTaskTemplate: ICareTaskTemplate) => Promise<boolean>
  onDelete?: (index: number) => Promise<boolean>
  render: (openCareTaskTemplateWizard: () => void) => React.ReactNode
}

const defaultCareTaskTemplate: ICareTaskTemplate & { id: number } = {
  datoId: '',
  id: 0,
  repeatEveryDays: 0,
  repeatTimes: 0,
  stateOffsetDays: 0,
}

export function CareTaskTemplateWizard({
  editCareTask,
  onCreate = () => Promise.resolve(false),
  onUpdate = () => Promise.resolve(false),
  onDelete = () => Promise.resolve(false),
  render,
}: CareTaskTemplateWizardProps): JSX.Element | null {
  const { t } = useTranslation()
  const isEditable = isDefined(editCareTask)
  const [isLoading, withLoading] = useLoading()
  const [availableCareTasks] = useSitesStore((state) => [state.availableCareTasks])

  const [{ isOpen, careTask }, set] = useReducer(
    (state: CareTaskState, update: Partial<CareTaskState>) => ({ ...state, ...update }),
    initialState,
  )

  function handleOpen(): void {
    set({
      isOpen: true,
      careTask: editCareTask,
    })
  }

  function handleClose(): void {
    set({ isOpen: false, careTask: undefined })
  }

  const handleSubmit = useCallback(async () => {
    if (!careTask) return
    const normalizedTemplate = convertEmptyStringToUndefined(trimDeep(omit(careTask, 'id'))) as ICareTaskTemplate
    const success = isEditable
      ? await withLoading(onUpdate(careTask.id, normalizedTemplate))
      : await onCreate(normalizedTemplate)

    if (success) {
      handleClose()
    }
  }, [careTask])

  const handleDelete = useCallback(async () => {
    if (careTask && isEditable) {
      const success = await onDelete(careTask.id)

      if (success) {
        handleClose()
      }
    }
  }, [careTask])

  function handleCareTaskUpdate(update: Partial<ICareTaskTemplate>): void {
    set({ careTask: { ...(careTask ?? defaultCareTaskTemplate), ...update } })
  }

  return (
    <>
      {render(handleOpen)}
      <Modal
        title={isEditable ? t('careTask.wizard.update') : t('careTask.wizard.create')}
        open={isOpen}
        style={{ maxWidth: '800px' }}
        width="66%"
        onCancel={() => set({ isOpen: false, careTask: undefined })}
        destroyOnClose
        centered
        footer={
          <>
            {isEditable && (
              <Popconfirm
                title={t('toDos.wizard.confirmDelete')}
                icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
                cancelButtonProps={{ type: 'text' }}
                cancelText={t('actions.cancel')}
                okButtonProps={{ danger: true }}
                okText={t('actions.delete')}
                onConfirm={handleDelete}
              >
                <Button danger>{t('actions.delete')}</Button>
              </Popconfirm>
            )}
            <Button
              disabled={isLoading || !careTask?.datoId || isEqual(careTask, editCareTask)}
              loading={isLoading}
              onClick={handleSubmit}
              type="primary"
            >
              {t('actions.save')}
            </Button>
          </>
        }
      >
        {careTask?.datoId && (
          <CareTaskWrapper>
            <CareTaskTemplate careTaskTemplate={careTask} />
          </CareTaskWrapper>
        )}
        <Wrapper>
          <WizardSection question={t('careTask.wizard.question.content')}>
            <Select
              placeholder={t('careTask.wizard.question.content')}
              showSearch
              value={careTask?.datoId}
              onSelect={(value) => handleCareTaskUpdate({ datoId: value })}
              options={availableCareTasks?.map((task) => ({
                label: task.name,
                value: task.id,
              }))}
              optionFilterProp="label"
              popupMatchSelectWidth={false}
            />
            {careTask?.datoId && <Input value={careTask.datoId} disabled />}
          </WizardSection>
          <WizardSection
            question={t('toDos.wizard.question.schedule')}
            explanations={[
              {
                title: t('careTask.wizard.explanations.stateOffsetDays.title'),
                description: t('careTask.wizard.explanations.stateOffsetDays.description'),
              },
              {
                title: t('careTask.wizard.explanations.repeatEveryDays.title'),
                description: t('careTask.wizard.explanations.repeatEveryDays.description'),
              },
              {
                title: t('careTask.wizard.explanations.repeatTimes.title'),
                description: t('careTask.wizard.explanations.repeatTimes.description'),
              },
            ]}
          >
            <ScheduleWrapper>
              <InputNumber
                min={0}
                precision={0}
                formatter={(i) => (i ? Math.round(i).toString() : '0')}
                value={careTask?.stateOffsetDays}
                disabled={!careTask}
                onChange={(stateOffsetDays) => isNumber(stateOffsetDays) && handleCareTaskUpdate({ stateOffsetDays })}
                addonBefore={t('careTask.wizard.stateOffset')}
              />
              <InputNumber
                min={0}
                precision={0}
                formatter={(i) => (i ? Math.round(i).toString() : '0')}
                value={careTask?.repeatEveryDays}
                disabled={!careTask}
                onChange={(repeatEveryDays) =>
                  isNumber(repeatEveryDays) &&
                  handleCareTaskUpdate({
                    repeatEveryDays,
                    ...(repeatEveryDays === 0 && { repeatTimes: 0 }),
                  })
                }
                addonBefore={t('careTask.wizard.repeatEveryDays')}
              />
              <InputNumber
                min={0}
                precision={0}
                formatter={(i) => (i ? Math.round(i).toString() : '0')}
                disabled={!careTask || careTask?.repeatEveryDays === 0}
                value={careTask?.repeatTimes}
                onChange={(repeatTimes) => isNumber(repeatTimes) && handleCareTaskUpdate({ repeatTimes })}
                addonBefore={
                  <Typography.Text disabled={!careTask || careTask?.repeatTimes === 0}>
                    {t('careTask.wizard.repeatTimes')}
                  </Typography.Text>
                }
              />
            </ScheduleWrapper>
          </WizardSection>
        </Wrapper>
      </Modal>
    </>
  )
}
