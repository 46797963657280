import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Avatar, Button, Dropdown, Tooltip } from 'antd'

import { MenuItemGroupType } from 'antd/lib/menu/interface'
import { routes } from '../../Router/routes'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { useAuthStore } from '../../stores/auth'
import { useUserStore } from '../../stores/user'

const Wrapper = styled.div`
  margin: 0 8px;
  cursor: pointer;
`

enum MenuKeys {
  settings = 'settings',
  signOut = 'signOut',
}

export function ProfileMenu(): JSX.Element {
  const handleSignout = useAuthStore((state) => state.handleSignout)
  const { t } = useTranslation()
  const [avatarImageUrl, firstName, lastName] = useUserStore((state) => [
    state.avatarImageUrl,
    state.firstName,
    state.lastName,
  ])
  const navigate = useNavigate()

  const [isVisible, setIsVisible] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  function handleVisibilityChange(visible: boolean): void {
    setIsVisible(visible)
    if (visible) setShowTooltip(false)
  }

  function handleSelection({ key }: { key: string }): void {
    setIsVisible(false)
    switch (key) {
      case MenuKeys.settings:
        trackEvent(TrackingEvent.profileMenuSettingsClicked)
        navigate(routes.settings())
        return
      case MenuKeys.signOut:
        trackEvent(TrackingEvent.profileMenuSignoutClicked)
        handleSignout()
        return
    }
  }

  const menuItems: MenuItemGroupType[] = [
    {
      type: 'group',
      label: `${firstName} ${lastName}`,
      children: [
        { key: MenuKeys.settings, label: t('menus.profile.settings') },
        { key: MenuKeys.signOut, label: t('menus.profile.signOut') },
      ],
    },
  ]

  return (
    <Wrapper onMouseEnter={() => setShowTooltip(!isVisible)} onMouseLeave={() => setShowTooltip(false)}>
      <Dropdown
        menu={{ items: menuItems, onClick: handleSelection }}
        onOpenChange={handleVisibilityChange}
        trigger={['click']}
        placement="bottomRight"
      >
        <Tooltip title={t('menus.profile.tooltip')} open={showTooltip} placement="bottomLeft">
          <Button
            shape="circle"
            type="text"
            onClick={() => trackEvent(TrackingEvent.profileMenuClicked)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {avatarImageUrl ? (
              <Avatar src={avatarImageUrl} size={32} style={{ padding: 0 }} />
            ) : (
              <Avatar style={{ color: Colors.white, backgroundColor: Colors.mauve800, padding: 0 }} size={32}>
                {firstName?.[0]}
                {lastName?.[0]}
              </Avatar>
            )}
          </Button>
        </Tooltip>
      </Dropdown>
    </Wrapper>
  )
}
