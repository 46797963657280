import { parsePhoneNumber } from 'libphonenumber-js'
import { useState } from 'react'

import { ArchiveSearchResult, PatientOverviewItem, StatusEnum } from '@vetahealth/tuna-can-api'

import { usePatientsStore } from '../../stores/patients'
import { API } from '../api'
import { TrackingEvent, trackEvent } from '../tracking'
import { useDebounce } from './useDebounce'

interface UsePatientSelectionProps {
  search: string
}

export type PatientSearchResult = PatientOverviewItem | (ArchiveSearchResult & { status: StatusEnum })

const searchProperties: Array<keyof PatientOverviewItem> = [
  'firstName',
  'lastName',
  'dateOfBirth',
  'clientIdentifier',
  'mobilePhone',
  'landlinePhone',
  'secondaryMobilePhone',
  'secondaryLandlinePhone',
]

function matchPattern(patient: Partial<PatientOverviewItem>, searchTerm: string): boolean {
  // first name prefix match
  if (patient.firstName?.startsWith(searchTerm)) return true
  // last name prefix match
  if (patient.lastName?.startsWith(searchTerm)) return true
  // birth year full match
  if (patient.dateOfBirth?.split('-')[0].toLowerCase() === searchTerm) return true
  // MRN full match
  if (patient.clientIdentifier === searchTerm) return true
  // phone full (international) match and national match (7-digit number) and local match (4-digit-number)
  if (
    patient.mobilePhone === searchTerm ||
    patient.mobilePhone?.slice(-7) === searchTerm ||
    patient.mobilePhone?.slice(-4) === searchTerm
  ) {
    return true
  }
  if (
    patient.landlinePhone === searchTerm ||
    patient.landlinePhone?.slice(-7) === searchTerm ||
    patient.landlinePhone?.slice(-4) === searchTerm
  ) {
    return true
  }
  if (
    patient.secondaryMobilePhone === searchTerm ||
    patient.secondaryMobilePhone?.slice(-7) === searchTerm ||
    patient.secondaryMobilePhone?.slice(-7) === searchTerm
  ) {
    return true
  }
  return (
    patient.secondaryLandlinePhone === searchTerm ||
    patient.secondaryLandlinePhone?.slice(-7) === searchTerm ||
    patient.secondaryLandlinePhone?.slice(-4) === searchTerm
  )
}

export function prepareQuery(searchValue: string): string[] {
  const resultQueries: string[] = []
  const parts = searchValue.toLowerCase().split(/\s/)
  let currentTerm = ''
  for (const part of parts) {
    if (!part.trim()) continue // ignore empty (whitespace only) parts
    const isAlphaNum = part.match(/[A-Za-z]/)
    if (isAlphaNum) {
      // not a number, finish previous merge and push unchanged
      if (currentTerm) {
        resultQueries.push(currentTerm)
        currentTerm = ''
      }
      resultQueries.push(part.trim())
      continue
    }
    // merge with consecutive numbers (if any)
    currentTerm += part.trim()
  }
  if (currentTerm) resultQueries.push(currentTerm)
  return resultQueries.map((query) => {
    if (/[A-Za-z]/.test(query)) return query // alphanumeric => not a phone number, don't parse
    try {
      const phone = parsePhoneNumber(query, { defaultCountry: 'US' })
      // if we cannot detect a valid phone, we remove all non-digits except a leading +, so full international number
      // search will still work, even if the number is detected as invalid
      return phone.isValid() ? phone.format('E.164') : query.replace(/[^0-9+]|(?<!^)\+/g, '')
    } catch (_e) {
      return query.replace(/[^0-9+]|(?<!^)\+/g, '')
    }
  })
}

export function searchPatients(patients: Array<PatientOverviewItem>, searchValue: string): Array<PatientOverviewItem> {
  const searchValues = prepareQuery(searchValue)

  return patients.filter((patient) => {
    const patientValuesLowercase: Partial<PatientOverviewItem> = Object.fromEntries(
      Object.entries(patient)
        .filter(([key, value]) => typeof value === 'string' && (searchProperties as Array<string>).includes(key))
        .map(([key, value]) => [key, value.toLowerCase()]),
    )
    return searchValues.every((splitSearchValue) => matchPattern(patientValuesLowercase, splitSearchValue))
  })
}

export function usePatientSearch({ search }: UsePatientSelectionProps): Array<PatientSearchResult> {
  const [patients, getPatients] = usePatientsStore((state) => [state.patients, state.getPatients])

  const [localResults, setLocalResults] = useState<PatientOverviewItem[]>([])
  const [archivedResults, setArchivedResults] = useState<Array<ArchiveSearchResult & { status: StatusEnum }>>([])

  void getPatients()

  useDebounce(
    () => {
      if (search) trackEvent(TrackingEvent.patientSearchEntered, { query: search })
    },
    500,
    [search],
  )

  useDebounce(
    () => {
      if (search.trim().length > 2) {
        const searchResult = searchPatients(patients, search)
        if (searchResult) setLocalResults(searchResult)
      } else {
        setLocalResults([])
      }
    },
    100,
    [search, patients],
  )

  useDebounce(
    () => {
      if (search.trim().length > 2) {
        ;(async () => {
          const searchResult = await API.quickSearchArchive(prepareQuery(search).join(' '))
          const archivedResults = searchResult
            ? searchResult.map((result) => ({ ...result, status: StatusEnum.Archived }))
            : []
          if (archivedResults) setArchivedResults(archivedResults)
        })()
      } else {
        setArchivedResults([])
      }
    },
    300,
    [search],
  )

  return [...localResults, ...archivedResults]
}
