import { Form, Input, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { countryNames } from '../../lib/countryNames'
import { timeZoneNames } from '../../lib/timeZoneNames'
import { TwoColumns } from './styles'
import { FormKeys } from './types'

type CountryOption = { value: string; label: string }
type TimeZoneOption = { value: string }

const countries = countryNames.map(({ iso, name }) => ({ value: iso, label: name }))
function countryFilter(inputValue: string, option: CountryOption): boolean {
  return option.label.toLowerCase().includes(inputValue.toLowerCase())
}

const timeZones: TimeZoneOption[] = timeZoneNames.map((value) => ({ value }))
function timeZoneFilter(inputValue: string, option: TimeZoneOption): boolean {
  return option.value.toLowerCase().includes(inputValue.toLowerCase())
}

export function Address(): JSX.Element {
  const { t } = useTranslation()

  return (
    <TwoColumns>
      <Form.Item style={{ width: '100%', gridRow: 'span 2' }} name={FormKeys.ADDRESS} label={t('form.address')}>
        <Input.TextArea placeholder={t('placeholders.address')} rows={5} style={{ resize: 'none' }} />
      </Form.Item>
      <Form.Item style={{ width: '100%' }} name={FormKeys.CITY} label={t('form.city')}>
        <Input placeholder={t('placeholders.city')} />
      </Form.Item>
      <Form.Item style={{ width: '100%' }} name={FormKeys.POSTAL_CODE} label={t('form.postalCode')}>
        <Input placeholder={t('placeholders.postalCode')} />
      </Form.Item>
      <Form.Item style={{ width: '100%' }} name={FormKeys.STATE} label={t('form.state')}>
        <Input placeholder={t('placeholders.state')} />
      </Form.Item>
      <Form.Item style={{ width: '100%' }} name={FormKeys.COUNTRY} label={t('form.country')}>
        <Select showSearch placeholder={t('placeholders.country')} options={countries} filterOption={countryFilter} />
      </Form.Item>
      <Form.Item name={FormKeys.TIME_ZONE} label={t('form.timezone')}>
        <Select showSearch options={timeZones} placeholder={t('placeholders.timezone')} filterOption={timeZoneFilter} />
      </Form.Item>
    </TwoColumns>
  )
}
