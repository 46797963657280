import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { ReminderTypeEnum, TaskTypeEnum, ToDoTemplate, TrackingTypeEnum } from '@vetahealth/tuna-can-api'
import {
  Button,
  Checkbox,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Tag,
  TimePicker,
  Typography,
  message,
} from 'antd'
import dayjs from 'dayjs'
import { isEqual, isNumber, omit } from 'lodash-es'
import React, { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { useLoading } from '../../../lib/hooks/useLoading'
import { convertEmptyStringToUndefined, trimDeep } from '../../../lib/normalizers'
import { getTrackingTitle } from '../../Widgets/helpers'
import { WizardSection } from '../../Wizard'
import { StylelessButton } from '../../styles'
import { ToDo } from '../ToDo'
import { getDefaultToDoTemplate, getTaskTypeTitle } from '../helpers'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
`
const TrackingTypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`
const ScheduleWrapper = styled.div`
  display: flex;
  gap: 5px;
`
const ToDoWrapper = styled.div`
  margin-bottom: 20px;
`

type ToDoTemplateWizardProps = {
  editToDoTemplate?: ToDoTemplate & { id: number }
  onCreate?: (toDoTemplate: ToDoTemplate) => Promise<boolean>
  onUpdate?: (index: number, updatedToDoTemplate: ToDoTemplate) => Promise<boolean>
  onDelete?: (index: number) => Promise<boolean>
  render: (openToDoTemplateWizard: () => void) => React.ReactNode
}

type ToDoTemplateWizardState = {
  toDoTemplate?: ToDoTemplate
  isVisible: boolean
  taskSelectionMode: 'automatic' | 'manual'
}

const initialState: ToDoTemplateWizardState = {
  toDoTemplate: undefined,
  isVisible: false,
  taskSelectionMode: 'automatic',
}

export function ToDoTemplateWizard({
  editToDoTemplate,
  onCreate = () => Promise.resolve(false),
  onUpdate = () => Promise.resolve(false),
  onDelete = () => Promise.resolve(false),
  render,
}: ToDoTemplateWizardProps): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation()
  const isEditable = isDefined(editToDoTemplate)
  const [isLoading, withLoading] = useLoading()
  const [{ isVisible, toDoTemplate, taskSelectionMode }, set] = useReducer(
    (state: ToDoTemplateWizardState, update: Partial<ToDoTemplateWizardState>) => ({ ...state, ...update }),
    initialState,
  )
  const isTaskWithoutDatoId =
    toDoTemplate?.reminderType === 'taskReminder' && taskSelectionMode === 'manual' && !toDoTemplate.datoId

  function handleClose(): void {
    set({ isVisible: false, toDoTemplate: undefined })
  }

  function handleOpen(): void {
    set({
      isVisible: true,
      toDoTemplate: editToDoTemplate,
      taskSelectionMode: editToDoTemplate?.datoId ? 'manual' : 'automatic',
    })
  }

  function handleReminderType(reminderType: ToDoTemplate['reminderType']): void {
    set({ toDoTemplate: getDefaultToDoTemplate(reminderType) })
  }

  function handleToDoTemplateUpdate(update: Partial<ToDoTemplate>): void {
    if (toDoTemplate) {
      set({ toDoTemplate: { ...toDoTemplate, ...update } })
    }
  }

  function handleTrackingType(trackingType: TrackingTypeEnum): void {
    let measurementTypes = toDoTemplate?.measurementTypes || []

    if (measurementTypes.includes(trackingType)) {
      measurementTypes = measurementTypes.filter((type) => type !== trackingType)
    } else {
      measurementTypes = [...measurementTypes, trackingType]
    }

    handleToDoTemplateUpdate({ measurementTypes })
  }

  const handleSubmit = useCallback(async () => {
    if (toDoTemplate) {
      const normalizedTemplate = convertEmptyStringToUndefined(trimDeep(omit(toDoTemplate, 'id'))) as ToDoTemplate
      const success = isEditable
        ? await withLoading(onUpdate(editToDoTemplate.id, normalizedTemplate))
        : await onCreate(normalizedTemplate)

      if (success) {
        handleClose()
        message.success(isEditable ? t('message.taskUpdated') : t('message.taskCreated'))
      }
    }
  }, [toDoTemplate])

  const handleDelete = useCallback(async () => {
    if (toDoTemplate && isEditable) {
      const success = await onDelete(editToDoTemplate.id)

      if (success) {
        handleClose()
        message.success(t('message.taskDeleted'))
      }
    }
  }, [toDoTemplate])

  return (
    <>
      {render(handleOpen)}
      <Modal
        title={isEditable ? t('toDos.wizard.update') : t('toDos.wizard.create')}
        open={isVisible}
        style={{ maxWidth: '800px' }}
        width="66%"
        onCancel={handleClose}
        centered
        footer={
          <>
            {isEditable && (
              <Popconfirm
                title={t('toDos.wizard.confirmDelete')}
                icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
                cancelButtonProps={{ type: 'text' }}
                cancelText={t('actions.cancel')}
                okButtonProps={{ danger: true }}
                okText={t('actions.delete')}
                onConfirm={handleDelete}
              >
                <Button danger>{t('actions.delete')}</Button>
              </Popconfirm>
            )}
            <Button
              disabled={isLoading || !toDoTemplate || isEqual(toDoTemplate, editToDoTemplate) || isTaskWithoutDatoId}
              loading={isLoading}
              onClick={handleSubmit}
              type="primary"
            >
              {t('actions.save')}
            </Button>
          </>
        }
      >
        {toDoTemplate && (
          <ToDoWrapper>
            <ToDo toDo={toDoTemplate} />
          </ToDoWrapper>
        )}
        <Wrapper>
          <WizardSection question={t('toDos.wizard.question.reminderType')}>
            <Radio.Group
              options={[
                {
                  value: ReminderTypeEnum.MeasurementReminder,
                  label: t('toDos.wizard.measurement'),
                },
                { value: ReminderTypeEnum.TaskReminder, label: t('toDos.wizard.content') },
              ]}
              value={toDoTemplate?.reminderType}
              onChange={({ target }) => target.value && handleReminderType(target.value)}
              optionType="button"
              buttonStyle="solid"
            />
          </WizardSection>
          {toDoTemplate?.reminderType && (
            <Wrapper key={toDoTemplate.reminderType}>
              {toDoTemplate.reminderType === 'measurementReminder' ? (
                <WizardSection
                  question={t('toDos.wizard.question.trackingType')}
                  explanations={[
                    { title: t('toDos.wizard.measurement'), description: t('toDos.wizard.explanations.measurement') },
                    {
                      title: t('toDos.wizard.requiresDevice'),
                      description: t('toDos.wizard.explanations.requiresDevice'),
                    },
                  ]}
                >
                  <div>
                    <TrackingTypeWrapper>
                      {Object.values(TrackingTypeEnum).map((trackingType) => (
                        <StylelessButton key={trackingType} onClick={() => handleTrackingType(trackingType)}>
                          <Tag
                            color={toDoTemplate.measurementTypes?.includes(trackingType) ? theme.primary : 'default'}
                            style={{ pointerEvents: 'none', margin: 0 }}
                          >
                            {getTrackingTitle(trackingType)}
                          </Tag>
                        </StylelessButton>
                      ))}
                    </TrackingTypeWrapper>
                    <Checkbox
                      checked={toDoTemplate.requiresDevice}
                      onChange={({ target }) => handleToDoTemplateUpdate({ requiresDevice: target.checked })}
                      style={{ marginTop: '15px' }}
                    >
                      {t('toDos.wizard.requiresDevice')}
                    </Checkbox>
                  </div>
                </WizardSection>
              ) : (
                <WizardSection
                  question={t('toDos.wizard.question.taskReminder')}
                  explanations={[
                    { title: t('toDos.wizard.automatic'), description: t('toDos.wizard.explanations.automatic') },
                    { title: t('toDos.wizard.manual'), description: t('toDos.wizard.explanations.manual') },
                  ]}
                >
                  <Radio.Group
                    options={[
                      { label: t('toDos.wizard.automatic'), value: 'automatic' },
                      { label: t('toDos.wizard.manual'), value: 'manual' },
                    ]}
                    onChange={({ target }) => target?.value && set({ taskSelectionMode: target.value })}
                    value={taskSelectionMode}
                    optionType="button"
                    buttonStyle="solid"
                  />
                  {taskSelectionMode === 'automatic' ? (
                    <Select
                      value={toDoTemplate.taskTypes?.length ? toDoTemplate.taskTypes[0] : 'all'}
                      onChange={(taskType: TaskTypeEnum | 'all') =>
                        handleToDoTemplateUpdate({ taskTypes: taskType === 'all' ? [] : [taskType] })
                      }
                      options={[
                        { label: t('toDos.wizard.all'), value: 'all' },
                        ...Object.values(TaskTypeEnum).map((taskType) => ({
                          label: getTaskTypeTitle(taskType),
                          value: taskType,
                        })),
                      ]}
                      popupMatchSelectWidth={false}
                    />
                  ) : (
                    <Space.Compact>
                      <Input
                        placeholder={t('toDos.wizard.datoId')}
                        value={toDoTemplate.datoId}
                        onChange={({ target }) =>
                          isDefined(target.value) && handleToDoTemplateUpdate({ datoId: target.value })
                        }
                      />
                      <Input
                        placeholder={t('toDos.wizard.name')}
                        value={toDoTemplate.name}
                        onChange={({ target }) =>
                          isDefined(target.value) && handleToDoTemplateUpdate({ name: target.value })
                        }
                      />
                    </Space.Compact>
                  )}
                </WizardSection>
              )}
              <WizardSection
                question={t('toDos.wizard.question.schedule')}
                explanations={[
                  { title: t('toDos.wizard.hourOfDay'), description: t('toDos.wizard.explanations.hourOfDay') },
                  {
                    title: t('toDos.wizard.explanations.referenceDateOffsetDays.title'),
                    description: t('toDos.wizard.explanations.referenceDateOffsetDays.description'),
                  },
                  {
                    title: t('toDos.wizard.explanations.repeatAfterDays.title'),
                    description: t('toDos.wizard.explanations.repeatAfterDays.description'),
                  },
                  {
                    title: t('toDos.wizard.explanations.repeatDurationDays.title'),
                    description: t('toDos.wizard.explanations.repeatDurationDays.description'),
                  },
                  {
                    title: t('toDos.wizard.isMuted'),
                    description: t('toDos.wizard.explanations.isMuted'),
                  },
                ]}
              >
                <div>
                  <ScheduleWrapper>
                    <TimePicker
                      inputReadOnly
                      format={t('dateFormats.timeOnlyHour')}
                      showNow={false}
                      allowClear={false}
                      value={dayjs().set('hour', toDoTemplate.hourOfDay).set('minute', 0)}
                      onOk={(date) => handleToDoTemplateUpdate({ hourOfDay: Number(date.format('H')) })}
                    />
                    <InputNumber
                      min={1}
                      precision={0}
                      formatter={(i) => (i ? Math.round(i).toString() : '0')}
                      value={toDoTemplate.referenceDateOffsetDays + 1}
                      onChange={(referenceDateOffsetDays) =>
                        isNumber(referenceDateOffsetDays) &&
                        handleToDoTemplateUpdate({ referenceDateOffsetDays: referenceDateOffsetDays - 1 })
                      }
                      addonBefore={t('toDos.wizard.referenceDateOffsetDays')}
                    />
                    <InputNumber
                      min={0}
                      precision={0}
                      formatter={(i) => (i ? Math.round(i).toString() : '0')}
                      value={toDoTemplate.repeatAfterDays}
                      onChange={(repeatAfterDays) =>
                        isNumber(repeatAfterDays) &&
                        handleToDoTemplateUpdate({
                          repeatAfterDays,
                          ...(repeatAfterDays === 0 && { repeatDurationDays: 0 }),
                        })
                      }
                      addonBefore={t('toDos.wizard.repeatAfterDays')}
                    />
                    <InputNumber
                      min={0}
                      precision={0}
                      formatter={(i) => (i ? Math.round(i).toString() : '0')}
                      disabled={toDoTemplate.repeatAfterDays === 0}
                      value={toDoTemplate.repeatDurationDays}
                      onChange={(repeatDurationDays) =>
                        isNumber(repeatDurationDays) && handleToDoTemplateUpdate({ repeatDurationDays })
                      }
                      addonBefore={
                        <Typography.Text disabled={toDoTemplate.repeatAfterDays === 0}>
                          {t('toDos.wizard.repeatDurationDays')}
                        </Typography.Text>
                      }
                    />
                  </ScheduleWrapper>
                  <Checkbox
                    checked={!toDoTemplate.isMuted}
                    onChange={({ target }) => handleToDoTemplateUpdate({ isMuted: !target.checked })}
                    style={{ marginTop: '15px' }}
                  >
                    {t('toDos.wizard.isMuted')}
                  </Checkbox>
                </div>
              </WizardSection>

              <WizardSection
                question={t('toDos.wizard.question.fulfillment')}
                explanations={[
                  {
                    title: t('toDos.wizard.fulfillment'),
                    description: t('toDos.wizard.explanations.fulfillment'),
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  precision={0}
                  formatter={(i) => (i ? Math.round(i).toString() : '0')}
                  addonBefore={t('toDos.wizard.fulfillmentHours')}
                  value={toDoTemplate.fulfillmentHours}
                  onChange={(fulfillmentHours) =>
                    isNumber(fulfillmentHours) && handleToDoTemplateUpdate({ fulfillmentHours })
                  }
                />
                <InputNumber
                  min={0}
                  precision={0}
                  formatter={(i) => (i ? Math.round(i).toString() : '0')}
                  addonBefore={t('toDos.wizard.overdueHours')}
                  value={toDoTemplate.overdueHours}
                  onChange={(overdueHours) => isNumber(overdueHours) && handleToDoTemplateUpdate({ overdueHours })}
                />
              </WizardSection>
            </Wrapper>
          )}
        </Wrapper>
      </Modal>
    </>
  )
}
