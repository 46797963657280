export function debouncePromise<F extends (...args: any[]) => Promise<any>>(
  func: F,
  delay = 0,
): (...args: Parameters<F>) => ReturnType<F> {
  let timeoutId: NodeJS.Timeout
  const pending: Array<{ resolve: (t: ReturnType<F>) => void; reject: (err: unknown) => void }> = []
  return (...args: Parameters<F>) =>
    new Promise((resolve, reject) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        const currentPending = [...pending]
        pending.length = 0
        Promise.resolve(func.apply(this, args)).then(
          (data: any) => {
            currentPending.forEach(({ resolve }) => {
              resolve(data)
            })
          },
          (error) => {
            currentPending.forEach(({ reject }) => {
              reject(error)
            })
          },
        )
      }, delay)
      pending.push({ resolve, reject })
    }) as ReturnType<F>
}
