import { CareTask, CareTaskTemplate } from '@vetahealth/tuna-can-api'
import i18next from 'i18next'

export function isCareTask(careTask: CareTask | CareTaskTemplate): careTask is CareTask {
  return 'createdAt' in careTask
}

export function isCareTaskTemplateWitId(
  careTask: CareTask | (CareTaskTemplate & { id: number }),
): careTask is CareTaskTemplate & { id: number } {
  return 'datoId' in careTask
}

export function getStartValue(careTask: CareTaskTemplate): number {
  const startValue = careTask.stateOffsetDays
  // Count from day 1 on positive start date and use 'Starts on the x day' semantic
  return startValue >= 0 ? startValue + 1 : startValue
}

export function getStartTooltip(careTask: CareTaskTemplate): string {
  const startValue = getStartValue(careTask)

  if (startValue < 0) {
    return i18next.t('widgets.programs.startBefore', {
      count: startValue * -1,
      ordinal: false,
      date: undefined,
    })
  }

  return i18next.t('widgets.programs.startOn', {
    count: startValue,
    ordinal: true,
    date: undefined,
  })
}

export function getRepeatValue(careTask: CareTaskTemplate): string {
  return careTask.repeatEveryDays === 0 ? '–' : String(careTask.repeatEveryDays)
}

export function getRepeatTooltip(careTask: CareTaskTemplate): string {
  return careTask.repeatEveryDays === 0
    ? i18next.t('widgets.programs.noRepeat')
    : i18next.t('widgets.programs.repeat', { count: careTask.repeatEveryDays })
}

export function getRepeatTimesValue(careTask: CareTaskTemplate): string {
  if (careTask.repeatTimes === 0 && careTask.repeatEveryDays === 0) return '–'
  if (careTask.repeatTimes) return String(careTask.repeatTimes)
  return '∞'
}

export function getRepeatTimesTooltip(careTask: CareTaskTemplate): string {
  if (careTask.repeatTimes === 0 && careTask.repeatEveryDays === 0) return i18next.t('widgets.programs.noRepeatTimes')
  if (careTask.repeatTimes) return i18next.t('widgets.programs.repeatTimes', { count: careTask.repeatTimes })
  return i18next.t('widgets.programs.repeatTimesInfinity')
}
