import React from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Input } from 'antd'

import { TwoColumns } from './styles'
import { FormKeys } from './types'

export function Insurance(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <TwoColumns>
        <Form.Item
          name={['billingInfo', FormKeys.PRIMARY_INSURANCE]}
          label={t('form.primaryInsurance')}
          rules={[{ type: 'string', min: 1 }]}
        >
          <Input placeholder={t('placeholders.primaryInsurance')} />
        </Form.Item>
        <Form.Item
          name={['billingInfo', FormKeys.SECONDARY_INSURANCE]}
          label={t('form.secondaryInsurance')}
          rules={[{ type: 'string', min: 1 }]}
        >
          <Input placeholder={t('placeholders.secondaryInsurance')} />
        </Form.Item>
        <Form.Item
          name={['billingInfo', FormKeys.SOCIAL_SECURITY_NUMBER]}
          label={t('form.socialSecurityNumber')}
          rules={[{ type: 'string', min: 1 }]}
        >
          <Input placeholder={t('placeholders.socialSecurityNumber')} />
        </Form.Item>
      </TwoColumns>
    </>
  )
}
