import { QuestionAsset } from '@vetahealth/tuna-can-api'
import { Button, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 2px;
`
const HintWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export function Scale({ asset }: { asset: QuestionAsset & { response?: string[] } }): React.ReactNode {
  const firstHint = asset.answerOptions[0]?.hint ?? ''
  const lastHint = asset.answerOptions[asset.answerOptions.length - 1]?.hint ?? ''

  return (
    <Wrapper>
      <ButtonWrapper>
        {asset.answerOptions.map((answerOption) => (
          <Button
            type={asset.response?.includes(answerOption.id) ? 'primary' : 'default'}
            size="small"
            key={answerOption.id}
            style={{ flexGrow: 1 }}
          >
            {answerOption.label}
          </Button>
        ))}
      </ButtonWrapper>
      <HintWrapper>
        {Boolean(firstHint ?? lastHint) && (
          <>
            <Typography.Text style={{ fontSize: 11 }}>{firstHint}</Typography.Text>
            <Typography.Text style={{ fontSize: 11 }}>{lastHint}</Typography.Text>
          </>
        )}
      </HintWrapper>
    </Wrapper>
  )
}
