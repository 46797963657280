import { getDisplayValue } from '@vetahealth/fishing-gear/conversions'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { SimpleTrackingEvent, TrackingTypeEnum } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import { groupBy, orderBy, sortBy } from 'lodash-es'
import { getTrackingTitle } from '../helpers'

export function getInsertedMeasurements(
  trackingEvents: SimpleTrackingEvent[],
  preferredUnitsByType: Record<TrackingTypeEnum, string>,
) {
  const groupedTrackingEvents = groupBy(trackingEvents, 'type')
  return sortBy(Object.entries(groupedTrackingEvents), '0')
    .map(([trackingType, events]: [TrackingTypeEnum, SimpleTrackingEvent[]]) => {
      return `${getTrackingTitle(trackingType)}:\n${orderBy(events, ['timestamp'], ['desc'])
        .map((event) => {
          return `${dayjs(event.timestamp).format('MM/DD h:mm a')}: ${getDisplayValue(
            [event.value, event.additionalValue].filter(isDefined),
            event.unit,
            event.type,
            preferredUnitsByType[event.type],
          )}`
        })
        .join('\n')}\n`
    })
    .join('\n')
}
