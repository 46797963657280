import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import React, { useCallback } from 'react'
import styled from 'styled-components'

const Grid = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  grid-gap: 16px;
  padding-bottom: 16px;
`

export function SortableGrid({
  items,
  render,
  isEditable,
  gridColumns,
  onChange,
}: {
  items: any[]
  render: (item: any, isEditable: boolean, onDelete: () => void) => JSX.Element
  isEditable: boolean
  gridColumns: number
  onChange: (ids: string[]) => void
}): JSX.Element {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (active.id !== over?.id) {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over?.id)

        onChange(arrayMove(items, oldIndex, newIndex).map((item) => item.id))
      }
    },
    [items],
  )

  function handleDelete(key: string) {
    onChange(items.filter(({ id }) => id !== key).map((item) => item.id))
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Grid $columns={gridColumns}>{items.map((item) => render(item, isEditable, () => handleDelete(item.id)))}</Grid>
      </SortableContext>
    </DndContext>
  )
}
