import { Dayjs } from 'dayjs'

import { BillingInfo, CareManager, Note, PatientDetails, ProgramStartType } from '@vetahealth/tuna-can-api'

export enum FormKeys {
  ACTIVE = 'active',
  ADDRESS = 'address',
  ARCHIVE_AFTER_INACTIVE_DAYS = 'archiveAfterInactiveDays',
  CHANNEL = 'channel',
  CITY = 'city',
  CLIENT_ID = 'clientIdentifier',
  CONDITIONS = 'conditions',
  CONSENTED_AT = 'consentedAt',
  CONSENTER = 'consenter',
  CONTACT_INFO = 'contactInformation',
  COUNTRY = 'country',
  CREDENTIALS = 'credentials',
  DATE_OF_BIRTH = 'dateOfBirth',
  DAYS = 'days',
  CARE_STATE_TEMPLATES = 'careStateTemplates',
  DEFAULT_LOCALE = 'defaultLocale',
  DEFAULT_UNITS = 'defaultUnits',
  DELIVERY_DATE = 'deliveryDate',
  DESCRIPTION = 'description',
  DEVICES = 'devices',
  EMAIL = 'email',
  ENABLE_MEASUREMENT_RESPONSES = 'enableMeasurementResponses',
  ENABLE_BILLING = 'enableBilling',
  DEFAULT_TRACKING_TYPES = 'defaultTrackingTypes',
  DEFAULT_CONDITIONS = 'defaultConditions',
  DISPATCH_MESSAGING = 'dispatchMessaging',
  DIAGNOSIS_CODES = 'diagnosisCodes',
  FIRST_NAME = 'firstName',
  FROM = 'from',
  HAS_CUSTOMER_IO = 'hasCustomerIo',
  HAS_DEVICE_ASSIGNING = 'hasDeviceAssigning',
  HAS_DEVICE_ORDERING = 'hasDeviceOrdering',
  HAS_MESSAGING = 'hasMessaging',
  HIGH_ALERT = 'highAlert',
  IS_DEMO = 'isDemo',
  IS_MANAGED_BY_VETA = 'isManagedByVeta',
  KEY = 'key',
  LANDLINE_PHONE = 'landlinePhone',
  LAST_NAME = 'lastName',
  MEDIUM_ALERT = 'mediumAlert',
  MESSAGE = 'message',
  METHOD = 'method',
  MOBILE_PHONE = 'mobilePhone',
  NAME = 'name',
  NICKNAME = 'nickname',
  NOTE_TYPE = 'type',
  NOTES = 'notes',
  ONBOARDING_DATE = 'onboardingDate',
  ORDER_DATE = 'orderDate',
  OUTPUT_FORMAT = 'outputFormat',
  PATIENT_INTERACTION = 'patientInteraction',
  POSTAL_CODE = 'postalCode',
  PRIMARY_INSURANCE = 'primaryInsurance',
  PROGRAM_START = 'programStart',
  PROGRAM_START_TYPE = 'programStartType',
  PROGRAM_TEMPLATE_ID = 'programTemplateId',
  PROVIDER = 'provider',
  RELATIONSHIP = 'relationship',
  REPORT_MONTH = 'reportMonth',
  ROLES = 'roles',
  SEARCH = 'search',
  SECONDARY_INSURANCE = 'secondaryInsurance',
  SOCIAL_SECURITY_NUMBER = 'socialSecurityNumber',
  SECONDARY_LANDLINE_PHONE = 'secondaryLandlinePhone',
  SECONDARY_MOBILE_PHONE = 'secondaryMobilePhone',
  SEX = 'sex',
  SHORT_NAME = 'shortName',
  SITE = 'site',
  STATE = 'state',
  SUPPORTED_LOCALES = 'supportedLocales',
  TAGS = 'tags',
  TEXT = 'text',
  TEXT_ORIGINAL = 'originalText',
  TEXT_ENHANCED = 'enhancedText',
  TIME_SPENT_IN_MIN = 'timeSpentInMin',
  TIME_ZONE = 'timeZone',
  TIMESTAMP = 'timestamp',
  USERNAME = 'username',
  VIEW_PRESET = 'viewPreset',
  WITNESS = 'witness',
}

export type PersonalFormValues = Pick<PatientDetails, 'firstName' | 'lastName' | 'site' | 'clientIdentifier'> & {
  [FormKeys.DATE_OF_BIRTH]: Dayjs
  [FormKeys.PROGRAM_TEMPLATE_ID]: number // FOLLOW-UP: Allow multiple programs
  [FormKeys.PROGRAM_START_TYPE]: ProgramStartType
  [FormKeys.PROGRAM_START]?: Dayjs
}

type BillingFormValues = Omit<BillingInfo, 'orderDate' | 'onboardingDate' | 'deliveryDate'> & {
  orderDate: Dayjs
  onboardingDate: Dayjs
  deliveryDate: Dayjs
}

type InsuranceFormValues = {
  primaryInsurance: string
  secondaryInsurance: string
  socialSecurityNumber: string
}

export type EditFormValues = Omit<PatientDetails, 'dateOfBirth' | 'billingInfo'> & {
  dateOfBirth: Dayjs
  billingInfo: BillingFormValues & InsuranceFormValues
}

export type NoteFormValues = Omit<Note, 'timestamp'> & {
  timestamp: Dayjs
  originalText?: string
  enhancedText?: string
}

export type SupportFormValues = {
  message: string
}

export type UserFormValues = Omit<CareManager, 'id' | 'createdAt' | 'roles'> & {
  viewPreset: number
  roles: Array<{
    roleId: string
    site: string | null
  }>
}

export type SetPasswordFormValues = {
  currentPassword?: string
  password: string
  confirmPassword: string
}

export enum DateRange {
  ONE_WEEK = 'oneWeek',
  ONE_MONTH = 'oneMonth',
  CUSTOM = 'custom',
}

export type SetTimeRangeFormValues = {
  dateRange: DateRange
  from: Dayjs
  until: Dayjs
}
