import React, { useMemo } from 'react'

import { CardDetails } from '@vetahealth/tuna-can-api'

import { Table as AntTable } from 'antd'

import { usePatientStore } from '../../../../../stores/patient'
import { useResultsStore } from '../../../../../stores/results'
import { getTableData } from '../../../../helpers'
import { getResultsConfig } from '../config'
import { createActions, getDefaultTableFields } from './helpers'
import { TableProps } from './types'

export function Table({ type }: TableProps): JSX.Element | null {
  const config = getResultsConfig(type)
  const [patient] = usePatientStore((state) => [state.patient])
  const [allResults] = useResultsStore((state) => [state.allResults])
  const data = (allResults[type] || []) as CardDetails[]

  const tableData = useMemo(() => {
    const fields = getDefaultTableFields({
      format: (_, event) => config.format?.(event, config.unit, true),
      title: `${config.title} ${config.unit ? `[${config.unit.replace('_', ' ')}]` : ''}`,
      timeZone: patient?.timeZone,
      timeFormat: config.table?.timeFormat,
      additionalFields: config.table?.additionalFields,
    })

    const actions = config.table?.actions?.length ? createActions<CardDetails>(config) : undefined

    return getTableData({ data, fields, actions })
  }, [data, config.unit, patient?.timeZone])

  if (!config.table) return null

  return (
    <AntTable
      scroll={{ x: true }}
      key={`table-${type}`}
      pagination={{ showSizeChanger: true }}
      tableLayout="fixed"
      {...tableData}
    />
  )
}
