import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { MutedEnum, StatusEnum } from '@vetahealth/tuna-can-api'

import { Button, Tooltip } from 'antd'

import { usePatientStore } from '../../../../stores/patient'
import { useSitesStore } from '../../../../stores/sites'
import { Alerts, TrackingAlertWizard } from '../../../Alerts'
import { TwoColumns } from '../../../Forms/styles'
import { ToDos } from '../../../ToDos'
import { HeadingFive, Line, Section, SubTitle } from '../../styles'
import { EditProgram } from './EditProgram'
import { PatientState } from './PatientState'
import { Pause } from './Pause'

const ProgramWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
const ProgramDetailsWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const Wrap = styled.div`
  display: flex;
`
const Label = styled.div`
  font-weight: 500;
`
const PauseWrap = styled.div`
  max-width: 60%;
  margin-top: 10px;
`
const Left = styled.div`
  flex: 50%;
  padding-right: 30px;
  border-right: 1px solid ${({ theme }) => theme.badgeBackground};
`
const Right = styled.div`
  flex: 50%;
  padding: 0 20px 0 30px;
`
const HeadingWrap = styled.div`
  display: flex;
  gap: 10px;
`

export function Program(): JSX.Element {
  const { t } = useTranslation()
  const [patient, alertConfigs, getAlertConfigs, createAlertConfig, updateAlertConfig, deleteAlertConfig] =
    usePatientStore((state) => [
      state.patient,
      state.alertConfigs,
      state.getAlertConfigs,
      state.createAlertConfig,
      state.updateAlertConfig,
      state.deleteAlertConfig,
    ])
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [sites] = useSitesStore((state) => [state.sites])

  const program = patient?.program
  const isProgramPaused = patient?.preferences.notifications.muted !== MutedEnum.None
  const isOnHold = program && !isDefined(program.referenceDate)
  const isInactiveOrArchived = patient?.status === StatusEnum.Inactive || patient?.status === StatusEnum.Archived

  const toDoTemplates = useMemo(() => {
    if (!sites || !patient || !program) return []
    const site = sites.find(({ key }) => key === patient.site)
    const programTemplate = site?.programs.find(({ id }) => id === program.templateId)
    return programTemplate?.toDos ?? []
  }, [patient, sites, program])

  useEffect(() => {
    getAlertConfigs()
  }, [])

  return (
    <>
      <SubTitle>{t('widgets.patient.program')}</SubTitle>
      <ProgramWrap>
        {program ? (
          <ProgramDetailsWrap>
            <Label>{program.name}</Label>
            <Label>
              {program.referenceDate
                ? t('widgets.programs.startDate', { date: dayjs(program.referenceDate).format('LL') })
                : t('widgets.programs.startOnNextMeasurement')}
            </Label>
            {program.referenceDate && (
              <PauseWrap>
                <Pause />
              </PauseWrap>
            )}
          </ProgramDetailsWrap>
        ) : (
          <div>{t('widgets.programs.notSet')}</div>
        )}
        <Tooltip open={isInactiveOrArchived ? undefined : false} title={t('widgets.programs.requireActive')}>
          <Button onClick={() => setIsModalVisible(true)} disabled={isInactiveOrArchived}>
            {t('actions.edit')}
          </Button>
        </Tooltip>
      </ProgramWrap>
      {!!program && (
        <Wrap>
          <Left>
            <>
              <HeadingFive>{t('widgets.patient.toDos')}</HeadingFive>
              <ToDos
                patient={patient}
                toDos={isOnHold ? toDoTemplates : program.toDos}
                referenceDate={program.referenceDate}
                isProgramPaused={isProgramPaused}
                isEditable={!isOnHold}
              />
            </>
          </Left>
          <Right>
            <HeadingWrap>
              <HeadingFive>{t('widgets.patient.alertSettings')}</HeadingFive>
              <TrackingAlertWizard
                onCreate={createAlertConfig}
                render={(openTrackingAlertWizard) => (
                  <Button style={{ marginTop: '-2px' }} shape="circle" size="small" onClick={openTrackingAlertWizard}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                )}
              />
            </HeadingWrap>
            <Alerts alertConfigs={alertConfigs} onUpdate={updateAlertConfig} onDelete={deleteAlertConfig} />
          </Right>
        </Wrap>
      )}
      <Line />
      <SubTitle>{t('widgets.patient.status')}</SubTitle>
      <Section>
        <TwoColumns>
          <PatientState />
        </TwoColumns>
      </Section>
      <EditProgram isVisible={isModalVisible} onCancel={() => setIsModalVisible(false)} />
    </>
  )
}
