import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import { EChartsOption } from 'echarts'
import { AggregatedAnalytics, ChartAggregationLevel } from '../../stores/analytics'
import { ReactConnector } from './ReactConnector'
import { createToolTip } from './createTooltip'
import { formatDateCategory } from './helpers'

interface ChartProps {
  type: 'bar' | 'line'
  data: AggregatedAnalytics['data']
  unit?: string
  color: string
  aggregationLevel: ChartAggregationLevel
}

export function TimeCategoryChart({ type, data, color, aggregationLevel, unit }: ChartProps): JSX.Element | null {
  const theme = useTheme()
  const { theme: antTheme } = React.useContext(ConfigProvider.ConfigContext)

  const categories = useMemo(() => {
    const values: string[] = []
    switch (aggregationLevel) {
      case 'year': {
        if (!data[0]) break
        let start = dayjs(data[0].timestamp).year()
        const currentYear = dayjs().year()
        while (start <= currentYear) {
          values.push(start.toString())
          start++
        }
        break
      }
      case 'month': {
        if (!data[0]) break
        const start = dayjs(data[0].timestamp).startOf('year')
        let current = dayjs(start)
        while (current.year() === start.year()) {
          values.push(current.format('YYYY-MM'))
          current = current.add(1, 'month')
        }
        break
      }
      case 'day': {
        if (!data[0]) break
        const start = dayjs(data[0].timestamp).startOf('month')
        let current = dayjs(start)
        while (current.month() === start.month()) {
          values.push(current.format('YYYY-MM-DD'))
          current = current.add(1, 'day')
        }
      }
    }
    return values
  }, [data, aggregationLevel])

  const seriesData: number[] = useMemo(
    () => categories.map((x) => data.find(({ timestamp }) => timestamp === x)?.value ?? 0),
    [categories, data],
  )

  const option: EChartsOption = useMemo(
    () => ({
      series: [
        {
          data: seriesData,
          color,
          type: type,
        },
      ],
      xAxis: {
        type: 'category' as const,
        data: categories,
        animation: false,
        axisLabel: {
          formatter: (timestamp: string) => formatDateCategory(timestamp, aggregationLevel),
          interval: aggregationLevel === ChartAggregationLevel.month ? 0 : 'auto',
        },
      },
      yAxis: {
        type: 'value',
        animation: false,
      },
      tooltip: {
        trigger: 'item',
        formatter: createToolTip({ theme, antTheme, aggregationLevel, unit, categories }),
        backgroundColor: theme.primaryBackground,
      },
    }),
    [seriesData, aggregationLevel, data, theme, antTheme, color, categories],
  )

  return <ReactConnector option={option} />
}
