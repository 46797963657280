declare global {
  interface Window {
    PUBLIC_TUNA_AUTH_API_KEY: string
    PUBLIC_STAGE: string
    PUBLIC_TUNA_SENTRY_DSN: string
    PUBLIC_TUNA_SENTRY_AUTH_TOKEN: string
    PUBLIC_TUNA_MIXPANEL_KEY: string
    PUBLIC_EPIC_CLIENT_ID: string
  }
}

export const env: Record<string, string> = {
  PUBLIC_TUNA_AUTH_API_KEY: process.env.PUBLIC_TUNA_AUTH_API_KEY || window.PUBLIC_TUNA_AUTH_API_KEY,
  PUBLIC_STAGE: process.env.PUBLIC_STAGE || window.PUBLIC_STAGE,
  PUBLIC_TUNA_SENTRY_DSN: process.env.PUBLIC_TUNA_SENTRY_DSN || window.PUBLIC_TUNA_SENTRY_DSN,
  PUBLIC_TUNA_SENTRY_AUTH_TOKEN: process.env.PUBLIC_TUNA_SENTRY_AUTH_TOKEN || window.PUBLIC_TUNA_SENTRY_AUTH_TOKEN,
  PUBLIC_TUNA_MIXPANEL_KEY: process.env.PUBLIC_TUNA_MIXPANEL_KEY || window.PUBLIC_TUNA_MIXPANEL_KEY,
  PUBLIC_EPIC_CLIENT_ID: process.env.PUBLIC_EPIC_CLIENT_ID || window.PUBLIC_EPIC_CLIENT_ID,
}
