import React from 'react'
import styled from 'styled-components'

const Video = styled.video`
  width: 100%;
`

export function VideoPlayer({ uri }: { uri: string }): JSX.Element {
  return (
    <Video id={uri} controls controlsList="nodownload" preload="metadata" key={uri}>
      <source src={uri} type="video/mp4" />
    </Video>
  )
}
