import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Tooltip } from 'antd'

import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import { useResultsStore } from '../../../../../stores/results'
import { BottomRow, Label, LabelWrapper, Row, Unit, Value, Wrapper, getAlertColor, getColorByCategory } from './styles'

export function SummaryCard(): JSX.Element | null {
  const { t } = useTranslation()
  const theme = useTheme()
  const [patient] = usePatientStore((state) => [state.patient])
  const [type, setType, getSummaryByInterval, summaryInterval, alerts] = useResultsStore((state) => [
    state.type,
    state.setType,
    state.getSummaryByInterval,
    state.summaryInterval,
    state.alerts,
  ])
  const title = t('widgets.results.summary')
  const alertsCount = alerts.unreviewed.length
  const colors = alertsCount > 0 ? getAlertColor(theme) : getColorByCategory({ category: 'tracking', theme })

  function handleCardSelection(): void {
    if (patient) {
      trackEvent(TrackingEvent.resultCardClicked, { type: 'summary' })
      getSummaryByInterval(patient.id, summaryInterval)
      setType('summary')
    }
  }

  if (!patient) return null

  return (
    <Wrapper onClick={handleCardSelection} $colors={colors} $isActive={type === 'summary'}>
      <Row>
        <Tooltip mouseEnterDelay={1} title={title}>
          <LabelWrapper>
            <Label>{title}</Label>
          </LabelWrapper>
        </Tooltip>
      </Row>
      <BottomRow>
        <div>
          <Tooltip mouseEnterDelay={1} title={`${alertsCount} ${t('widgets.results.alerts', { count: alertsCount })}`}>
            <Value $noMargin>{alertsCount}</Value>
            <Unit>{t('widgets.results.alerts', { count: alertsCount })}</Unit>
          </Tooltip>
        </div>
      </BottomRow>
    </Wrapper>
  )
}
