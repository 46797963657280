import i18next from 'i18next'
import { Rule, RuleObject } from 'rc-field-form/lib/interface'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ChannelEnum,
  UpdatePatientValidationErrorErrorEnum,
  UpdatePatientValidationFieldEnum,
} from '@vetahealth/tuna-can-api'
import { Form, Input, Select } from 'antd'
import { API } from '../../lib/api'
import { debouncePromise } from '../../lib/debouncePromise'

import { usePatientStore } from '../../stores/patient'
import { PhoneInput, isPossiblePhoneNumber } from './CustomInputs/PhoneInput'
import { getLabelFromChannel } from './helpers'
import { TwoColumns } from './styles'
import { FormKeys } from './types'

async function phoneValidator(_: RuleObject, value: string): Promise<void> {
  if (!value) return

  if (!isPossiblePhoneNumber(value)) throw i18next.t('validations.phone')
}

const phoneRule: Rule = {
  type: 'string',
  validator: phoneValidator,
}

const debouncedValidation = debouncePromise(
  (id: string, field: UpdatePatientValidationFieldEnum, value: string) =>
    API.validateUpdatePatientField(id, field, value),
  300,
)

export function Contact(): JSX.Element {
  const { t } = useTranslation()
  const [patient] = usePatientStore((state) => [state.patient])
  const {
    mobilePhoneInvalid = false,
    landlinePhoneInvalid = false,
    mobilePhone,
    landlinePhone,
    id,
    preferences,
  } = patient ?? {}
  const [mobilePhoneState, setMobilePhoneState] = useState<string>(mobilePhone ?? '')
  const [landlinePhoneState, setLandlinePhoneState] = useState<string>(landlinePhone ?? '')

  const showInvalidMobilePhoneAlert = mobilePhone === mobilePhoneState && mobilePhoneInvalid
  const showInvalidLandlinePhoneAlert = landlinePhone === landlinePhoneState && landlinePhoneInvalid
  const showInvalidNotificationChannelAlert = preferences?.notifications.channel === 'none'

  async function phoneUniqueValidator(_: RuleObject, value: string): Promise<void> {
    if (!value || !id || !isPossiblePhoneNumber(value)) return

    const validationError = await debouncedValidation(id, UpdatePatientValidationFieldEnum.Phone, value)
    if (validationError && validationError.error === UpdatePatientValidationErrorErrorEnum.Exists) {
      throw i18next.t('validations.phoneAlreadyUsed')
    }
  }

  async function emailUniqueValidator(_: RuleObject, value: string): Promise<void> {
    if (!value || !id) return

    const validationError = await debouncedValidation(id, UpdatePatientValidationFieldEnum.Email, value)
    if (validationError && validationError.error === UpdatePatientValidationErrorErrorEnum.Exists) {
      throw i18next.t('validations.emailAlreadyUsed')
    }
  }

  const phoneUniqueRule: Rule = {
    type: 'string',
    validator: phoneUniqueValidator,
  }

  const emailUniqueRule: Rule = {
    type: 'string',
    validator: emailUniqueValidator,
  }

  return (
    <>
      <TwoColumns>
        <Form.Item
          style={{ width: '100%' }}
          name={FormKeys.MOBILE_PHONE}
          label={t('form.mobilePhone')}
          rules={[phoneRule, phoneUniqueRule]}
          validateStatus={showInvalidMobilePhoneAlert ? 'warning' : undefined}
          hasFeedback={showInvalidMobilePhoneAlert}
          help={showInvalidMobilePhoneAlert ? t('validations.phoneInvalid') : undefined}
        >
          <PhoneInput placeholder={t('placeholders.phone')} onChange={(e) => setMobilePhoneState(e.target.value)} />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name={FormKeys.LANDLINE_PHONE}
          label={t('form.landlinePhone')}
          rules={[phoneRule, phoneUniqueRule]}
          validateStatus={showInvalidLandlinePhoneAlert ? 'warning' : undefined}
          hasFeedback={showInvalidLandlinePhoneAlert}
          help={showInvalidLandlinePhoneAlert ? t('validations.phoneInvalid') : undefined}
        >
          <PhoneInput placeholder={t('placeholders.phone')} onChange={(e) => setLandlinePhoneState(e.target.value)} />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name={FormKeys.SECONDARY_MOBILE_PHONE}
          label={t('form.secondaryMobilePhone')}
          rules={[phoneRule]}
        >
          <PhoneInput placeholder={t('placeholders.phone')} />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name={FormKeys.SECONDARY_LANDLINE_PHONE}
          label={t('form.secondaryLandlinePhone')}
          rules={[phoneRule]}
        >
          <PhoneInput placeholder={t('placeholders.phone')} />
        </Form.Item>
        <Form.Item
          name={FormKeys.EMAIL}
          label={t('form.email')}
          rules={[{ type: 'email', message: t('validations.email') }, emailUniqueRule]}
        >
          <Input placeholder={t('placeholders.email')} />
        </Form.Item>
        <Form.Item
          validateStatus={showInvalidNotificationChannelAlert ? 'warning' : undefined}
          hasFeedback={showInvalidNotificationChannelAlert}
          help={showInvalidNotificationChannelAlert ? t('validations.notificationsChannelInvalid') : undefined}
          name={['preferences', 'notifications', FormKeys.CHANNEL]}
          label={t('form.channel')}
        >
          <Select
            placeholder={t('placeholders.channel')}
            options={[
              {
                value: ChannelEnum.Sms,
                label: getLabelFromChannel(ChannelEnum.Sms),
                disabled: !mobilePhone ?? mobilePhoneInvalid,
              },
              {
                value: ChannelEnum.Call,
                label: getLabelFromChannel(ChannelEnum.Call),
                disabled: !landlinePhone ?? landlinePhoneInvalid,
              },
              {
                value: ChannelEnum.Push,
                label: getLabelFromChannel(ChannelEnum.Push),
                disabled: !preferences?.notifications.pushDevices?.length,
              },
              ...(preferences?.notifications.channel === 'none'
                ? [{ value: ChannelEnum.None, label: getLabelFromChannel(ChannelEnum.None) }]
                : []),
            ]}
          />
        </Form.Item>
      </TwoColumns>
    </>
  )
}
