import {
  BulkCreatePatientsBody,
  CareStateEnum,
  ConditionEnum,
  PatientOverviewItem,
  SexEnum,
  Site,
} from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { parsePhoneNumber } from 'libphonenumber-js'
import { invert, mapValues } from 'lodash-es'
import React, { Fragment, ReactNode } from 'react'
import * as yup from 'yup'

declare module 'yup' {
  export interface StringSchema {
    phone(errorMessage?: string): StringSchema
    uniqueMobile(errorMessage?: string): StringSchema
    email(errorMessage?: string): StringSchema
    isoDate(errorMessage?: string): StringSchema
    validProgram(errorMessage?: string): StringSchema
    validCareState(errorMessage?: string): StringSchema
  }
}

yup.addMethod(yup.StringSchema, 'phone', function (errorMessage?: string) {
  return this.test('phone', errorMessage || '', (value: string) => {
    try {
      return parsePhoneNumber(value, 'US').isValid()
    } catch {
      return false
    }
  })
})

yup.addMethod(yup.StringSchema, 'email', function (errorMessage?: string) {
  return this.test('email', errorMessage || '', (value: string, { options }) => {
    const context = options.context as ValidateContext | undefined
    try {
      const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
      if (!emailRegex.test(value)) return false
      if (!context?.allPatients || !value) return true
      return !context.allPatients.find(({ email }) => value === email)
    } catch {
      return false
    }
  })
})

yup.addMethod(yup.StringSchema, 'isoDate', function (errorMessage?: string) {
  return this.test('isoDate', errorMessage || '', (value: string) => {
    try {
      return dayjs(value, 'YYYY-MM-DD', true).isValid()
    } catch {
      return false
    }
  })
})

yup.addMethod(yup.StringSchema, 'validProgram', function (errorMessage?: string) {
  return this.test('validProgram', errorMessage || '', (value, { options }) => {
    const context = options.context as ValidateContext | undefined
    if (!context) return false
    if (!context.validPrograms) return true
    return !!context.validPrograms.find((program) => program.name === value)
  })
})

yup.addMethod(yup.StringSchema, 'validCareState', function (errorMessage?: string) {
  return this.test('validCareState', errorMessage || '', (value: CareStateEnum, { options }) => {
    const context = options.context as ValidateContext | undefined
    if (!context) return false
    if (!context.validPrograms || !value) return true
    const program = context.validPrograms.find((program) => program.name === context.item.program)
    return !!program && program.careStateTemplates.some((template) => template.name === value)
  })
})

yup.addMethod(yup.StringSchema, 'uniqueMobile', function (errorMessage?: string) {
  return this.test('uniqueMobile', errorMessage || '', (value: CareStateEnum, { options }) => {
    const context = options.context as ValidateContext | undefined
    if (!context) return false
    if (!context.allPatients || !value) return true
    try {
      const phoneValue = parsePhoneNumber(value, 'US').format('E.164')
      return !context.allPatients.find(({ mobilePhone }) => phoneValue === mobilePhone)
    } catch {
      return false
    }
  })
})

export enum MappingKey {
  address = 'address',
  careState = 'careState',
  city = 'city',
  clientIdentifier = 'clientIdentifier',
  conditions = 'conditions',
  country = 'country',
  customData = 'customData',
  dateOfBirth = 'dateOfBirth',
  email = 'email',
  firstName = 'firstName',
  landlinePhone = 'landlinePhone',
  lastName = 'lastName',
  mobilePhone = 'mobilePhone',
  postalCode = 'postalCode',
  program = 'program',
  provider = 'provider',
  sex = 'sex',
  state = 'state',
}

interface ValidateContext {
  item: Partial<Record<MappingKey, string>>
  allPatients: PatientOverviewItem[]
  validPrograms?: Site['programs']
}

export const requiredMappings = [
  MappingKey.firstName,
  MappingKey.lastName,
  MappingKey.dateOfBirth,
  MappingKey.conditions,
]

const requireNotEmpty = [...requiredMappings]

const searchTerms: Record<MappingKey, Array<string[] | undefined>> = {
  [MappingKey.address]: [['street'], ['address']],
  [MappingKey.careState]: [
    ['program', 'state'],
    ['care', 'state'],
  ],
  [MappingKey.city]: [['city']],
  [MappingKey.clientIdentifier]: [['client', 'id'], ['client', 'reference'], ['reference'], ['id']],
  [MappingKey.conditions]: [['condition']],
  [MappingKey.country]: [['country']],
  [MappingKey.customData]: [],
  [MappingKey.dateOfBirth]: [['date', 'birth'], ['birthday']],
  [MappingKey.email]: [['email'], ['mail']],
  [MappingKey.firstName]: [['first', 'name'], ['forename'], ['given name'], ['prename']],
  [MappingKey.landlinePhone]: [['landline'], ['phone']],
  [MappingKey.lastName]: [['last', 'name'], ['family', 'name'], ['name']],
  [MappingKey.mobilePhone]: [['mobile'], ['phone']],
  [MappingKey.postalCode]: [['postal', 'code'], ['zip']],
  [MappingKey.program]: [undefined, ['program']],
  [MappingKey.provider]: [['provider']],
  [MappingKey.sex]: [['sex'], ['biological', 'sex']],
  [MappingKey.state]: [['state'], ['region', 'code']],
}

const validators: Partial<Record<MappingKey, yup.Schema>> = {
  dateOfBirth: yup.string().isoDate(),
  email: yup.string().email(),
  mobilePhone: yup.string().phone().uniqueMobile(),
  landlinePhone: yup.string().phone(),
  conditions: yup.string().oneOf(Object.values(ConditionEnum)),
  sex: yup.string().oneOf(Object.values(SexEnum)),
  program: yup.string().validProgram(),
  careState: yup.string().validCareState(),
}

export function getMappingLabels(): Record<MappingKey, string> {
  const labels: Record<MappingKey, string> = {
    address: i18next.t('form.address'),
    careState: i18next.t('form.careState'),
    city: i18next.t('form.city'),
    clientIdentifier: i18next.t('form.clientReference'),
    conditions: i18next.t('form.condition'),
    country: i18next.t('form.country'),
    customData: i18next.t('widgets.csvImport.customData'),
    dateOfBirth: i18next.t('form.dateOfBirth'),
    email: i18next.t('form.email'),
    firstName: i18next.t('form.firstName'),
    landlinePhone: i18next.t('form.landlinePhone'),
    lastName: i18next.t('form.lastName'),
    mobilePhone: i18next.t('form.mobilePhone'),
    postalCode: i18next.t('form.postalCode'),
    program: i18next.t('form.program'),
    provider: i18next.t('form.provider'),
    sex: i18next.t('form.sex'),
    state: i18next.t('form.state'),
  } as const
  return mapValues(labels, (value, key: MappingKey) => (requiredMappings.includes(key) ? `${value} *` : value))
}

export function autoDetectColumnMappings(fields: string[]): Record<number, MappingKey> {
  const columnMappings: { [n: number]: MappingKey } = {}
  const maxSearchLevel = Math.max(...Object.values(searchTerms).map((terms) => terms.length))
  for (let searchLevel = 0; searchLevel < maxSearchLevel; searchLevel++) {
    Object.entries(searchTerms).forEach(([key, levels]: [key: MappingKey, terms: string[][]]) => {
      if (Object.values(columnMappings).includes(key)) return
      const searchTerms = levels[searchLevel]
      if (!searchTerms) return
      const matchedIndex = fields.findIndex(
        (field, index) => !columnMappings[index] && searchTerms.every((term) => field.toLowerCase().includes(term)),
      )
      if (matchedIndex > -1) columnMappings[matchedIndex] = key
    })
  }
  return columnMappings
}

export function mapData(
  data: string[][],
  columnMappings: Record<number, MappingKey>,
): Partial<Record<MappingKey, string>>[] {
  return data.map((row) => {
    return Object.fromEntries(Object.entries(columnMappings).map(([index, key]) => [key, row[+index]]))
  }) as Record<MappingKey, string>[]
}

export function validateColumns(
  data: string[][],
  columnMappings: Record<number, MappingKey>,
  selectedRows: number[],
  allPatients: PatientOverviewItem[],
  validPrograms?: Site['programs'],
): Record<number, number> {
  const result: Record<number, number> = {}
  const mappedData = mapData(data, columnMappings)
  Object.entries(columnMappings).forEach(([colStr, mappingKey]) => {
    const colIndex = Number.parseInt(colStr)
    result[colIndex] = data.reduce(
      (errorCount, item, index: number) =>
        !selectedRows.includes(index) ||
        validateCell(item[colIndex] ?? '', mappingKey, { item: mappedData[index], validPrograms, allPatients })
          ? errorCount
          : errorCount + 1,
      0,
    )
  })
  return result
}

export function validateCell(value: string, mappingKey: MappingKey | undefined, context: ValidateContext): boolean {
  if (!mappingKey) return true
  if (!value) return !requireNotEmpty.includes(mappingKey)
  const validator = validators[mappingKey]
  return !validator || validator.isValidSync(value, { context })
}

function normalizeValue(value: string, mappingKey?: MappingKey): string | string[] | undefined {
  if (!value) return undefined
  if (!mappingKey) return value
  return mappingKey === MappingKey.conditions ? [value] : value
}

const propertiesRequiringTransformation: MappingKey[] = [
  MappingKey.program,
  MappingKey.careState,
  MappingKey.customData,
]

export function createPatientData(
  data: string[][],
  fields: string[],
  columnMappings: Record<number, MappingKey>,
  selectedRows: number[],
  site: string,
  programTemplates: Site['programs'],
): BulkCreatePatientsBody[] {
  return [...selectedRows]
    .sort((a, b) => a - b)
    .map((row) => data[row])
    .map((record) => {
      const patient = Object.fromEntries(
        Object.entries(columnMappings)
          .filter(
            ([colIndex, mappingKey]) => !propertiesRequiringTransformation.includes(mappingKey) && record[+colIndex],
          )
          .map(([colIndex, mappingKey]) => [mappingKey, normalizeValue(record[+colIndex], mappingKey)]),
      ) as unknown as BulkCreatePatientsBody

      const customData = Object.fromEntries(
        Object.entries(columnMappings)
          .filter(([, mappingKey]) => mappingKey === MappingKey.customData)
          .map(([colIndex]) => [fields[+colIndex], record[+colIndex]]),
      )
      const mappingToCol = invert(columnMappings)

      const programName = record[+mappingToCol[MappingKey.program]]
      const templateId = programTemplates.find(({ name }) => name === programName)?.id
      if (templateId) {
        patient.programTemplateId = templateId
        const careStateName = record[+mappingToCol[MappingKey.careState]]
        if (careStateName) patient.careStateName = careStateName
      }

      patient.site = site
      if (Object.keys(customData).length) patient.customData = customData
      return patient
    })
}

export function joinJSX(arr: ReactNode[], separator = ','): ReactNode[] {
  const filtered = arr.filter((item) => item)
  return filtered
    .reduce<ReactNode[]>((res, item, index) => {
      res.push(item)

      if (index !== filtered.length - 1) res.push(separator)

      return res
    }, [])
    .map((item, index) => <Fragment key={index}>{item}</Fragment>)
}
