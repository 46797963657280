import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Form, Input, Select, SelectProps, Space, Switch } from 'antd'

import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TwoColumns } from './styles'
import { FormKeys } from './types'

interface UserFormProps {
  presetOptions: NonNullable<SelectProps['options']>
  siteOptions: NonNullable<SelectProps['options']>
  rolesOptions: NonNullable<SelectProps['options']>
  permissionsOnly?: boolean
  isActive: boolean
  isNew: boolean
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`

const RoleWrap = styled(Space.Compact)`
  display: flex;
  margin-bottom: 8px;
  > div {
   flex: 1;
  }
`

const Label = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
`

export function User({
  siteOptions,
  permissionsOnly,
  isActive,
  presetOptions,
  rolesOptions,
  isNew,
}: UserFormProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div>
        <TwoColumns>
          <Form.Item name={FormKeys.CREDENTIALS} label={t('form.credentials')} rules={[{ whitespace: true }]}>
            <Input placeholder={t('placeholders.credentials')} disabled={!isActive || permissionsOnly} />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name={FormKeys.USERNAME}
            label={t('form.username')}
            rules={[{ required: false, whitespace: false }]}
          >
            <Input placeholder={t('placeholders.epicId')} disabled={!isActive || permissionsOnly} />
          </Form.Item>
        </TwoColumns>
        <TwoColumns>
          <Form.Item
            style={{ width: '100%' }}
            name={FormKeys.FIRST_NAME}
            label={t('form.firstName')}
            rules={[{ required: true, whitespace: true, message: t('validations.enterFirstName') }]}
          >
            <Input placeholder={t('placeholders.firstName')} disabled={!isActive || permissionsOnly} />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name={FormKeys.LAST_NAME}
            label={t('form.lastName')}
            rules={[{ required: true, whitespace: true, message: t('validations.enterLastName') }]}
          >
            <Input placeholder={t('placeholders.lastName')} disabled={!isActive || permissionsOnly} />
          </Form.Item>
        </TwoColumns>
        <Form.Item
          style={{ width: '100%' }}
          name={FormKeys.EMAIL}
          label={t('form.email')}
          rules={[{ required: true, whitespace: true, message: t('validations.email') }]}
        >
          <Input placeholder={t('placeholders.email')} disabled={!isActive || permissionsOnly} />
        </Form.Item>
        {isNew && (
          <Form.Item name={FormKeys.VIEW_PRESET} label={t('form.viewPreset')}>
            <Select options={presetOptions} />
          </Form.Item>
        )}
        <Label>{t('form.status')}</Label>
        <Form.Item name={FormKeys.ACTIVE} label={t('form.active')} valuePropName="checked">
          <Switch disabled={permissionsOnly} />
        </Form.Item>
      </div>
      <div>
        <Label>{t('form.roles')}</Label>
        <Form.List name="roles">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <RoleWrap key={key} block>
                  <Form.Item
                    {...restField}
                    noStyle
                    name={[name, 'roleId']}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select options={rolesOptions} placeholder={t('form.chooseRole')} disabled={!isActive} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    noStyle
                    name={[name, 'site']}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select options={siteOptions} placeholder={t('form.chooseSite')} disabled={!isActive} />
                  </Form.Item>
                  <Button
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => remove(name)}
                    disabled={fields.length === 1 || !isActive}
                  />
                </RoleWrap>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  disabled={!isActive}
                >
                  {t('form.addRole')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </Wrapper>
  )
}
