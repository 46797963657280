import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'

export function formatPhone(phone: string, forCountry: CountryCode = 'US'): string | undefined {
  if (!phone) return
  const parsed = parsePhoneNumberFromString(phone)
  return parsed?.format(parsed?.country === forCountry ? 'NATIONAL' : 'IDD', {
    humanReadable: true,
    fromCountry: forCountry,
  })
}
