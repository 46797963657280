import dayjs from 'dayjs'
import i18next from 'i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { convert, getDisplayValue, sleepShiftHour } from '@vetahealth/fishing-gear/conversions'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { Table } from 'antd'

import { usePatientStore } from '../../../../../stores/patient'
import { useResultsStore } from '../../../../../stores/results'
import { Field, getTableData } from '../../../../helpers'
import { getResultsConfig } from '../config'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Time = styled.div``

function getTimeColumn(
  event: string | number | undefined,
  record: CardDetails,
  timeZone?: string,
): JSX.Element | string {
  const minutes = convert(record.value, record.unit, 'sleep', 'min')[0]
  if (!minutes) return '-'

  const timeFormat = i18next.t('dateFormats.dayAndMonthAndTime')
  const timeZoneLabel = dayjs(event).tz(timeZone).format('z')
  const start = `${dayjs(event).tz(timeZone).format(timeFormat)} ${timeZoneLabel}`
  const end = `${dayjs(event).tz(timeZone).add(minutes, 'minute').format(timeFormat)} ${timeZoneLabel}`

  return (
    <Wrapper>
      <Time>{start}</Time>
      <Time>{end}</Time>
    </Wrapper>
  )
}

export function SleepTable(): JSX.Element {
  const { t } = useTranslation()
  const [patient] = usePatientStore((state) => [state.patient])
  const [allResults] = useResultsStore((state) => [state.allResults])
  const data = allResults.sleep || []
  const config = getResultsConfig('sleep')
  const timeZone = patient?.timeZone

  const tableData = useMemo(() => {
    const fields: Field<CardDetails>[] = [
      {
        key: 'timestamp',
        title: t('form.date'),
        format: ((value: string) =>
          dayjs(value).tz(timeZone).add(sleepShiftHour, 'hour').startOf('day').format('ll')) as never,
        defaultSortOrder: 'descend',
        sortDirections: ['descend', 'ascend', 'descend'],
      },
      {
        key: 'value',
        format: (event) => {
          return event
            ? typeof event === 'number' && config?.unit && getDisplayValue(event, config.unit, 'sleep', 'h_min')
            : '-'
        },
        title: config.title,
        noSorting: true,
      },
      {
        key: 'timestamp',
        title: t('table.time'),
        format: (event, record) => getTimeColumn(event, record, timeZone),
        noSorting: true,
      },
    ]

    return getTableData({ data, fields })
  }, [data, timeZone])

  return <Table key="table-sleep" tableLayout="fixed" pagination={{ showSizeChanger: true }} {...tableData} />
}
