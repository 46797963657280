import React from 'react'
import styled from 'styled-components'

import { Typography } from 'antd'

import { Explanation, ExplanationProps } from './Explanation'

const WizardSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .ant-input-number {
    max-width: 75px;
  }
`
const WizardQuestionWrapper = styled.div``

const WizardChildrenWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 5px;
`

export function WizardSection({
  question,
  explanations,
  children,
}: {
  question: string
  explanations?: ExplanationProps['explanations']
  children: React.ReactNode
}): JSX.Element {
  return (
    <WizardSectionWrapper>
      <WizardQuestionWrapper>
        <Explanation explanations={explanations} placement="right">
          <Typography.Text>{question}</Typography.Text>
        </Explanation>
      </WizardQuestionWrapper>
      <WizardChildrenWrapper>{children}</WizardChildrenWrapper>
    </WizardSectionWrapper>
  )
}
