import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Notes } from '../../components/Widgets/Notes'
import { PatientDetails as PatientDetailsComponent } from '../../components/Widgets/PatientDetails'
import { PatientHeader } from '../../components/Widgets/PatientHeader'
import { useValidatedPatient } from '../../lib/hooks/useValidatedPatient'
import { routes } from '../routes'
import { PatientWrapper } from './styles'

export enum PatientDetailsTab {
  results = 'results',
  demographics = 'demographics',
  devices = 'devices',
  activity = 'activity',
  documents = 'documents',
  program = 'program',
}

export function PatientDetails(): JSX.Element {
  const isValidPatientId = useValidatedPatient()
  const navigate = useNavigate()
  const { id, tab } = useParams<{ id: string; tab: PatientDetailsTab }>()
  const location = useLocation()

  const query = new URLSearchParams(window.location.search)

  if (!isValidPatientId || !id) return <div />

  const handleTabChange = (newTab: PatientDetailsTab): void => {
    navigate(routes.patientDetails(id, newTab) + location.search, { replace: true })
  }

  return (
    <PatientWrapper>
      {query.get('headless') !== 'true' && <PatientHeader />}
      <PatientDetailsComponent tab={tab} onTabChange={handleTabChange} />
      <Notes />
    </PatientWrapper>
  )
}
