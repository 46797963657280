import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { API, isAxiosError } from '../../lib/api'
import { useUserStore } from '../user'
import { AppState } from './types'

export const useAppStore = createWithEqualityFn<AppState>(
  (set, get) => ({
    releaseInfo: [],
    getReleaseInfo: async (offset = 0) => {
      const { locale } = useUserStore.getState()
      const releaseInfo = (await API.getReleaseInfo(locale, offset)) ?? []
      if (isAxiosError(releaseInfo)) return []
      if (offset) {
        set({ releaseInfo: [...get().releaseInfo, ...releaseInfo] })
      } else {
        // Release infos will be re-fetched on language change and are therefore overwritten
        set({ releaseInfo })
      }
      return releaseInfo
    },
    resetReleaseInfo: () => {
      set({ releaseInfo: [] })
    },
  }),
  shallow,
)
