import React from 'react'
import styled from 'styled-components'

const Iframe = styled.iframe`
  width: 100%;
  height: 315px;
`

export function YouTubePlayer({ id }: { id: string }): JSX.Element {
  return (
    <Iframe
      width="100%"
      src={`https://www.youtube.com/embed/${id}?rel=0`}
      title="YouTube video player"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}
