import { faUserPlus, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AutoComplete, Button, Popover, Tooltip } from 'antd'

import { TrackingEvent, trackEvent } from '../lib/tracking'
import { usePatientStore } from '../stores/patient'
import { useSitesStore } from '../stores/sites'
import { useUserStore } from '../stores/user'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const SubscribersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
`
const SubscriberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.secondaryBackground};
`

export function Subscribe(): JSX.Element {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>('')

  const [patient, subscribeToPatient] = usePatientStore((state) => [state.patient, state.subscribeToPatient])
  const [userId, firstName, lastName] = useUserStore((state) => [state.userId, state.firstName, state.lastName])
  const users = useSitesStore((state) => state.users)

  const subscribers = patient?.subscribers || []
  const isSubscribed = !!userId && subscribers.includes(userId)
  const nonSubscribers = users
    .filter(({ id, sites }) => sites.includes(patient?.site as string) && !subscribers.includes(id) && id !== userId)
    .map(({ id, name }) => ({ label: name, value: id }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))

  if (userId && !isSubscribed) {
    nonSubscribers.unshift({ label: `${firstName} ${lastName}`, value: userId })
  }

  function handleOnSelect(id: string): void {
    trackEvent(TrackingEvent.patientSubscribeClicked, { enabled: true, self: id === userId })
    subscribeToPatient([id])
    setValue('')
  }

  function handleOnClick(id: string): () => void {
    return () => {
      trackEvent(TrackingEvent.patientSubscribeClicked, { enabled: false, self: id === userId })
      subscribeToPatient([id])
    }
  }

  return (
    <Popover
      title={t('patient.subscribe')}
      trigger="click"
      placement="leftTop"
      overlayInnerStyle={{ width: '240px' }}
      content={
        <Wrapper>
          {subscribers.length ? (
            <SubscribersWrapper>
              {subscribers.map((id) => (
                <SubscriberWrapper key={id}>
                  <span>{users.find((user) => user.id === id)?.name}</span>
                  <Button shape="circle" size="small" type="text" onClick={handleOnClick(id)}>
                    <FontAwesomeIcon size="xs" icon={faX} />
                  </Button>
                </SubscriberWrapper>
              ))}
            </SubscribersWrapper>
          ) : null}
          <AutoComplete
            options={nonSubscribers}
            popupMatchSelectWidth={false}
            filterOption={(value, option) => !!option?.label.includes(value)}
            placeholder={t('actions.addSubscribers')}
            value={value}
            onSearch={setValue}
            onSelect={handleOnSelect}
          />
        </Wrapper>
      }
    >
      <Tooltip title={t('patient.manageSubscribers')}>
        <Button shape="circle" type={isSubscribed ? 'primary' : 'default'}>
          <FontAwesomeIcon icon={faUserPlus} />
        </Button>
      </Tooltip>
    </Popover>
  )
}
