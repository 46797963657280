import { Form } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { SiteOrderSettings } from '../../../Forms/SiteOrderSettings'
import { SiteUpdateProps } from '../SiteManagement'

const Section = styled.div`
  margin-bottom: 20px;
`

export function Ordering({ site, form, handleSiteUpdate }: SiteUpdateProps): JSX.Element {
  if (!site) return <div />
  return (
    <Section>
      <Form
        form={form}
        layout="vertical"
        validateTrigger="onChange"
        onValuesChange={handleSiteUpdate}
        onFinish={handleSiteUpdate}
        scrollToFirstError
      >
        <SiteOrderSettings form={form} />
      </Form>
    </Section>
  )
}
