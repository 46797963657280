import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { StatusEnum } from '@vetahealth/tuna-can-api'
import { Button, Popconfirm, Typography, message } from 'antd'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { usePatientStore } from '../../../../stores/patient'
import { Explanation } from '../../../Explanation'
import { EditProgram } from './EditProgram'

const StatusWrap = styled.div`
  margin: 8px 0 12px 0;
`

const StatusLabel = styled(Typography.Text)`
  color: ${({ color }) => color};
  font-weight: 600;
`

const StatusDescription = styled.div`
  font-size: 13px;
  opacity: 0.6;
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 16px;
`

export function PatientState(): JSX.Element {
  const { t } = useTranslation()
  const [patient, updatePatient] = usePatientStore((state) => [state.patient, state.updatePatient])
  const [loading, withLoading] = useLoading()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleStatusConfirm = useCallback(
    async (status: StatusEnum) => {
      trackEvent(TrackingEvent.demographicsPatientStatusClicked, { status })
      if (!patient) return
      const success = await withLoading(updatePatient(patient, { status }))

      if (success) {
        message.success({ content: t('message.patientUpdate') })
        if (status === StatusEnum.Active) setIsModalVisible(true)
      }
    },
    [patient],
  )

  const getStatus = (status: StatusEnum): { title: string; description: string; color: Colors } => {
    return {
      [StatusEnum.Active]: {
        title: t('patientStatus.active'),
        description: t('patientStatus.activeInfo'),
        color: Colors.green500,
      },
      [StatusEnum.Locked]: {
        title: t('patientStatus.locked'),
        description: t('patientStatus.lockedInfo'),
        color: Colors.apricot400,
      },
      [StatusEnum.Inactive]: {
        title: t('patientStatus.inactive'),
        description: t('patientStatus.inactiveInfo'),
        color: Colors.ruby600,
      },
      [StatusEnum.Archived]: {
        title: t('patientStatus.archived'),
        description: t('patientStatus.archivedInfo'),
        color: Colors.gray600,
      },
    }[status]
  }

  const statusDefinition = patient?.status && getStatus(patient.status)
  const isInactive = patient?.status !== StatusEnum.Active
  const isArchived = patient?.status === StatusEnum.Archived

  return (
    <div>
      <Explanation
        explanations={Object.keys(StatusEnum).map((key: keyof typeof StatusEnum) => getStatus(StatusEnum[key]))}
        placement="right"
      >
        <Typography.Text>{t('form.patientStatus')}</Typography.Text>
      </Explanation>

      <StatusWrap>
        <StatusLabel color={statusDefinition?.color}>{statusDefinition?.title}</StatusLabel>
        <StatusDescription>{statusDefinition?.description}</StatusDescription>
      </StatusWrap>
      {isInactive ? (
        <ButtonWrap>
          {!isArchived && (
            <Button
              disabled={loading}
              loading={loading}
              type={'primary'}
              onClick={() => handleStatusConfirm(StatusEnum.Archived)}
            >
              {t('actions.archive')}
            </Button>
          )}
          <Button
            disabled={loading}
            loading={loading}
            type={isArchived ? 'primary' : 'default'}
            onClick={() => handleStatusConfirm(StatusEnum.Active)}
          >
            {t('actions.reactivate')}
          </Button>
        </ButtonWrap>
      ) : (
        <>
          <Popconfirm
            title={t('widgets.patientEdit.confirmDelete', {
              patientName: `${patient?.firstName} ${patient?.lastName}`,
            })}
            okText={t('actions.inactivate')}
            onConfirm={() => handleStatusConfirm(StatusEnum.Inactive)}
            okButtonProps={{ danger: true }}
            cancelText={t('actions.cancel')}
            cancelButtonProps={{ type: 'text' }}
            placement="topRight"
            icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
          >
            <Button disabled={loading} loading={loading} danger>
              {t('actions.inactivate')}
            </Button>
          </Popconfirm>
        </>
      )}
      <EditProgram
        isVisible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        warning={t('widgets.patientEdit.confirmActivate')}
      />
    </div>
  )
}
