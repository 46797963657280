import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Tooltip } from 'antd'

const AlertIconWrap = styled.span`
  margin: 0 4px;
`

export function AlertIcon({ message, messages = [] }: { message?: string; messages?: string[] }): JSX.Element {
  const title = message || (
    <ul style={{ margin: 0, paddingLeft: 20, paddingRight: 10 }}>
      {messages.map((m) => (
        <li key={m}>{m}</li>
      ))}
    </ul>
  )

  return (
    <AlertIconWrap>
      <Tooltip title={title}>
        <FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />
      </Tooltip>
    </AlertIconWrap>
  )
}
