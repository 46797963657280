import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { DeviceTypeEnum, OrderKitTypeEnum } from '@vetahealth/tuna-can-api'
import { Button, Form, Modal, Popconfirm, Select, Typography, message } from 'antd'
import { debounce } from 'lodash-es'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { API } from '../../../../lib/api'
import { countryNames } from '../../../../lib/countryNames'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { usePatientStore } from '../../../../stores/patient'
import { usePatientsStore } from '../../../../stores/patients'
import { useSitesStore } from '../../../../stores/sites'
import { useUserStore } from '../../../../stores/user'
import { SubTitle } from '../../styles'
import { getIsTenoviOrderForExistingDeviceType, getOrderDeviceName } from './helpers'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Name = styled(Typography.Text)`
  font-weight: 700;
  margin-top: 10px;
`
const AddressDetail = styled(Typography.Text)``

const Warning = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`

export function OrderDevice({
  isVisible,
  onCancel,
  onOrderDevice,
  existingDeviceTypes,
}: {
  isVisible: boolean
  onCancel: () => void
  onOrderDevice: (patientId: string) => void
  existingDeviceTypes: Array<DeviceTypeEnum | OrderKitTypeEnum>
}): JSX.Element | null {
  const { t } = useTranslation()
  const [units] = useUserStore((state) => [state.units])
  const [patient] = usePatientStore((state) => [state.patient])
  const [expirePatients] = usePatientsStore((state) => [state.expirePatients])
  const [sites] = useSitesStore((state) => [state.sites])
  const [form] = Form.useForm()
  const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceTypeEnum | OrderKitTypeEnum>()

  const availableDeviceTypes = Object.keys(
    sites?.find(({ key }) => key === patient?.site)?.devices ?? {},
  ).sort() as Array<DeviceTypeEnum | OrderKitTypeEnum>
  const deviceSelectOptions = availableDeviceTypes.map((deviceType) => ({
    label: getOrderDeviceName(deviceType, units.weight),
    value: deviceType,
  }))
  const isOrderForExistingDevice = selectedDeviceType && existingDeviceTypes.includes(selectedDeviceType)
  const isTenoviOrderForExistingDeviceType =
    selectedDeviceType && getIsTenoviOrderForExistingDeviceType(existingDeviceTypes, selectedDeviceType)

  const handleOrderDevice = useCallback(
    debounce((values: { orderType: DeviceTypeEnum | OrderKitTypeEnum }): void => {
      if (patient?.id) {
        trackEvent(TrackingEvent.deviceOrderConfirmClicked, { type: values.orderType })
        onCancel()
        API.createOrder({ id: patient.id, values }).then(() => {
          onOrderDevice(patient.id)
          expirePatients()
          message.success(t('message.orderCreate'))
        })
      }
    }, 300),
    [],
  )

  if (!patient?.id) return <div />

  return (
    <Modal
      title={t('widgets.devices.orderDevice')}
      open={isVisible}
      onCancel={onCancel}
      destroyOnClose
      centered
      footer={
        <Popconfirm
          title={
            isOrderForExistingDevice &&
            t('widgets.devices.orderExistingConfirmation', {
              deviceType: getOrderDeviceName(selectedDeviceType, units.weight),
            })
          }
          onConfirm={form.submit}
          disabled={!isOrderForExistingDevice}
          icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
        >
          <Button
            type="primary"
            onClick={isOrderForExistingDevice ? undefined : form.submit}
            disabled={isTenoviOrderForExistingDeviceType}
          >
            {t('widgets.devices.orderDevice')}
          </Button>
        </Popconfirm>
      }
    >
      <Form name="orderDevice" form={form} onFinish={handleOrderDevice} layout="vertical">
        <Wrapper>
          <Form.Item
            name="orderType"
            rules={[{ required: true, message: t('validations.enterDevice') }]}
            label={t('widgets.devices.selectDeviceType')}
          >
            <Select
              style={{ width: '100%' }}
              placeholder={t('placeholders.deviceType')}
              onSelect={(type) => setSelectedDeviceType(type)}
              options={deviceSelectOptions}
            />
          </Form.Item>
        </Wrapper>
        <SubTitle>{t('widgets.devices.shippingAddress')}</SubTitle>
        <Wrapper>
          <Name>{`${patient?.firstName} ${patient?.lastName}`}</Name>
          <AddressDetail>{patient?.address}</AddressDetail>
          <AddressDetail>{`${patient?.city} ${patient?.state} ${patient?.postalCode}`}</AddressDetail>
          <AddressDetail>{countryNames.find(({ iso }) => iso === patient?.country)?.name}</AddressDetail>
        </Wrapper>
        {isTenoviOrderForExistingDeviceType && (
          <Warning>
            <Typography.Text type="warning">{t('widgets.devices.duplicateTenoviDeviceWarning')}</Typography.Text>
          </Warning>
        )}
      </Form>
    </Modal>
  )
}
