import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Popover, PopoverProps, Typography } from 'antd'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled(Typography.Text)<{ $color?: Colors }>`
  font-weight: 600;
  ${({ $color }) => $color && `color: ${$color}`};
`
const Description = styled(Typography.Paragraph)`
  font-size: 13px;
  opacity: 0.6;
`

export type ExplanationConfig = { title?: string; description?: string; color?: Colors; custom?: React.ReactNode }

export type ExplanationProps = {
  children: React.ReactNode
  explanations?: ExplanationConfig[]
  placement?: PopoverProps['placement']
}

export function Explanation({ children, explanations = [], placement }: ExplanationProps): JSX.Element {
  return (
    <>
      {children}
      {!!explanations.length && (
        <Popover
          content={
            <Wrapper>
              {explanations.map(({ title, description, color, custom = null }) => (
                <div key={title}>
                  {!!title && <Title color={color}>{title}</Title>}
                  {!!description && <Description>{description}</Description>}
                  {custom}
                </div>
              ))}
            </Wrapper>
          }
          overlayInnerStyle={{ maxWidth: '300px', padding: '20px' }}
          placement={placement}
        >
          <FontAwesomeIcon icon={faQuestionCircle} style={{ marginLeft: '6px', cursor: 'pointer' }} />
        </Popover>
      )}
    </>
  )
}
