import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Table } from 'antd'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { CareManager, UpdateCareManager } from '@vetahealth/tuna-can-api'
import { convertEmptyStringToNull, convertEmptyStringToUndefined } from '../../../lib/normalizers'
import { useRolesStore } from '../../../stores/roles'
import { useUserStore } from '../../../stores/user'
import { useUsersStore } from '../../../stores/users'
import { UserFormValues } from '../../Forms'
import { Spinner } from '../../Spinner'
import { getTableData } from '../../helpers'
import { Card } from '../styles'
import { UserModal } from './UserModal'
import { getFields } from './helpers'

const TableWrapper = styled.div`
  overflow: auto;
  .ant-table-row {
    cursor: pointer;
  }
`

const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`

export function Users(): JSX.Element {
  const { t } = useTranslation()
  const [roles, getRoles] = useRolesStore((state) => [state.roles, state.getRoles])
  const [users, getUsers, createUser, updateUser, updateUserRoles] = useUsersStore((state) => [
    state.users,
    state.getUsers,
    state.createUser,
    state.updateUser,
    state.updateUserRoles,
  ])
  const hasPermission = useUserStore((state) => state.hasPermission)
  const [editUser, setEditUser] = useState<CareManager>()
  const [isModalVisible, setModalVisible] = useState(false)

  void getRoles()
  void getUsers()

  const { dataSource, columns } = getTableData<CareManager>({ data: users, fields: getFields(t, roles) })

  async function handleFormSubmit(values: UserFormValues): Promise<void> {
    if (editUser) {
      if (editUser.editable || hasPermission(PermissionName.manageUser)) {
        const update: UpdateCareManager = {}
        update.firstName = values.firstName
        update.lastName = values.lastName
        update.email = values.email
        update.username = values.username
        update.active = values.active
        update.credentials = values.credentials
        await updateUser(editUser.id, convertEmptyStringToNull(update))
      }
      await updateUserRoles(editUser.id, values.roles)
    } else {
      await createUser({
        ...{
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          active: values.active,
          roles: values.roles,
          viewPreset: values.viewPreset,
        },
        ...convertEmptyStringToUndefined({
          username: values.username,
          credentials: values.credentials,
        }),
      })
    }
    setModalVisible(false)
  }

  function handleRow(record: CareManager): { onClick: () => void } {
    return {
      onClick: () => {
        setEditUser(record)
        setModalVisible(true)
      },
    }
  }

  function handleAdd(): void {
    setEditUser(undefined)
    setModalVisible(true)
  }

  return (
    <Card>
      <ButtonWrapper>
        <Button type="primary" htmlType="submit" onClick={handleAdd}>
          {t('actions.add')}
        </Button>
      </ButtonWrapper>
      {users.length > 0 ? (
        <TableWrapper>
          <Table scroll={{ x: true }} dataSource={dataSource} columns={columns} onRow={handleRow} />
        </TableWrapper>
      ) : (
        <Spinner />
      )}
      <UserModal
        user={editUser}
        roles={roles}
        isVisible={isModalVisible}
        onSubmit={handleFormSubmit}
        onCancel={() => setModalVisible(false)}
      />
    </Card>
  )
}
