import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Tabs } from 'antd'

import { useChatStore } from '../../../stores/chat'
import { usePatientStore } from '../../../stores/patient'
import { useResultsStore } from '../../../stores/results'
import { Card } from '../styles'
import { TabProps } from '../types'
import { Activity } from './Activity'
import { Demographics } from './Demographics'
import { Devices } from './Devices'
import { Documents } from './Documents'
import { Program } from './Program'
import { Results } from './Results'

export function PatientDetails({ tab, onTabChange }: TabProps): JSX.Element {
  const { t } = useTranslation()
  const [patient] = usePatientStore((state) => [state.patient])
  const [resetChatMessages] = useChatStore((state) => [state.resetChatMessages])
  const [getAlerts, reset] = useResultsStore((state) => [state.getAlerts, state.reset])

  useEffect(() => {
    if (patient) {
      reset()
      resetChatMessages()
      void getAlerts(patient.id)
    }
  }, [patient?.id])

  const tabItems = [
    {
      key: 'results',
      label: t('widgets.patient.results'),
      children: <Results />,
    },
    {
      key: 'demographics',
      label: t('widgets.patient.demographics'),
      children: <Demographics />,
    },
    {
      key: 'devices',
      label: t('widgets.patient.devices'),
      children: <Devices />,
    },
    {
      key: 'activity',
      label: t('widgets.patient.activity'),
      children: <Activity />,
    },
    {
      key: 'documents',
      label: t('widgets.patient.documents'),
      children: <Documents />,
    },
    {
      key: 'program',
      label: t('widgets.patient.program'),
      children: <Program />,
    },
  ]

  return (
    <Card>
      <Tabs activeKey={tab} onChange={onTabChange} size="small" items={tabItems} />
    </Card>
  )
}
