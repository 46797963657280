import React from 'react'

import { Archive as ArchiveComponent } from '../../components/Widgets/Archive'
import { GenericWrapper } from './styles'

export function Archive(): JSX.Element {
  return (
    <GenericWrapper>
      <ArchiveComponent />
    </GenericWrapper>
  )
}
