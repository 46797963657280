import { Button, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { AnalyticsAggregationEnum, AnalyticsTypeEnum } from '@vetahealth/tuna-can-api'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from '../../../Router/routes'
import { useLoading } from '../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { AggregatedAnalytics, useAnalyticsStore } from '../../../stores/analytics'
import { useUserStore } from '../../../stores/user'
import { SortableGrid } from '../../SortableGrid'
import { Add } from './Add'
import { Card } from './Card'
import { Filter } from './Filter'
import { getCardItems, getChartAggregationLevel, isAnalyticsStoredForTypes } from './helpers'
import { Actions, Bar, Main } from './styles'

export function Dashboard() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, withLoading] = useLoading()
  const [aggregatedAnalytics, getAggregatedAnalytics] = useAnalyticsStore((state) => [
    state.aggregatedAnalytics,
    state.getAggregatedAnalytics,
  ])
  const [analyticsSettings, updateAnalyticsSettings] = useUserStore((state) => [
    state.analyticsSettings,
    state.updateAnalyticsSettings,
  ])

  const [isEditable, setIsEditable] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [compactItems, setCompactItems] = useState<AggregatedAnalytics[]>([])
  const [chartItems, setChartItems] = useState<AggregatedAnalytics[]>([])

  const addableAnalyticsTypes = useMemo(
    () =>
      Object.values(AnalyticsTypeEnum).filter(
        (type) => ![...compactItems, ...chartItems].map((item) => item.type).includes(type),
      ),
    [compactItems, chartItems],
  )

  async function getAnalyticsData(
    siteKey: string,
    types: AnalyticsTypeEnum[],
    programs: number[],
    aggregationLevel: AnalyticsAggregationEnum,
    start: string,
    end: string,
  ): Promise<void> {
    const chartAggregationLevel = getChartAggregationLevel(aggregationLevel)
    if (!isAnalyticsStoredForTypes(types, aggregatedAnalytics)) {
      // Note: On filter change, aggregatedAnalytics and rawAnalytics are reset
      await withLoading(getAggregatedAnalytics(siteKey, types, programs, chartAggregationLevel, start, end))
    }
  }

  function handleEdit(): void {
    trackEvent(TrackingEvent.analyticsEditClicked)
    setIsEditable(true)
  }

  async function handleSave(): Promise<void> {
    trackEvent(TrackingEvent.analyticsSaveClicked)
    setIsEditable(false)
    const success = await updateAnalyticsSettings({
      compact: compactItems.map((item) => item.type),
      chart: chartItems.map((item) => item.type),
    })
    if (success) message.success(t('message.dashboardUpdated'))
  }

  function handleCancel(): void {
    setIsEditable(false)
    if (aggregatedAnalytics && analyticsSettings) {
      setCompactItems(getCardItems(analyticsSettings.compact, aggregatedAnalytics))
      setChartItems(getCardItems(analyticsSettings.chart, aggregatedAnalytics))
    }
  }

  function handleOnAdd(displayFormat: 'compact' | 'chart', item: AggregatedAnalytics): void {
    trackEvent(TrackingEvent.analyticsAddClicked, { item: item.type, displayFormat })
    if (displayFormat === 'compact') setCompactItems((prevItems) => [item, ...prevItems])
    if (displayFormat === 'chart') setChartItems((prevItems) => [item, ...prevItems])
    setIsModalOpen(false)
  }

  function handleGridChange(ids: string[], displayFormat: 'compact' | 'chart'): void {
    trackEvent(TrackingEvent.analyticsGridChanged, { types: ids, displayFormat })
    if (displayFormat === 'compact') {
      setCompactItems((prevItems) => ids.map((id) => prevItems.find((item) => item.id === id)).filter(isDefined))
    } else {
      setChartItems((prevItems) => ids.map((id) => prevItems.find((item) => item.id === id)).filter(isDefined))
    }
  }

  function handleOnCardClick(chartId: string): void {
    navigate(routes.analyticDetails(chartId))
  }

  useEffect(() => {
    const { site, programs, aggregationLevel, dateRange, chart, compact } = analyticsSettings || {}

    if (site && programs && aggregationLevel && dateRange && chart && compact) {
      getAnalyticsData(site, [...chart, ...compact], programs, aggregationLevel, dateRange[0], dateRange[1])
    }
  }, [analyticsSettings])

  useEffect(() => {
    if (aggregatedAnalytics && analyticsSettings && !isModalOpen) {
      setCompactItems(getCardItems(analyticsSettings.compact, aggregatedAnalytics))
      setChartItems(getCardItems(analyticsSettings.chart, aggregatedAnalytics))
    }
  }, [aggregatedAnalytics, analyticsSettings])

  return (
    <>
      <Bar>
        <Filter />
        <Actions>
          {isEditable && (
            <>
              <Button
                disabled={!addableAnalyticsTypes.length}
                onClick={() => setIsModalOpen(true)}
                shape="round"
                icon={<FontAwesomeIcon icon={faPlus} />}
              >
                {t('actions.add')}
              </Button>
              <Button onClick={handleCancel}>{t('actions.cancel')}</Button>
              <Button onClick={handleSave} type="primary">
                {t('actions.save')}
              </Button>
            </>
          )}
          {!isEditable && location.pathname.endsWith('analytics') && (
            <Button onClick={handleEdit}>{t('actions.edit')}</Button>
          )}
        </Actions>
      </Bar>
      <Main>
        <SortableGrid
          gridColumns={4}
          items={compactItems}
          render={(item, isEditable, onDelete) => (
            <Card
              isCompact
              key={item.type}
              item={item}
              isEditable={isEditable}
              isLoading={isLoading}
              onDelete={onDelete}
              onOpen={handleOnCardClick}
            />
          )}
          onChange={(ids) => handleGridChange(ids, 'compact')}
          isEditable={isEditable}
        />
        <SortableGrid
          gridColumns={4}
          items={chartItems}
          render={(item, isEditable, onDelete) => (
            <Card
              key={item.type}
              item={item}
              isEditable={isEditable}
              isLoading={isLoading}
              onDelete={onDelete}
              onOpen={handleOnCardClick}
            />
          )}
          onChange={(ids) => handleGridChange(ids, 'chart')}
          isEditable={isEditable}
        />
        <Add
          onAdd={handleOnAdd}
          isVisible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          types={addableAnalyticsTypes}
        />
      </Main>
    </>
  )
}
