import { faPlus, faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { Button, Input, InputRef, Popconfirm, Tag, Tooltip } from 'antd'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { usePatientStore } from '../../../stores/patient'

const Wrap = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 12px;
  .ant-tag {
    cursor: pointer;
    margin: 0;
    padding: 2px 6px;
    background-color: ${({ theme }) => theme.secondaryBackground};
    font-size: 14px;
    display: flex;
    align-items: center;
    border: none;
  }
  input {
    width: auto;
    vertical-align: top;
  }
  .ant-tooltip .ant-tooltip-inner {
    color: black !important;
  }
`
const QuickNoteWrapper = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export function QuickNotes(): JSX.Element {
  const { t } = useTranslation()
  const [patient, updatePatient] = usePatientStore((state) => [state.patient, state.updatePatient])
  const [quickNotes, setQuickNotes] = useState<string[]>(patient?.quickNotes || [])
  const [isAddInputVisible, setIsAddInputVisible] = useState(false)
  const [addValue, setAddValue] = useState('')
  const [editIndex, setEditIndex] = useState(-1)
  const [editValue, setEditValue] = useState('')
  const [popConfirmIndex, setPopConfirmIndex] = useState(-1)
  const addInputRef = useRef<InputRef>(null)
  const editInputRef = useRef<InputRef>(null)
  const hasQuickNotes = quickNotes.length > 0

  const handleDelete = (index: number): void => {
    setQuickNotes((oldNotes) => {
      const newNotes = oldNotes.filter((_, quickNoteIndex) => quickNoteIndex !== index)
      if (patient) void updatePatient(patient, { quickNotes: newNotes })
      return newNotes
    })
    setPopConfirmIndex(-1)
  }

  const handleAddChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAddValue(e.target.value)
  }

  const handleAddConfirm = (): void => {
    if (addValue && !quickNotes.includes(addValue)) {
      trackEvent(TrackingEvent.quickNotesAddClicked)
      setQuickNotes((oldNotes) => {
        const newNotes = [...oldNotes, addValue]
        if (patient) void updatePatient(patient, { quickNotes: newNotes })
        return newNotes
      })
    }
    setIsAddInputVisible(false)
    setAddValue('')
  }

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEditValue(e.target.value)
  }

  const handleEditConfirm = (): void => {
    if (editValue && !quickNotes.includes(editValue)) {
      trackEvent(TrackingEvent.quickNotesChangeClicked)
      setQuickNotes((oldNotes) => {
        const newNotes = [...oldNotes]
        newNotes[editIndex] = editValue
        if (patient) void updatePatient(patient, { quickNotes: newNotes })
        return newNotes
      })
    }
    setEditIndex(-1)
  }

  return (
    <Wrap>
      {quickNotes.map((quickNote, index) => {
        if (editIndex === index) {
          return (
            <Input
              autoFocus
              ref={editInputRef}
              key={index}
              size="small"
              value={editValue}
              onChange={handleEditChange}
              onBlur={handleEditConfirm}
              onPressEnter={handleEditConfirm}
            />
          )
        }

        return (
          <Tooltip
            title={quickNote}
            mouseEnterDelay={1}
            key={index}
            open={popConfirmIndex === index ? false : undefined}
          >
            <Popconfirm
              title={t('widgets.patient.confirmDeleteQuickNote')}
              open={popConfirmIndex === index}
              okText={t('actions.delete')}
              okButtonProps={{ danger: true }}
              cancelText={t('actions.cancel')}
              onConfirm={() => handleDelete(index)}
              onCancel={() => setPopConfirmIndex(-1)}
              icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
            >
              <Tag
                key={index}
                onClick={(e) => {
                  e.preventDefault()
                  setEditIndex(index)
                  setEditValue(quickNote)
                }}
                closable
                onClose={(e) => {
                  e.preventDefault()
                  setPopConfirmIndex(index)
                }}
              >
                <QuickNoteWrapper>{quickNote}</QuickNoteWrapper>
              </Tag>
            </Popconfirm>
          </Tooltip>
        )
      })}
      {isAddInputVisible ? (
        <Input
          autoFocus
          ref={addInputRef}
          type="text"
          size="small"
          value={addValue}
          onChange={handleAddChange}
          onBlur={handleAddConfirm}
          onPressEnter={handleAddConfirm}
        />
      ) : (
        <Button shape={hasQuickNotes ? 'circle' : 'default'} size="small" onClick={() => setIsAddInputVisible(true)}>
          {hasQuickNotes ? <FontAwesomeIcon icon={faPlus} /> : t('widgets.patient.addQuickNote')}
        </Button>
      )}
    </Wrap>
  )
}
