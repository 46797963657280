import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Table } from 'antd'
import { FormInstance } from 'antd/lib'
import { FieldData } from 'rc-field-form/lib/interface'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTableData } from '../../helpers'
import { getFields } from './helpers'
import { RoleTableFormValues } from './types'

interface PermissionTableProps {
  roles: FieldData[]
  form: FormInstance
  onAddRole: () => void
  onSubmit: (role: RoleTableFormValues) => void
  onDelete: (id: string) => void
  onRemoveRole: (index: number) => void
}

export const PermissionTable = ({ roles, form, onAddRole, onRemoveRole, onSubmit, onDelete }: PermissionTableProps) => {
  const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined)
  const [isNewRole, setIsNewRole] = useState<boolean>(false)
  const { t } = useTranslation()

  function handleAddRole() {
    onAddRole()
    setEditingIndex(roles.length)
    setIsNewRole(true)
  }

  async function handleSubmit(index: number) {
    try {
      const { roles } = await form.validateFields()
      setIsNewRole(false)
      setEditingIndex(undefined)
      onSubmit(roles[index])
    } catch (_) {}
  }

  function handleCancel(index: number) {
    if (isNewRole) {
      onRemoveRole(index)
    } else {
      form.resetFields([['roles', index]])
    }
    setIsNewRole(false)
    setEditingIndex(undefined)
  }

  function handleDelete(index: number) {
    const roles = form.getFieldValue('roles')
    onRemoveRole(index)
    setEditingIndex(undefined)
    onDelete(roles[index].id)
  }

  const { columns, dataSource } = getTableData({
    data: roles,
    fields: getFields({
      t,
      editingIndex,
      onSave: handleSubmit,
      onCancel: handleCancel,
      onDelete: handleDelete,
      onEdit: (index) => setEditingIndex(index),
    }),
  })

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      bordered
      scroll={{ x: '100%' }}
      footer={() => (
        <Button onClick={handleAddRole} disabled={!!editingIndex}>
          <FontAwesomeIcon icon={faPlus} />
          {t('form.addRole')}
        </Button>
      )}
    />
  )
}
