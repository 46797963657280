import { Colors } from '@vetahealth/fishing-gear/colors'
import { Asset, TaskResponseContent } from '@vetahealth/tuna-can-api'
import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { DatoAssets } from '../Assets'

const Wrapper = styled.div`
  background-color: ${Colors.mauve100};
  padding: 30px;
  max-width: 600px;
  border-radius: 4px;
`

export function Task({ content, title }: TaskResponseContent & { title?: string }): React.ReactNode {
  const assets = (content?.assets as unknown as Asset[]) ?? []

  return (
    <Wrapper>
      {!!title && <Typography.Title level={5}>{title}</Typography.Title>}
      <DatoAssets assets={assets} />
    </Wrapper>
  )
}
