import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { CareManager, CareManagerRole } from '@vetahealth/tuna-can-api'
import { Button, Form, Modal } from 'antd'
import { BaseOptionType } from 'antd/es/select'
import { isEqual, uniqBy } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '../../../lib/hooks/useLoading'
import { useSitesStore } from '../../../stores/sites'
import { useUserStore } from '../../../stores/user'
import { UserFormValues } from '../../Forms'
import { User } from '../../Forms/User'
import { getManageableUserSites } from './helpers'

interface UserModalProps {
  isVisible: boolean
  onSubmit: (user: UserFormValues) => Promise<void>
  onCancel: () => void
  user?: CareManager
  roles: CareManagerRole[]
}

const allSitesKey = '.all.' // note: full stops are not valid in site keys

export function UserModal({ isVisible, user, roles, onSubmit, onCancel }: UserModalProps): JSX.Element {
  const [form] = Form.useForm<UserFormValues>()
  const [isLoading, withLoading] = useLoading()
  const [hasPermission, permissions] = useUserStore((state) => [state.hasPermission, state.permissions])
  const [sites, getSites] = useSitesStore((state) => [state.sites, state.getSites])
  const [isChanged, setIsChanged] = useState<boolean>(false)

  const { t } = useTranslation()

  const presetOptions = useMemo(() => {
    if (!sites || !permissions) return []
    const userSites = getManageableUserSites(permissions)
    return uniqBy(
      userSites
        .map((siteKey) => sites.find((site) => site.key === siteKey))
        .filter(isDefined)
        .flatMap((site) => site.viewPresets)
        .map((preset) => ({ label: preset.name, value: preset.id })),
      'value',
    )
  }, [sites, permissions])

  const initialState = useMemo(() => {
    if (!user) {
      return {
        viewPreset: presetOptions[0]?.value,
        active: true,
        roles: [{ roleId: undefined, site: undefined }],
      }
    }
    // if the manager does not have the permission to manage users of all sites, we remove global roles from the
    // role set, since they cannot be edited
    return {
      active: user.active,
      credentials: user.credentials,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      roles: user.roles
        .filter(({ site }) => !!site || hasPermission(PermissionName.manageUser))
        .map(({ roleId, site }) => ({ roleId, site: site ?? allSitesKey })),
    }
  }, [user, presetOptions[0]?.value])

  const rolesOptions = roles.map((role) => ({
    value: role.id,
    label: role.name,
  }))

  const siteOptions = useMemo(() => {
    if (!permissions) return []
    const options: BaseOptionType[] = hasPermission(PermissionName.manageAdmin)
      ? (sites
          ?.map<BaseOptionType>((site) => ({ value: site.key, label: site.key }))
          .concat([{ value: allSitesKey, label: 'All sites' }]) ?? [])
      : getManageableUserSites(permissions).map((siteKey) => ({ value: siteKey, label: siteKey }))
    return options
  }, [permissions, sites])

  // FIXME: THROWS WARNINGS FOR FORM
  useEffect(() => {
    form.resetFields()
    setIsChanged(false)
    form.setFieldsValue(initialState)
  }, [initialState, form])

  void getSites()

  function handleSubmit(userValues: UserFormValues): void {
    void withLoading(
      onSubmit({
        ...userValues,
        roles: userValues.roles.map(({ roleId, site }) => ({ roleId, site: site === allSitesKey ? null : site })),
      }),
    )
  }

  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      maskClosable={false}
      title={user ? t('widgets.users.updateUser') : t('widgets.users.createUser')}
      width={1000}
      centered
      footer={
        <Button form="user" disabled={isLoading || !isChanged} loading={isLoading} type="primary" htmlType="submit">
          {t('actions.save')}
        </Button>
      }
    >
      <Form
        name="user"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFieldsChange={() => {
          setIsChanged(!isEqual(initialState, form.getFieldsValue()))
        }}
      >
        <User
          presetOptions={presetOptions}
          rolesOptions={rolesOptions}
          siteOptions={siteOptions}
          permissionsOnly={user && !user.editable}
          isActive={!user || user.active}
          isNew={!user}
        />
      </Form>
    </Modal>
  )
}
