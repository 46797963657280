import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import {
  Alert,
  AlertTypeEnum,
  RiskAlertConfig,
  TaskAlertMeta,
  TrackingAlertConfig,
  TrackingAlertMeta,
} from '@vetahealth/tuna-can-api'

export function isAlertUnreviewed(alert: Alert): boolean {
  return alert.reviewType === undefined || alert.reviewType === 'withdrawn'
}

export function isTrackingAlert(alert: Alert): alert is Omit<
  Alert,
  'meta' | 'type' | 'trackingEventId' | 'alertConfig'
> & {
  meta: TrackingAlertMeta
  type: AlertTypeEnum
  trackingEventId: number
  alertConfig: TrackingAlertConfig
} {
  return !!alert.meta && 'values' in alert.meta
}

export function isRiskScoreAlert(
  alert: Alert,
): alert is Omit<Alert, 'taskResponseId' | 'alertConfig'> & { taskResponseId: number; alertConfig: RiskAlertConfig } {
  return alert.type === 'riskScore' && isDefined(alert.taskResponseId)
}

export function isTaskAlert(
  alert: Alert,
): alert is Omit<Alert, 'meta' | 'taskResponseId'> & { meta: TaskAlertMeta; taskResponseId: number } {
  return alert.type === 'task' && isDefined(alert.taskResponseId) && isDefined(alert.meta)
}
