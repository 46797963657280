import { groupBy, sortBy } from 'lodash-es'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { AlertConfig } from '../../lib/api'
import { Alert } from './Alert'
import { TrackingAlertWizard } from './Wizard'
import { convertTrackingAlertSubjectToTrackingType, getAlertDefinition, isTrackingAlertConfig } from './helpers'
import { TrackingAlertConfigWithId } from './types'

const Wrap = styled.div`
  margin-top: 12px;
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`

const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-left: 5px;
`

const AlertWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  gap: 5px;
`

const Section = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBackground};
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const Dot = styled.div<{ $color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${({ $color }) => $color};
  left: 50%;
`

type AlertsProps = {
  alertConfigs?: AlertConfig[]
  onUpdate: (index: number, updatedAlertConfig: AlertConfig, applyToEnrolled: boolean) => Promise<boolean>
  onDelete: (index: number, applyToEnrolled: boolean) => Promise<boolean>
  showApplyToEnrolled?: boolean
  isEditable?: boolean
}

export function Alerts({
  alertConfigs,
  onUpdate,
  onDelete,
  showApplyToEnrolled = false,
  isEditable = true,
}: AlertsProps): JSX.Element | null {
  const theme = useTheme()

  if (!alertConfigs) return null

  const groupedAlertConfigs = groupBy(
    alertConfigs.map((alertConfig, id) => ({ id, ...alertConfig })),
    (alertConfig) =>
      isTrackingAlertConfig(alertConfig)
        ? convertTrackingAlertSubjectToTrackingType(alertConfig.subject)
        : alertConfig.datoId,
  )

  return (
    <Wrap>
      {sortBy(Object.entries(groupedAlertConfigs), '0').map(
        ([category, alertConfigsByCategory]: [string, TrackingAlertConfigWithId[]]) => {
          const { color, title } = getAlertDefinition(category, theme)
          return (
            <Section key={category}>
              <TitleWrap>
                <Dot $color={color.active} />
                <Title>{title}</Title>
              </TitleWrap>
              <AlertWrap>
                {alertConfigsByCategory.map((alertConfig, index) => {
                  if (isTrackingAlertConfig(alertConfig))
                    return (
                      <TrackingAlertWizard
                        key={index}
                        editAlertConfig={alertConfig}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        showApplyToEnrolled={showApplyToEnrolled}
                        render={(openTrackingAlertWizard) => (
                          <Alert onClick={isEditable ? openTrackingAlertWizard : undefined} alertConfig={alertConfig} />
                        )}
                      />
                    )
                  return <Alert key={index} alertConfig={alertConfig} />
                })}
              </AlertWrap>
            </Section>
          )
        },
      )}
    </Wrap>
  )
}
