import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { Button, Popconfirm } from 'antd'
import { produce } from 'immer'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from '../../../Router/routes'
import { PatientDetailsTab } from '../../../Router/routes/PatientDetails'
import { usePatientStore } from '../../../stores/patient'
import { useResultsStore } from '../../../stores/results'
import { ReviewBulk, ReviewBulkActions } from '../PatientDetails/Results/Actions'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / span 2;
  align-content: flex-start;
  align-items: center;
  gap: 5px;
`

export function Deviations(): JSX.Element | null {
  const { t } = useTranslation()
  const alertModalRef = useRef<ReviewBulkActions>(null)
  const [patient, updatePatient] = usePatientStore((state) => [state.patient, state.updatePatient])
  const [alerts] = useResultsStore((state) => [state.alerts])
  const navigate = useNavigate()

  const isMuted = patient?.preferences.notifications.muted !== 'none'
  const hasUnreviewedAlerts = alerts.unreviewed.length > 0
  const isLocked = patient?.status === 'LOCKED'
  const isArchived = patient?.status === 'ARCHIVED'
  const hasNoProgram = patient?.status === 'ACTIVE' && !patient.program
  const hasDeviations = isMuted || hasUnreviewedAlerts || isLocked || isArchived || hasNoProgram

  function handleUnmute(): void {
    if (patient) {
      void updatePatient(
        patient,
        produce(patient, (draft) => {
          draft.preferences.notifications.muted = 'none'
        }),
      )
    }
  }

  function handleUnlock(): void {
    if (patient) {
      void updatePatient(
        patient,
        produce(patient, (draft) => {
          draft.status = 'ACTIVE'
        }),
      )
    }
  }

  function handleNoProgramClick(): void {
    if (patient) {
      navigate({
        pathname: routes.patientDetails(patient.id, PatientDetailsTab.program),
        search: window.location.search,
      })
    }
  }

  if (!patient || !hasDeviations) return null

  return (
    <Wrapper>
      {hasNoProgram && (
        <Button danger type="primary" size="small" onClick={handleNoProgramClick}>
          {t('deviations.noProgram')}
        </Button>
      )}
      {hasUnreviewedAlerts && (
        <Button danger type="primary" size="small" onClick={() => alertModalRef.current?.open()}>
          {t('deviations.unreviewedAlerts')}
        </Button>
      )}
      <ReviewBulk type="summary" hasButton={false} ref={alertModalRef} />
      {isLocked && (
        <Popconfirm
          overlayStyle={{ maxWidth: 400 }}
          placement="right"
          title={t('deviations.unlock.title')}
          cancelButtonProps={{ type: 'text' }}
          cancelText={t('actions.cancel')}
          okText={t('deviations.unlock.action')}
          onConfirm={handleUnlock}
          icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
        >
          <Button danger type="primary" size="small">
            {t('deviations.locked')}
          </Button>
        </Popconfirm>
      )}
      {isArchived && (
        <Popconfirm
          overlayStyle={{ maxWidth: 400 }}
          placement="right"
          title={t('deviations.reactivate.title')}
          cancelButtonProps={{ type: 'text' }}
          cancelText={t('actions.cancel')}
          okText={t('deviations.reactivate.action')}
          onConfirm={handleUnlock}
          icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
        >
          <Button danger type="primary" size="small">
            {t('deviations.archived')}
          </Button>
        </Popconfirm>
      )}
      {patient.preferences.notifications.muted === 'permanent' && (
        <Popconfirm
          overlayStyle={{ maxWidth: 400 }}
          placement="right"
          title={t('deviations.unmute.title')}
          cancelButtonProps={{ type: 'text' }}
          cancelText={t('actions.cancel')}
          okText={t('deviations.unmute.action')}
          onConfirm={handleUnmute}
          icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
        >
          <Button danger type="primary" size="small">
            {t('deviations.mutedPermanently')}
          </Button>
        </Popconfirm>
      )}
      {patient.preferences.notifications.muted === 'untilNextMeasurement' && (
        <Popconfirm
          overlayStyle={{ maxWidth: 400 }}
          placement="right"
          title={t('deviations.unmute.title')}
          cancelButtonProps={{ type: 'text' }}
          cancelText={t('actions.cancel')}
          okText={t('deviations.unmute.action')}
          onConfirm={handleUnmute}
          icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
        >
          <Button danger type="primary" size="small">
            {t('deviations.mutedUntilNextMeasurement')}
          </Button>
        </Popconfirm>
      )}
    </Wrapper>
  )
}
