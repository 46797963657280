import { Components, renderDatoAssets } from '@vetahealth/fishing-gear/react/datoAssets'
import { Asset } from '@vetahealth/tuna-can-api'
import React from 'react'
import { Checkbox } from './Checkbox'
import { Image } from './Image'
import { Markdown } from './Markdown'
import { Radio } from './Radio'
import { Scale } from './Scale'
import { TextInput } from './TextInput'
import { VideoPlayer } from './VideoPlayer'
import { YouTubePlayer } from './YouTubePlayer'

function getComponents(): Components {
  const components: Components = {
    Markdown,
    Question: ({ text }) => <Markdown text={text} />,
    Answer: ({ text }) => <Markdown text={text} />,
    Image: ({ src, caption }) => <Image src={src} caption={caption} />,
    Video: ({ src }) => <VideoPlayer uri={src} />,
    Youtube: ({ id }) => <YouTubePlayer id={id} />,
    Scale: ({ asset }) => <Scale asset={asset} />,
    TextInput: ({ asset }) => <TextInput asset={asset} />,
    Checkbox: ({ asset }) => <Checkbox asset={asset} />,
    Radio: ({ asset }) => <Radio asset={asset} />,
  }

  return components
}

export function DatoAssets({ assets }: { assets: Asset[] }): JSX.Element {
  return <div>{renderDatoAssets(assets, getComponents())}</div>
}
