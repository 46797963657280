import { Colors } from '@vetahealth/fishing-gear/colors'
import { defaultUnits } from '@vetahealth/fishing-gear/conversions'
import { TrackingTypeEnum } from '@vetahealth/tuna-can-api'
import { Flex, Form, Tag, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SiteGeneralSettings } from '../../../Forms/SiteGeneralSettings'
import { Badge, BadgeLabel, BadgeValue } from '../../../Forms/styles'
import { getTrackingTitle } from '../../helpers'
import { SubTitle } from '../../styles'
import { SiteUpdateProps } from '../SiteManagement'

const Description = styled(Typography.Text)`
  font-size: 16px;
  opacity: 0.6;
  display: block;
  margin-bottom: 12px;
`
const Units = styled.div`
  flex: 1;
  margin-right: 30px;
`
const Language = styled.div`
  flex: 2;
`

const trackingTypes = Object.values(TrackingTypeEnum)

export function Settings({
  site,
  isEditable,
  form,
  handleSiteUpdate,
}: SiteUpdateProps & { isEditable: boolean }): JSX.Element {
  const { t } = useTranslation()

  if (!site) return <div />

  return (
    <div>
      {/* User admins are allowed to edit a site */}
      <SubTitle>{t('widgets.siteManagement.settings.generalTitle')}</SubTitle>
      <Description>
        {t('widgets.siteManagement.settings.description')} <Tag color={Colors.anthrazit500}>{site.key}</Tag>
      </Description>
      {isEditable ? (
        <Form
          form={form}
          layout="vertical"
          validateTrigger="onChange"
          onValuesChange={handleSiteUpdate}
          onFinish={handleSiteUpdate}
          scrollToFirstError
        >
          <SiteGeneralSettings form={form} />
        </Form>
      ) : (
        <Flex>
          <Units>
            <SubTitle>{t('widgets.siteManagement.settings.units')}</SubTitle>
            {trackingTypes.map((type: TrackingTypeEnum) => {
              return (
                <Badge key={type} $isActive>
                  <BadgeLabel $isActive>{getTrackingTitle(type)}</BadgeLabel>
                  <BadgeValue>{defaultUnits[type]?.length ? defaultUnits[type] : '-'}</BadgeValue>
                </Badge>
              )
            })}
          </Units>
          <Language>
            <SubTitle>{t('widgets.siteManagement.settings.language')}</SubTitle>
            <Typography.Text>{site.defaultLocale}</Typography.Text>
          </Language>
        </Flex>
      )}
    </div>
  )
}
