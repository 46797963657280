import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Select, SelectProps } from 'antd'

interface TagSelectProps extends SelectProps {
  tagOptions: string[]
  getCustomAddLabel?: (newTag: string) => string
}

const searchCaseInsensitive = (tags: string[], query: string): string | undefined => {
  return tags.find((tag) => tag.toLowerCase() === query.toLowerCase())
}

const toSelectOption = (tag: string): { value: string; label: string } => ({ value: tag, label: tag })

export default function TagSelect({ tagOptions, getCustomAddLabel, ...antProps }: TagSelectProps): JSX.Element {
  const { t } = useTranslation()
  const [tags, setTags] = useState([...tagOptions].sort())
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setTags([...tagOptions].sort())
  }, [tagOptions, setTags])

  const onSelect = (value: string): void => {
    const hasTag = tags.find((tag) => tag.toLowerCase() === value.toLowerCase())

    if (!hasTag) {
      setTags([...tags, value].sort())
      setSearchValue('')
    }
  }

  const selectOptions =
    searchValue && !searchCaseInsensitive(tags, searchValue)
      ? [
          ...tags.map(toSelectOption),
          {
            value: searchValue,
            label: getCustomAddLabel ? getCustomAddLabel(searchValue) : t('form.tagSelectAdd', { newTag: searchValue }),
          },
        ]
      : [...tags.map(toSelectOption)]

  return <Select options={selectOptions} mode="multiple" onSearch={setSearchValue} onSelect={onSelect} {...antProps} />
}
