import { Consent, ConsentMethodEnum, ConsentRelationshipEnum } from '@vetahealth/tuna-can-api'
import { Button, DatePicker, Form, Input, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { isEmpty, omitBy } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { usePatientStore } from '../../../../stores/patient'
import { useUserStore } from '../../../../stores/user'
import { FormKeys } from '../../../Forms'

export function ConsentModal({
  isVisible,
  onCancel,
  onConsent,
}: {
  isVisible: boolean
  onCancel: () => void
  onConsent: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const [consentForm] = Form.useForm()
  const [patient, consent, getConsent, setConsent] = usePatientStore((state) => [
    state.patient,
    state.consent,
    state.getConsent,
    state.setConsent,
  ])
  const [consentMethod, setConsentMethod] = useState<ConsentMethodEnum>()
  const [firstName, lastName] = useUserStore((state) => [state.firstName, state.lastName])
  const [loading, withLoading] = useLoading()

  async function handleConsentSubmit(values: Consent): Promise<void> {
    const patientConsent = {
      ...omitBy(values, isEmpty),
      consentedAt: dayjs(values.consentedAt).toISOString(),
    }

    const success = await withLoading(setConsent(patientConsent as Consent))

    if (success) {
      onConsent()
    }
  }

  useEffect(() => {
    if (!patient?.id) return
    getConsent()
  }, [patient?.id])

  useEffect(() => {
    // set contact to email
    if (consentMethod === ConsentMethodEnum.Email) {
      if (patient?.email) return consentForm.setFieldsValue({ [FormKeys.CONTACT_INFO]: patient.email })
    }
    // set contact to phone (landline or mobile)
    if (consentMethod === ConsentMethodEnum.PhoneCall) {
      if (patient?.landlinePhone || patient?.mobilePhone) {
        return consentForm.setFieldsValue({ [FormKeys.CONTACT_INFO]: patient.landlinePhone || patient.mobilePhone })
      }
    }
  }, [consentMethod])

  return (
    <Modal
      title={t('widgets.patientEdit.onboardingConsent')}
      open={isVisible}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      centered
      footer={
        <Button
          form="consent"
          disabled={loading}
          loading={loading}
          style={{ width: '120px' }}
          htmlType="submit"
          type="primary"
        >
          {t('actions.ok')}
        </Button>
      }
    >
      <Form
        name="consent"
        form={consentForm}
        initialValues={{
          [FormKeys.RELATIONSHIP]: consent?.relationship || ConsentRelationshipEnum.Self,
          [FormKeys.CONSENTER]: consent?.consenter || (patient ? `${patient.firstName} ${patient.lastName}` : ''),
          [FormKeys.CONTACT_INFO]:
            consent?.contactInformation || patient?.landlinePhone || patient?.mobilePhone || patient?.email || '',
          [FormKeys.METHOD]: consent?.method || ConsentMethodEnum.PhoneCall,
          [FormKeys.CONSENTED_AT]: dayjs(consent?.consentedAt),
          [FormKeys.WITNESS]: consent?.witness || `${firstName ?? ''} ${lastName ?? ''}`.trim(),
          [FormKeys.NOTES]: consent?.notes || '',
        }}
        onFinish={handleConsentSubmit}
        layout="vertical"
        validateTrigger="onChange"
      >
        <Form.Item
          name={FormKeys.CONSENTER}
          label={t('form.consenter')}
          rules={[{ required: true, message: t('validations.enterConsenter') }]}
        >
          <Input placeholder={t('placeholders.consenter')} />
        </Form.Item>
        <Form.Item name={FormKeys.RELATIONSHIP} label={t('form.relationship')} required>
          <Select
            options={[
              { value: ConsentRelationshipEnum.Self, label: t('consents.relationship.self') },
              { value: ConsentRelationshipEnum.Partner, label: t('consents.relationship.partner') },
              { value: ConsentRelationshipEnum.Child, label: t('consents.relationship.child') },
              { value: ConsentRelationshipEnum.Grandchild, label: t('consents.relationship.grandchild') },
              { value: ConsentRelationshipEnum.Other, label: t('consents.relationship.other') },
            ]}
            placeholder={t('placeholders.relationship')}
          />
        </Form.Item>
        <Form.Item name={FormKeys.METHOD} label={t('form.method')} required>
          <Select
            options={[
              { value: ConsentMethodEnum.InPerson, label: t('consents.method.inPerson') },
              { value: ConsentMethodEnum.Email, label: t('consents.method.email') },
              { value: ConsentMethodEnum.PhoneCall, label: t('consents.method.phoneCall') },
              { value: ConsentMethodEnum.Voicemail, label: t('consents.method.voicemail') },
            ]}
            onSelect={(value: ConsentMethodEnum) => setConsentMethod(value)}
            placeholder={t('placeholders.method')}
          />
        </Form.Item>
        <Form.Item
          name={FormKeys.CONTACT_INFO}
          label={t('form.contactInformation')}
          rules={[{ required: true, message: t('validations.enterContactInfo') }]}
        >
          <Input placeholder={t('placeholders.contactInformation')} />
        </Form.Item>
        <Form.Item
          name={FormKeys.CONSENTED_AT}
          label={t('form.consentedAt')}
          rules={[{ required: true, message: t('validations.enterConsentedAt') }]}
        >
          <DatePicker format="lll" showTime={{ format: t('dateFormats.time') }} showNow style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={FormKeys.WITNESS}
          label={t('form.witness')}
          rules={[
            {
              required: consentMethod === ConsentMethodEnum.InPerson || consentMethod === ConsentMethodEnum.Email,
              message: t('validations.enterWitness'),
            },
          ]}
        >
          <Input placeholder={t('placeholders.witness')} />
        </Form.Item>
        <Form.Item name={FormKeys.NOTES} label={t('form.notes')}>
          <Input.TextArea placeholder={t('placeholders.notes')} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
