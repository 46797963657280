import { AxiosError } from 'axios'

export const isAxiosError = (res: object | ArrayBuffer | AxiosError | undefined | boolean): res is AxiosError => {
  return !!res && typeof res !== 'boolean' && ('isAxiosError' in res || ('name' in res && res.name === 'AxiosError'))
}

export const isFieldErrorData = (data: unknown): data is { error: { field: string; value: string } } => {
  return (
    !!data &&
    typeof data === 'object' &&
    'error' in data &&
    !!data.error &&
    typeof data.error === 'object' &&
    'field' in data.error &&
    'value' in data.error
  )
}
