import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import {
  AlertComparatorEnum,
  AlertConfigTypeEnum,
  TrackingAlertConfig,
  TrackingAlertConfigAggregationEnum,
  TrackingAlertConfigReferenceAggregationEnum,
  TrackingAlertSubjectEnum,
} from '@vetahealth/tuna-can-api'
import { Button, Checkbox, Divider, InputNumber, Modal, Popconfirm, Select, Tag, message } from 'antd'
import { isEqual, isNumber } from 'lodash-es'
import React, { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { AlertConfig } from '../../../lib/api'
import { useLoading } from '../../../lib/hooks/useLoading'
import { useUserStore } from '../../../stores/user'
import { WizardSection } from '../../Wizard'
import { StylelessButton } from '../../styles'
import { Alert } from '../Alert'
import {
  convertTrackingAlertSubjectToTrackingType,
  getAlertAggregationTitle,
  getAlertConfigExplanations,
  getAlertTypeTitle,
  getDefaultTrackingAlertConfig,
  getThresholdRange,
  getTrackingSubjectTitle,
  getUpdatedAlertConfig,
  printableComparator,
  toTrackingAlertConfig,
  toTrackingAlertWizardConfig,
  trackingUnits,
} from '../helpers'
import { TrackingAlertConfigWithId, TrackingAlertWizardConfig } from '../types'

type TrackingAlertWizardProps = {
  editAlertConfig?: TrackingAlertConfigWithId
  onCreate?: (alertConfig: AlertConfig, applyToEnrolled: boolean) => Promise<boolean>
  onUpdate?: (index: number, updatedAlertConfig: AlertConfig, applyToEnrolled: boolean) => Promise<boolean>
  onDelete?: (index: number, applyToEnrolled: boolean) => Promise<boolean>
  render: (openTrackingAlertWizard: () => void) => React.ReactNode
  showApplyToEnrolled?: boolean
}

type TrackingAlertWizardState = {
  alertConfig?: TrackingAlertWizardConfig
  isVisible: boolean
  applyToEnrolled: boolean
}

const initialState: TrackingAlertWizardState = {
  alertConfig: undefined,
  isVisible: false,
  applyToEnrolled: false,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
const SubjectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`
const AlertWrapper = styled.div`
  margin-bottom: 15px;
`

export function TrackingAlertWizard({
  editAlertConfig,
  onCreate = () => Promise.resolve(false),
  onUpdate = () => Promise.resolve(false),
  onDelete = () => Promise.resolve(false),
  render,
  showApplyToEnrolled = false,
}: TrackingAlertWizardProps): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation()
  const isEditable = isDefined(editAlertConfig)
  const [units] = useUserStore((state) => [state.units])
  const [isLoading, withLoading] = useLoading()
  const [{ isVisible, alertConfig, applyToEnrolled }, set] = useReducer(
    (state: TrackingAlertWizardState, update: Partial<TrackingAlertWizardState>) => ({ ...state, ...update }),
    initialState,
  )

  const comparatorsWithLabel: Record<AlertComparatorEnum, string> = {
    gt: t('alerts.greaterThan'),
    gte: t('alerts.greaterThanEqual'),
    lt: t('alerts.lessThan'),
    lte: t('alerts.lessThanEqual'),
  }

  function handleClose(): void {
    set({ isVisible: false, alertConfig: undefined })
  }

  function handleOpen(): void {
    set({
      isVisible: true,
      alertConfig: isEditable ? toTrackingAlertWizardConfig(editAlertConfig) : undefined,
    })
  }

  function handleSubject(subject: TrackingAlertConfig['subject']): void {
    if (alertConfig?.subject !== subject) {
      set({ alertConfig: getDefaultTrackingAlertConfig(subject, units) })
    }
  }

  function handleAlertConfigUpdate(update: Partial<TrackingAlertWizardConfig>): void {
    if (alertConfig) {
      set({ alertConfig: getUpdatedAlertConfig({ alertConfig, update, units }) })
    }
  }

  const handleSubmit = useCallback(async () => {
    if (alertConfig) {
      const success = isEditable
        ? await withLoading(onUpdate(editAlertConfig.id, toTrackingAlertConfig(alertConfig), applyToEnrolled))
        : await withLoading(onCreate(toTrackingAlertConfig(alertConfig), applyToEnrolled))

      if (success) {
        handleClose()
        message.success(isEditable ? t('message.alertUpdated') : t('message.alertCreated'))
      }
    }
  }, [alertConfig, applyToEnrolled])

  const handleDelete = useCallback(async () => {
    if (isEditable) {
      const success = await onDelete(editAlertConfig.id, applyToEnrolled)

      if (success) {
        handleClose()
        message.success(t('message.alertDeleted'))
      }
    }
  }, [editAlertConfig, applyToEnrolled])

  return (
    <>
      {render(handleOpen)}
      <Modal
        title={isEditable ? t('alerts.wizard.update') : t('alerts.wizard.create')}
        open={isVisible}
        style={{ maxWidth: '800px' }}
        width="66%"
        onCancel={handleClose}
        centered
        footer={
          <>
            {isEditable && (
              <Popconfirm
                title={t('alerts.wizard.confirmDelete')}
                icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
                cancelButtonProps={{ type: 'text' }}
                cancelText={t('actions.cancel')}
                okButtonProps={{ danger: true }}
                okText={t('actions.delete')}
                onConfirm={handleDelete}
              >
                <Button danger>{t('actions.delete')}</Button>
              </Popconfirm>
            )}
            <Button
              disabled={isLoading || !alertConfig || isEqual(alertConfig, editAlertConfig)}
              loading={isLoading}
              onClick={handleSubmit}
              type="primary"
            >
              {t('actions.save')}
            </Button>
          </>
        }
      >
        {alertConfig && (
          <AlertWrapper>
            <Alert alertConfig={toTrackingAlertConfig(alertConfig)} keepAlertUnit />
          </AlertWrapper>
        )}
        <Wrapper>
          <WizardSection question={t('alerts.wizard.question.subject')}>
            <SubjectWrapper>
              {Object.values(TrackingAlertSubjectEnum).map((subject) => (
                <StylelessButton key={subject} onClick={() => handleSubject(subject)}>
                  <Tag
                    color={alertConfig?.subject === subject ? theme.primary : 'default'}
                    style={{ pointerEvents: 'none', margin: 0 }}
                  >
                    {getTrackingSubjectTitle(subject)}
                  </Tag>
                </StylelessButton>
              ))}
            </SubjectWrapper>
          </WizardSection>
          {alertConfig && (
            <Wrapper key={alertConfig.subject}>
              <WizardSection
                question={t('alerts.wizard.question.alertType')}
                explanations={[getAlertConfigExplanations('absolute'), getAlertConfigExplanations('relative')]}
              >
                <Select
                  value={alertConfig.type}
                  onChange={(type) => handleAlertConfigUpdate({ type })}
                  options={Object.values(AlertConfigTypeEnum).map((type) => ({
                    label: getAlertTypeTitle(type),
                    value: type,
                  }))}
                  popupMatchSelectWidth={false}
                />
                <InputNumber
                  min={getThresholdRange(alertConfig)[0]}
                  max={getThresholdRange(alertConfig)[1]}
                  value={alertConfig.threshold}
                  onChange={(threshold) => isNumber(threshold) && handleAlertConfigUpdate({ threshold })}
                  addonBefore={
                    <Select
                      value={alertConfig.comparator}
                      onChange={(comparator) => handleAlertConfigUpdate({ comparator })}
                      options={Object.entries(comparatorsWithLabel).map(
                        ([comparator, label]: [AlertComparatorEnum, string]) => ({
                          label: `${label} ${printableComparator[comparator]}`,
                          value: comparator,
                        }),
                      )}
                      popupMatchSelectWidth={false}
                    />
                  }
                  addonAfter={
                    <Select
                      value={alertConfig.relativeThreshold ? 'relative' : alertConfig.unit}
                      onChange={(unit) => {
                        const isRelative = unit === 'relative'
                        handleAlertConfigUpdate({ unit: isRelative ? '%' : unit, relativeThreshold: isRelative })
                      }}
                      options={[
                        {
                          label: t('alerts.wizard.units.absolute'),
                          options: trackingUnits[convertTrackingAlertSubjectToTrackingType(alertConfig.subject)].map(
                            (unit) => ({ label: unit || '–', value: unit }),
                          ),
                        },
                        {
                          label: t('alerts.wizard.units.relative'),
                          options: [{ label: '%', value: 'relative', disabled: alertConfig.type === 'value' }],
                        },
                      ]}
                      popupMatchSelectWidth={false}
                    />
                  }
                />
              </WizardSection>

              <WizardSection
                question={t('alerts.wizard.question.aggregation')}
                explanations={Object.values(TrackingAlertConfigAggregationEnum).map(getAlertConfigExplanations)}
              >
                <InputNumber
                  min={0}
                  precision={0}
                  value={alertConfig.aggregationTimespan}
                  onChange={(aggregationTimespan) =>
                    aggregationTimespan !== null && handleAlertConfigUpdate({ aggregationTimespan })
                  }
                  formatter={(i) => (i ? Math.round(i).toString() : '0')}
                  disabled={alertConfig.aggregation === 'none'}
                  addonBefore={
                    <Select
                      options={[
                        {
                          label: getAlertAggregationTitle('none'),
                          value: 'none',
                        },
                        {
                          label: getAlertAggregationTitle('avg'),
                          value: 'avg',
                        },
                        {
                          label: getAlertAggregationTitle('avgHours'),
                          value: 'avgHours',
                        },
                      ]}
                      value={alertConfig.aggregation}
                      onChange={(aggregation) => handleAlertConfigUpdate({ aggregation })}
                      popupMatchSelectWidth={false}
                    />
                  }
                />
              </WizardSection>

              <WizardSection
                question={t('alerts.wizard.question.reference')}
                explanations={[...Object.values(TrackingAlertConfigReferenceAggregationEnum), 'offset'].map(
                  getAlertConfigExplanations,
                )}
              >
                <InputNumber
                  min={0}
                  precision={0}
                  value={alertConfig.referenceAggregationTimespan}
                  onChange={(referenceAggregationTimespan) =>
                    referenceAggregationTimespan !== null && handleAlertConfigUpdate({ referenceAggregationTimespan })
                  }
                  formatter={(input) => (input ? Math.round(input).toString() : '0')}
                  disabled={
                    alertConfig.type === 'value' ||
                    alertConfig.aggregation === 'avg' ||
                    alertConfig.aggregation === 'avgHours' ||
                    alertConfig.referenceAggregation === 'none'
                  }
                  addonBefore={
                    <Select
                      value={alertConfig.referenceAggregation}
                      onChange={(referenceAggregation) => handleAlertConfigUpdate({ referenceAggregation })}
                      options={[
                        {
                          label: getAlertAggregationTitle('none'),
                          value: 'none',
                        },
                        {
                          label: getAlertAggregationTitle('avg'),
                          value: 'avg',
                        },
                        {
                          label: getAlertAggregationTitle('avgHours'),
                          value: 'avgHours',
                        },
                        {
                          label: getAlertAggregationTitle('range'),
                          value: 'range',
                        },
                        {
                          label: getAlertAggregationTitle('rangeHours'),
                          value: 'rangeHours',
                        },
                      ]}
                      disabled={
                        alertConfig.type === 'value' ||
                        alertConfig.aggregation === 'avg' ||
                        alertConfig.aggregation === 'avgHours'
                      }
                      popupMatchSelectWidth={false}
                    />
                  }
                />
                <InputNumber
                  min={0}
                  value={alertConfig.referenceOffsetTimespan}
                  onChange={(referenceOffsetTimespan) =>
                    isNumber(referenceOffsetTimespan) && handleAlertConfigUpdate({ referenceOffsetTimespan })
                  }
                  formatter={(i) => (i ? Math.round(i).toString() : '0')}
                  disabled={
                    alertConfig.type === 'value' ||
                    alertConfig.referenceAggregation === 'range' ||
                    alertConfig.referenceAggregation === 'rangeHours'
                  }
                  addonBefore={
                    <Select
                      value={alertConfig.referenceUnit}
                      onChange={(referenceUnit) => handleAlertConfigUpdate({ referenceUnit })}
                      options={[
                        {
                          label: t('alerts.wizard.offset'),
                          value: 'day',
                        },
                        {
                          label: t('alerts.wizard.offsetHours'),
                          value: 'hour',
                        },
                      ]}
                      disabled={
                        alertConfig.type === 'value' ||
                        alertConfig.referenceAggregation === 'range' ||
                        alertConfig.referenceAggregation === 'rangeHours'
                      }
                      popupMatchSelectWidth={false}
                    />
                  }
                />
              </WizardSection>
            </Wrapper>
          )}
        </Wrapper>

        <Divider />

        {alertConfig && showApplyToEnrolled && (
          <>
            <Checkbox checked={applyToEnrolled} onChange={(e) => set({ applyToEnrolled: e.target.checked })}>
              {t('alerts.wizard.applyToEnrolled')}
            </Checkbox>

            <Divider />
          </>
        )}
      </Modal>
    </>
  )
}
