import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { faBars, faClose } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Typography } from 'antd'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { SortableListItem } from './types'

const Wrap = styled.div<{ $hasExtra: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ $hasExtra }) => ($hasExtra ? 'auto 1fr 3fr auto' : 'auto 3fr auto')} ;
  grid-gap: 8px;
  padding: 3px 6px 3px 0;
  margin-bottom: 3px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.primaryBackground};
`
const Draggable = styled.div`
  display: flex;
  padding: 3px 8px;
  align-items: center;
  cursor: grab;
`
const Title = styled(Typography.Text)`
  margin-left: 6px;
`

export function SortableItem({
  item,
  index,
  isDisabled,
  onDelete,
}: { item: SortableListItem; index: number; isDisabled?: boolean; onDelete: (key: string) => void }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })
  const theme = useTheme()

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Wrap style={style} $hasExtra={!!item.extra}>
      <Draggable {...attributes} {...listeners} ref={setNodeRef}>
        <FontAwesomeIcon icon={faBars} color={theme.primary} />
      </Draggable>
      <Title>{`${index + 1}. ${item.label}`}</Title>
      {item.extra}
      <Button shape="circle" type="text" size="small" onClick={() => onDelete(item.id)} disabled={isDisabled}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
    </Wrap>
  )
}
