import { Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { PatientSearchResult } from '../../lib/hooks/usePatientSearch'
import { getStatusName, getTagColor } from './helpers'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const Name = styled(Typography.Text)`
  margin-right: 20px;
`
const DateOfBirth = styled(Typography.Text)`
  font-size: 12px;
  margin-right: 6px;
`

const Tag = styled.span<{ color: Colors }>`
  color: ${({ color }) => `color-mix(in srgb, ${color} 90%, black)`};
  background-color: ${({ color }) => `color-mix(in srgb, ${color} 8%, white)`};
  border: 1px solid ${({ color }) => `color-mix(in srgb, ${color} 50%, white)`};
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 4px;
`

export function SearchDropDown({ patient }: { patient: PatientSearchResult }): JSX.Element {
  const { firstName, lastName, dateOfBirth, status } = patient
  return (
    <Wrapper>
      <Name>{`${firstName} ${lastName}`}</Name>
      <div>
        <DateOfBirth>{dayjs(dateOfBirth).format('ll')}</DateOfBirth>
        <Tag color={getTagColor(status)}>{getStatusName(status)}</Tag>
      </div>
    </Wrapper>
  )
}
