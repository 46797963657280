import { Card } from '@vetahealth/tuna-can-api'

import { ResultsTaskType, ResultsType, ResultsTypeWithSummary } from '../../../../../stores/results'
import { useUserStore } from '../../../../../stores/user'
import { getBloodGlucoseConfig } from './bloodGlucose'
import { getBloodOxygenConfig } from './bloodOxygen'
import { getBloodPressureConfig } from './bloodPressure'
import { getBodyTemperatureConfig } from './bodyTemperature'
import { getHbA1cConfig } from './hbA1c'
import { getHeartRateConfig } from './heartRate'
import { getHeightConfig } from './height'
import { getMeasurementCountConfig } from './measurementCount'
import { getRespiratoryRateConfig } from './respiratoryRate'
import { getRiskScoreConfig } from './riskScore'
import { getSleepConfig } from './sleep'
import { getStepsConfig } from './steps'
import { getTaskConfig } from './task'
import { getTotalInteractionTimeConfig } from './totalInteractionTime'
import { GetResultsConfig, ResultsConfig } from './types'
import { getWeightConfig } from './weight'

export function getResultsConfig(type: ResultsType): ResultsConfig {
  const configByType: Record<ResultsType, GetResultsConfig> = {
    bloodGlucose: getBloodGlucoseConfig,
    bloodOxygen: getBloodOxygenConfig,
    bloodPressure: getBloodPressureConfig,
    bodyTemperature: getBodyTemperatureConfig,
    hbA1c: getHbA1cConfig,
    heartRate: getHeartRateConfig,
    height: getHeightConfig,
    measurementCount: getMeasurementCountConfig,
    respiratoryRate: getRespiratoryRateConfig,
    riskScore: getRiskScoreConfig,
    sleep: getSleepConfig,
    steps: getStepsConfig,
    task: getTaskConfig,
    totalInteractionTime: getTotalInteractionTimeConfig,
    weight: getWeightConfig,
  }

  return configByType[type]({ userUnits: useUserStore.getState().units })
}

export function isResultsType(type: Card['type']): type is ResultsType {
  const nonResultsTypes: Array<Card['type']> = ['medicationAdherence', 'qualityOfLife']
  return !nonResultsTypes.includes(type)
}

export function isResultsTaskType(type: `${ResultsTypeWithSummary}`): type is ResultsTaskType {
  const taskResultTypes: Array<ResultsTypeWithSummary> = ['task', 'riskScore']
  return taskResultTypes.includes(type)
}
