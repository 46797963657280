import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Typography } from 'antd'

import { Image } from '../Image'
import { OrderedList, Table, UnOrderedList } from './styles'

const { Title, Text, Paragraph, Link } = Typography

type MarkdownProps = {
  text: string
}

export function Markdown({ text }: MarkdownProps): JSX.Element {
  return (
    <ReactMarkdown
      allowedElements={[
        'a',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'p',
        'i',
        'em',
        'strong',
        'del',
        'code',
        'blockquote',
        'ol',
        'ul',
        'li',
        'table',
        'tbody',
        'td',
        'th',
        'thead',
        'tr',
        'img',
      ]}
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({ children, href }) => (
          <Link href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </Link>
        ),
        p: ({ children }) => <Paragraph>{children}</Paragraph>,
        h1: ({ children }) => <Title level={1}>{children}</Title>,
        h2: ({ children }) => <Title level={2}>{children}</Title>,
        h3: ({ children }) => <Title level={3}>{children}</Title>,
        h4: ({ children }) => <Title level={4}>{children}</Title>,
        h5: ({ children }) => <Title level={5}>{children}</Title>,
        i: ({ children }) => <Text italic>{children}</Text>,
        em: ({ children }) => <Text italic>{children}</Text>,
        strong: ({ children }) => <Text strong>{children}</Text>,
        del: ({ children }) => <Text delete>{children}</Text>,
        code: ({ children }) => <Text mark>{children}</Text>,
        blockquote: ({ children }) => (
          <Text>
            <blockquote>{children}</blockquote>
          </Text>
        ),
        ol: ({ children, node }) => <OrderedList start={Number(node?.properties?.start) || 1}>{children}</OrderedList>,
        ul: ({ children }) => <UnOrderedList>{children}</UnOrderedList>,
        table: ({ children }) => <Table>{children}</Table>,
        img: ({ src }) => (src ? <Image src={src} /> : null),
      }}
    >
      {text}
    </ReactMarkdown>
  )
}
