import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'

import { CardDetails } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { PropertyPath, get, isNumber, orderBy } from 'lodash-es'
import { unparse } from 'papaparse'
import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import { ResultsType, useResultsStore } from '../../../../../stores/results'
import { getResultsConfig } from '../config'

export function DownloadCsv({ type }: { type: ResultsType }): JSX.Element {
  const { t } = useTranslation()
  const config = getResultsConfig(type)
  const [patient] = usePatientStore((state) => [state.patient])
  const [allResults] = useResultsStore((state) => [state.allResults])
  const rawData = (allResults[type] || []) as CardDetails[]

  function handleCSVDownload(): void {
    trackEvent(TrackingEvent.resultDownloadCsvClicked)

    const timestampTitle = t('table.date')
    const valueTitle = `${config.title} ${config.unit ? `[${config.unit.replace('_', ' ')}]` : ''}`

    const csvExport = {
      fields: [timestampTitle, valueTitle],
      data: orderBy(rawData, 'timestamp', 'desc').map((item) => [
        dayjs.utc(item.timestamp).tz(patient?.timeZone).format(),
        config.format?.(item, config.unit, true),
      ]),
    }

    const csv = unparse(csvExport, { quotes: (value) => !isNumber(value) })
    fileDownload(csv, `${patient?.id} - ${config.title}.csv`)
  }

  return <Button onClick={handleCSVDownload}>{t('actions.downloadCsv')}</Button>
}
