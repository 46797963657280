import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { isValidUUID } from '@vetahealth/fishing-gear/environment'

import { routes } from '../../Router/routes'
import { usePatientStore } from '../../stores/patient'

export function useValidatedPatient(): boolean {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [patient, getPatient, reset] = usePatientStore((state) => [state.patient, state.getPatient, state.reset])
  const isValidPatientId = !!id && isValidUUID(id)

  useEffect(() => {
    if (isValidPatientId) {
      if (id !== patient?.id) {
        reset()
        void getPatient(id)
      }
    } else {
      navigate(routes.root(), { replace: true })
    }
  }, [id])

  return isValidPatientId && patient?.id === id
}
