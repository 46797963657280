import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { Note as NoteType } from '@vetahealth/tuna-can-api'
import { Form, Modal, message } from 'antd'
import dayjs from 'dayjs'
import jsDownload from 'js-file-download'
import { debounce } from 'lodash-es'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { API } from '../../../lib/api'
import { useLoading } from '../../../lib/hooks/useLoading'
import { trimDeep } from '../../../lib/normalizers'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { useNotesStore } from '../../../stores/notes'
import { usePatientStore } from '../../../stores/patient'
import { useUserStore } from '../../../stores/user'
import { Note, NoteFormValues, SetPasswordFormValues } from '../../Forms'
import { SetPdfPassword } from '../../Forms/SetPdfPassword'
import { getDocumentFilename } from '../PatientDetails/Documents'
import { NotesModalProps } from './types'

export function NotesModal({ patientId, note, onCancel, isVisible }: NotesModalProps): JSX.Element {
  const [notesForm] = Form.useForm<NoteFormValues>()
  const [passwordForm] = Form.useForm<SetPasswordFormValues>()
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const [isEditable, setIsEditable] = useState(false)
  const [isPasswordModalShown, setIsPasswordModalShown] = useState(false)
  const [updateNote, deleteNote] = useNotesStore((state) => [state.updateNote, state.deleteNote])
  const [userId, hasPermission] = useUserStore((state) => [state.userId, state.hasPermission])
  const [patient] = usePatientStore((state) => [state.patient])

  useEffect(() => {
    if (note) {
      notesForm.setFieldsValue({ ...note, timestamp: dayjs(note.timestamp) })
      if (dayjs().diff(dayjs(note.createdAt), 'hour') < 24 && note.createdById === userId) setIsEditable(true)
    }
  }, [note, notesForm, userId])

  const handleDownload = useCallback(
    debounce(async (values: SetPasswordFormValues, note: NoteType): Promise<void> => {
      if (!patient) return
      const escalationPDF = await withLoading(API.getPatientEscalationPDF(patient.id, note.id, values.password))
      if (escalationPDF) {
        jsDownload(
          escalationPDF,
          getDocumentFilename(t('widgets.documents.escalation'), patient.site, patient.lastName, note.timestamp),
        )
        setIsPasswordModalShown(false)
      }
    }, 300),
    [patient],
  )

  if (!note) return <div />

  const handleUpdate = async (values: NoteFormValues): Promise<void> => {
    const success = await withLoading(updateNote({ id: patientId, noteId: note.id, values: trimDeep(values) }))

    if (success) {
      message.success(t('message.noteUpdate'))
    }
  }

  const handleDelete = async (): Promise<void> => {
    trackEvent(TrackingEvent.notesDeleteClicked)
    const success = await withLoading(deleteNote({ id: patientId, noteId: note.id }))
    if (success) {
      message.success(t('message.noteDelete'))
      setIsEditable(false)
      onCancel()
    }
  }

  const handleCancel = (): void => {
    setIsEditable(false)
    onCancel()
  }

  return (
    <>
      {/* Note modal to display and edit note */}
      <Modal
        title={t('widgets.notes.note')}
        open={isVisible}
        style={{ maxWidth: '800px' }}
        width="66%"
        onCancel={handleCancel}
        destroyOnClose
        centered
        footer={null}
      >
        <Form
          form={notesForm}
          layout="vertical"
          onFinish={handleUpdate}
          initialValues={{ ...note, timestamp: dayjs(note.timestamp) }}
        >
          <Note
            loading={loading}
            onDelete={handleDelete}
            onDownload={() => setIsPasswordModalShown(true)}
            onSubmit={notesForm.submit}
            note={note}
            isEditable={isEditable}
            isDeletable={isEditable || hasPermission(PermissionName.deleteAnyNote, patient?.site)}
          />
        </Form>
      </Modal>
      {/* Password confirm modal for escalation report download */}
      <Modal
        title={t('form.password')}
        open={isPasswordModalShown}
        style={{ maxWidth: '600px' }}
        width="46%"
        onCancel={() => setIsPasswordModalShown(false)}
        destroyOnClose
        centered
        footer={null}
      >
        <Form
          layout="vertical"
          form={passwordForm}
          onFinish={(values) => handleDownload(values, note)}
          validateTrigger="onSubmit"
          preserve={false}
        >
          <SetPdfPassword isLoading={loading} />
        </Form>
      </Modal>
    </>
  )
}
