import React, { useEffect } from 'react'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { useNavigate } from 'react-router'
import { routes } from '.'
import { ManualOrdersList } from '../../components/Widgets/ManualOrders'
import { useUserStore } from '../../stores/user'
import { GenericWrapper } from './styles'

export function ManualOrders(): JSX.Element {
  const navigate = useNavigate()
  const [hasSomePermission] = useUserStore((state) => [state.hasSomePermission])

  useEffect(() => {
    if (!hasSomePermission(PermissionName.dispatchManualOrder)) {
      navigate(routes.patientList(), { replace: true })
      return
    }
  }, [])

  return (
    <GenericWrapper>
      <ManualOrdersList />
    </GenericWrapper>
  )
}
