import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { AutoComplete, Input } from 'antd'

import { BaseSelectRef } from 'rc-select'
import { routes } from '../../Router/routes'
import { PatientDetailsTab } from '../../Router/routes/PatientDetails'
import { usePatientSearch } from '../../lib/hooks/usePatientSearch'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { SearchDropDown } from './SearchDropDown'

export function PatientSearch({ onFocus }: { onFocus: (value: boolean) => void }): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const autocompleteRef = useRef<BaseSelectRef>(null)

  const [searchValue, setSearchValue] = useState('')
  const searchPatients = usePatientSearch({ search: searchValue })

  function handleSearch(value: string): void {
    setSearchValue(value)
  }

  function handleSelect(id: string): void {
    trackEvent(TrackingEvent.patientSearchSuggestionSelected)

    setSearchValue('')
    if (!location.pathname.includes(id)) {
      navigate(routes.patientDetails(id, PatientDetailsTab.demographics))
    }
    if (autocompleteRef.current) autocompleteRef.current.blur()
  }

  function handleFocus(): void {
    onFocus(true)
  }

  function handleBlur(): void {
    onFocus(false)
    setSearchValue('')
  }

  const searchOptions = searchValue.trim()
    ? searchPatients.map((patient) => ({
        value: patient.id,
        label: <SearchDropDown patient={patient} />,
      }))
    : []

  return (
    <AutoComplete
      ref={autocompleteRef}
      allowClear
      style={{ width: '100%', height: '40px' }}
      dropdownStyle={{ minWidth: '400px' }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={searchValue}
      onSearch={handleSearch}
      onSelect={handleSelect}
      notFoundContent={searchValue.trim() ? t('empty.noMatches') : ''}
      options={searchOptions}
      open={!!searchValue.trim() && searchValue.length > 2}
      defaultActiveFirstOption={true}
    >
      <Input.Search size="large" placeholder={t('placeholders.searchPatient')} />
    </AutoComplete>
  )
}
