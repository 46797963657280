import dayjs from 'dayjs'
import i18next from 'i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { BarChart } from '../Chart/BarChart'
import { createAlertField } from '../Table/helpers'
import { ResultsConfig } from './types'

export function getTotalInteractionTimeConfig(): ResultsConfig {
  const unit = 'min'

  return {
    key: 'totalInteractionTime',
    title: i18next.t('tracking.totalInteractionTime.title'),
    category: 'statistics',
    format: (event) => `${event.value ?? '-'} ${unit}`,
    card: {
      formatTime: () => dayjs().format(i18next.t('dateFormats.monthAndYear')),
    },
    unit,
    chart: {
      component: BarChart,
      intervals: ['week', 'month', 'all'],
      dataKeys: [{ key: 'value', color: Colors.ink400 }],
      getData: (data: CardDetails[]) => data.map((item) => ({ ...item, note: item.additionalText })),
    },
    table: { defaultFields: true, additionalFields: [createAlertField()], timeFormat: 'll' },
  }
}
