import React from 'react'
import styled from 'styled-components'

import { Typography } from 'antd'

import { AlertConfig } from '../../lib/api'
import { convertTrackingAlertSubjectToTrackingType, getAlertMessage, isTrackingAlertConfig } from './helpers'

const Wrap = styled.div`
  display: flex;
  align-items: center;
`
const Message = styled(Typography.Text)`
  font-size: 13px;
  color: ${({ theme }) => theme.alertActive};
  background-color: ${({ theme }) => theme.alertBackground};
  padding: 3px 6px;
  border-radius: 6px;
  ${({ onClick, theme }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${theme.alertBackgroundHover};
    }
  `}
`

export function Alert({
  alertConfig,
  keepAlertUnit = false,
  onClick,
}: {
  alertConfig: AlertConfig
  keepAlertUnit?: boolean
  onClick?: () => void
}): JSX.Element {
  let alertUnit = {}

  if (isTrackingAlertConfig(alertConfig)) {
    const trackingType = convertTrackingAlertSubjectToTrackingType(alertConfig.subject)
    if (keepAlertUnit) alertUnit = { [trackingType]: alertConfig.unit }
  }

  const message = getAlertMessage(alertConfig, alertUnit)

  return (
    <Wrap>
      <Message onClick={onClick}>{message}</Message>
    </Wrap>
  )
}
