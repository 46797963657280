import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { ExistingPatient, ProgramStartType } from '@vetahealth/tuna-can-api'
import { Button, Form, Modal, Popconfirm, Typography } from 'antd'
import dayjs from 'dayjs'
import { pick } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../Router/routes'
import { PatientDetailsTab } from '../../Router/routes/PatientDetails'
import { API } from '../../lib/api'
import { useLoading } from '../../lib/hooks/useLoading'
import { trimDeep } from '../../lib/normalizers'
import { usePatientStore } from '../../stores/patient'
import { Personal, PersonalFormValues } from '../Forms'
import { ModalProps } from './types'

export function AddPatient({ isVisible, onCancel }: ModalProps): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLoading, withLoading] = useLoading()
  const [existingPatients, setExistingPatients] = useState<ExistingPatient[] | undefined>()
  const [createPatient] = usePatientStore((state) => [state.createPatient])
  const navigate = useNavigate()

  async function handleSubmit(values: PersonalFormValues): Promise<void> {
    const response = await withLoading(
      API.checkPatientExists(pick(trimDeep(values), ['firstName', 'lastName', 'site', 'clientIdentifier'])),
    )

    setExistingPatients(response)

    if (!response?.length) handleCreatePatient()
  }

  async function handleCreatePatient(): Promise<void> {
    const values: PersonalFormValues = form.getFieldsValue()
    const patientId = await withLoading(
      createPatient({
        ...trimDeep(values),
        dateOfBirth: values.dateOfBirth.format('YYYY-MM-DD'),
        programTemplateId: values.programTemplateId,
        programStartType: values.programStartType,
        programStart:
          values.programTemplateId && values?.programStartType === ProgramStartType.ReferenceDate && values.programStart
            ? values.programStart.format('YYYY-MM-DD')
            : undefined,
      }),
    )

    handleCancel()

    if (patientId) {
      navigate(routes.patientDetails(patientId, PatientDetailsTab.demographics))
    }
  }

  function createLinkToPatient(patientId: string): string {
    return window.location.origin + routes.patientDetails(patientId, PatientDetailsTab.demographics)
  }

  function handleCancel(): void {
    setExistingPatients(undefined)
    form.resetFields()
    onCancel()
  }

  useEffect(() => {
    if (!isVisible) handleCancel()
  }, [isVisible])

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      maskClosable={false}
      title={t('menus.main.addPatient')}
      width={640}
      destroyOnClose
      forceRender
      centered
      footer={
        <Popconfirm
          open={!!existingPatients?.length}
          title={t('menu.main.existingPatients', { count: existingPatients?.length || 0 })}
          okText={t('actions.continue')}
          cancelText={t('actions.cancel')}
          onConfirm={handleCreatePatient}
          onCancel={handleCancel}
          okButtonProps={{ disabled: isLoading, loading: isLoading }}
          cancelButtonProps={{ type: 'text' }}
          icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
          placement="topRight"
          overlayStyle={{ maxWidth: 600 }}
          description={
            existingPatients?.length && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {existingPatients.map(({ firstName, lastName, id, createdAt }) => (
                  <Typography.Link
                    key={id}
                    href={createLinkToPatient(id)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginBottom: 5 }}
                  >{`${firstName} ${lastName} - ${dayjs(createdAt).format('lll')}`}</Typography.Link>
                ))}
              </div>
            )
          }
        >
          <Button form="addPatient" disabled={isLoading} loading={isLoading} type="primary" htmlType="submit">
            {t('menus.main.addPatient')}
          </Button>
        </Popconfirm>
      }
    >
      <Form name="addPatient" form={form} layout="vertical" style={{ width: '100%' }} onFinish={handleSubmit}>
        <Personal form={form} isAddPatientModal />
      </Form>
    </Modal>
  )
}
