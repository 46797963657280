import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { Info } from '@vetahealth/tuna-can-api'

import { Card as AntCard, Spin, Typography, message } from 'antd'

import packageJson from '../../../package.json'
import { API } from '../../lib/api'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { AuthStep, useAuthStore } from '../../stores/auth'
import { Doctors } from '../Svg/Doctors'
import { ProsperCare } from '../Svg/ProsperCare'
import { VetaHealth } from '../Svg/VetaHealth'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'
import { Signin } from './Signin'
import { TwoFactorResetPassword } from './TwoFactorResetPassword'
import { TwoFactorSignin } from './TwoFactorSignin'
import { EpicState, getEpicAccessToken, getEpicCodeRedirect } from './epicSSO'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
`

const Background = styled.div`
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background: ${({ theme }) => theme.loginBackground};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`
const Card = styled(AntCard)`
  min-height: 600px;
  width: 80%;
  max-width: 1200px;
  .ant-card-body {
    position: relative;
    display: flex;
  }
`
const ColumnWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 80px;
`
const Illustration = styled.div`
  width: 100%;
`
const ProsperCareLogo = styled.div`
  align-self: flex-start;
  width: 65%;
  margin-bottom: 40px;
`
const VetaHealthLogo = styled.div`
  width: 30%;
  margin-top: 40px;
  align-self: flex-end;
`
const InfoText = styled(Typography.Text)`
  font-size: 11px;
  color: ${Colors.gray600};
  margin: 8px;
  text-align: right;
`

export function Auth(): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const [step, setStep, resetSoft, handleEpicLaunch] = useAuthStore((state) => [
    state.step,
    state.setStep,
    state.resetSoft,
    state.handleEpicLaunch,
  ])
  const [info, setInfo] = useState<Info>()
  const location = useLocation()

  const query = new URLSearchParams(location.search)
  const epicIss = query.get('iss')
  const epicLaunch = query.get('launch')
  const epicCode = query.get('code')
  const resetPasswordCode = query.get('resetPassword')
  const isInitialPasswordSetup = query.get('initial') === 'true'
  const twoFactorEmail = query.get('twoFactorEmail')
  const { tokenUrl: epicTokenUrl, api: epicApi } = JSON.parse(query.get('state') ?? '{}') as Partial<EpicState>

  useEffect(() => {
    API.getInfo().then((serverInfo) => {
      if (serverInfo) setInfo(serverInfo)
    })

    return resetSoft
  }, [])

  useEffect(() => {
    if (resetPasswordCode) {
      if (twoFactorEmail) {
        setStep(AuthStep.TWO_FACTOR_RESET_PASSWORD)
      } else {
        setStep(AuthStep.RESET_PASSWORD)
      }
    }
  }, [resetPasswordCode, twoFactorEmail])

  useEffect(() => {
    if (epicIss && epicLaunch) {
      trackEvent(TrackingEvent.authEpicSSOLaunchRequest, { iss: epicIss })
      getEpicCodeRedirect({ iss: epicIss, launch: epicLaunch })
        .then((redirectUri) => {
          window.location.href = redirectUri
        })
        .catch((e) => {
          void message.error({ content: t('message.epicFail'), duration: 30, key: 'errorEpicFail' })
          Sentry.captureException(e, { tags: { source: 'epic/getEpicCodeRedirect' }, extra: { iss: epicIss } })
          console.error(e)
        })
    }
  }, [epicIss, epicLaunch])

  useEffect(() => {
    if (epicCode && epicTokenUrl && epicApi) {
      trackEvent(TrackingEvent.authEpicSSOOauth)
      getEpicAccessToken({ code: epicCode, tokenUrl: epicTokenUrl })
        .then(({ access_token, id_token, patient, userEmail, userId, encounter, patientId }) => {
          void handleEpicLaunch({
            accessToken: access_token,
            idToken: id_token ?? '',
            fhirPatientId: patient,
            encounter,
            userEmail: userEmail || undefined,
            epicUserId: userId || undefined,
            epicUrl: epicApi,
            medicalRecordNumber: patientId || undefined,
          })
        })
        .catch((e) => {
          void message.error({ content: t('message.epicFail'), duration: 30, key: 'errorEpicFail' })
          const tags = { source: 'epic/getEpicAccessToken' }
          const extra = { tokenUrl: epicTokenUrl, apiUrl: epicApi }
          Sentry.captureException(e, { tags, extra })
          console.error(e)
        })
    }
  }, [epicCode])

  const CurrentStep: React.ElementType = {
    [AuthStep.SIGNIN]: Signin,
    [AuthStep.TWO_FACTOR_SIGNIN]: TwoFactorSignin,
    [AuthStep.TWO_FACTOR_RESET_PASSWORD]: TwoFactorResetPassword,
    [AuthStep.FORGOT_PASSWORD]: ForgotPassword,
    [AuthStep.RESET_PASSWORD]: ResetPassword,
  }[step]

  const serverVersion = info?.serverVersion.includes('beta')
    ? `${info?.serverVersion} (${info?.serverGithubSha})`
    : (info?.serverVersion ?? '')

  return (
    <Wrapper>
      <Background>
        <InfoText>
          <div>{t('version.client', { version: packageJson.version })}</div>
          <div>{t('version.server', { version: serverVersion })}</div>
        </InfoText>
      </Background>
      <Card bordered={false}>
        <ColumnWrapper>
          <ProsperCareLogo>
            <ProsperCare width="100%" height="100%" color={theme.logo} aria-label="Prosper Care" />
          </ProsperCareLogo>
          <Illustration>
            <Doctors width="100%" height="100%" aria-label={t('images.doctors')} />
          </Illustration>
          <VetaHealthLogo>
            <VetaHealth width="100%" height="100%" color={theme.logo} aria-label="Prosper Care" />
          </VetaHealthLogo>
        </ColumnWrapper>
        <ColumnWrapper>
          {epicLaunch || epicCode ? (
            <Spin size="large" />
          ) : (
            <CurrentStep resetCode={resetPasswordCode} isInitial={isInitialPasswordSetup} email={twoFactorEmail} />
          )}
        </ColumnWrapper>
      </Card>
    </Wrapper>
  )
}
