import React from 'react'
import styled from 'styled-components'

import { Typography } from 'antd'

const ImageWrapper = styled.img.attrs({
  decoding: 'async',
})`
  width: 100%;
  margin-bottom: 12px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.secondaryBackground};
  box-shadow: 0 1px 12px -3px ${({ theme }) => theme.tertiaryBackground};
  border-radius: 6px;
`

const HintText = styled(Typography.Text).attrs(({ theme }) => ({
  size: 'small',
  color: theme.text,
}))``

export function Image({ src, caption }: { src: string; caption?: string }): JSX.Element {
  return (
    <div>
      <ImageWrapper src={src} alt={caption || ''} />
      {!!caption && <HintText>{caption}</HintText>}
    </div>
  )
}
