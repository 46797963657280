import styled from 'styled-components'

export const GenericWrapper = styled.div`
  padding: 16px;
`

export const SettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  padding: 16px;
`

export const PatientWrapper = styled.div<{ gap?: string }>`
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(450px, 1fr);
  grid-gap: 16px;
  padding: 0 16px 16px;
`
