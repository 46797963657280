import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { message } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { API } from '../../lib/api'
import { RoleState } from './types'

const dataExpirationMinutes = 5

function expireRoles(): number {
  return Date.now() + dayjs.duration({ minutes: dataExpirationMinutes }).asMilliseconds()
}

export const useRolesStore = createWithEqualityFn<RoleState>(
  (set, get) => ({
    roles: [],
    roleExpiration: Date.now(),
    getRoles: async () => {
      const { roleExpiration } = get()
      if (roleExpiration > Date.now()) return
      const roles = await API.getRoles()
      if (roles) {
        set({ roles, roleExpiration: expireRoles() })
      }
    },
    createRole: async (role) => {
      const newRole = await API.createRole(role)
      if (newRole) {
        set((prevState) => ({
          roles: [...prevState.roles, newRole],
        }))
        message.success(i18next.t('message.roleCreate'))
      }
    },
    updateRole: async (role) => {
      const { id, ...updateRole } = role
      const updatedRole = await API.updateRole(id, updateRole)
      if (updatedRole) {
        set((prevState) => ({
          roles: prevState.roles.map((prevRole) => (prevRole.id === updatedRole.id ? updatedRole : prevRole)),
        }))
        message.success(i18next.t('message.roleUpdate'))
      }
    },
    deleteRole: async (id) => {
      const deleted = await API.deleteRole(id)
      if (deleted) {
        set((prevState) => ({
          roles: prevState.roles.filter((prevRole) => prevRole.id !== id),
        }))
        message.success(i18next.t('message.roleDelete'))
      }
    },
  }),
  shallow,
)
