import { isEmpty } from 'lodash-es'
import React from 'react'
import { AggregatedAnalytics } from '../../../stores/analytics'
import { useUserStore } from '../../../stores/user'
import { TimeCategoryChart } from '../../ECharts/TimeCategoryChart'
import { NoData } from '../styles'
import { getChartAggregationLevel } from './helpers'
import { Chart as ChartComponent } from './styles'

export function Chart({
  item,
  height,
}: {
  item: AggregatedAnalytics
  height?: number
}) {
  const [analyticsSettings, theme] = useUserStore((state) => [state.analyticsSettings, state.theme])
  const isDark = (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) || theme === 'dark'

  return (
    <ChartComponent $color={item.color} $isDark={isDark} $height={height}>
      {!isEmpty(item.data) ? (
        <TimeCategoryChart
          type={item.chartType === 'line' ? 'line' : 'bar'}
          data={item.data}
          unit={item.unit}
          color={item.color}
          aggregationLevel={getChartAggregationLevel(analyticsSettings?.aggregationLevel)}
        />
      ) : (
        <NoData />
      )}
    </ChartComponent>
  )
}
