import { faFlagCheckered, faPlay, faRepeat, faTimer, faVolumeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { ToDo as IToDo, ToDoTemplate } from '@vetahealth/tuna-can-api'

import { Tooltip } from 'antd'

import {
  getDurationTooltip,
  getDurationValue,
  getRepeatTooltip,
  getRepeatValue,
  getStartTooltip,
  getStartValue,
  getToDoDefinition,
  isToDo,
} from './helpers'
import { Card, Flex, Label, Name, Timing, TimingItem, Value } from './styles'

type ToDoProps = {
  toDo: IToDo | ToDoTemplate
  referenceDate?: string
  isProgramPaused?: boolean
  onClick?: () => void
}

export function ToDo({ toDo, referenceDate, isProgramPaused, onClick }: ToDoProps): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const { color, labels } = getToDoDefinition(toDo, theme)
  return (
    <>
      <Card $colors={color} onClick={onClick} $isEditable={!!onClick}>
        <Flex>
          <Label>
            {labels.length > 0 &&
              labels.map((label) => (
                <Name key={label} color={color.border}>
                  {label}
                </Name>
              ))}
          </Label>

          <Timing>
            {isToDo(toDo) && !toDo.nextExecutionDate && (
              <Tooltip title={isProgramPaused ? null : t('widgets.programs.finished')}>
                <TimingItem $isOnlyIcon>
                  <FontAwesomeIcon icon={faFlagCheckered} size="sm" />
                </TimingItem>
              </Tooltip>
            )}
            {toDo.isMuted && (
              <Tooltip title={isProgramPaused ? null : t('widgets.programs.muted')}>
                <TimingItem $isOnlyIcon>
                  <FontAwesomeIcon icon={faVolumeSlash} size="sm" />
                </TimingItem>
              </Tooltip>
            )}
            <Tooltip
              title={isProgramPaused ? null : getStartTooltip(toDo, referenceDate)}
              overlayStyle={{ maxWidth: '500px' }}
            >
              <TimingItem>
                <FontAwesomeIcon icon={faPlay} size="sm" />
                <Value>{getStartValue(toDo, referenceDate)}</Value>
              </TimingItem>
            </Tooltip>
            <Tooltip title={isProgramPaused ? null : getRepeatTooltip(toDo)}>
              <TimingItem>
                <FontAwesomeIcon icon={faRepeat} size="sm" />
                <Value>{getRepeatValue(toDo)}</Value>
              </TimingItem>
            </Tooltip>
            <Tooltip title={isProgramPaused ? null : getDurationTooltip(toDo)} overlayStyle={{ maxWidth: '500px' }}>
              <TimingItem>
                <FontAwesomeIcon icon={faTimer} size="sm" />
                <Value>{getDurationValue(toDo)}</Value>
              </TimingItem>
            </Tooltip>
          </Timing>
        </Flex>
      </Card>
    </>
  )
}
