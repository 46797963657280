import { Alert, Button, Form } from 'antd'
import { debounce } from 'lodash-es'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthStep, useAuthStore } from '../../stores/auth'
import { ChangePassword as ChangePasswordForm, isValidPassword } from '../Forms/ChangePassword'
import { Action, ButtonWrapper, FormWrapper } from './styles'
import { FormValues } from './types'

export function ResetPassword({ resetCode, isInitial }: { resetCode: string; isInitial: boolean }): JSX.Element {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [handleResetPassword, setStep, errorStatus] = useAuthStore((state) => [
    state.handleResetPassword,
    state.setStep,
    state.errorStatus,
  ])

  const handleSubmit = useCallback(
    debounce(async (values: Pick<FormValues, 'newPassword'>): Promise<void> => {
      await handleResetPassword({ ...values, resetCode })
    }, 300),
    [],
  )

  return (
    <>
      <Action>{isInitial ? t('auth.setupPassword') : t('auth.resetPassword')}</Action>
      {errorStatus && <Alert type="error" message={t('error.resetPassword')} style={{ marginTop: '20px' }} />}
      <FormWrapper>
        <Form onFinish={handleSubmit} validateTrigger="onSubmit">
          <ChangePasswordForm password={password} onPasswordChange={setPassword} isInitial={isInitial} />
          <Form.Item>
            <Button block disabled={!isValidPassword(password)} type="primary" htmlType="submit" size="large">
              {t('actions.continue')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
      <ButtonWrapper>
        <Button onClick={() => setStep(AuthStep.FORGOT_PASSWORD)} type="link">
          {t('actions.back')}
        </Button>
      </ButtonWrapper>
    </>
  )
}
