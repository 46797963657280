import { faBell } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { Button, Popover, Tooltip } from 'antd'
import { isEmpty } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useChatStore } from '../../stores/chat'
import { usePatientsStore } from '../../stores/patients'
import { useUserStore } from '../../stores/user'
import { UnreadMessages } from './UnreadMessages'
import { Badge } from './styles'

const ButtonWrap = styled.div`
  position: relative;
`

export function Notifications(): JSX.Element {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [patients] = usePatientsStore((state) => [state.patients])
  const [userId] = useUserStore((state) => [state.userId])
  const [unreadChatMessages, getUnreadChatMessages] = useChatStore((state) => [
    state.unreadChatMessages,
    state.getUnreadChatMessages,
  ])
  const subscribedPatientIds = patients
    .map((patient) => userId && patient.subscribers.includes(userId) && patient.id)
    .filter(Boolean)
  const subscribedMessages = unreadChatMessages.filter((message) => subscribedPatientIds.includes(message.userId))

  const handleOpenChange = (newOpen: boolean): void => {
    setIsOpen(newOpen)
    if (newOpen) setShowTooltip(false)
  }

  useEffect(() => {
    getUnreadChatMessages()
  }, [])

  return (
    <div onMouseEnter={() => setShowTooltip(!isOpen)} onMouseLeave={() => setShowTooltip(false)}>
      <Tooltip
        title={
          isEmpty(unreadChatMessages)
            ? t('chat.notifications')
            : t('chat.messageCount', { count: Object.values(unreadChatMessages).length })
        }
        open={showTooltip}
        placement="bottom"
      >
        <Popover
          content={<UnreadMessages onClose={() => setIsOpen(false)} />}
          trigger="click"
          open={isOpen}
          onOpenChange={handleOpenChange}
        >
          <ButtonWrap>
            <Button shape="circle" type="text">
              <FontAwesomeIcon icon={faBell} size="lg" />
            </Button>
            {!isEmpty(unreadChatMessages) && (
              <Badge $color={subscribedMessages.length ? Colors.ruby600 : Colors.gray500} />
            )}
          </ButtonWrap>
        </Popover>
      </Tooltip>
    </div>
  )
}
