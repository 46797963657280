import dayjs from 'dayjs'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { BulkCreatePatientsBody } from '@vetahealth/tuna-can-api'

import { API } from '../../lib/api'
import { takeLeading } from '../../lib/takeLeading'
import { useUserStore } from '../user'
import { PatientsState } from './types'

export const initialState: Pick<
  PatientsState,
  'patients' | 'patientsExpiration' | 'tags' | 'tagsExpiration' | 'currentlyViewedPatients'
> = {
  patients: [],
  patientsExpiration: Date.now(),
  tags: [],
  tagsExpiration: Date.now(),
  currentlyViewedPatients: {},
}

function expireInMinutes(minutes: number): number {
  return Date.now() + dayjs.duration({ minutes }).asMilliseconds()
}

export const usePatientsStore = createWithEqualityFn<PatientsState>(
  (set, get) => ({
    ...initialState,
    getPatients: async () => {
      const { patientsExpiration } = get()
      const { userId } = useUserStore.getState()
      if (!userId || patientsExpiration > Date.now()) return

      set({ patientsExpiration: expireInMinutes(5) })

      const patients = await API.getPatients()

      if (patients) set({ patients })
    },
    expirePatients: () => {
      set({ patientsExpiration: Date.now() })
    },
    createPatients: async (patients: BulkCreatePatientsBody[]) => {
      const success = await API.bulkCreatePatients(patients)
      if (success) set({ patientsExpiration: Date.now() })

      return !!success
    },
    getTags: takeLeading(async () => {
      if (get().tagsExpiration > Date.now()) return

      const tags = await API.getTags()

      if (tags)
        set({
          tags,
          tagsExpiration: expireInMinutes(5),
        })
    }),
    expireTags: () => {
      set({ tagsExpiration: Date.now() })
    },
    deIdentifyPatients: async (selectedPatientIds: string[]) => {
      const result = await API.bulkDeIdentifyPatients(selectedPatientIds)
      set({ patientsExpiration: Date.now(), tagsExpiration: Date.now() })

      return result
    },
    reset: () => set(initialState),
  }),
  shallow,
)
