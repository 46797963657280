import dayjs from 'dayjs'
import i18next from 'i18next'
import { DefaultTheme } from 'styled-components'

import { ReminderTypeEnum, TaskTypeEnum, ToDo, ToDoTemplate, TrackingTypeEnum } from '@vetahealth/tuna-can-api'

import { ToDoDefinition } from './types'

export function isToDo(toDo: ToDo | ToDoTemplate): toDo is ToDo {
  return 'firstDueDate' in toDo
}

export function isToDoTemplateWithId(
  toDo: ToDo | (ToDoTemplate & { id?: number }),
): toDo is ToDoTemplate & { id: number } {
  return 'hourOfDay' in toDo && 'id' in toDo
}

export function getStartValue(toDo: ToDo | ToDoTemplate, referenceDate?: string): number {
  const startValue = isToDo(toDo)
    ? dayjs(toDo.firstDueDate).tz(toDo.timeZone).startOf('day').diff(dayjs(referenceDate).tz(toDo.timeZone), 'days')
    : toDo.referenceDateOffsetDays
  // Count from day 1 on positive start date and use 'Starts on the x day' semantic
  return startValue >= 0 ? startValue + 1 : startValue
}

export function getStartTooltip(toDo: ToDo | ToDoTemplate, referenceDate?: string): string {
  const startDate = isToDo(toDo) ? dayjs(toDo.firstDueDate).tz(toDo.timeZone).format('LLL z') : undefined
  const startValue = getStartValue(toDo, referenceDate)

  if (startValue < 0) {
    return i18next.t('widgets.programs.startBefore', {
      count: startValue * -1,
      ordinal: false,
      date: startDate && `- ${startDate}`,
    })
  }

  return i18next.t('widgets.programs.startOn', {
    count: startValue,
    ordinal: true,
    date: startDate && `- ${startDate}`,
  })
}

export function getRepeatValue(toDo: ToDo | ToDoTemplate): string {
  return toDo.repeatAfterDays === 0 ? '–' : String(toDo.repeatAfterDays)
}

export function getRepeatTooltip(toDo: ToDo | ToDoTemplate): string {
  return toDo.repeatAfterDays === 0
    ? i18next.t('widgets.programs.noRepeat')
    : i18next.t('widgets.programs.repeat', { count: toDo.repeatAfterDays })
}

export function getDurationValue(toDo: ToDo | ToDoTemplate): string {
  // ToDo
  if (isToDo(toDo)) {
    if (toDo.repeatAfterDays === 0) return '-'
    if (toDo.repeatUntil)
      return String(
        dayjs(toDo.repeatUntil)
          .tz(toDo.timeZone)
          .endOf('day')
          .diff(dayjs(toDo.firstDueDate).tz(toDo.timeZone).startOf('day'), 'days'),
      )
    return '∞'
  }
  // ToDoTemplate
  if (toDo.repeatAfterDays === 0) return '–'
  if (toDo.repeatDurationDays) return String(toDo.repeatDurationDays)
  return '∞'
}

export function getDurationTooltip(toDo: ToDo | ToDoTemplate): string {
  // ToDo
  if (isToDo(toDo)) {
    if (toDo.repeatAfterDays === 0) return i18next.t('widgets.programs.noDuration')
    if (toDo.repeatUntil)
      return i18next.t('widgets.programs.duration', {
        count: dayjs(toDo.repeatUntil)
          .tz(toDo.timeZone)
          .endOf('day')
          .diff(dayjs(toDo.firstDueDate).tz(toDo.timeZone).startOf('day'), 'days'),
        date: `- ${dayjs(toDo.repeatUntil).tz(toDo.timeZone).format('LLL z')}`,
      })
    return i18next.t('widgets.programs.durationInfinity')
  }
  // ToDoTemplate
  if (toDo.repeatAfterDays === 0) return i18next.t('widgets.programs.noDuration')
  if (toDo.repeatDurationDays)
    return i18next.t('widgets.programs.duration', {
      count: toDo.repeatDurationDays,
      date: undefined,
    })
  return i18next.t('widgets.programs.durationInfinity')
}

const getSubTypeLabel = (subType: TaskTypeEnum | TrackingTypeEnum): string => {
  return {
    [TrackingTypeEnum.BloodGlucose]: i18next.t('tracking.bloodGlucose.title'),
    [TrackingTypeEnum.BloodOxygen]: i18next.t('tracking.bloodOxygen.title'),
    [TrackingTypeEnum.BloodPressure]: i18next.t('tracking.bloodPressure.title'),
    [TrackingTypeEnum.HbA1c]: i18next.t('tracking.hbA1c.title'),
    [TrackingTypeEnum.HeartRate]: i18next.t('tracking.heartRate.title'),
    [TrackingTypeEnum.RespiratoryRate]: i18next.t('tracking.respiratoryRate.title'),
    [TrackingTypeEnum.BodyTemperature]: i18next.t('tracking.bodyTemperature.title'),
    [TrackingTypeEnum.Sleep]: i18next.t('tracking.sleep.title'),
    [TrackingTypeEnum.Steps]: i18next.t('tracking.steps.title'),
    [TrackingTypeEnum.Weight]: i18next.t('tracking.weight.title'),
    [TrackingTypeEnum.Height]: i18next.t('tracking.height.title'),
    [TaskTypeEnum.Information]: i18next.t('widgets.programs.informationTaskLabel'),
    [TaskTypeEnum.Survey]: i18next.t('widgets.programs.surveyLabel'),
  }[subType]
}

function getTaskLabels(toDo: ToDo | ToDoTemplate): string[] {
  if (toDo.name) return [toDo.name]
  if (toDo.datoId) return [i18next.t('widgets.programs.manualSelection', { taskId: toDo.datoId })]
  if (toDo.taskTypes?.length) return toDo.taskTypes.map(getSubTypeLabel)
  return [i18next.t('widgets.programs.automaticSelection')]
}

function getMeasurementLabels(toDo: ToDo | ToDoTemplate): string[] {
  if (toDo.measurementTypes?.length) return toDo.measurementTypes.map(getSubTypeLabel)
  return [i18next.t('widgets.programs.availableDevices')]
}

export function getToDoDefinition(toDo: ToDo | ToDoTemplate, theme: DefaultTheme): ToDoDefinition {
  const { reminderType } = toDo

  const toDoDefinition = {
    [ReminderTypeEnum.TaskReminder]: {
      color: {
        background: theme.taskBackground,
        hover: theme.taskHover,
        active: theme.taskActive,
      },
      title: i18next.t('widgets.programs.content'),
      labels: getTaskLabels(toDo),
    },
    [ReminderTypeEnum.MeasurementReminder]: {
      color: {
        background: theme.trackingBackground,
        hover: theme.trackingHover,
        active: theme.trackingActive,
      },
      title: i18next.t('widgets.programs.measurement'),
      labels: getMeasurementLabels(toDo),
    },
  }

  return toDoDefinition[reminderType]
}

const baseDefaultToDoTemplate: Pick<
  ToDoTemplate,
  | 'fulfillmentHours'
  | 'overdueHours'
  | 'referenceDateOffsetDays'
  | 'repeatAfterDays'
  | 'repeatDurationDays'
  | 'isMuted'
  | 'hourOfDay'
> = {
  fulfillmentHours: 3,
  overdueHours: 3,
  hourOfDay: 19,
  referenceDateOffsetDays: 0,
  repeatAfterDays: 1,
  repeatDurationDays: 7,
  isMuted: false,
}

const defaultTaskToDoTemplate: ToDoTemplate = {
  ...baseDefaultToDoTemplate,
  reminderType: 'taskReminder',
  datoId: '',
  name: '',
  taskTypes: [],
  requiresDevice: false,
}

const defaultMeasurementToDoTemplate: ToDoTemplate = {
  ...baseDefaultToDoTemplate,
  reminderType: 'measurementReminder',
  measurementTypes: [],
  requiresDevice: true,
}

export function getDefaultToDoTemplate(reminderType: ToDoTemplate['reminderType']): ToDoTemplate {
  return {
    measurementReminder: defaultMeasurementToDoTemplate,
    taskReminder: defaultTaskToDoTemplate,
  }[reminderType]
}

export function getTaskTypeTitle(type: TaskTypeEnum): string {
  const translations: Record<TaskTypeEnum, string> = {
    [TaskTypeEnum.Information]: i18next.t('widgets.programs.informationTask'),
    [TaskTypeEnum.Survey]: i18next.t('widgets.programs.surveyTask'),
  }

  return translations[type]
}
