import styled from 'styled-components'

import { Typography } from 'antd'

export const Action = styled(Typography.Title).attrs({
  level: 4,
  style: {
    fontWeight: 'bold',
  },
})``

export const Description = styled(Typography.Text)``

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const FormWrapper = styled.div`
  margin-top: 20px;
`
