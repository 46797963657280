import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/es-us'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Locale } from 'antd/es/locale'
import antDeDE from 'antd/lib/locale/de_DE'
import antEnUS from 'antd/lib/locale/en_US'
import antEsES from 'antd/lib/locale/es_ES'

import { storage } from '../storage'
import deDE from './de-DE.json'
import enUS from './en-US.json'
import es419 from './es-419.json'

export enum SupportedLocale {
  EN_US = 'en-US',
  ES_US = 'es-US',
  ES_419 = 'es-419',
  DE_DE = 'de-DE',
}

enum FallbackLocale {
  EN = 'en',
  ES = 'es',
  DE = 'de',
}

export const antLocales: Record<string, Locale> = {
  en: antEnUS,
  es: antEsES,
  de: antDeDE,
}

export function initI18n(): void {
  i18next.use(initReactI18next).init({
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    resources: {
      en: { translation: enUS },
      es: { translation: es419 },
      de: { translation: deDE },
    },
    interpolation: {
      escapeValue: false,
    },
    nsSeparator: false,
    returnNull: false,
  })
}

export function getInitialLocale(): SupportedLocale {
  const supportedLocales = Object.values(SupportedLocale)

  const storedLocale = storage.getLocale()

  let systemLocale: SupportedLocale | undefined
  for (const locale of navigator.languages as SupportedLocale[]) {
    if (systemLocale) break

    systemLocale = supportedLocales.includes(locale)
      ? locale
      : supportedLocales.find((supportedLocale) => supportedLocale.startsWith(locale))
  }

  return storedLocale || systemLocale || SupportedLocale.EN_US
}

export function getLibLocale({
  lib,
  locale = SupportedLocale.EN_US,
}: {
  lib: 'ant' | 'dayjs'
  locale?: SupportedLocale
}): FallbackLocale | SupportedLocale {
  const libLocales: Array<FallbackLocale | SupportedLocale> = {
    ant: [FallbackLocale.EN, FallbackLocale.ES, FallbackLocale.DE],
    dayjs: [FallbackLocale.EN, FallbackLocale.ES, SupportedLocale.ES_US, FallbackLocale.DE],
  }[lib]

  return libLocales.find((libLocale) => [locale, locale.slice(0, 2)].includes(libLocale)) || libLocales[0]
}
