import { PatientDetails, PatientQuickInfoEnum, Site } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { formatPhone } from '../../../../lib/formatPhone'
import { formatInterval } from '../../helpers'

const getAddressLabel = ({ city, state }: PatientDetails): string => {
  const cityString = city ?? '-'
  const stateString = state ? `/ ${state}` : ''
  if (cityString && stateString) return `${cityString} ${stateString}`
  return cityString
}

const getFormattedPhoneLabel = ({ landlinePhone, mobilePhone }: PatientDetails): string | undefined => {
  const phone = mobilePhone || landlinePhone
  return phone && formatPhone(phone)
}

export const getQuickInfoLabel = (label: PatientQuickInfoEnum): string => {
  switch (label) {
    case PatientQuickInfoEnum.Id:
      return i18next.t('widgets.patient.clientReference')
    case PatientQuickInfoEnum.Site:
      return i18next.t('widgets.patient.site')
    case PatientQuickInfoEnum.DateOfBirth:
      return i18next.t('widgets.patient.dateOfBirth')
    case PatientQuickInfoEnum.DaysSinceLastOnboarded:
      return i18next.t('widgets.patient.daysSinceLastOnboarded')
    case PatientQuickInfoEnum.Phone:
      return i18next.t('widgets.patient.phone')
    case PatientQuickInfoEnum.Address:
      return i18next.t('widgets.patient.address')
    case PatientQuickInfoEnum.BillingPeriod:
      return i18next.t('widgets.patient.billingPeriod')
    case PatientQuickInfoEnum.Provider:
      return i18next.t('widgets.patient.provider')
    default:
      return ''
  }
}

export const getQuickInfoValue = (
  patient: PatientDetails,
  label: PatientQuickInfoEnum,
  site?: Site,
  lastOnboardedAt?: string,
): string => {
  switch (label) {
    case PatientQuickInfoEnum.Id:
      return patient.clientIdentifier || '-'
    case PatientQuickInfoEnum.Site:
      return site?.shortName ?? patient.site
    case PatientQuickInfoEnum.DateOfBirth:
      return dayjs(patient.dateOfBirth).format('ll')
    case PatientQuickInfoEnum.DaysSinceLastOnboarded:
      return lastOnboardedAt ? String(dayjs().diff(lastOnboardedAt, 'days')) : '-'
    case PatientQuickInfoEnum.Phone:
      return getFormattedPhoneLabel(patient) || '-'
    case PatientQuickInfoEnum.Address:
      return getAddressLabel(patient)
    case PatientQuickInfoEnum.BillingPeriod:
      return patient.billingPeriod ? formatInterval(patient.billingPeriod) : '-'
    case PatientQuickInfoEnum.Provider:
      return patient.billingInfo.provider || '-'
    default:
      return '-'
  }
}
