import fileDownload from 'js-file-download'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { message } from 'antd'

import { Spinner } from '../../components/Spinner'
import { API } from '../../lib/api'
import { routes } from './index'

export function FileDownload(): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(true)
  const { site, id } = useParams<{ site: string; id: string; name: string }>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { t } = useTranslation()

  useEffect(() => {
    if (site && id) {
      API.downloadSharedFile(site, +id).then((response) => {
        if (response instanceof ArrayBuffer) {
          fileDownload(response, queryParams.get('name') ?? 'file')
          void message.success(t('message.downloadTriggered'))
        } else if (response === 'forbidden') {
          void message.error(t('message.missingDownloadPermission'))
        } else {
          void message.error({ content: t('error.generic'), key: 'errorGeneric' })
        }
        setLoading(false)
      })
    }
  }, [])

  return isLoading ? <Spinner /> : <Navigate to={routes.root()} />
}
