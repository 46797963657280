import { CreateProgramTemplate } from '@vetahealth/tuna-can-api'
import { Button, Form, Input, Modal, message } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { useSitesStore } from '../../../../stores/sites'
import { FormKeys } from '../../../Forms'

export function AddProgramTemplate({
  isVisible,
  siteKey,
  onCancel,
}: {
  isVisible: boolean
  siteKey: string
  onCancel: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLoading, withLoading] = useLoading()
  const [createProgramTemplate] = useSitesStore((state) => [state.createProgramTemplate])

  const handleAddProgram = async (values: Omit<CreateProgramTemplate, 'site'>): Promise<void> => {
    const success = await withLoading(createProgramTemplate(siteKey, values))
    if (success) message.success(t('message.programCreate'))
    onCancel()
  }

  return (
    <Modal
      title={t('widgets.siteManagement.programs.addProgramTemplate')}
      open={isVisible}
      onCancel={onCancel}
      destroyOnClose
      centered
      footer={
        <Button form="addProgramTemplate" htmlType="submit" type="primary" loading={isLoading}>
          {t('actions.save')}
        </Button>
      }
    >
      <Form
        name="addProgramTemplate"
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleAddProgram}
        validateTrigger="onSubmit"
      >
        <Form.Item name={FormKeys.NAME} label={t('form.name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={FormKeys.DESCRIPTION} label={t('form.description')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}
