import i18next from 'i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  convert,
  defaultUnits,
  getDisplayValue,
  normalize,
  normalizedUnitRanges,
  normalizedUnits,
} from '@vetahealth/fishing-gear/conversions'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { LineChart } from '../Chart/LineChart'
import { createAlertField, createDeviceField } from '../Table/helpers'
import { AddAction, GetResultsConfigParams, ResultsConfig } from './types'

export function getWeightConfig({ userUnits }: GetResultsConfigParams): ResultsConfig {
  const unit = userUnits.weight || defaultUnits.weight

  const [min, max] = normalizedUnitRanges.weight
  const [convertedMin, convertedMin2] = convert(min, normalizedUnits.weight, 'weight', unit)
  const [convertedMax, convertedMax2] = convert(max, normalizedUnits.weight, 'weight', unit)

  const add: AddAction = [
    {
      key: 'value',
      label: i18next.t('tracking.weight.weight', { unit: unit?.split('_')[0] }),
      validators: [
        { required: true, message: i18next.t('validations.enterWeight') },
        () => {
          return {
            message: i18next.t('validations.trackingRange', {
              min: `${convertedMin}${
                convertedMin2 ? ` ${unit?.split('_')[0]} / ${convertedMin2} ${unit?.split('_')[1]}` : ''
              }`,
              max: `${convertedMax}${
                convertedMax2 ? ` ${unit?.split('_')[0]} / ${convertedMax2} ${unit?.split('_')[1]}` : ''
              }`,
              unit,
            }),
            validator: (_, value: number) =>
              convertedMin <= value && value <= convertedMax ? Promise.resolve() : Promise.reject(),
          }
        },
      ],
    },
  ]

  if (unit.includes('_')) {
    add.push({
      key: 'additionalValue',
      label: i18next.t('tracking.weight.weight', { unit: unit?.split('_')[1] }),
      validators: [
        { required: true, message: i18next.t('validations.enterWeight') },
        ({ getFieldValue }) => {
          const weightValue = getFieldValue('value') ?? 0

          return {
            message: i18next.t('validations.trackingRange', {
              min: `${convertedMin}${
                convertedMin2 ? ` ${unit?.split('_')[0]} / ${convertedMin2} ${unit?.split('_')[1]}` : ''
              }`,
              max: `${convertedMax}${
                convertedMax2 ? ` ${unit?.split('_')[0]} / ${convertedMax2} ${unit?.split('_')[1]}` : ''
              }`,
              unit,
            }),
            validator: (_, value: number) => {
              const normalizedValue = normalize('weight', [Number(weightValue), Number(value)], unit)[0]

              return min <= normalizedValue && normalizedValue <= max ? Promise.resolve() : Promise.reject()
            },
          }
        },
      ],
    })
  }

  return {
    key: 'weight',
    title: i18next.t('tracking.weight.title'),
    category: 'tracking',
    unit,
    format: (event, targetUnit, omitUnit) => {
      const displayValue = getDisplayValue(
        [event.value ?? 0, event.additionalValue ?? 0],
        event.unit ?? normalizedUnits.weight,
        'weight',
        { targetUnit, omitUnit },
      )

      return event.value === undefined ? '–' : displayValue
    },
    actions: { add, reviewBulk: true, downloadCsv: true },
    chart: {
      component: LineChart,
      intervals: ['day', 'week', 'month', 'trend'],
      dataKeys: [{ key: 'value', color: Colors.azure500 }],
      getData: (data: CardDetails[]) =>
        data.map((event) => ({
          ...event,
          value: convert(event.value, event.unit.split('_')[0], 'weight', unit.split('_')[0])[0],
        })),
    },
    table: {
      defaultFields: true,
      additionalFields: [createAlertField(), createDeviceField()],
      actions: ['review', 'delete'],
    },
  }
}
