import React, { useEffect } from 'react'

import { useAuthStore } from '../../stores/auth'
import { SigninType } from '../../stores/auth/types'

type CredentialCallback = (response: { credential: string }) => void

declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (params: { client_id: string; callback: CredentialCallback; auto_select: boolean }) => void
          renderButton: (elem: HTMLElement | null, options: { theme: string; size: string }) => void
          prompt: () => void
        }
      }
    }
  }
}

export function GoogleSSOButton(): JSX.Element {
  const handleSignin = useAuthStore((state) => state.handleSignin)

  const handleCredentialResponse: CredentialCallback = ({ credential }) => {
    void handleSignin({ signinType: SigninType.SSO, token: credential })
  }

  useEffect(() => {
    if (!window.google) return
    window.google.accounts.id.initialize({
      client_id: '367155311928-g048ocb7729pe972duiciuabn3tao06l.apps.googleusercontent.com',
      callback: handleCredentialResponse,
      auto_select: true,
    })
    window.google.accounts.id.renderButton(document.getElementById('google-sso'), { theme: 'outline', size: 'large' })
  }, [!!window.google])

  return <div id="google-sso" />
}
