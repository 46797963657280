import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import React from 'react'

import { SortableItem } from './SortableItem'
import { SortableListItem } from './types'

export function SortableList({
  items,
  isDisabled,
  onChange,
}: { items: SortableListItem[]; isDisabled?: boolean; onChange: (items: string[]) => void }): JSX.Element {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id)
      const newIndex = items.findIndex((item) => item.id === over?.id)

      onChange(arrayMove(items, oldIndex, newIndex).map((item) => item.id))
    }
  }

  function handleDelete(key: string) {
    onChange(items.filter(({ id }) => id !== key).map((item) => item.id))
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <SortableItem key={item.id} item={item} index={index} onDelete={handleDelete} isDisabled={isDisabled} />
        ))}
      </SortableContext>
    </DndContext>
  )
}
