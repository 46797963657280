import React, { useEffect } from 'react'

import { Card as ICard } from '@vetahealth/tuna-can-api'

import { usePatientStore } from '../../../../../stores/patient'
import { ResultsType, useResultsStore } from '../../../../../stores/results'
import { defaultSorting } from '../../../../helpers'
import { isTrackingType } from '../../../helpers'
import { getResultsConfig, isResultsType } from '../config'
import { ResultsCard } from './ResultsCard'
import { SummaryCard } from './SummaryCard'
import { CardWrapper } from './styles'

export function Cards(): JSX.Element | null {
  const [patient] = usePatientStore((state) => [state.patient])
  const [getLastResults, lastResults] = useResultsStore((state) => [state.getLastResults, state.lastResults])

  useEffect(() => {
    if (patient) getLastResults(patient.id)
  }, [patient?.id])

  const lastResultsWithTitle = Object.entries(lastResults).map(([type, lastResult]: [ResultsType, ICard]) => {
    const title = getResultsConfig(type).title
    return { ...lastResult, title }
  })

  const trackingResults = lastResultsWithTitle
    .filter((result) => isTrackingType(result.type))
    .sort((a, b) => defaultSorting(a, b, 'title', 'ascend'))
  const statisticResults = lastResultsWithTitle
    .filter((result) => !isTrackingType(result.type))
    .sort((a, b) => defaultSorting(a, b, 'title', 'ascend'))

  const lastResultsSorted = [...trackingResults, ...statisticResults]

  return (
    <CardWrapper>
      <SummaryCard />
      {lastResultsSorted.map(({ type: lastResultType }) => {
        return isResultsType(lastResultType) ? <ResultsCard key={lastResultType} type={lastResultType} /> : null
      })}
    </CardWrapper>
  )
}
