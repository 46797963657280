import { CreatePatientBody, PatientDetails, UpdatePatientBody, VitalsPDFBody } from '@vetahealth/tuna-can-api'

import { CreateVitalsReportParams } from '../../components/Widgets/PatientDetails/Results/Actions/CreateVitalsReport'
import { convertEmptyStringToNull, omitUndefined, trimDeep } from '../../lib/normalizers'

export function hasPatientAddress(patient?: PatientDetails): boolean {
  if (!patient) return false
  const { address, postalCode, city, state, country } = patient

  return Boolean(address && postalCode && city && state && country)
}

export function normalizePatientValues(
  values: Partial<PatientDetails | UpdatePatientBody>,
): UpdatePatientBody | CreatePatientBody {
  // Do not normalize preferences
  const { preferences, ...rest } = trimDeep(values)
  return { ...convertEmptyStringToNull(omitUndefined({ ...rest })), preferences }
}

export function normalizeVitalsBody(values: CreateVitalsReportParams): VitalsPDFBody {
  return { ...values, from: values.from.toISOString(), until: values.until.toISOString() }
}
