import { getInstanceByDom, init } from 'echarts'
import type { ECharts, EChartsOption, SetOptionOpts } from 'echarts'
import React, { useRef, useEffect } from 'react'
import type { CSSProperties } from 'react'

export interface ReactEChartsProps {
  option: EChartsOption
  style?: CSSProperties
  settings?: SetOptionOpts
  loading?: boolean
}

export function ReactConnector({ option, style, settings, loading }: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let chart: ECharts | undefined
    if (chartRef.current !== null) {
      chart = init(chartRef.current)
    }

    function resizeChart() {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  useEffect(() => {
    // Update chart data
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      if (!chart) return
      chart.setOption(option, settings)
    }
  }, [option, settings])

  useEffect(() => {
    // Update chart after loading
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      if (!chart) return
      loading ? chart.showLoading() : chart.hideLoading()
    }
  }, [loading])

  return <div ref={chartRef} style={{ width: '100%', height: '100%', ...style }} />
}
