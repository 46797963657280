import { faArrowUpRightFromSquare, faTrash, faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { Device, DeviceStatusEnum } from '@vetahealth/tuna-can-api'
import { Button, Popconfirm, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { Line } from '../../styles'
import { getDeviceName, getStatusColor, getStatusDescription, getStatusLabel, tenoviDevices } from './helpers'

const Card = styled.div`
  padding: 8px 12px 6px 12px;
  background-color: ${({ theme }) => theme.deviceBackground};
  border: 1px solid ${({ theme }) => theme.deviceBorder};
  border-radius: 6px;
  margin-bottom: 10px;
  max-width: 60%;
`
const Wrap = styled.div<{ $spaceBetween?: boolean }>`
  display: flex;
  ${({ $spaceBetween }) => $spaceBetween && 'justify-content: space-between;'}
`
const Title = styled(Typography.Text)`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.35;
  min-height: 32px;
`
const DetailsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`
const DeviceDetails = styled.div`
  width: 100%;
`

const Shipping = styled.div<{ $hasOpacity?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ $hasOpacity }) => $hasOpacity && 'opacity: 0.35'};
`
const ShippingDetails = styled.div`
  width: auto;
  min-width: 50%;
`
const Label = styled(Typography.Text)`
  font-weight: 600;
  font-size: 13px;
  margin-right: 6px;
`
const Value = styled(Typography.Text)`
  font-size: 13px;
`
const PendingLabel = styled(Typography.Text)`
  font-size: 13px;
  opacity: 0.6;
`
const DeletableWrap = styled.div`
  margin-top: 6px;
  color: ${Colors.apricot400};
`
const Deletable = styled.span`
  margin-left: 6px;
  font-size: 12px;
  font-weight: 600;
`

const Pending = (): JSX.Element => <PendingLabel>{i18next.t('widgets.devices.pending')}</PendingLabel>

const getTrackingNumberLabel = (trackingNumber?: string, trackingUrl?: string): JSX.Element => {
  if (trackingNumber && trackingUrl)
    return (
      <Typography.Link target="_blank" rel="noopener noreferrer" href={trackingUrl}>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ margin: '0 6px 0 3px' }} />
        {trackingNumber}
      </Typography.Link>
    )
  if (trackingNumber) return <Value>{trackingNumber}</Value>
  return <Pending />
}

export function DeviceCard({
  device,
  onDeleteDevice,
  onDeleteOrder,
  isAllowedToOrderDevice,
  isAllowedToAssignDevice,
  isAllowedToDeleteOrders,
}: {
  device: Device
  onDeleteDevice: (deviceId: string) => void
  onDeleteOrder: (orderId: string) => void
  isAllowedToOrderDevice: boolean
  isAllowedToAssignDevice: boolean
  isAllowedToDeleteOrders: boolean
}): JSX.Element | null {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    deviceType,
    deviceId,
    lastUsage,
    status,
    creationDate,
    carrier,
    modificationDate,
    trackingNumber,
    trackingUrl,
    orderId,
    hubId,
  } = device

  const isAssignedDevice = deviceId && !orderId
  const isOrderedDevice = deviceId && orderId
  const isOrderOngoing = !deviceId && orderId
  const isHubIDShown = hubId || tenoviDevices.includes(deviceType)

  // Device order can only be deleted for 15 minutes
  const minutesDiff = dayjs().diff(dayjs(creationDate), 'minute')
  const timeUntilDeletable = dayjs(creationDate).add(15, 'minute').format('LT')
  const isCreatedOrderDeletable = minutesDiff < 15 && status === DeviceStatusEnum.Created && isAllowedToOrderDevice
  const isOrderDeletable = isCreatedOrderDeletable || isAllowedToDeleteOrders
  const popConfirmOrderTitle =
    status === DeviceStatusEnum.Created
      ? t('widgets.devices.confirmDeleteOrder')
      : t('widgets.devices.confirmDeleteSubmittedOrder')

  return (
    <Card>
      <Wrap $spaceBetween>
        <Title>{deviceType && getDeviceName(deviceType)}</Title>
        {/* Ongoing order deletion */}
        {isOrderOngoing && (
          <Popconfirm
            title={popConfirmOrderTitle}
            icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
            cancelButtonProps={{ type: 'text' }}
            cancelText={t('actions.cancel')}
            okButtonProps={{ danger: true }}
            okText={t('actions.delete')}
            disabled={!isOrderDeletable}
            onConfirm={() => onDeleteOrder(orderId)}
          >
            <Tooltip title={!isOrderDeletable && t('widgets.devices.orderNotDeletable')}>
              <Button
                shape="circle"
                icon={
                  <FontAwesomeIcon icon={faTrash} color={isOrderDeletable ? theme.error : theme.tertiaryBackground} />
                }
                type="text"
                disabled={!isOrderDeletable}
                onClick={() => trackEvent(TrackingEvent.deviceDeleteOrderClicked, { type: deviceType })}
              />
            </Tooltip>
          </Popconfirm>
        )}
        {/* Device deletion */}
        {isAllowedToAssignDevice && (isAssignedDevice || (isOrderedDevice && !isOrderOngoing)) && (
          <Popconfirm
            title={t('widgets.devices.confirmDeleteDevice')}
            icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
            cancelButtonProps={{ type: 'text' }}
            cancelText={t('actions.cancel')}
            okButtonProps={{ danger: true }}
            okText={t('actions.delete')}
            onConfirm={() => onDeleteDevice(deviceId)}
          >
            <Button
              shape="circle"
              icon={<FontAwesomeIcon icon={faTrash} color={theme.error} />}
              type="text"
              onClick={() => trackEvent(TrackingEvent.deviceDeleteClicked, { type: deviceType })}
            />
          </Popconfirm>
        )}
      </Wrap>
      <Wrap>
        <Tooltip title={getStatusDescription(status)}>
          <Tag color={getStatusColor(status, theme)}>{getStatusLabel(status)}</Tag>
        </Tooltip>
      </Wrap>
      <DetailsWrap>
        <DeviceDetails>
          <Label>{t('widgets.devices.deviceId')}</Label>
          {deviceId ? <Value>{deviceId}</Value> : <Pending />}
        </DeviceDetails>
        <DeviceDetails>
          <Label>{t('widgets.devices.lastUsage')}</Label>
          {lastUsage ? <Value>{dayjs(lastUsage).format('lll')}</Value> : <Pending />}
        </DeviceDetails>
        <DeviceDetails>
          <Label>{isAssignedDevice ? t('widgets.devices.assigned') : t('widgets.devices.ordered')}</Label>
          <Value>{dayjs(creationDate).format('lll')}</Value>
        </DeviceDetails>
        {isHubIDShown && (
          <DeviceDetails>
            <Label>{t('widgets.devices.hubId')}</Label>
            {hubId ? <Value>{hubId}</Value> : <Pending />}
          </DeviceDetails>
        )}
      </DetailsWrap>
      {!isAssignedDevice && (
        <>
          <Line $noMargin />
          <Shipping $hasOpacity={status === DeviceStatusEnum.Delivered || status === DeviceStatusEnum.InUse}>
            <ShippingDetails>
              <Label>{t('widgets.devices.courier')}</Label>
              {carrier ? <Value>{carrier}</Value> : <Pending />}
            </ShippingDetails>
            <ShippingDetails>
              <Label>{t('widgets.devices.lastUpdated')}</Label>
              {<Value>{dayjs(modificationDate).format('lll')}</Value>}
            </ShippingDetails>
            <ShippingDetails>
              <Label>{t('widgets.devices.trackingId')}</Label>
              {getTrackingNumberLabel(trackingNumber, trackingUrl)}
            </ShippingDetails>
          </Shipping>
        </>
      )}
      {isCreatedOrderDeletable && (
        <>
          <Line $noMargin />
          <DeletableWrap>
            <FontAwesomeIcon icon={faWarning} />
            <Deletable>{t('widgets.devices.deletable', { time: timeUntilDeletable })}</Deletable>
          </DeletableWrap>
        </>
      )}
    </Card>
  )
}
