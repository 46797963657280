import { Colors } from '@vetahealth/fishing-gear/colors'
import { StatusEnum } from '@vetahealth/tuna-can-api'
import i18next from 'i18next'
import { useTheme } from 'styled-components'

export function getTagColor(status: StatusEnum): Colors {
  const theme = useTheme()

  const mapStatusEnumToColor: Record<StatusEnum, Colors> = {
    [StatusEnum.Active]: theme.tagSuccess,
    [StatusEnum.Inactive]: theme.tagCreated,
    [StatusEnum.Locked]: theme.tagReturned,
    [StatusEnum.Archived]: theme.tagArchived,
  }

  return mapStatusEnumToColor[status]
}

export function getStatusName(status: StatusEnum): string {
  const mapStatusEnumToName: Record<StatusEnum, string> = {
    [StatusEnum.Active]: i18next.t('status.active'),
    [StatusEnum.Inactive]: i18next.t('status.inactive'),
    [StatusEnum.Locked]: i18next.t('status.locked'),
    [StatusEnum.Archived]: i18next.t('status.archived'),
  }

  return mapStatusEnumToName[status]
}
