import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AppRoutes } from './AppRoutes'
import { createRouter } from './router'

const router = createBrowserRouter([{ path: '*', element: <AppRoutes /> }])

createRouter(router)

export function AppRouter(): JSX.Element {
  return <RouterProvider router={router} />
}
