import { faLock, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, Form, Input } from 'antd'
import { debounce } from 'lodash-es'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AuthStep, useAuthStore } from '../../stores/auth'
import { SigninType } from '../../stores/auth/types'
import { GoogleSSOButton } from './GoogleSSOButton'
import { Action, ButtonWrapper, FormWrapper } from './styles'
import { FormValues } from './types'

const ExternalIdProviders = styled.div`
  margin-top: 20px;
  min-height: 44px;
  max-height: 44px;
  max-width: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

export function Signin(): JSX.Element {
  const { t } = useTranslation()
  const [handleSignin, setStep, errorStatus] = useAuthStore((state) => [
    state.handleSignin,
    state.setStep,
    state.errorStatus,
  ])

  const handleSubmit = useCallback(
    debounce(async (values: Pick<FormValues, 'email' | 'password'>): Promise<void> => {
      await handleSignin({ signinType: SigninType.CREDENTIALS, userName: values.email, password: values.password })
    }, 300),
    [],
  )

  return (
    <>
      <Action>{t('auth.login')}</Action>
      {errorStatus === 404 && <Alert type="error" message={t('error.emailPassword')} style={{ marginTop: '10px' }} />}
      {errorStatus === 423 && <Alert type="error" message={t('error.locked')} style={{ marginTop: '10px' }} />}
      <FormWrapper>
        <Form onFinish={handleSubmit} validateTrigger="onSubmit">
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('validations.enterEmail') },
              { type: 'email', message: t('validations.email') },
            ]}
          >
            <Input
              size="large"
              placeholder={t('placeholders.email')}
              prefix={<FontAwesomeIcon icon={faUser} size="sm" />}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: t('validations.enterPassword') }]}>
            <Input.Password
              size="large"
              placeholder={t('placeholders.password')}
              type="password"
              prefix={<FontAwesomeIcon icon={faLock} size="sm" />}
            />
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit" size="large">
              {t('actions.login')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
      <ButtonWrapper>
        <Button onClick={() => setStep(AuthStep.FORGOT_PASSWORD)} type="link">
          {t('actions.forgotPassword')}
        </Button>
      </ButtonWrapper>
      <ExternalIdProviders>
        <GoogleSSOButton />
      </ExternalIdProviders>
    </>
  )
}
