import React from 'react'
import styled from 'styled-components'

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

export const Actions = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return <ActionsWrapper>{children}</ActionsWrapper>
}
