// biome-ignore lint/style/useEnumInitializers: no specific declaration needed here
export enum ModalName {
  addPatient,
  emailSupport,
  epicImport,
  releaseInfo,
  reportWizard,
  resetDemoPatients,
  resetUserSettings,
  preview,
}

export type ModalState = {
  openModal: (name: ModalName) => boolean
  closeModal: (name?: ModalName) => void
  modal?: ModalName
}
