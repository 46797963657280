import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { SiteManagement as SiteManagementComponent } from '../../components/Widgets/SiteManagement'
import { useSitesStore } from '../../stores/sites'
import { useUserStore } from '../../stores/user'
import { routes } from '../routes'
import { GenericWrapper } from './styles'

export enum SiteManagementTab {
  settings = 'settings',
  programs = 'programs',
  ordering = 'ordering',
  files = 'files',
  snapshots = 'snapshots',
}

export function SiteManagement(): JSX.Element {
  const navigate = useNavigate()
  const [hasSomePermission] = useUserStore((state) => [state.hasSomePermission])
  const [sites, managedSiteKeys, getSites] = useSitesStore((state) => [
    state.sites,
    state.managedSiteKeys,
    state.getSites,
  ])

  const { tab } = useParams<{ tab: SiteManagementTab }>()

  void getSites()

  const handleTabChange = (newTab: SiteManagementTab): void => {
    navigate(routes.siteManagement(newTab), { replace: true })
  }

  useEffect(() => {
    if (sites && managedSiteKeys) {
      const isAllowedToManageSites = sites?.some((site) => managedSiteKeys?.includes(site.key))
      if (!isAllowedToManageSites && !hasSomePermission(PermissionName.manageSite)) {
        navigate(routes.patientList(), { replace: true })
        return
      }
    }
  }, [sites, managedSiteKeys])

  return (
    <GenericWrapper>
      <SiteManagementComponent tab={tab} onTabChange={handleTabChange} />
    </GenericWrapper>
  )
}
