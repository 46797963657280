import { faMessages } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Button, Tooltip } from 'antd'

import { useChatStore } from '../../stores/chat'
import { usePatientStore } from '../../stores/patient'
import { Chat } from './Chat'
import { Badge } from './styles'

const Wrap = styled.div`
  position: relative;
`

export function OpenChat(): JSX.Element {
  const [isChatOpen, unreadChatMessages, setIsChatOpen] = useChatStore((state) => [
    state.isChatOpen,
    state.unreadChatMessages,
    state.setIsChatOpen,
  ])
  const [patient] = usePatientStore((state) => [state.patient])
  const { t } = useTranslation()

  const hasNewMessage = unreadChatMessages.some((message) => message.userId === patient?.id)

  return (
    <Wrap>
      <Tooltip title={hasNewMessage ? t('chat.newMessages') : t('chat.title')}>
        <Button shape="circle" onClick={() => setIsChatOpen(true)}>
          <FontAwesomeIcon icon={faMessages} />
        </Button>
        {hasNewMessage && <Badge $color={Colors.ruby600} />}
      </Tooltip>
      {isChatOpen && <Chat />}
    </Wrap>
  )
}
