import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { API } from '../../lib/api'
import { convertAggregatedAnalytics, convertRawAnalytics } from './helpers'
import { AppState } from './types'

export const useAnalyticsStore = createWithEqualityFn<AppState>(
  (set, get) => ({
    aggregatedAnalytics: undefined,
    rawAnalytics: [],
    getAggregatedAnalytics: async (site, types, programs, aggregateBy, start, end) => {
      const storedAggregatedAnalytics = get().aggregatedAnalytics
      const result = await API.getAggregatedAnalytics(site, types, programs, aggregateBy, start, end)

      if (result) {
        const groupedConvertedAnalytics = convertAggregatedAnalytics(result, storedAggregatedAnalytics)
        set({ aggregatedAnalytics: groupedConvertedAnalytics })
      }
    },
    getRawAnalytics: async (site, type, programs, start, end) => {
      const rawAnalytics = await API.getRawAnalytics(site, type, programs, start, end)

      if (rawAnalytics) {
        const convertedAnalytics = convertRawAnalytics(rawAnalytics, type)
        set({ rawAnalytics: convertedAnalytics })
      }
    },
    resetAnalytics: () => {
      set({ aggregatedAnalytics: undefined, rawAnalytics: [] })
    },
  }),
  shallow,
)
