import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DeviceTypeEnum, OrderKitTypeEnum, OrderProcessing } from '@vetahealth/tuna-can-api'

import { Form, Select, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'

import { getOrderDeviceName, tenoviDevices } from '../Widgets/PatientDetails/Devices/helpers'
import { Badge, BadgeLabel, ThreeColumns } from './styles'
import { FormKeys } from './types'

const Devices = styled.div`
  grid-column: 2 / 4;
  grid-row: 1 / 5;
`
const DeviceOrdering = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
`
const DeviceAssigning = styled.div`
  grid-column: 1 / 2;
  grid-row: 2/ 2;
`

const availableDevices = [...Object.values(DeviceTypeEnum), ...Object.values(OrderKitTypeEnum)]

export function SiteOrderSettings({ form }: { form: FormInstance }): JSX.Element {
  const { t } = useTranslation()

  const { hasDeviceOrdering, devices } = form.getFieldsValue([FormKeys.HAS_DEVICE_ORDERING, FormKeys.DEVICES])

  function handleDeviceOrderChange(key: DeviceTypeEnum | OrderKitTypeEnum, value: OrderProcessing): void {
    const deviceUpdate = { ...devices, [key]: value }
    form.setFieldValue(FormKeys.DEVICES, deviceUpdate)
    form.submit()
  }

  return (
    <ThreeColumns>
      <DeviceOrdering>
        <Form.Item
          name={FormKeys.HAS_DEVICE_ORDERING}
          label={t('form.deviceOrdering')}
          extra={t('form.deviceOrderingDescription')}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </DeviceOrdering>
      <DeviceAssigning>
        <Form.Item
          name={FormKeys.HAS_DEVICE_ASSIGNING}
          label={t('form.deviceAssigning')}
          extra={t('form.deviceAssigningDescription')}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </DeviceAssigning>
      <Devices>
        <Form.Item
          name={FormKeys.DEVICES}
          style={{ flex: 1, marginBottom: 0 }}
          label={t('form.devices')}
          extra={t('form.devicesDescription')}
        >
          {availableDevices.map((deviceKey: DeviceTypeEnum) => {
            const isActive = devices[deviceKey]
            const isTenoviDevice = tenoviDevices.includes(deviceKey)
            return (
              <Badge key={deviceKey} $isActive={isActive && hasDeviceOrdering}>
                <BadgeLabel $isActive={isActive && hasDeviceOrdering}>
                  {getOrderDeviceName(deviceKey, 'lbs')}
                </BadgeLabel>
                <Select
                  onChange={(value) => handleDeviceOrderChange(deviceKey, value)}
                  value={devices[deviceKey]}
                  placeholder={t('validations.selectOrderType')}
                  allowClear
                  disabled={!hasDeviceOrdering}
                  style={{ flex: 1, marginBottom: 0 }}
                  options={[
                    { value: OrderProcessing.Manual, label: t('form.manual') },
                    ...(!isTenoviDevice
                      ? [{ value: OrderProcessing.Manufacturer, label: t('form.manufacturer') }]
                      : []),
                    { value: OrderProcessing._3pl, label: t('form.3pl') },
                  ]}
                />
              </Badge>
            )
          })}
        </Form.Item>
      </Devices>
    </ThreeColumns>
  )
}
