import { assign } from 'lodash-es'
import { DefaultTheme } from 'styled-components'
import { VictoryThemeDefinition } from 'victory'

export const getTheme = (globalTheme: DefaultTheme): VictoryThemeDefinition => {
  const padding = 8

  const baseLabelStyles = {
    fontSize: 12,
    fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
    fontWeight: 500,
    letterSpacing: 'normal',
    padding,
    fill: globalTheme.text,
    stroke: 'transparent',
    strokeWidth: 0,
  }

  const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles)

  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'

  return {
    axis: assign({
      style: {
        axis: {
          fill: 'transparent',
          stroke: globalTheme.chartGridLines,
          strokeWidth: 0,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: assign({}, centeredLabelStyles, {
          padding,
          stroke: 'transparent',
        }),
        grid: {
          fill: 'none',
          stroke: globalTheme.chartGridLines,
          strokeDasharray: '1, 6',
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: 'painted',
          strokeWidth: 1,
        },
        ticks: {
          fill: 'transparent',
          size: 5,
          stroke: globalTheme.text,
          strokeWidth: 0,
          strokeLinecap,
          strokeLinejoin,
        },
        tickLabels: assign({}, baseLabelStyles),
      },
    }),
    bar: assign({
      style: {
        data: {
          padding,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    }),
    line: assign({
      style: {
        data: {
          opacity: 1,
          strokeWidth: 2.7,
        },
        labels: baseLabelStyles,
      },
    }),
    scatter: assign({
      style: {
        data: {
          opacity: 1,
        },
        labels: baseLabelStyles,
      },
    }),
  }
}
