import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Modal, Typography } from 'antd'

import { useLoading } from '../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { useAppStore } from '../../stores/app'
import { useUserStore } from '../../stores/user'
import { DatoAssets } from '../Assets'
import { Line, NoData } from '../Widgets/styles'
import { ModalProps } from './types'

const Info = styled.div`
  margin-bottom: 50px;
`
const Title = styled(Typography.Title)`
  margin-top: 0;
`
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`

export function ReleaseInfo({ isVisible, onCancel }: ModalProps): JSX.Element {
  const [getReleaseInfo, releaseInfo] = useAppStore((state) => [state.getReleaseInfo, state.releaseInfo])
  const [viewedReleaseInfo, updateViewedReleaseInfo] = useUserStore((state) => [
    state.viewedReleaseInfo,
    state.updateViewedReleaseInfo,
  ])
  const { t } = useTranslation()
  const [hasMoreData, setHasMoreData] = useState(true)
  const [loading, withLoading] = useLoading()

  const handleCancel = (): void => {
    const lastReleaseInfo = releaseInfo[0]
    if (lastReleaseInfo && !viewedReleaseInfo.includes(lastReleaseInfo.id)) updateViewedReleaseInfo(lastReleaseInfo.id)
    setHasMoreData(true)
    onCancel()
  }

  const handleLoadMore = async (): Promise<void> => {
    trackEvent(TrackingEvent.releaseInfoPreviousClicked)
    const response = await withLoading(getReleaseInfo(releaseInfo.length))
    if (response.length === 0) setHasMoreData(false)
  }

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      maskClosable={false}
      title={t('menus.main.releaseInfo')}
      footer={null}
      width={800}
      destroyOnClose
      centered
    >
      {releaseInfo.map((info) => {
        const { id, content, releaseDate } = info
        const heading = t('menus.main.releaseInfoModal.release', { date: dayjs(releaseDate).format('LL') })
        return (
          <div key={id}>
            <Info>
              <Title level={3}>{heading}</Title>
              <DatoAssets assets={content} />
            </Info>
            <Line />
          </div>
        )
      })}
      {hasMoreData && (
        <ButtonWrap>
          <Button disabled={!hasMoreData} loading={loading} onClick={handleLoadMore} type="link">
            {t('actions.previousRelease')}
          </Button>
        </ButtonWrap>
      )}
      {(!hasMoreData || !releaseInfo.length) && <NoData />}
    </Modal>
  )
}
