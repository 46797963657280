import styled, { DefaultTheme } from 'styled-components'

import { Typography } from 'antd'

import { Category } from '../config'
import { CardColors } from './types'

export const getColorByCategory = ({
  category,
  theme,
}: {
  category: `${Category}`
  theme: DefaultTheme
}): CardColors => {
  return {
    tracking: {
      background: theme.secondaryBackground,
      active: theme.trackingActive,
      hover: theme.trackingBorderHover,
    },
    task: {
      background: theme.secondaryBackground,
      active: theme.taskActive,
      hover: theme.taskBorderHover,
    },
    statistics: {
      background: theme.secondaryBackground,
      active: theme.statsActive,
      hover: theme.statsHover,
    },
  }[category]
}

export const getAlertColor = (theme: DefaultTheme): CardColors => ({
  background: theme.concerningBackground,
  active: theme.concerningActive,
  hover: theme.concerningHover,
})

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 8px;
  margin-bottom: 16px;
`

export const Wrapper = styled.div<{ $colors: CardColors; $isActive?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 6px;
  border: 2px solid transparent;
  border-color: ${({ $isActive, $colors }) => ($isActive ? $colors.active : $colors.background)};
  &:hover {
    border-color: ${({ $isActive, $colors }) => ($isActive ? $colors.active : $colors.hover)};
  }
  background-color: ${({ $colors }) => $colors.background};
  border-radius: 6px;
  cursor: pointer;
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20px;
`
export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3px;
`
export const LabelWrapper = styled.div`
  display: flex;
  overflow: hidden;
`
export const Label = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Value = styled.span<{ $noMargin?: boolean }>`
  font-size: 19px;
  line-height: 1.1;
  margin-right: ${({ $noMargin }) => ($noMargin ? '0px' : '8px')};
`
export const Unit = styled.span`
  font-size: 13px;
  font-weight: 500;
  margin: 0 4px;
`

export const Time = styled.span`
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
