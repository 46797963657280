import { QuestionAsset } from '@vetahealth/tuna-can-api'
import { Checkbox as AntCheckbox } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 2px;
`

export function Checkbox({ asset }: { asset: QuestionAsset & { response?: string[] } }): React.ReactNode {
  return (
    <Wrapper>
      {asset.answerOptions.map((answerOption) => (
        <AntCheckbox key={answerOption.id} value={answerOption.id} checked={asset.response?.includes(answerOption.id)}>
          {answerOption.label}
        </AntCheckbox>
      ))}
    </Wrapper>
  )
}
