import { faTrash, faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Button, Popconfirm } from 'antd'

import { useLoading } from '../../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import { useResultsStore } from '../../../../../stores/results'

export function Delete({ id }: { id: number }): JSX.Element {
  const { t } = useTranslation()
  const [patient] = usePatientStore((state) => [state.patient])
  const [deleteTrackingEvent] = useResultsStore((state) => [state.deleteTrackingEvent])
  const [isVisible, setVisibility] = useState(false)
  const [isLoading, withLoading] = useLoading()
  const isRendered = useRef(true)

  useEffect(() => {
    return () => {
      isRendered.current = false
    }
  }, [])

  const handleConfirm = (): void => {
    if (patient) {
      trackEvent(TrackingEvent.resultListDeleteValueClicked)
      withLoading(deleteTrackingEvent(patient.id, id)).finally(() => isRendered.current && setVisibility(false))
    }
  }

  return (
    <Popconfirm
      title={t('widgets.results.confirmDelete')}
      okText={t('actions.delete')}
      okButtonProps={{ loading: isLoading, danger: true }}
      onConfirm={handleConfirm}
      cancelText={t('actions.cancel')}
      onCancel={() => setVisibility(false)}
      cancelButtonProps={{ type: 'text' }}
      placement="topRight"
      open={isVisible}
      icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
    >
      <Button
        shape="circle"
        icon={<FontAwesomeIcon icon={faTrash} color={Colors.ruby600} />}
        type="text"
        onClick={() => setVisibility(true)}
      />
    </Popconfirm>
  )
}
