import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Tooltip } from 'antd'

import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import { ResultsType, useResultsStore } from '../../../../../stores/results'
import { getResultsConfig } from '../config'
import {
  BottomRow,
  Label,
  LabelWrapper,
  Row,
  Time,
  Unit,
  Value,
  Wrapper,
  getAlertColor,
  getColorByCategory,
} from './styles'

export function ResultsCard({ type }: { type: ResultsType }): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const [patient] = usePatientStore((state) => [state.patient])
  const [setType, currentType, lastResults, alerts] = useResultsStore((state) => [
    state.setType,
    state.type,
    state.lastResults,
    state.alerts,
  ])

  function handleCardSelection(): void {
    if (patient) {
      trackEvent(TrackingEvent.resultCardClicked, { type })
      setType(type)
    }
  }

  const { title, format, category, card, unit } = getResultsConfig(type)
  const lastResult = lastResults[type]
  const colors = alerts.unreviewed.some((alert) => alert.type === type)
    ? getAlertColor(theme)
    : getColorByCategory({ category, theme })

  const displayTitle = card?.formatTitle?.(lastResult) || title
  const displayValue = (lastResult && format?.(lastResult, unit)) || ''
  const displayTimestamp =
    lastResult?.timestamp &&
    (card?.formatTime?.(lastResult) ||
      dayjs(lastResult.timestamp).tz(patient?.timeZone).format(t('dateFormats.dayAndMonth')))

  return (
    <Wrapper onClick={handleCardSelection} $colors={colors} $isActive={type === currentType}>
      <Row>
        <Tooltip mouseEnterDelay={1} title={displayTitle}>
          <LabelWrapper>
            <Label>{displayTitle}</Label>
          </LabelWrapper>
        </Tooltip>
      </Row>
      <BottomRow>
        <div>
          <Tooltip mouseEnterDelay={1} title={displayValue}>
            {displayValue.split(new RegExp(/\s/g)).map((part, index) => {
              return index % 2 === 0 ? (
                <Value key={index} $noMargin>
                  {part}
                </Value>
              ) : (
                <Unit key={index}>{part}</Unit>
              )
            })}
          </Tooltip>
        </div>
        {displayTimestamp && (
          <Tooltip mouseEnterDelay={1} title={displayTimestamp}>
            <Time>{displayTimestamp}</Time>
          </Tooltip>
        )}
      </BottomRow>
    </Wrapper>
  )
}
