import { defaultUnits } from '@vetahealth/fishing-gear/conversions'
import { BaseClaims } from '@vetahealth/fishing-gear/express/requireAuthorization'
import { PermissionName, hasPermission } from '@vetahealth/fishing-gear/permissions'
import { AlertDigestFrequencyEnum, PopulationView, UnitSetting } from '@vetahealth/tuna-can-api'
import { jwtDecode } from 'jwt-decode'
import { omit, pick } from 'lodash-es'
import { matchPath } from 'react-router'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { router } from '../../Router/router'
import { routes } from '../../Router/routes'
import { API } from '../../lib/api'
import { SupportedLocale, getInitialLocale } from '../../lib/i18n'
import { takeLeading } from '../../lib/takeLeading'
import { identify } from '../../lib/tracking'
import { useAnalyticsStore } from '../analytics'
import { UserState } from './types'

export const initialState: Omit<
  UserState,
  | 'getSettings'
  | 'setUserInfo'
  | 'updateLocale'
  | 'updateUnits'
  | 'updateViews'
  | 'updateAlertDigest'
  | 'updateViewedReleaseInfo'
  | 'updateAppearance'
  | 'updateTheme'
  | 'updateSummarySettings'
  | 'updateChartVisibility'
  | 'updateQuickInfo'
  | 'updateAnalyticsSettings'
  | 'handleToken'
  | 'hasPermission'
  | 'hasSomePermission'
  | 'reset'
> = {
  userId: undefined,
  firstName: undefined,
  lastName: undefined,
  hasTwoFactorEnabled: undefined,
  avatarImageUrl: undefined,
  viewedReleaseInfo: [],
  theme: undefined,
  summarySettings: undefined,
  isChartVisible: undefined,
  patientQuickInfo: [],
  locale: getInitialLocale(),
  permissions: undefined,
  views: [],
  units: defaultUnits,
  alertDigest: { frequency: AlertDigestFrequencyEnum.Never, hourToSend: 6, weekday: 1 },
  analyticsSettings: undefined,
}

const updatableUnits: Array<keyof UnitSetting> = [
  'bloodGlucose',
  'bloodPressure',
  'bodyTemperature',
  'height',
  'hbA1c',
  'weight',
]

export const useUserStore = createWithEqualityFn<UserState>(
  (set, get) => ({
    ...initialState,
    getSettings: takeLeading(async () => {
      const settings = await API.getSettings()

      if (settings) {
        set({
          views: settings.populationViews,
          units: Object.assign({}, defaultUnits, settings.units),
          alertDigest: settings.alertDigest,
          viewedReleaseInfo: settings.viewedReleaseInfo,
          theme: settings.appearance.theme,
          summarySettings: settings.appearance.summary,
          isChartVisible: settings.appearance.chart,
          patientQuickInfo: settings.appearance.patientQuickInfo,
          analyticsSettings: settings.analytics,
        })

        const isPatientListPath = matchPath(window.location.pathname, routes.patientList.path)

        if (isPatientListPath && settings.populationViews.length && !window.location.search) {
          // redirect to first view after login/token refresh (only if no query string set - to enable external links)
          router?.navigate(routes.patientList() + settings.populationViews[0].queryString)
        }
      }

      return !!settings
    }),
    setUserInfo: async (
      { credentials, firstName, lastName, locale, email, hasTwoFactorEnabled, createdAt },
      permissions,
    ) => {
      set({
        credentials,
        firstName,
        lastName,
        email,
        hasTwoFactorEnabled,
        createdAt,
        permissions,
      })

      if (locale) {
        set({ locale: locale as SupportedLocale })
      } else {
        // set initial language in fusion user
        void API.updateUserSettings({ locale: get().locale })
      }
      return true
    },
    updateUnits: async (units: UnitSetting) => {
      const success = await API.updateUnitSettings(pick(units, updatableUnits))

      if (success) set({ units: Object.assign({}, defaultUnits, units) })

      return !!success
    },
    updateViews: async (views: PopulationView[]) => {
      const success = await API.updatePopulationViewSettings(views)

      if (success) set({ views })

      return !!success
    },
    updateLocale: async (locale) => {
      const { userId } = get()

      if (userId) {
        const success = await API.updateUserSettings({ locale })

        if (success) set({ locale })

        return !!success
      }

      return false
    },
    updateAlertDigest: async (alertDigest) => {
      const success = await API.updateAlertDigestSettings(alertDigest)

      if (success) set({ alertDigest })

      return !!success
    },
    updateViewedReleaseInfo: async (id) => {
      const success = await API.updateViewedReleaseInfo(id)
      if (success) set({ viewedReleaseInfo: [id] })

      return !!success
    },
    updateAppearance: async (updatedAppearance) => {
      const { theme, summarySettings, isChartVisible, patientQuickInfo } = get()
      if (!theme || !summarySettings || isChartVisible === undefined || !patientQuickInfo) return false

      const success = await API.updateAppearance({
        theme: updatedAppearance.theme ?? theme,
        summary: updatedAppearance.summary ?? summarySettings,
        chart: updatedAppearance.chart ?? isChartVisible,
        patientQuickInfo: updatedAppearance.patientQuickInfo ?? patientQuickInfo,
      })

      if (success) set(updatedAppearance)

      return !!success
    },
    updateTheme: async (theme) => await get().updateAppearance({ theme }),
    updateChartVisibility: async (isChartVisible) => await get().updateAppearance({ chart: isChartVisible }),
    updateSummarySettings: async (summarySettings) => await get().updateAppearance({ summary: summarySettings }),
    updateQuickInfo: async (patientQuickInfo) => await get().updateAppearance({ patientQuickInfo }),
    updateAnalyticsSettings: async (updatedAnalyticsSettings) => {
      const { analyticsSettings } = get()
      if (!analyticsSettings) return false
      const isLayoutChange = 'compact' in updatedAnalyticsSettings || 'chart' in updatedAnalyticsSettings
      const payload = Object.assign({}, analyticsSettings, updatedAnalyticsSettings)
      const success = await API.updateAnalytics(payload)

      if (success) {
        if (!isLayoutChange) {
          useAnalyticsStore.getState().resetAnalytics()
        }
        set({ analyticsSettings: payload })
      }

      return !!success
    },
    handleToken: (token) => {
      const { sub: userId } = jwtDecode<BaseClaims>(token)

      set({
        userId,
      })

      identify(userId)
    },
    hasPermission: (permissionName: PermissionName, site?: string) => {
      const { permissions } = get()

      if (!permissions) return false
      return hasPermission(permissionName, permissions, site)
    },
    hasSomePermission: (permissionName: PermissionName) => {
      const { permissions } = get()

      if (!permissions) return false
      return permissions.some((permission) => permission.startsWith(`${permissionName}:`))
    },
    reset: () => {
      set(omit(initialState, 'locale'))
    },
  }),
  shallow,
)
