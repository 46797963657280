import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { Button, Select, Table, Upload, UploadProps, message } from 'antd'
import { difference, omit, range, sortBy, sum } from 'lodash-es'
import { ParseResult, parse } from 'papaparse'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLoading } from '../../../lib/hooks/useLoading'
import { usePatientsStore } from '../../../stores/patients'
import { useSitesStore } from '../../../stores/sites'
import { useUserStore } from '../../../stores/user'
import { getSiteSelectOption } from '../../helpers'
import { Card } from '../styles'
import TableHead from './TableHead'
import {
  MappingKey,
  autoDetectColumnMappings,
  createPatientData,
  getMappingLabels,
  joinJSX,
  mapData,
  requiredMappings,
  validateCell,
  validateColumns,
} from './helpers'

interface UploadData {
  fields: string[]
  data: string[][]
}

const PreviewTable = styled(Table)`
  margin-top: 28px;
  .ant-checkbox-inner {
    border-radius: 4px;
  }
`

const Pale = styled.div`
  color: ${Colors.gray600};
`

const ErrorLabel = styled.span`
  color: ${Colors.ruby600};
  font-style: italic;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonWrap = styled.div`
  > *:not(:last-child) {
    margin-right: 8px;
  }
`

export function CsvImport(): JSX.Element {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const [uploadData, setUploadData] = useState<UploadData>()
  const [columnMappings, setColumnMappings] = useState<Record<number, MappingKey>>({})
  const [columnErrors, setColumnErrors] = useState<Record<number, number>>({})
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedSite, setSelectedSite] = useState<string>()
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [sites, getSites] = useSitesStore((state) => [state.sites, state.getSites])
  const [hasPermission] = useUserStore((state) => [state.hasPermission])
  const [allPatients, createPatients] = usePatientsStore((state) => [state.patients, state.createPatients])

  const validPrograms = useMemo(() => {
    if (!sites || !selectedSite) return undefined
    return sites.find((site) => site.key === selectedSite)?.programs
  }, [sites, selectedSite])

  const mappedData: Partial<Record<MappingKey, string>>[] = useMemo(() => {
    if (!uploadData) return []
    return mapData(uploadData.data, columnMappings)
  }, [uploadData, columnMappings])

  useEffect(() => {
    if (!uploadData) return
    setColumnErrors(validateColumns(uploadData.data, columnMappings, selectedRows, allPatients, validPrograms))
  }, [uploadData, validPrograms, selectedRows, columnMappings])

  void getSites()

  const uploadProps: UploadProps = {
    accept: 'text/csv',
    beforeUpload: (file) => {
      const onComplete = (parseResult: ParseResult<unknown>): void => {
        if (!parseResult) return

        const fields = parseResult.data[0] as string[]
        const data = parseResult.data.slice(1) as string[][]
        setUploadData({ fields, data })

        const detectedColumnMappings = autoDetectColumnMappings(fields)
        setColumnMappings(detectedColumnMappings)

        const initiallySelectedRows = range(data.length)
        setSelectedRows(initiallySelectedRows)
      }
      file.text().then((content) => {
        parse(content, { complete: onComplete, header: false, transform: (value) => value.trim() })
      })
      return false
    },
    multiple: false,
  }

  const onSelectColumnMapping = (index: number, key: MappingKey | '<ignore>'): void => {
    const newColumnMappings = key === '<ignore>' ? omit(columnMappings, index) : { ...columnMappings, [index]: key }
    setColumnMappings(newColumnMappings)
  }

  const onSubmit = async (): Promise<void> => {
    if (!uploadData || !selectedSite) return

    const patients = createPatientData(
      uploadData.data,
      uploadData.fields,
      columnMappings,
      selectedRows,
      selectedSite,
      validPrograms ?? [],
    )
    const success = await withLoading(createPatients(patients))

    if (!success) return

    message.success(t('message.csvImport'))
    setUploadData(undefined)
  }

  const mappingLabels = getMappingLabels()
  const totalErrors = sum(Object.values(columnErrors))
  const missingRequiredMappings = difference(requiredMappings, Object.values(columnMappings))

  const dataSource = uploadData?.data.map((item, index) =>
    Object.fromEntries([['key', index], ...item.map((value, column) => [column, value])]),
  )

  const availableMappings = difference(
    Object.values(MappingKey),
    Object.values(columnMappings).filter((mappingKey) => mappingKey !== MappingKey.customData),
  )

  const columns = uploadData?.fields.map((field, index) => ({
    key: index,
    dataIndex: index,
    title: (
      <TableHead
        mapping={columnMappings[index]}
        errorCount={columnErrors[index] ?? 0}
        isSelected={selectedIndex === index}
        onClick={() => setSelectedIndex(index)}
        onSelect={(key: MappingKey) => onSelectColumnMapping(index, key)}
        onBlur={() => setSelectedIndex(-1)}
        mappingLabels={mappingLabels}
        availableMappings={availableMappings}
        field={field}
      />
    ),
    render: function renderCell(text: string, _: string[], rowIndex: number) {
      if (columnMappings[index] && selectedRows.includes(rowIndex)) {
        const context = { item: mappedData[rowIndex], validPrograms, allPatients }
        if (validateCell(text, columnMappings[index], context)) return text
        return <ErrorLabel>{text || '<empty>'}</ErrorLabel>
      }
      return <Pale>{text}</Pale>
    },
  }))

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (updatedSelectedRows: number[]) => {
      setSelectedRows(updatedSelectedRows)
    },
  }

  const statusMessages = joinJSX(
    [
      t('widgets.csvImport.countRecords', { count: uploadData?.data.length }),
      t('widgets.csvImport.countSelected', { count: selectedRows.length }),
      !selectedSite && <ErrorLabel>{t('widgets.csvImport.noSite')}</ErrorLabel>,
      totalErrors > 0 && <ErrorLabel>{t('widgets.csvImport.countErrors', { count: totalErrors })}</ErrorLabel>,
      missingRequiredMappings.length > 0 && (
        <ErrorLabel>{t('widgets.csvImport.countUnmapped', { count: missingRequiredMappings.length })}</ErrorLabel>
      ),
    ],
    ', ',
  )
  const accessibleSites = sites?.filter((site) => hasPermission(PermissionName.accessPatient, site.key))

  return (
    <Card title={t('widgets.csvImport.title')}>
      {uploadData ? (
        <>
          <Controls>
            <div>
              <Select<string>
                style={{ minWidth: '240px' }}
                value={selectedSite}
                options={sortBy(accessibleSites?.map(getSiteSelectOption), (item) => item.label.toLowerCase())}
                placeholder={t('placeholders.site')}
                onSelect={setSelectedSite}
              />
            </div>
            <div>{statusMessages}</div>
            <ButtonWrap>
              <Button onClick={() => setUploadData(undefined)}>{t('widgets.csvImport.buttonCancel')}</Button>
              <Button
                disabled={loading || totalErrors > 0 || missingRequiredMappings.length > 0 || !selectedSite}
                loading={loading}
                onClick={onSubmit}
              >
                {t('widgets.csvImport.buttonImport', { count: selectedRows.length })}
              </Button>
            </ButtonWrap>
          </Controls>
          <PreviewTable dataSource={dataSource} columns={columns} scroll={{ x: true }} rowSelection={rowSelection} />
        </>
      ) : (
        <Upload {...uploadProps}>
          <Button icon={<FontAwesomeIcon icon={faUpload} />}>{t('actions.uploadFile')}</Button>
        </Upload>
      )}
    </Card>
  )
}
