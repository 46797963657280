import { CompareFn, SortOrder } from 'antd/lib/table/interface'
import { sortBy, uniq } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getConditionNames } from '../../components/Forms/helpers'
import { getFields } from '../../components/Widgets/Population/helpers'
import { getCareStateNames } from '../../components/Widgets/helpers'
import { defaultSorting } from '../../components/helpers'
import { usePatientsStore } from '../../stores/patients'
import { useSitesStore } from '../../stores/sites'
import { PopulationViewState } from '../PopulationViewState'
import { FilterData, Patient } from './types'
import { useDebounce } from './useDebounce'

const emptyFilterData: FilterData = {
  tags: [],
  sites: [],
  careStates: [],
  conditions: [],
  diagnosisCodes: [],
  providers: [],
  programs: [],
  subscribers: [],
  openTasks: [],
  states: [],
}

function createStableSortComparator(
  key: string,
  allItems: Array<Pick<Patient, 'id'>>,
  sortOrder: SortOrder = 'ascend',
  compare?: CompareFn<Patient>,
): (a: Patient, b: Patient) => number {
  return (a: Patient, b: Patient): number => {
    const sortResult = compare ? compare(a, b, sortOrder) : defaultSorting(a, b, key, sortOrder)
    if (sortResult) return sortOrder === 'descend' ? sortResult * -1 : sortResult

    const aIndex = allItems.findIndex(({ id }) => id === a.id)
    const bIndex = allItems.findIndex(({ id }) => id === b.id)
    if (aIndex === bIndex) return 0
    if (sortOrder === 'ascend') return aIndex > bIndex ? 1 : -1
    return aIndex < bIndex ? 1 : -1
  }
}

function sortByViewState(patients: Patient[], viewState?: PopulationViewState): Patient[] {
  if (!viewState?.sortColumn) return patients
  const fieldMap = getFields({ filterData: emptyFilterData, viewState })

  const { key, compare } = fieldMap[viewState.sortColumn] ?? {}
  if (!key) return patients
  return [...patients].sort(createStableSortComparator(key, patients, viewState.sortDirection, compare))
}

export function usePatients(filter?: PopulationViewState): [Patient[], FilterData] {
  const [patients, getPatients] = usePatientsStore((state) => [state.patients, state.getPatients])
  const [filteredPatients, setFilteredPatients] = useState<Patient[]>([])
  const { i18n } = useTranslation()

  void getPatients()

  const [allSites = [], users] = useSitesStore((state) => [state.sites, state.users])

  const patientsWithSiteName = useMemo(() => {
    return patients.map((patient) => {
      const { name: siteName, shortName: siteShortName } = allSites.find(({ key }) => key === patient.site) ?? {}
      return { ...patient, siteName, siteShortName }
    })
  }, [patients, allSites])

  const filterData = useMemo(() => {
    const tags = sortBy(uniq(patientsWithSiteName.flatMap((patient) => patient.tags)), (item) =>
      item.toLowerCase(),
    ).map((tag) => ({
      text: tag,
      value: tag,
    }))
    const subscribers = sortBy(
      users.map(({ id, name }) => ({ value: id, text: name })),
      (item) => item.text.toLowerCase(),
    )
    const openTasks = sortBy(
      uniq(patientsWithSiteName.flatMap((patient) => patient.openTasks).concat(...(filter?.openTasks ?? []))).map(
        (text) => ({
          value: text,
          text,
        }),
      ),
      (item) => item.text.toLowerCase(),
    )

    const sites = sortBy(uniq(patientsWithSiteName.map((patient) => patient.site)), (item) => item.toLowerCase()).map(
      (siteKey) => {
        const site = allSites.find((site) => site.key === siteKey)
        return { value: siteKey, text: site?.shortName ?? siteKey }
      },
    )
    const careStates = sortBy(
      Object.entries(getCareStateNames()).map(([value, text]) => ({ value, text })),
      (item) => item.text.toLowerCase(),
    )
    const conditionNames: Record<string, string> = getConditionNames()
    const conditions = sortBy(
      uniq(patientsWithSiteName.flatMap((patient) => ('conditions' in patient ? patient.conditions : []))).map(
        (key) => ({
          text: conditionNames[key] || key,
          value: key,
        }),
      ),
      (item) => item.text.toLowerCase(),
    )
    const diagnosisCodes = sortBy(
      uniq(patientsWithSiteName.flatMap((patient) => ('diagnosisCodes' in patient ? patient.diagnosisCodes : []))).map(
        (code) => ({
          text: code,
          value: code,
        }),
      ),
      (item) => item.text.toLowerCase(),
    )
    const providers = sortBy(
      uniq(
        patientsWithSiteName.flatMap((patient) =>
          'provider' in patient && patient.provider ? [patient.provider] : [],
        ),
      ),
      (item) => item?.toLowerCase(),
    ).map((provider) => ({
      text: provider,
      value: provider,
    }))
    const programs = sortBy(
      uniq(patientsWithSiteName.flatMap((patient) => ('programName' in patient ? [patient.programName] : []))).map(
        (programName: string) => ({
          text: programName,
          value: programName,
        }),
      ),
      (item) => item.text.toLowerCase(),
    )
    const states = sortBy(
      uniq(patientsWithSiteName.flatMap((patient) => ('state' in patient && patient.state ? [patient.state] : []))).map(
        (state: string) => ({
          text: state,
          value: state,
        }),
      ),
      (item) => item.text.toLowerCase(),
    )
    return { tags, sites, careStates, conditions, diagnosisCodes, providers, programs, subscribers, openTasks, states }
  }, [patientsWithSiteName, i18n.resolvedLanguage, allSites, users])

  useDebounce(
    () => {
      const patientSelection = filter?.isFiltered()
        ? patientsWithSiteName.filter((patient) => filter.matches(patient))
        : patients
      setFilteredPatients(sortByViewState(patientSelection, filter))
    },
    100,
    [filter?.toQueryString(), patientsWithSiteName],
  )

  return [filteredPatients, filterData]
}
