import { ToDo as IToDo, PatientDetails, ReminderTypeEnum, ToDoTemplate } from '@vetahealth/tuna-can-api'
import { Timeline } from 'antd'
import dayjs from 'dayjs'
import { groupBy, isEmpty } from 'lodash-es'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { ToDo } from './ToDo'
import { ToDoTemplateWizard, ToDoWizard } from './Wizard'
import { getToDoDefinition, isToDo, isToDoTemplateWithId } from './helpers'
import { Dot, DotMultiple, LabelWrap, Time, ToDoTitle, Wrap } from './styles'

type ToDosProps = {
  toDos?: IToDo[] | ToDoTemplate[]
  referenceDate?: string
  isProgramPaused?: boolean
  isEditable?: boolean
  patient?: PatientDetails
  onUpdate?: (index: number, updatedToDo: IToDo | ToDoTemplate) => Promise<boolean>
  onDelete?: (index: number) => Promise<boolean>
}

export function ToDos({
  toDos = [],
  referenceDate,
  isProgramPaused,
  isEditable,
  patient,
  onUpdate,
  onDelete,
}: ToDosProps): JSX.Element {
  const theme = useTheme()

  const toDosByHour = useMemo(() => {
    const withId = toDos.map((toDo, index) => ({ ...toDo, id: 'id' in toDo ? toDo.id : index }))
    return groupBy(withId, (toDo: IToDo | ToDoTemplate) => {
      if (isToDo(toDo)) {
        const hour = dayjs(toDo.firstDueDate).tz(toDo.timeZone).get('hour')
        return dayjs(referenceDate).tz(toDo.timeZone).set('hour', hour).valueOf()
      }

      return dayjs().startOf('date').hour(toDo.hourOfDay).valueOf()
    })
  }, [toDos, referenceDate])

  return (
    <Wrap $hasOpacity={isProgramPaused}>
      <Timeline
        mode="left"
        items={Object.entries(toDosByHour)
          .sort(([timeRefA], [timeRefB]) => Number(timeRefA) - Number(timeRefB))
          .map(([referenceTime, groupedToDos]) => {
            const taskReminder = groupedToDos.filter((toDo) => toDo.reminderType === ReminderTypeEnum.TaskReminder)
            const measurementReminder = groupedToDos.filter(
              (toDo) => toDo.reminderType === ReminderTypeEnum.MeasurementReminder,
            )
            const measurementTitle =
              !isEmpty(measurementReminder) && getToDoDefinition(measurementReminder[0], theme).title
            const taskTitle = !isEmpty(taskReminder) && getToDoDefinition(taskReminder[0], theme).title
            const hasMultiple = !isEmpty(taskReminder) && !isEmpty(measurementReminder)
            const { color } = getToDoDefinition(groupedToDos[0], theme)

            return {
              label: (
                <LabelWrap>
                  <Time>{dayjs(Number(referenceTime)).tz(patient?.timeZone).format('LT')}</Time>
                </LabelWrap>
              ),
              dot: hasMultiple ? (
                <DotMultiple $colors={[theme.taskActive, theme.trackingActive]} />
              ) : (
                <Dot $color={color.active} />
              ),
              children: (
                <>
                  {!isEmpty(measurementReminder) && (
                    <>
                      <ToDoTitle>{measurementTitle}</ToDoTitle>
                      {measurementReminder.map((toDo, index) => {
                        if (isToDoTemplateWithId(toDo)) {
                          return (
                            <ToDoTemplateWizard
                              key={index}
                              onUpdate={onUpdate}
                              onDelete={onDelete}
                              editToDoTemplate={toDo}
                              render={(openToDoTemplateWizard) => (
                                <ToDo
                                  onClick={isEditable ? openToDoTemplateWizard : undefined}
                                  toDo={toDo}
                                  referenceDate={referenceDate}
                                  isProgramPaused={isProgramPaused}
                                />
                              )}
                            />
                          )
                        }

                        if (isToDo(toDo)) {
                          return (
                            <ToDoWizard
                              key={index}
                              editToDo={toDo}
                              referenceDate={referenceDate}
                              render={(openToDoWizard) => (
                                <ToDo
                                  onClick={openToDoWizard}
                                  toDo={toDo}
                                  referenceDate={referenceDate}
                                  isProgramPaused={isProgramPaused}
                                />
                              )}
                            />
                          )
                        }
                      })}
                    </>
                  )}

                  {!isEmpty(taskReminder) && (
                    <>
                      <ToDoTitle>{taskTitle}</ToDoTitle>
                      {taskReminder.map((toDo, index) => {
                        if (isToDoTemplateWithId(toDo)) {
                          return (
                            <ToDoTemplateWizard
                              key={index}
                              onUpdate={onUpdate}
                              onDelete={onDelete}
                              editToDoTemplate={toDo}
                              render={(openToDoTemplateWizard) => (
                                <ToDo
                                  onClick={isEditable ? openToDoTemplateWizard : undefined}
                                  toDo={toDo}
                                  referenceDate={referenceDate}
                                  isProgramPaused={isProgramPaused}
                                />
                              )}
                            />
                          )
                        }

                        if (isToDo(toDo)) {
                          return (
                            <ToDoWizard
                              key={index}
                              editToDo={toDo}
                              referenceDate={referenceDate}
                              render={(openToDoWizard) => (
                                <ToDo
                                  onClick={openToDoWizard}
                                  toDo={toDo}
                                  referenceDate={referenceDate}
                                  isProgramPaused={isProgramPaused}
                                />
                              )}
                            />
                          )
                        }
                      })}
                    </>
                  )}
                </>
              ),
            }
          })}
      />
    </Wrap>
  )
}
