import React from 'react'
import styled from 'styled-components'

import { Spin } from 'antd'

const SpinWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export function Spinner(): JSX.Element {
  return (
    <SpinWrapper>
      <Spin spinning />
    </SpinWrapper>
  )
}
