import {
  CountryCode,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js'
import React, { ChangeEvent, useEffect, useState } from 'react'

import { Input, InputProps, Select, Space } from 'antd'

import { countryNames } from '../../../lib/countryNames'

const countryOptions: Array<{ value: string; label: string; display: string }> = countryNames.map(({ iso, name }) => {
  const callingCode = getCountryCallingCode(iso as CountryCode)

  return { value: iso, label: `${name} (+${callingCode})`, display: `${iso} +${callingCode}` }
})

export { isPossiblePhoneNumber }

export function PhoneInput({ value, onChange, placeholder }: InputProps): JSX.Element {
  const initialValue = value ? parsePhoneNumberFromString(value as string) : undefined
  const [country, setCountry] = useState<CountryCode>(initialValue?.country ?? 'US')
  const [phone, setPhone] = useState<string>(initialValue?.nationalNumber ?? '')
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (value) {
      const parsedPhone = parsePhoneNumberFromString(value as string)
      parsedPhone?.country && setCountry(parsedPhone.country)
      parsedPhone?.nationalNumber && setPhone(parsedPhone.nationalNumber as string)
    }
  }, [value])

  useEffect(() => {
    if (!touched) return
    const { number = '' } = parsePhoneNumberFromString(phone, { defaultCountry: country }) || {}
    onChange?.({ target: { value: number } } as ChangeEvent<HTMLInputElement>)
  }, [country, phone, touched])

  const handleChangeNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value)
    setTouched(true)
  }

  const handleSelectCountry = (country: CountryCode) => {
    setCountry(country)
    setTouched(true)
  }

  return (
    <Space.Compact style={{ display: 'flex' }}>
      <Select
        popupMatchSelectWidth={false}
        value={country}
        onSelect={handleSelectCountry}
        options={countryOptions}
        optionLabelProp="display"
        style={{ display: 'flex', flex: 1 }}
      />
      <Input value={phone} type="tel" onChange={handleChangeNumber} placeholder={placeholder} />
    </Space.Compact>
  )
}
