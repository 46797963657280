import { isTruthy } from '@vetahealth/fishing-gear/lib/typeguards'
import { ArchiveSearchResult } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { Field, defaultSorting } from '../../helpers'

function formatAddress(_: unknown, patient: ArchiveSearchResult): string {
  const state = [patient.city, patient.state, patient.postalCode].filter(isTruthy).join(' ')
  return [patient.address, state, patient.country].filter(isTruthy).join('\n')
}

export function getFields(): Field<ArchiveSearchResult>[] {
  return [
    { key: 'firstName', title: i18next.t('table.firstName') },
    { key: 'lastName', title: i18next.t('table.lastName') },
    {
      key: 'dateOfBirth',
      title: i18next.t('table.dateOfBirth'),
      format: (dob: string) => dayjs(dob).format('L'),
    },
    { key: 'clientIdentifier', title: i18next.t('table.clientReference') },
    {
      key: 'address',
      title: i18next.t('table.address'),
      format: formatAddress,
      noSorting: true,
    },
    {
      key: 'inactivatedAt',
      title: i18next.t('table.lastVisit'),
      format: (inactivatedAt: string) => `${dayjs(inactivatedAt).format('lll')}\n${dayjs(inactivatedAt).fromNow()}`,
      compare: (a: ArchiveSearchResult, b: ArchiveSearchResult, sortOrder) =>
        defaultSorting(a, b, 'inactivatedAt', sortOrder ?? null),
    },
  ]
}
