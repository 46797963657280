import { faLock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Form, Input, Modal, Typography } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { useAuthStore } from '../../../stores/auth'
import { useUserStore } from '../../../stores/user'
import { FormValues } from '../../Auth/types'
import { ChangePassword as ChangePasswordForm, isValidPassword } from '../../Forms/ChangePassword'
import { AccountModalProps } from './types'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

type ChangePasswordState = { password?: string }
const initialState: ChangePasswordState = { password: undefined }

export function ChangePassword({ isVisible, onCancel }: AccountModalProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()
  const [changePasswordForm] = Form.useForm()
  const [enterCodeForm] = Form.useForm()
  const [handleChangePassword, getTrustToken, trustToken] = useAuthStore((state) => [
    state.handleChangePassword,
    state.getTrustToken,
    state.trustToken,
  ])
  const [email, hasTwoFactorEnabled] = useUserStore((state) => [state.email, state.hasTwoFactorEnabled])
  const [{ password }, set] = useReducer(
    (state: ChangePasswordState, update: Partial<ChangePasswordState>) => ({ ...state, ...update }),
    initialState,
  )

  const needsTrustToken = !!hasTwoFactorEnabled
  const isEnterCodeModalVisible = needsTrustToken && !trustToken

  async function handleChangePasswordSubmit(values: Pick<FormValues, 'password' | 'newPassword'>): Promise<void> {
    const response = await withLoading(handleChangePassword({ ...values, onSuccess: onCancel }))

    if (response === 404) {
      changePasswordForm.setFields([{ name: 'password', value: values.password, errors: [t('validations.password')] }])
    }
  }

  async function handleEnterCodeSubmit(values: Pick<FormValues, 'code'>): Promise<void> {
    if (values.code && email) {
      const response = await withLoading(getTrustToken({ code: values.code, email }))

      if (response === 421) {
        enterCodeForm.setFields([{ name: 'code', value: values.code, errors: [t('validations.code')] }])
      }
    }
  }

  useEffect(() => {
    if (!isVisible) set(initialState)
  }, [isVisible])

  return (
    <>
      <Modal
        title={t('widgets.account.changePassword')}
        open={isVisible && !isEnterCodeModalVisible}
        footer={null}
        onCancel={onCancel}
        destroyOnClose
        maskClosable={false}
      >
        <Form form={changePasswordForm} onFinish={handleChangePasswordSubmit} preserve={false}>
          <Form.Item name="password" rules={[{ required: true, message: t('validations.enterCurrentPassword') }]}>
            <Input.Password
              size="large"
              placeholder={t('placeholders.currentPassword')}
              type="password"
              prefix={<FontAwesomeIcon icon={faLock} size="sm" />}
            />
          </Form.Item>
          <ChangePasswordForm password={password} onPasswordChange={(password) => set({ password })} />
          <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 0 }}>
            <Button
              disabled={!password || !isValidPassword(password) || isLoading}
              loading={isLoading}
              type="primary"
              htmlType="submit"
              size="large"
              onClick={() => trackEvent(TrackingEvent.settingsChangePasswordConfirmClicked)}
            >
              {t('actions.save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t('widgets.account.changePassword')}
        open={isVisible && isEnterCodeModalVisible}
        footer={null}
        onCancel={onCancel}
        destroyOnClose
        maskClosable={false}
      >
        <>
          <Typography.Paragraph>{t('widgets.account.twoFactor.changePassword')}</Typography.Paragraph>
          <Form form={enterCodeForm} onFinish={handleEnterCodeSubmit} preserve={false}>
            <Form.Item name="code" rules={[{ required: true, message: t('validations.enterCode') }]}>
              <Input
                size="large"
                style={{ textAlign: 'center', fontWeight: '500' }}
                placeholder={t('placeholders.twoFactor')}
                maxLength={6}
              />
            </Form.Item>
            <ButtonWrapper>
              <Button type="primary" size="large" htmlType="submit" disabled={isLoading} loading={isLoading}>
                {t('actions.continue')}
              </Button>
            </ButtonWrapper>
          </Form>
        </>
      </Modal>
    </>
  )
}
