import i18next from 'i18next'
import React, { Component, ReactNode } from 'react'
import styled from 'styled-components'

import { Button, Typography } from 'antd'

import { captureException } from '../lib/error'
import { Unexpected } from './Svg/Unexpected'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const UnexpectedSvg = styled.div`
  width: 400px;
  margin-bottom: 20px;
`
const Title = styled(Typography.Text)`
  font-weight: 700;
  font-size: 20px;
`

type Props = {
  children: React.ReactNode
}

export class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  private static getDerivedStateFromError(): { hasError: true } {
    return { hasError: true }
  }

  componentDidCatch(error: Error): void {
    captureException(error, 'ErrorBoundary')
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <UnexpectedSvg>
            <Unexpected width="100%" height="100%" aria-label={i18next.t('images.error')} />
          </UnexpectedSvg>
          <Title>{i18next.t('error.generic')}</Title>
          <Title>{i18next.t('error.tryAgain')}</Title>
          <Button onClick={() => window.location.reload()} style={{ marginTop: '20px' }} type="primary">
            {i18next.t('error.retry')}
          </Button>
        </Wrapper>
      )
    }

    return this.props.children
  }
}
