import { QuestionAsset } from '@vetahealth/tuna-can-api'
import { Radio as AntRadio } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export function Radio({ asset }: { asset: QuestionAsset & { response?: string[] } }): React.ReactNode {
  return (
    <Wrapper>
      <AntRadio.Group style={{ display: 'flex', flexDirection: 'column', gap: 2 }} value={asset.response?.[0]}>
        {asset.answerOptions.map((answerOption) => (
          <AntRadio key={answerOption.id} value={answerOption.id}>
            {answerOption.label}
          </AntRadio>
        ))}
      </AntRadio.Group>
    </Wrapper>
  )
}
