import React, { useEffect } from 'react'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { useNavigate } from 'react-router'
import { routes } from '.'
import { Roles as RoleManagementComponent } from '../../components/Widgets/RoleManagement'
import { useUserStore } from '../../stores/user'
import { GenericWrapper } from './styles'

export function RoleManagement(): JSX.Element {
  const navigate = useNavigate()
  const [hasPermission] = useUserStore((state) => [state.hasPermission])

  useEffect(() => {
    if (!hasPermission(PermissionName.manageRole)) {
      navigate(routes.patientList(), { replace: true })
      return
    }
  }, [])

  return (
    <GenericWrapper>
      <RoleManagementComponent />
    </GenericWrapper>
  )
}
