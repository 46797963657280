import React from 'react'

import { CsvImport as CsvImportComponent } from '../../components/Widgets/CsvImport'
import { GenericWrapper } from './styles'

export function CsvImport(): JSX.Element {
  return (
    <GenericWrapper>
      <CsvImportComponent />
    </GenericWrapper>
  )
}
