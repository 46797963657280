import { Colors } from '@vetahealth/fishing-gear/colors'
import { getDisplayValue } from '@vetahealth/fishing-gear/conversions'
import { Typography } from 'antd'
import { isEmpty, orderBy } from 'lodash-es'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { VictoryTooltip } from 'victory'
import { isAlertUnreviewed } from '../../../../../stores/results'
import { Alert } from '../../../../Alerts'
import { CustomToolTipProps, ToolTipProps } from './types'

const Wrapper = styled.div<{ $color?: Colors }>`
  position: relative;
  border: 1px solid ${({ $color }) => $color};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.quinaryBackground};
  padding: 8px;
  box-shadow:
    0 1px 2px rgb(0 0 0 / 5%),
    0 1px 6px -1px rgb(0 0 0 / 3%),
    0 2px 4px rgb(0 0 0 / 3%);
`

const LabelWrap = styled.div`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Label = styled.span`
  font-weight: 500;
`

const SubLabel = styled.span`
  opacity: 0.6;
  font-size: 13px;
`

const Total = styled.div`
  border-top: 1px solid ${({ theme }) => theme.secondaryBackground};
  margin-top: 6px;
  padding-top: 2px;
`

const DateLabel = styled.div`
  font-size: 13px;
  opacity: 0.6;
`

const AlertContainer = styled.div`
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`

const AlertMessage = styled(Typography.Text)`
  font-size: 13px;
  color: ${({ theme }) => theme.alertActive};
  background-color: ${({ theme }) => theme.alertBackground};
  padding: 3px 6px;
  border-radius: 6px;
`

export function ToolTip(props: ToolTipProps): JSX.Element {
  const { activePoints, alerts, getToolTipData, aggregationInterval, isAveragedPerDay } = props
  const unreviewedAlerts =
    activePoints?.[0]?.ids
      ?.flatMap(
        (id: number) =>
          alerts?.tracking?.[id]?.filter(isAlertUnreviewed) ??
          alerts?.task?.[id]?.filter(isAlertUnreviewed) ??
          alerts?.riskScore?.[id]?.filter(isAlertUnreviewed),
      )
      .filter(Boolean) ?? []

  const toolTipData = activePoints?.map(({ x, y, y0 }) =>
    getToolTipData({ x, y, y0, aggregationInterval, isAveragedPerDay }),
  )

  return (
    <VictoryTooltip
      {...props}
      flyoutComponent={
        <CustomToolTip
          {...props}
          toolTipData={toolTipData}
          alerts={unreviewedAlerts}
          isAveragedPerDay={!!isAveragedPerDay}
        />
      }
      renderInPortal
      constrainToVisibleArea
    />
  )
}

function CustomToolTip({
  x,
  y,
  toolTipData,
  alerts,
  width,
  height,
  chartType,
  isAveragedPerDay,
}: CustomToolTipProps): JSX.Element | null {
  const [size, setSize] = useState([0, 0])
  const theme = useTheme()
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const color = !isEmpty(alerts) ? theme.concerningActive : toolTipData?.[0]?.color
  const hasTrackingAlerts = alerts.some((alert) => alert.alertConfig)
  const hasRiskScoreOrTaskAlerts = alerts.some((alert) => !alert.alertConfig)

  function getToolTipX(x: number, toolTipWidth: number, containerWidth: number): number {
    if (x + toolTipWidth / 2 > containerWidth) return containerWidth - toolTipWidth
    if (x - toolTipWidth / 2 < 0) return 0
    return x - toolTipWidth / 2
  }

  useEffect(() => {
    if (ref.current) setSize([ref.current.offsetHeight, ref.current.offsetWidth])
  }, [])

  if (!x || !y || !width || !height || !toolTipData) return null

  const toolTipWidth = alerts.length > 2 && hasTrackingAlerts ? 380 : 280
  const toolTipHeight = size[0] + 10 // 10px for shadow
  const toolTipX = getToolTipX(x, toolTipWidth, width)
  const toolTipY = y - toolTipHeight
  const orderedToolTipProps = orderBy(Object.values(toolTipData), ['timestamp'], ['desc'])
  const totalSleep = getDisplayValue(
    toolTipData.reduce((acc, current) => acc + current.value, 0),
    'min',
    'sleep',
    'h_min',
  )

  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject x={toolTipX} y={toolTipY} width={toolTipWidth} height={toolTipHeight}>
        <Wrapper ref={ref} $color={color}>
          {!isEmpty(orderedToolTipProps) && (
            <LabelWrap>
              <DateLabel>{orderedToolTipProps[0].dateLabel}</DateLabel>
              {chartType === 'line' ? (
                <>
                  {isAveragedPerDay && <Label>{`${'Ø'} `}</Label>}
                  <Label>{orderedToolTipProps.map((prop) => prop.label).join(' / ')}</Label>
                </>
              ) : (
                orderedToolTipProps.map((prop, index) => (
                  <div key={index}>
                    <Label>{prop.label}</Label>
                    {prop.subLabel && <SubLabel>{` ${prop.subLabel}`}</SubLabel>}
                  </div>
                ))
              )}
              {chartType === 'sleep' && orderedToolTipProps.length > 1 && (
                <Total>
                  <Label>{`${totalSleep}`}</Label>
                </Total>
              )}
            </LabelWrap>
          )}
          {hasTrackingAlerts &&
            !isAveragedPerDay &&
            alerts.map(
              (alert) =>
                alert.alertConfig && (
                  <AlertContainer key={alert.id}>
                    <Alert alertConfig={alert.alertConfig} />
                  </AlertContainer>
                ),
            )}
          {(hasRiskScoreOrTaskAlerts || (hasTrackingAlerts && isAveragedPerDay)) && (
            <AlertMessage>{t('alerts.hasAlerts')}</AlertMessage>
          )}
        </Wrapper>
      </foreignObject>
    </g>
  )
}
