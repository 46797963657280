import { PreviewCareTask } from '@vetahealth/tuna-can-api'
import { Drawer, Select } from 'antd'
import { debounce } from 'lodash-es'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { API } from '../../lib/api'
import { useLoading } from '../../lib/hooks/useLoading'
import { SupportedLocale } from '../../lib/i18n'
import { DatoAssets } from '../Assets'
import { labelFromLocale } from '../Forms/helpers'
import { HeadingFive, NoData } from '../Widgets/styles'
import { ModalProps } from './types'

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding-top: 0;
  }
`

const InputWrap = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.quinaryBackground};
  padding: 18px 0;
  display: flex;
`

export function Preview({ isVisible, onCancel }: ModalProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()
  const [previewRefs, setPreviewRefs] = useState<string[]>()
  const [preview, setPreview] = useState<PreviewCareTask | undefined>()
  const [locale, setLocale] = useState<SupportedLocale>(SupportedLocale.EN_US)

  function fetchPreview(ref: string, locale: SupportedLocale): void {
    API.getPreview({ ref, locale }).then(setPreview)
  }

  const handleQuery = useCallback(
    debounce((query: string) => {
      withLoading(API.searchPreviewRefs(query.trim()).then(setPreviewRefs))
    }, 300),
    [setPreviewRefs],
  )

  function handleLanguageChange(newLocale: SupportedLocale): void {
    setLocale(newLocale)
    if (preview) fetchPreview(preview.ref, newLocale)
  }

  return (
    <StyledDrawer
      width={'calc(33% + 6px)'}
      title={t('menus.main.preview')}
      placement={'right'}
      onClose={onCancel}
      open={isVisible}
      keyboard
      extra={
        <Select
          onChange={handleLanguageChange}
          defaultValue={locale}
          options={Object.values(SupportedLocale).map((locale) => ({
            value: locale,
            label: labelFromLocale[locale],
            disabled: locale === SupportedLocale.DE_DE,
          }))}
        />
      }
    >
      <InputWrap>
        <Select
          style={{ width: '100%' }}
          showSearch
          loading={isLoading}
          value={preview?.ref}
          placeholder={t('placeholders.searchRefs')}
          onSearch={handleQuery}
          onSelect={(ref) => fetchPreview(ref, locale)}
          options={(previewRefs || []).map((ref) => ({
            value: ref,
            label: ref,
          }))}
        />
      </InputWrap>
      {preview?.title ? (
        <>
          <HeadingFive>{preview.title}</HeadingFive>
          <DatoAssets assets={preview.content} />
        </>
      ) : (
        preview && <NoData />
      )}
    </StyledDrawer>
  )
}
