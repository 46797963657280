import { Colors } from '@vetahealth/fishing-gear/colors'
import { Typography } from 'antd'
import styled from 'styled-components'

// Dashboard

export const Main = styled.div`
  padding: 12px 16px 16px 16px;
`
export const Bar = styled.div`
  position: sticky;
  top: 0;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.primaryBackground};
  z-index: 2;
`
export const Actions = styled.div`
  flex: 1;
  display: flex;
  grid-gap: 8px;
  justify-content: flex-end;
  align-items: center;
  padding-left: 40px;
`

// Details

export const TableWrap = styled.div`
  margin-top: 20px;
`

// Card

export const Wrap = styled.div<{ $isCompact?: boolean; $isActive?: boolean; $isEditable?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: ${({ $isCompact }) => ($isCompact ? 'span 1' : 'span 2')} ;
  grid-gap: 4px;
  padding: 9px 12px;
  border-radius: 6px;
  border: 2px solid transparent;
  border-color: ${({ theme, $isActive }) => ($isActive ? theme.primary : 'transparent')};
  background-color: ${({ theme }) => theme.quinaryBackground};
  ${({ $isEditable }) =>
    !$isEditable &&
    `
     cursor: pointer;
     canvas {
    cursor: pointer;
  }
    `};
`
export const Headline = styled(Typography.Text)`
  font-size: 18px;
  line-height: 1.4;
  font-weight: 600;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`
export const Draggable = styled.div`
  display: flex;
  padding: 0 4px;
  align-items: center;
  cursor: grab;
`
export const Title = styled(Typography.Text)`
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
`
export const Description = styled(Typography.Text)`
  flex: 1;
  font-size: 12px;
  line-height: 1.2;
  opacity: 0.6;
`
export const Value = styled(Typography.Text)<{ $color: string }>`
  font-size: 32px;
  font-weight: 500;
  color: ${({ theme, $color }) => ($color ? $color : theme.primary)};
`
export const Chart = styled.div<{ $color: string; $height?: number; $isDark?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : '300px')};
  background: linear-gradient(0.15turn, ${({ $isDark, $color }) =>
    $isDark ? Colors.gray1000 : `color-mix(in srgb, ${$color} 4%, white)`},
    ${({ $isDark, $color }) => ($isDark ? Colors.anthrazit500 : `color-mix(in srgb, ${$color} 11%, white)`)});
  display: flex;
  border-radius: 6px;
`
export const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 12px 16px 16px 16px;
  padding: 16px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.quinaryBackground};
`
