import { AssignProgramBody, CreatePatientBody, PatientDetails, UpdateCareStateRequest } from '@vetahealth/tuna-can-api'
import { message } from 'antd'
import i18next from 'i18next'
import { produce } from 'immer'
import { isEmpty, pick } from 'lodash-es'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { router } from '../../Router/router'
import { routes } from '../../Router/routes'
import { API, Conflict, isAxiosError } from '../../lib/api'
import { omitEqualValues } from '../../lib/normalizers'
import { takeLeading } from '../../lib/takeLeading'
import { usePatientsStore } from '../patients'
import { useResultsStore } from '../results'
import { normalizePatientValues } from './helpers'
import { PatientState } from './types'

export const initialState: Pick<PatientState, 'patient' | 'consent' | 'alertConfigs' | 'careStateHistory'> = {
  patient: undefined,
  consent: undefined,
  alertConfigs: undefined,
  careStateHistory: undefined,
}

const isConflict = (response: PatientDetails | Conflict): response is Conflict => 'field' in response

export const usePatientStore = createWithEqualityFn<PatientState>(
  (set, get) => ({
    ...initialState,

    getPatient: async (id) => {
      const response = await API.getPatient(id)

      if (isAxiosError(response)) {
        if (response.response?.status === 404) {
          message.error({ content: i18next.t('error.patientNotFound'), key: 'patientNotFound' })
        }
        if (response.response?.status === 403) {
          message.error({ content: i18next.t('error.patientForbidden'), key: 'patientForbidden' })
        }

        router?.navigate(routes.patientList(), { replace: true })

        return
      }

      if (response) set({ patient: response })
    },

    updatePatient: async (patient, updateValues) => {
      const messageKey = 'patientUpdate'
      message.loading({ content: i18next.t('message.updating'), key: messageKey, duration: 0 })

      const normalizedValues = normalizePatientValues(updateValues)
      const values = omitEqualValues(patient, normalizedValues)

      if (isEmpty(values)) return

      const response = await API.updatePatient({ id: patient.id, values })

      if (response) {
        if (isConflict(response)) {
          message.error({ content: i18next.t('message.updateFailed'), key: messageKey })
          return response
        }

        // Make sure the updated patient matches the current one before updating the store
        // Update might have been triggered with delay/debounce and patient might have changed in the meantime
        const { patient: currentPatient } = get()
        if (patient.id === currentPatient?.id) set({ patient: response })

        message.success({ content: i18next.t('message.patientUpdate'), key: messageKey })

        usePatientsStore.getState().expirePatients()
        if ('tags' in values) usePatientsStore.getState().expireTags()
      } else {
        message.destroy(messageKey)
      }
    },

    createPatient: async (createValues) => {
      const normalizedValues = normalizePatientValues(createValues) as CreatePatientBody
      const patient = await API.createPatient(normalizedValues)

      usePatientsStore.getState().expirePatients()

      if (patient) return patient.id
    },

    subscribeToPatient: async (userIds) => {
      const { patient, getPatient } = get()

      if (!patient) return

      const subscriberIds: string[] = []
      const unsubscriberIds: string[] = []

      userIds.forEach((userId) => {
        patient.subscribers.some((id) => id === userId) ? unsubscriberIds.push(userId) : subscriberIds.push(userId)
      })

      await API.subscribePatient(patient.id, subscriberIds, unsubscriberIds)

      await getPatient(patient.id)

      // Update subscribers for all patients to reflect in unread messages
      usePatientsStore.getState().expirePatients()
      usePatientsStore.getState().getPatients()
    },

    getConsent: takeLeading(async () => {
      const { patient } = get()

      if (!patient) return

      const consent = await API.getPatientConsent(patient.id)

      set({ consent })
    }),

    setConsent: async (consent) => {
      const { patient } = get()

      if (!patient) return false

      const success = await API.setPatientConsent({ id: patient.id, consent })

      if (success) set({ consent })

      return !!success
    },

    setProgram: async (data: AssignProgramBody) => {
      const { patient, getAlertConfigs, getPatient } = get()

      if (!patient) return false

      const program = await API.assignProgram(patient.id, data)

      if (program) {
        await getPatient(patient.id)
        getAlertConfigs()
        useResultsStore.getState().getLastResults(patient?.id)

        return true
      }
      return false
    },

    setCareState: async (params: UpdateCareStateRequest) => {
      const { patient } = get()

      if (!patient) return false

      const careState = await API.updateCareState(patient.id, params)

      if (careState) {
        set({ patient: { ...patient, careState } })
        usePatientsStore.getState().expirePatients()

        return true
      }
      return false
    },

    getCareStateHistory: async () => {
      const { patient } = get()

      if (!patient) return

      const careStateHistory = await API.getCareStateHistory(patient.id)

      if (careStateHistory) set({ careStateHistory })
    },

    completeCareTask: async (id: number) => {
      const { patient } = get()

      if (!patient) return false

      const success = await API.completeCareTask(patient.id, id)

      if (success) {
        usePatientsStore.getState().expirePatients()
        return true
      }
      return false
    },

    getAlertConfigs: async () => {
      const { patient } = get()

      if (!patient) return

      const alertConfigs = await API.getPatientAlertConfigs(patient.id)

      if (alertConfigs) set({ alertConfigs })
    },

    createAlertConfig: async (alertConfig) => {
      const { patient, alertConfigs = [], getAlertConfigs } = get()

      if (!patient) return false

      const success = await API.updatePatientAlertConfigs(patient.id, [...alertConfigs, alertConfig])

      if (success) getAlertConfigs()

      return !!success
    },

    updateAlertConfig: async (updateIndex, updatedAlertConfig) => {
      const { patient, alertConfigs = [], getAlertConfigs } = get()

      if (!patient) return false

      const success = await API.updatePatientAlertConfigs(
        patient.id,
        produce(alertConfigs, (draft) => {
          draft[updateIndex] = updatedAlertConfig
        }),
      )

      if (success) void getAlertConfigs()

      return !!success
    },

    deleteAlertConfig: async (deleteIndex) => {
      const { patient, alertConfigs = [], getAlertConfigs } = get()

      if (!patient) return false
      const success = await API.updatePatientAlertConfigs(
        patient.id,
        alertConfigs.filter((_, index) => index !== deleteIndex),
      )

      if (success) void getAlertConfigs()

      return !!success
    },

    updateToDo: async (id, toDo) => {
      const { patient, getPatient } = get()

      if (!patient) return false

      const updatedToDo = pick(toDo, ['firstDueDate', 'isMuted'])

      const success = await API.updateToDo({
        patientId: patient.id,
        taskId: id,
        toDo: updatedToDo,
      })

      if (success) getPatient(patient.id)

      return !!success
    },

    reset: () => set(initialState),
  }),
  shallow,
)
