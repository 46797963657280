import { useAuthStore } from '../stores/auth'
import { API } from './api'

declare global {
  interface Window {
    dev: {
      resetDemoData: () => Promise<void>
      refreshMetaData: () => Promise<void>
    }
  }
}

const checkAuthorization = (): void => {
  const { isAuthorized } = useAuthStore.getState()
  if (!isAuthorized) throw new Error('You must be signed-in to use this feature.')
}

const delayedReload = (): void => {
  console.warn('App will reload in 3 seconds to apply changes.')
  setTimeout(() => {
    window.location.reload()
  }, 3000)
}

const resetDemoData = async (): Promise<void> => {
  checkAuthorization()
  const success = await API.devResetDemoData()
  if (success) {
    console.warn('Successfully reset data of demo patients.')
    delayedReload()
  } else {
    console.error('Something went wrong!')
  }
}

const refreshMetaData = async (queryString = ''): Promise<void> => {
  checkAuthorization()
  const success = await API.devRefreshMetaData(new URLSearchParams(queryString))
  if (success) {
    console.warn('Successfully refreshed concerning events and billing meta for all patients.')
    delayedReload()
  } else {
    console.error('Something went wrong!')
  }
}

window.dev = {
  resetDemoData,
  refreshMetaData,
}
