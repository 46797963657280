import { SupportedLocale } from '../i18n'
import { LocalStorageKey, Tokens } from './types'

export const storage = {
  getTokens: (): Tokens | undefined => {
    const tokens = localStorage.getItem(LocalStorageKey.tokens)
    if (tokens) return JSON.parse(tokens)
  },
  updateTokens: (tokens: Tokens): void => {
    localStorage.setItem(LocalStorageKey.tokens, JSON.stringify(tokens))
  },
  deleteTokens: (): void => {
    localStorage.removeItem(LocalStorageKey.tokens)
  },
  getLocale: (): SupportedLocale | undefined => {
    const locale = localStorage.getItem(LocalStorageKey.locale)
    if (locale) return locale as SupportedLocale
  },
  updateLocale: (locale: SupportedLocale): void => {
    localStorage.setItem(LocalStorageKey.locale, locale)
  },
}
