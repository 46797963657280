import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DatePicker, Form, Select } from 'antd'

import { TwoColumns } from './styles'
import { DateRange } from './types'

export function SetDateRange(): JSX.Element {
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.ONE_WEEK)

  return (
    <>
      <Form.Item name="dateRange" label={t('form.dateRange')} initialValue={DateRange.ONE_WEEK}>
        <Select
          defaultValue={DateRange.ONE_WEEK}
          style={{ width: '100%' }}
          options={[
            { value: DateRange.ONE_WEEK, label: t('form.lastSevenDays') },
            { value: DateRange.ONE_MONTH, label: t('form.lastThirtyDays') },
            { value: DateRange.CUSTOM, label: t('form.custom') },
          ]}
          onChange={setDateRange}
        />
      </Form.Item>
      {dateRange === DateRange.CUSTOM && (
        <TwoColumns>
          <Form.Item
            name="from"
            label={t('form.start')}
            initialValue={dayjs()}
            validateFirst
            rules={[
              { required: true, message: t('validations.enterDate') },
              {
                type: 'date',
                validator: (_, value: Dayjs) => (dayjs(value) < dayjs() ? Promise.resolve() : Promise.reject()),
                message: t('validations.noFutureDate'),
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              format="lll"
              showTime={{ format: t('dateFormats.time') }}
              showNow
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            name="until"
            label={t('form.end')}
            initialValue={dayjs()}
            validateFirst
            rules={[
              { required: true, message: t('validations.enterDate') },
              {
                type: 'date',
                validator: (_, value: Dayjs) => (dayjs(value) < dayjs() ? Promise.resolve() : Promise.reject()),
                message: t('validations.noFutureDate'),
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              format="lll"
              showTime={{ format: t('dateFormats.time') }}
              showNow
              style={{ width: '100%' }}
            />
          </Form.Item>
        </TwoColumns>
      )}
    </>
  )
}
