import { Colors } from '@vetahealth/fishing-gear/colors'
import { AnalyticsTypeEnum } from '@vetahealth/tuna-can-api'

type Config = {
  [key in AnalyticsTypeEnum]: {
    aggregationType: 'sum' | 'average'
    color: string
    aggregationUnit?: string
    rawUnit?: string
    chartType?: 'bar' | 'line'
  }
}

export const config: Config = {
  [AnalyticsTypeEnum.DevicesOrdered]: {
    aggregationType: 'sum',
    color: Colors.mint500,
    rawUnit: '$',
    chartType: 'bar',
  },
  [AnalyticsTypeEnum.NumberOfPatientsEnrolled]: {
    aggregationType: 'sum',
    color: Colors.mauve500,
    chartType: 'bar',
  },
  [AnalyticsTypeEnum.TotalDeviceCost]: {
    aggregationType: 'sum',
    color: Colors.mint500,
    aggregationUnit: '$',
    rawUnit: '$',
    chartType: 'bar',
  },
  [AnalyticsTypeEnum.NpsScore]: {
    aggregationType: 'average',
    color: Colors.azure600,
    chartType: 'bar',
  },
  [AnalyticsTypeEnum.DeliveryTime]: {
    aggregationType: 'average',
    color: Colors.mint500,
    aggregationUnit: 'days',
    rawUnit: 'days',
    chartType: 'bar',
  },
  [AnalyticsTypeEnum.FirstMeasurementTime]: {
    aggregationType: 'average',
    color: Colors.azure600,
    aggregationUnit: 'days',
    rawUnit: 'days',
    chartType: 'bar',
  },
  [AnalyticsTypeEnum.ParkviewSurveyOne]: {
    aggregationType: 'sum',
    color: Colors.plum500,
    aggregationUnit: 'answers',
    rawUnit: 'answers',
    chartType: 'bar',
  },
  [AnalyticsTypeEnum.ParkviewSurveyTwo]: {
    aggregationType: 'sum',
    color: Colors.plum500,
    aggregationUnit: 'answers',
    rawUnit: 'answers',
    chartType: 'bar',
  },
}
