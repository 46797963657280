import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CourierEnum,
  DeviceOrderStatusEnum,
  DeviceTypeEnum,
  ManualOrder,
  ManualOrderUpdate,
  OrderKitTypeEnum,
} from '@vetahealth/tuna-can-api'
import { Button, Form, Input, Modal, Select, Table, Typography, message } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from '../../../Router/routes'
import { PatientDetailsTab } from '../../../Router/routes/PatientDetails'
import { API, ApiError, AssignIdConflictError } from '../../../lib/api'
import { omitEqualValues, trimDeep } from '../../../lib/normalizers'
import { Field, getTableData } from '../../helpers'
import { getDeviceName, getStatusLabel } from '../PatientDetails/Devices/helpers'
import { Card } from '../styles'

const TableWrapper = styled.div`
  .ant-table-row {
    cursor: pointer;
  }
`

function isApiError(
  response: ManualOrder | AssignIdConflictError | ApiError | undefined,
): response is AssignIdConflictError | ApiError {
  return !!response && 'errorName' in response
}

export function ManualOrdersList(): JSX.Element {
  const [orders, setOrders] = useState<ManualOrder[]>([])
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [activeOrder, setActiveOrder] = useState<ManualOrder | undefined>(undefined)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSaveOrder = (formValues: ManualOrderUpdate): void => {
    if (!activeOrder?.id) return
    const trimmedValues = trimDeep(formValues)
    const updatedOrder = omitEqualValues(activeOrder, trimmedValues)
    if (isEmpty(updatedOrder)) {
      handleCloseModal()
      return
    }
    API.updateManualOrder(activeOrder.id, updatedOrder).then((response) => {
      if (!response) return
      if (isApiError(response) && response.status === 409) {
        message.error(
          <>
            {t('error.deviceIdAlreadyAssigned')}
            <Button
              style={{ paddingLeft: '6px' }}
              type="link"
              onClick={() => {
                message.destroy()
                navigate(routes.patientDetails(response?.error.userId, PatientDetailsTab.devices))
              }}
            >
              {t('error.unassignHere')}
            </Button>
          </>,
          6,
        )
      } else if (isApiError(response) && response.status === 400) {
        message.error({ content: t('error.noDashes'), duration: 6, key: 'errorNoDashes' })
      } else if (isApiError(response) && response.status === 404) {
        message.error({ content: t('error.noDevice'), duration: 6, key: 'errorNoDevice' })
      }
      if (!isApiError(response)) {
        setOrders((prevOrders) => {
          const cardIndex = prevOrders.findIndex((order) => order.id === response.id)
          if (cardIndex < 0) return prevOrders

          const newOrders = [...prevOrders]
          newOrders[cardIndex] = response
          return newOrders
        })
        message.success(t('message.orderUpdated'))
      }
    })
    handleCloseModal()
  }

  const handleTableRowClick = (record: ManualOrder): void => {
    setActiveOrder(record)
    setIsModalVisible(true)
  }

  const handleCloseModal = (): void => {
    setActiveOrder(undefined)
    setIsModalVisible(false)
  }

  useEffect(() => {
    API.getManualOrders().then((manualOrders) => {
      setOrders(manualOrders ?? [])
    })
  }, [])

  const fields: Field<ManualOrder>[] = [
    { key: 'timestamp', title: t('table.creationDate'), format: (timestamp: string) => dayjs(timestamp).format('lll') },
    {
      key: 'orderType',
      title: t('table.deviceType'),
      format: (orderType: DeviceTypeEnum | OrderKitTypeEnum) => getDeviceName(orderType),
      noSorting: true,
    },
    { key: 'firstName', title: t('table.firstName') },
    { key: 'lastName', title: t('table.lastName') },
    { key: 'address', title: t('table.address'), noSorting: true },
    { key: 'city', title: t('table.city'), noSorting: true },
    { key: 'state', title: t('table.state'), noSorting: true },
    { key: 'postalCode', title: t('table.postalCode'), noSorting: true },
    {
      key: 'status',
      title: t('table.orderStatus'),
      format: (status: DeviceOrderStatusEnum) => getStatusLabel(status),
      noSorting: true,
    },
    { key: 'deviceId', title: t('table.deviceId'), noSorting: true },
    { key: 'courier', title: t('table.courier'), noSorting: true },
    {
      key: 'trackingId',
      title: t('table.trackingId'),
      noSorting: true,
      format: (trackingId: string, { trackingUrl }: { trackingUrl?: string }) => {
        if (trackingUrl) {
          return (
            <Typography.Link
              target="_blank"
              rel="noopener noreferrer"
              href={trackingUrl}
              onClick={(e) => e.stopPropagation()}
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '6px' }} />
              {trackingId}
            </Typography.Link>
          )
        }
        return trackingId
      },
    },
    { key: 'hubId', title: t('table.hubId'), noSorting: true },
    {
      key: 'patientId',
      title: t('table.actions'),
      noSorting: true,
      format: (id: string) => (
        <Button
          shape="circle"
          type="link"
          href={routes.patientDetails(id, PatientDetailsTab.devices)}
          icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
  ]

  const { dataSource, columns } = getTableData({
    fields,
    data: orders,
  })

  return (
    <Card title={t('widgets.manualOrders.title')}>
      <TableWrapper>
        <Table
          onRow={(record: ManualOrder) => ({ onClick: () => handleTableRowClick(record) })}
          style={{ width: '100%' }}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: true }}
        />
      </TableWrapper>
      <Modal
        title={t('widgets.devices.processOrder')}
        open={isModalVisible}
        onCancel={handleCloseModal}
        destroyOnClose
        centered
        footer={
          <Button form="manualOrders" htmlType="submit" type="primary">
            {t('actions.saveChanges')}
          </Button>
        }
      >
        <Form name="manualOrders" onFinish={handleSaveOrder} layout="vertical" initialValues={activeOrder}>
          <Form.Item name="deviceId" rules={[{ type: 'string', min: 1 }]} label={t('widgets.devices.deviceId')}>
            <Input placeholder={t('placeholders.deviceId')} />
          </Form.Item>
          <Form.Item name="hubId" rules={[{ type: 'string', min: 1 }]} label={t('widgets.devices.hubId')}>
            <Input placeholder={t('placeholders.hubId')} />
          </Form.Item>
          <Form.Item name="trackingId" rules={[{ type: 'string', min: 1 }]} label={t('widgets.devices.trackingId')}>
            <Input placeholder={t('placeholders.trackingId')} />
          </Form.Item>
          <Form.Item name="courier" rules={[{ type: 'string', min: 1 }]} label={t('widgets.devices.courier')}>
            <Select
              style={{ width: '100%' }}
              placeholder={t('placeholders.courier')}
              options={Object.values(CourierEnum).map((courier) => ({
                label: courier,
                value: `${courier}`,
              }))}
            />
          </Form.Item>
          <Form.Item name="status" rules={[{ type: 'string', min: 1 }]} label={t('widgets.devices.status')}>
            <Select
              style={{ width: '100%' }}
              placeholder={t('placeholders.status')}
              options={Object.values(DeviceOrderStatusEnum).map((status) => ({
                label: getStatusLabel(status),
                value: `${status}`,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}
