import React from 'react'

import { PatientDetails } from '@vetahealth/tuna-can-api'

import { Typography } from 'antd'

import { usePatientsStore } from '../../../stores/patients'
import { Viewers } from '../../Viewers'

export function Name({ patient }: { patient: PatientDetails }): JSX.Element {
  const [currentlyViewedPatients] = usePatientsStore((state) => [state.currentlyViewedPatients])
  const nickname = patient?.nickname ? ` (${patient.nickname})` : ''

  return (
    <>
      <Typography.Title
        style={{ margin: 0, fontSize: 28 }}
      >{`${patient.firstName} ${patient.lastName}${nickname}`}</Typography.Title>
      <Viewers patientId={patient.id} viewers={currentlyViewedPatients} type="patient" />
    </>
  )
}
