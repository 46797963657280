import styled from 'styled-components'

export const OrderedList = styled.ol.attrs((props) => ({
  start: props.start || 1,
}))`
  li {
    font-size: 14px;
    line-height: 1.57;
    color: ${({ theme }) => theme.text};
    position: relative;
    margin: 4px 0;
    div {
      margin: 0;
    }
  }
  li::marker {
    font-weight: 600;
  }
`
export const UnOrderedList = styled.ul`
  li {
    font-size: 14px;
    line-height: 1.57;
    color: ${({ theme }) => theme.text};
    position: relative;
    margin: 4px 0;
  }
  li::marker {
    font-size: 1.2em;
  }
`

export const Table = styled.table`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
  border-spacing: 10px;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  border-color: inherit;
  background-color: ${({ theme }) => theme.secondaryBackground};
  thead {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.textInverted};
    tr th {
      font-weight: 500;
      text-align: left;
      padding: 10px 10px 8px;
    }
  }
  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.primary};
    }
    tr td {
      color: ${({ theme }) => theme.text};
      text-align: left;
      padding: 10px 10px 8px;
    }
  }
`
