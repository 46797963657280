import { faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input } from 'antd'
import { debounce } from 'lodash-es'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthStep, useAuthStore } from '../../stores/auth'
import { Action, ButtonWrapper, Description, FormWrapper } from './styles'
import { FormValues } from './types'

export function ForgotPassword(): JSX.Element {
  const { t } = useTranslation()
  const [handleForgotPassword, setStep] = useAuthStore((state) => [state.handleForgotPassword, state.setStep])

  const handleSubmit = useCallback(
    debounce(async (values: Pick<FormValues, 'email'>): Promise<void> => {
      await handleForgotPassword(values.email)
    }, 300),
    [],
  )

  return (
    <>
      <Action>{t('auth.forgotPassword')}</Action>
      <Description>{t('auth.instructionsForgotPassword')}</Description>
      <FormWrapper>
        <Form onFinish={handleSubmit} validateTrigger="onSubmit">
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('validations.enterEmail') },
              { type: 'email', message: t('validations.email') },
            ]}
          >
            <Input
              size="large"
              placeholder={t('placeholders.email')}
              prefix={<FontAwesomeIcon icon={faUser} size="sm" />}
            />
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit" size="large">
              {t('actions.send')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
      <ButtonWrapper>
        <Button onClick={() => setStep(AuthStep.SIGNIN)} type="link">
          {t('actions.back')}
        </Button>
      </ButtonWrapper>
    </>
  )
}
