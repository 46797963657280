import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'antd'

import { TrackingEvent, trackEvent } from '../lib/tracking'
import { routes } from './routes'
import { PatientDetailsTab } from './routes/PatientDetails'

interface TopBarPatientNavigationProps {
  ids: string[]
  currentIndex: number
  previous?: boolean
  tab: PatientDetailsTab
  locationSearch: string
}

const Placeholder = styled.span`
  width: 30px;
`

export default function TopBarPatientNavigation({
  ids,
  currentIndex,
  previous = false,
  tab,
  locationSearch,
}: TopBarPatientNavigationProps): JSX.Element {
  if (previous && currentIndex < 1) return <Placeholder />
  if (!previous && currentIndex >= ids.length - 1) return <Placeholder />
  if (previous) {
    return (
      <Link
        to={routes.patientDetails(ids[currentIndex - 1], tab) + locationSearch}
        onClick={() => trackEvent(TrackingEvent.navPatientClicked, { direction: 'backward' })}
      >
        <Button
          size="small"
          shape="circle"
          icon={<FontAwesomeIcon icon={faChevronLeft} size="sm" />}
          style={{ marginRight: '8px' }}
        />
      </Link>
    )
  }

  return (
    <Link
      to={routes.patientDetails(ids[currentIndex + 1], tab) + locationSearch}
      onClick={() => trackEvent(TrackingEvent.navPatientClicked, { direction: 'forward' })}
    >
      <Button
        size="small"
        shape="circle"
        icon={<FontAwesomeIcon icon={faChevronRight} size="sm" />}
        style={{ marginLeft: '8px' }}
      />
    </Link>
  )
}
