import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { Site } from '@vetahealth/tuna-can-api'
import { Alert, Button, Form, Modal, Popconfirm, Select, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import copy from 'copy-to-clipboard'
import dayjs from 'dayjs'
import i18next from 'i18next'
import fileDownload from 'js-file-download'
import { last } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { routes } from '../../Router/routes'
import { API, ApiError } from '../../lib/api'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { useSitesStore } from '../../stores/sites'
import { FormKeys } from '../Forms'
import { getSiteSelectOption } from '../helpers'
import { ModalProps } from './types'

const getOutputFormats = (): Record<string, string> => ({
  'report-xls': i18next.t('menus.main.billingReportModal.outputFormat.default'),
  'report-xls-condensed': i18next.t('menus.main.billingReportModal.outputFormat.condensed'),
  'report-pdf': i18next.t('menus.main.billingReportModal.outputFormat.pdf'),
})

export function getBillingMonths(
  reportDates: string[],
  initialOnboarding?: string,
): Array<{ value: string; label: string }> {
  const months = []
  if (!initialOnboarding) return []
  let date = dayjs(initialOnboarding).startOf('month')

  while (date.year() < dayjs().year() || (date.year() === dayjs().year() && date.month() < dayjs().month())) {
    const value = date.format('YYYY-MM')
    const context = reportDates.includes(value) ? 'published' : undefined
    months.push({
      value,
      label: i18next.t('menus.main.billingReportModal.dateLabel', { context, date: date.format('MMMM YYYY') }),
    })
    date = date.add(1, 'month')
  }

  return months.reverse()
}

function isApiError(response: ArrayBuffer | ApiError | undefined): response is ApiError {
  return !!response && 'status' in response
}

export function ReportWizard({ onCancel, isVisible }: ModalProps): JSX.Element {
  const { t } = useTranslation()
  const [sites, getSites, resetSites] = useSitesStore((state) => [state.sites, state.getSites, state.reset])
  const [error, setError] = useState<ApiError | undefined>()
  const [currentSite, setCurrentSite] = useState<Site>()
  const [isPublished, setPublished] = useState(false)
  const [isPublishing, setPublishing] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const [form] = useForm()
  const [isPreviewed, setPreviewed] = useState(false)
  const [report, setReport] = useState<ArrayBuffer>()
  const billingMonthOptions = currentSite
    ? getBillingMonths(currentSite.reportDates, currentSite.initialOnboarding)
    : []

  void getSites()

  useEffect(() => {
    if (!currentSite || !sites) return
    const updatedSite = sites.find((site) => site.key === currentSite.key)
    setCurrentSite(updatedSite)
  }, [sites])

  function handleCancel(): void {
    onCancel()
    setError(undefined)
  }

  async function handleSubmit(
    values: {
      [FormKeys.SITE]: string
      [FormKeys.REPORT_MONTH]: string
      [FormKeys.OUTPUT_FORMAT]: string
    },
    preview = false,
  ): Promise<void> {
    setError(undefined)
    const [year, month] = values[FormKeys.REPORT_MONTH].split('-')

    preview ? setLoading(true) : setPublishing(true)
    const response = await API.getBilling({
      site: values[FormKeys.SITE],
      month: +month,
      year: +year,
      publish: !preview,
      outputFormat: values[FormKeys.OUTPUT_FORMAT],
    })
    preview ? setLoading(false) : setPublishing(false)

    if (isApiError(response)) {
      if (response.status === 404) message.error(t('menus.main.billingReportModal.notFoundError'))
      return setError(response)
    }

    if (response) {
      preview ? handleDownload(response) : setReport(response)
    }

    if (!preview) resetSites()
  }

  function handleDownload(data: ArrayBuffer | undefined): void {
    if (!data) return
    const values = form.getFieldsValue()
    const reportDate = dayjs(values[FormKeys.REPORT_MONTH])
    const filename = [values[FormKeys.SITE], reportDate.format('YYYY'), reportDate.format('MMMM')].join(' - ')
    fileDownload(data, `${filename}.zip`)
    setReport(undefined)
  }

  async function handlePreview(): Promise<void> {
    trackEvent(TrackingEvent.careSummaryReportPreviewClicked)

    try {
      const values = await form.validateFields()
      await handleSubmit(values, true)
      setPreviewed(true)
    } catch (_) {}
  }

  function handlePublish(): void {
    trackEvent(
      isPublished ? TrackingEvent.careSummaryReportDownloadClicked : TrackingEvent.careSummaryReportPublishClicked,
    )
    if (isPreviewed || isPublished) form.submit()
  }

  async function handleCopyLink(): Promise<void> {
    try {
      const values = await form.validateFields()
      const reportDate = dayjs(values[FormKeys.REPORT_MONTH])
      const format = values[FormKeys.OUTPUT_FORMAT] ?? 'report-xls'
      copy(
        window.location.origin +
          routes.reportDownload(values[FormKeys.SITE], reportDate.year(), reportDate.month() + 1, format),
      )
      message.success(t('message.copied'))
      setReport(undefined)
    } catch (_) {}
  }

  function renderError(): JSX.Element | null {
    if (error?.errorName === 'MissingPreviousMonthError') {
      return (
        <Alert
          type="error"
          style={{ marginBottom: '20px' }}
          message={t('menus.main.billingReportModal.previousMonthWarning')}
        />
      )
    }

    return null
  }

  function handleSiteChange(siteKey?: string): void {
    if (!siteKey || !sites) return

    const currentSite = sites.find((site) => site.key === siteKey)
    setCurrentSite(currentSite)
    if (!currentSite) return

    form.resetFields([FormKeys.REPORT_MONTH])
    setPublished(false)
    setError(undefined)
  }

  function handleDateChange(reportDate?: string): void {
    if (!currentSite || !reportDate) return

    const alreadyPublished = currentSite.reportDates.includes(reportDate)
    setPublished(alreadyPublished)
    setError(undefined)

    const previousReportDate = dayjs(reportDate).subtract(1, 'month')
    const hasPreviousReport =
      alreadyPublished ||
      (!currentSite.reportDates.length && reportDate === last(billingMonthOptions)?.value) ||
      currentSite.reportDates.includes(previousReportDate.format('YYYY-MM'))
    if (!hasPreviousReport) setError({ status: 412, errorName: 'MissingPreviousMonthError' })
  }

  return (
    <Modal
      title={t('menus.main.careSummaryReport')}
      open={isVisible}
      centered
      onCancel={handleCancel}
      destroyOnClose
      footer={
        <>
          <Button
            disabled={isLoading || isPublishing || isPublished || !!error}
            loading={isLoading}
            type="default"
            onClick={handlePreview}
          >
            {t('menus.main.billingReportModal.preview')}
          </Button>
          <Popconfirm
            title={t('menus.main.billingReportModal.confirm')}
            disabled={isPreviewed || isPublished || isLoading || isPublishing || !!error}
            onConfirm={form.submit}
            icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
          >
            <Button
              disabled={isLoading || isPublishing || !!error}
              loading={isPublishing}
              type="primary"
              onClick={handlePublish}
            >
              {isPublished ? t('menus.main.billingReportModal.getReport') : t('menus.main.billingReportModal.publish')}
            </Button>
          </Popconfirm>
        </>
      }
    >
      {renderError()}
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ [FormKeys.OUTPUT_FORMAT]: 'report-xls' }}
        onFieldsChange={() => setPreviewed(false)}
      >
        <Form.Item
          name={FormKeys.SITE}
          rules={[{ required: true, message: t('validations.enterSite') }]}
          label={t('form.selectSite')}
        >
          <Select
            placeholder={t('placeholders.site')}
            options={sites?.map(getSiteSelectOption)}
            onChange={handleSiteChange}
          />
        </Form.Item>
        <Form.Item name={FormKeys.OUTPUT_FORMAT}>
          <Select
            defaultActiveFirstOption
            options={Object.entries(getOutputFormats()).map(([value, label]) => ({ value, label }))}
          />
        </Form.Item>
        <Form.Item
          name={FormKeys.REPORT_MONTH}
          rules={[{ required: true, message: t('validations.enterBillingMonth') }]}
          label={t('form.selectMonth')}
        >
          <Select
            placeholder={t('placeholders.billingMonth')}
            options={billingMonthOptions}
            onChange={handleDateChange}
          />
        </Form.Item>
      </Form>
      <Modal
        open={!!report}
        centered
        onCancel={() => setReport(undefined)}
        title={t('menus.main.billingReportModal.published')}
        footer={
          <>
            <Button type="default" onClick={handleCopyLink}>
              {t('menus.main.billingReportModal.copyLink')}
            </Button>
            <Button type="default" onClick={() => handleDownload(report)}>
              {t('menus.main.billingReportModal.download')}
            </Button>
          </>
        }
      >
        {t('menus.main.billingReportModal.accessReport')}
      </Modal>
    </Modal>
  )
}
