import i18next from 'i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  convert,
  defaultUnits,
  getDisplayValue,
  normalize,
  normalizedUnitRanges,
  normalizedUnits,
} from '@vetahealth/fishing-gear/conversions'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { LineChart } from '../Chart/LineChart'
import { createAlertField, createDeviceField } from '../Table/helpers'
import { GetResultsConfigParams, ResultsConfig } from './types'

export function getBloodPressureConfig({ userUnits }: GetResultsConfigParams): ResultsConfig {
  const unit = userUnits.bloodPressure || defaultUnits.bloodPressure

  const [min, max] = normalizedUnitRanges.bloodPressure
  const [convertedMin] = convert(min, normalizedUnits.bloodPressure, 'bloodPressure', unit)
  const [convertedMax] = convert(max, normalizedUnits.bloodPressure, 'bloodPressure', unit)

  return {
    key: 'bloodPressure',
    title: i18next.t('tracking.bloodPressure.title'),
    category: 'tracking',
    unit,
    format: (event, targetUnit, omitUnit) =>
      event.value !== undefined
        ? getDisplayValue(
            [event.value, event.additionalValue ?? 0],
            event.unit ?? normalizedUnits.bloodPressure,
            'bloodPressure',
            { targetUnit, omitUnit },
          )
        : '–',
    actions: {
      add: [
        {
          key: 'value',
          label: i18next.t('tracking.bloodPressure.systolic', { unit }),
          validators: [
            { required: true, message: i18next.t('validations.enterSystolic') },
            () => ({
              message: i18next.t('validations.trackingRange', {
                min: convertedMin,
                max: convertedMax,
                unit,
              }),
              validator: (_, value: number) => {
                const [normalizedValue] = normalize('bloodPressure', Number(value), unit)

                return min <= normalizedValue && normalizedValue <= max ? Promise.resolve() : Promise.reject()
              },
            }),
          ],
        },
        {
          key: 'additionalValue',
          label: i18next.t('tracking.bloodPressure.diastolic', { unit }),
          validators: [
            { required: true, message: i18next.t('validations.enterDiastolic') },
            ({ getFieldValue }) => {
              const systolicValue = getFieldValue('systolic')

              return {
                message: i18next.t('validations.trackingRange', {
                  min: convertedMin,
                  max: systolicValue <= convertedMax ? systolicValue : convertedMax,
                  unit,
                }),
                validator: (_, value: number) => {
                  const normalizedSystolicValue =
                    systolicValue && normalize('bloodPressure', Number(systolicValue), unit)[0]
                  const dependentMax =
                    normalizedSystolicValue && normalizedSystolicValue < max ? normalizedSystolicValue : max

                  const [normalizedValue] = normalize('bloodPressure', Number(value), unit)

                  return min <= normalizedValue && normalizedValue <= dependentMax
                    ? Promise.resolve()
                    : Promise.reject()
                },
              }
            },
          ],
        },
      ],
      reviewBulk: true,
      downloadCsv: true,
    },
    chart: {
      component: LineChart,
      intervals: ['day', 'week', 'month', 'trend'],
      dataKeys: [
        { key: 'value', color: Colors.azure500 },
        { key: 'additionalValue', color: Colors.ink400 },
      ],
      getData: (data: CardDetails[]) =>
        data.map((event) => {
          const [systolic, diastolic] = convert(
            [event.value, event.additionalValue].filter(isDefined),
            event.unit,
            'bloodPressure',
            unit,
          )
          return {
            ...event,
            value: systolic,
            additionalValue: diastolic,
          }
        }),
    },
    table: {
      defaultFields: true,
      additionalFields: [createAlertField(), createDeviceField()],
      actions: ['review', 'delete'],
    },
  }
}
