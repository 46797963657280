import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory'

import { ToolTip } from './ToolTip'
import { getAggregationLabel, getLineIntervalData, hasUnreviewedAlerts } from './helpers'
import { HoverLine, Wrapper, chartPadding } from './styles'
import { getTheme } from './theme'
import { ChartProps, LabelFormatterParams } from './types'

export function LineChart({
  data,
  dataKeys,
  intervalDates,
  intervalType,
  alerts,
  onChangeAggregationLabel,
  getToolTipData,
}: ChartProps): JSX.Element | null {
  const theme = useTheme()
  const [width, setWidth] = useState<number | undefined>()
  const ref = useRef<HTMLDivElement>(null)

  const { intervalData, ticks, formatter, y, aggregationInterval, isAveragedPerDay } = useMemo(() => {
    return getLineIntervalData({ data, dataKeys, intervalDates, intervalType })
  }, [data, dataKeys, intervalDates, intervalType])

  const isTrend = ['trend', 'all'].includes(intervalType)

  function handleChartWidth(): void {
    if (ref) setWidth(ref.current?.getBoundingClientRect().width)
  }

  function getDomain(): { y: DomainTuple } {
    if (Object.values(intervalData).length === 0) {
      return y.all[0] === y.all[1] ? { y: [Number(y.all[0]) * 0.9, Number(y.all[1]) * 1.1] } : { y: y.all }
    }

    return y.interval[0] === y.interval[1]
      ? { y: [Number(y.interval[0]) * 0.9, Number(y.interval[1]) * 1.1] }
      : { y: y.interval }
  }

  useEffect(() => {
    onChangeAggregationLabel(getAggregationLabel(aggregationInterval, isAveragedPerDay))
  }, [aggregationInterval, isAveragedPerDay])

  useEffect(() => {
    handleChartWidth()
    window.addEventListener('resize', handleChartWidth)
    return () => window.removeEventListener('resize', handleChartWidth)
  }, [])

  return (
    <Wrapper ref={ref}>
      <VictoryChart
        width={width}
        domainPadding={{ x: 20, y: 10 }}
        padding={chartPadding}
        theme={getTheme(theme)}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiBlacklist={dataKeys.flatMap((_, index) => [`scatter-${index}`, `line-${index}`])}
            voronoiDimension="x"
            labelComponent={
              <ToolTip
                alerts={alerts}
                getToolTipData={getToolTipData}
                aggregationInterval={aggregationInterval}
                chartType="line"
                isAveragedPerDay={isAveragedPerDay}
              />
            }
            labels={() => ' '} // https://github.com/FormidableLabs/victory/issues/1805#issuecomment-802179487
            disable={isTrend}
          />
        }
      >
        <VictoryAxis tickValues={ticks} tickFormat={formatter} />

        <VictoryAxis dependentAxis domain={getDomain()} />

        {dataKeys.map(({ key, color }, index) => (
          <VictoryGroup color={color} key={key}>
            <VictoryLine name={`line-${index}`} interpolation="monotoneX" data={intervalData[index] ?? []} />
            <VictoryScatter
              name={`hoverLine-${index}`}
              dataComponent={<HoverLine />}
              data={intervalData[index] ?? []}
            />
            {(!isTrend || intervalData[index].length === 1) && (
              <VictoryScatter
                name={`scatter-${index}`}
                size={3.3}
                data={intervalData[index] ?? []}
                style={{
                  data: {
                    fill: ({ datum: { ids } }: LabelFormatterParams) =>
                      hasUnreviewedAlerts(alerts, ids) ? theme.concerningActive : dataKeys[index].color,
                  },
                }}
              />
            )}
          </VictoryGroup>
        ))}
      </VictoryChart>
    </Wrapper>
  )
}
