import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { ProgramStartType } from '@vetahealth/tuna-can-api'
import { DatePicker, Form, FormInstance, Input, Select, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { invert, sortBy, uniq } from 'lodash-es'
import { CustomTagProps } from 'rc-select/lib/BaseSelect'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePatients } from '../../lib/hooks/usePatients'
import { usePatientsStore } from '../../stores/patients'
import { useSitesStore } from '../../stores/sites'
import { useUserStore } from '../../stores/user'
import { getSiteSelectOption } from '../helpers'
import TagSelect from './CustomInputs/TagSelect'
import { getConditionNames, getSexNames } from './helpers'
import { TwoColumns } from './styles'
import { FormKeys } from './types'

export function Personal({
  form,
  isAddPatientModal,
}: {
  form: FormInstance
  isAddPatientModal?: boolean
}): JSX.Element {
  const { t } = useTranslation()
  const [hasPermission] = useUserStore((state) => [state.hasPermission])
  const [sites, getSites] = useSitesStore((state) => [state.sites, state.getSites])
  const [tags, getTags] = usePatientsStore((state) => [state.tags, state.getTags])
  const [siteKey, setSiteKey] = useState<string>(form ? form.getFieldValue(FormKeys.SITE) : '')
  const [, filterData] = usePatients()

  const showReferenceDateInput = Form.useWatch(FormKeys.PROGRAM_START_TYPE, form) === ProgramStartType.ReferenceDate
  const selectedProgram = Form.useWatch<number | undefined>(FormKeys.PROGRAM_TEMPLATE_ID, form)

  void getSites()
  void getTags()

  const conditionNames = getConditionNames()
  const conditionNameToKey = invert(conditionNames)
  const usedConditionsNames = filterData.conditions.map((condition) => condition.text)
  const conditionOptions = uniq(Object.keys(conditionNameToKey).concat(usedConditionsNames))
  const accessibleSites = sites?.filter((site) => hasPermission(PermissionName.accessPatient, site.key))
  const usedDiagnosisCodeNames = filterData.diagnosisCodes.map((code) => code.text)

  const renderCondition = (props: CustomTagProps): JSX.Element => {
    const { value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>): void => {
      event.preventDefault()
      event.stopPropagation()
    }
    const isUserDefined = !(value in conditionNameToKey)
    return (
      <Tooltip
        open={isUserDefined ? undefined : false}
        title={t('widgets.patientEdit.userDefinedCondition')}
        mouseEnterDelay={1}
      >
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ fontSize: 14, fontStyle: isUserDefined ? 'italic' : 'inherit' }}
        >
          {value}
        </Tag>
      </Tooltip>
    )
  }

  useEffect(() => {
    if (!sites) return
    if (sites.length === 1) setSiteKey(sites[0].key)
  }, [sites])

  return (
    <TwoColumns>
      <Form.Item
        name={FormKeys.FIRST_NAME}
        label={t('form.firstName')}
        rules={[{ required: true, whitespace: true, message: t('validations.enterFirstName') }]}
      >
        <Input placeholder={t('placeholders.firstName')} />
      </Form.Item>
      <Form.Item
        name={FormKeys.LAST_NAME}
        label={t('form.lastName')}
        rules={[{ required: true, whitespace: true, message: t('validations.enterLastName') }]}
      >
        <Input placeholder={t('placeholders.lastName')} />
      </Form.Item>
      <Form.Item
        style={{ gridColumn: '1 / span 1', gridRow: '2 / 3' }}
        name={FormKeys.NICKNAME}
        label={t('form.nickname')}
        rules={[{ whitespace: true }]}
      >
        <Input placeholder={t('placeholders.nickname')} />
      </Form.Item>
      <Form.Item
        style={{ gridColumn: '1 / span 1', gridRow: '3 / 4' }}
        name={FormKeys.DATE_OF_BIRTH}
        label={t('form.dateOfBirth')}
        rules={[
          { required: true, message: t('validations.enterDate') },
          { type: 'date', max: dayjs().endOf('day').valueOf(), message: t('validations.noFutureDate') },
        ]}
      >
        <DatePicker
          placeholder={dayjs().format('L')}
          format="L"
          showNow={false}
          style={{ width: '100%' }}
          disabledDate={(current) => current && current > dayjs()}
        />
      </Form.Item>
      <Form.Item style={{ gridColumn: '2 / span 1', gridRow: '3 / 4' }} name={FormKeys.SEX} label={t('form.sex')}>
        <Select
          options={Object.entries(getSexNames()).map(([value, label]) => ({ value, label }))}
          placeholder={t('placeholders.sex')}
        />
      </Form.Item>
      {accessibleSites && accessibleSites.length > 0 && (
        <>
          {isAddPatientModal && (
            <>
              <Form.Item
                style={{ gridColumn: '1 / span 1', gridRow: '4 / 5' }}
                name={FormKeys.SITE}
                label={t('form.site')}
                rules={[{ required: true, message: t('validations.enterSite') }]}
              >
                <Select
                  showSearch
                  onChange={(value: string) => {
                    setSiteKey(value)

                    const programs = accessibleSites?.find((site) => site.key === value)?.programs
                    const initialProgram = programs?.length === 1 ? programs[0] : undefined
                    form.setFieldsValue({
                      [FormKeys.PROGRAM_TEMPLATE_ID]: initialProgram?.id ?? undefined,
                      [FormKeys.CONDITIONS]:
                        initialProgram?.defaultConditions?.map((condition) => getConditionNames()[condition]) ?? [],
                    })
                  }}
                  options={sortBy(accessibleSites.map(getSiteSelectOption), (item) => item.label.toLowerCase())}
                  placeholder={t('placeholders.site')}
                />
              </Form.Item>
              <Form.Item
                style={{ gridColumn: 'span 2' }}
                key={siteKey}
                name={FormKeys.PROGRAM_TEMPLATE_ID}
                label={t('form.program')}
                rules={[{ required: true, message: t('validations.enterProgram') }]}
              >
                <Select
                  disabled={!siteKey}
                  showSearch
                  options={
                    sites
                      ?.find((site) => site.key === siteKey)
                      ?.programs?.map(({ name, id }) => ({ label: name, value: id })) || []
                  }
                  onChange={(value) => {
                    const programs = accessibleSites?.find((site) => site.key === siteKey)?.programs
                    const defaultConditions = programs
                      ?.find((program) => program.id === value)
                      ?.defaultConditions?.map((condition) => getConditionNames()[condition])
                    form.setFieldValue(FormKeys.CONDITIONS, defaultConditions)
                  }}
                  placeholder={t('placeholders.program')}
                />
              </Form.Item>
              <Form.Item
                name={FormKeys.PROGRAM_START_TYPE}
                initialValue={ProgramStartType.NextMeasurement}
                label={t('form.startOn')}
                rules={[{ required: true }]}
              >
                <Select
                  disabled={!selectedProgram}
                  options={[
                    { value: ProgramStartType.NextMeasurement, label: t('form.startOnNextMeasurement') },
                    { value: ProgramStartType.ReferenceDate, label: t('form.startOnReferenceDate') },
                  ]}
                />
              </Form.Item>
              {showReferenceDateInput && (
                <Form.Item
                  name={FormKeys.PROGRAM_START}
                  label={t('form.programStart')}
                  rules={[{ required: true, message: t('validations.enterProgramStart') }]}
                  initialValue={dayjs()}
                  dependencies={[FormKeys.PROGRAM_TEMPLATE_ID, FormKeys.PROGRAM_START_TYPE]}
                >
                  <DatePicker
                    disabled={!selectedProgram}
                    placeholder={dayjs().format('L')}
                    format="L"
                    showNow
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </>
          )}
          {!isAddPatientModal && (
            <>
              <Form.Item
                style={{ gridColumn: '1 / span 1', gridRow: '4 / 5' }}
                name={FormKeys.TAGS}
                label={t('form.tagSelect')}
              >
                <TagSelect tagOptions={tags} />
              </Form.Item>
              <Form.Item
                name={FormKeys.DIAGNOSIS_CODES}
                label={t('form.diagnosisCodes')}
                style={{ gridColumn: '2 / span 1', gridRow: '5 / 6' }}
              >
                <TagSelect
                  tagOptions={usedDiagnosisCodeNames}
                  getCustomAddLabel={(value) => t('form.tagSelectAddDiagnosisCode', { newTag: value })}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            style={{ gridColumn: '2 / span 1', gridRow: '4 / 5' }}
            name={FormKeys.CLIENT_ID}
            label={t('form.clientReference')}
          >
            <Input placeholder={t('placeholders.clientReference')} />
          </Form.Item>
          <Form.Item
            key={siteKey}
            style={{ gridColumn: '1 / span 1', gridRow: '5 / 6' }}
            name={FormKeys.CONDITIONS}
            label={t('form.conditions')}
          >
            <TagSelect
              tagOptions={conditionOptions}
              getCustomAddLabel={(value) => t('form.tagSelectAddCondition', { newTag: value })}
              disabled={!siteKey}
              tagRender={renderCondition}
              placeholder={!siteKey && t('placeholders.programFirst')}
            />
          </Form.Item>
        </>
      )}
    </TwoColumns>
  )
}
