import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { PatientTask } from '@vetahealth/tuna-can-api'
import { Button, Modal, message } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePatientStore } from '../../../../../stores/patient'
import { useResultsStore } from '../../../../../stores/results'
import { Task } from '../../../../Task'

export function RenderTask({ record }: { record: PatientTask }): React.ReactNode {
  const { t } = useTranslation()
  const [patient] = usePatientStore((state) => [state.patient])
  const [isVisible, setVisibility] = useState(false)
  const [tasks, getTask] = useResultsStore((state) => [state.tasks, state.getTask])
  const task = tasks[record.responseId]

  async function handleTask(event: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> {
    event.stopPropagation()

    if (patient) {
      getTask(patient.id, record.responseId).then((task) => {
        if (task?.content) {
          setVisibility(true)
        } else {
          message.info({ content: t('message.noTaskPreviewAvailable'), key: 'noTaskPreviewAvailable' })
        }
      })
    }
  }

  function handleClose(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.stopPropagation()
    setVisibility(false)
  }

  return (
    <>
      <Button
        shape="circle"
        icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.teal500} />}
        type="text"
        onClick={handleTask}
      />
      <Modal
        title={task?.content?.title as string}
        open={isVisible}
        onCancel={handleClose}
        maskClosable
        destroyOnClose
        centered
        closeIcon={false}
        wrapProps={{ onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation() }}
        footer={
          <Button onClick={handleClose} type="primary">
            {t('actions.ok')}
          </Button>
        }
      >
        {!!task && <Task id={task.id} content={task.content} />}
      </Modal>
    </>
  )
}
