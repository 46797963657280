import React from 'react'
import styled from 'styled-components'

import { Typography } from 'antd'

export const InlineForm = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
`

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  grid-gap: 0 20px;
`

export const ThreeColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 15px));
  grid-gap: 0 30px;
`

const Description = styled(Typography.Paragraph)`
  font-size: 13px;
  opacity: 0.5;
  padding-top: 2px;
`

export const Badge = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 0;
  background: ${({ $isActive, theme }) => ($isActive ? theme.badgeBackground : theme.secondaryBackground)};
  border-radius: 6px;
  padding: 4px 4px 4px 8px;
`

export const BadgeLabel = styled(Typography.Text)<{ $isActive: boolean }>`
  font-weight: 500;
  flex: 1;
  margin-right: 20px;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
`

export const BadgeValue = styled(Typography.Text)`
  margin-right: 6px;
`

const LabelWrap = styled.div<{ $width?: number }>`
  max-width: ${({ $width = 100 }) => $width}%;
`

export const LabelWithDescription = ({
  label,
  description,
  width,
}: { label: string; description: string; width?: number }): JSX.Element => {
  return (
    <LabelWrap $width={width}>
      <div>{label}</div>
      <Description>{description}</Description>
    </LabelWrap>
  )
}
