import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { Device } from '@vetahealth/tuna-can-api'
import { Button, Popconfirm, message } from 'antd'
import { orderBy } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from '../../../../Router/routes'
import { PatientDetailsTab } from '../../../../Router/routes/PatientDetails'
import { API } from '../../../../lib/api'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { hasPatientAddress, usePatientStore } from '../../../../stores/patient'
import { useSitesStore } from '../../../../stores/sites'
import { useUserStore } from '../../../../stores/user'
import { AssignDevice } from './AssignDevice'
import { DeviceCard } from './DeviceCard'
import { OrderDevice } from './OrderDevice'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 12px;
  }
`

const DevicesWrapper = styled.div`
  margin-top: 16px;
`

export function Devices(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [devices, setDevices] = useState<Device[]>([])
  const [isOrderDeviceModalVisible, setOrderDeviceModalVisibility] = useState<boolean>(false)
  const [isAssignDeviceModalVisible, setAssignDeviceModalVisibility] = useState<boolean>(false)
  const [patient] = usePatientStore((state) => [state.patient])
  const [sites, getSites] = useSitesStore((state) => [state.sites, state.getSites])
  const hasPermission = useUserStore((state) => state.hasPermission)

  void getSites()

  useEffect(() => {
    if (patient?.id) {
      getDevices(patient.id)
    }
  }, [patient?.id])

  if (!sites || !patient?.id) return <div />

  const { hasDeviceOrdering = false, hasDeviceAssigning = false } = sites.find(({ key }) => key === patient?.site) || {}

  const handleRemoveDevice = (deviceId: string): void => {
    API.removeDevice(patient.id, deviceId).then(() => {
      setDevices(devices.filter((device) => device.deviceId !== deviceId))
      getDevices(patient.id)
      void message.success(t('message.deviceDelete'))
    })
  }

  const handleRemoveOrder = (orderId: string): void => {
    API.deleteOrder({ id: patient.id, orderId }).then(() => {
      setDevices(devices.filter((device) => device.orderId !== orderId))
      getDevices(patient.id)
      message.success(t('message.orderDelete'))
    })
  }

  function getDevices(patientId: string): void {
    API.getDevices(patientId).then((response) => response && setDevices(response))
  }

  const hasAddress = hasPatientAddress(patient)
  const hasPhone = !!patient.mobilePhone || !!patient.landlinePhone
  const isAllowedToOrderDevice = hasPermission(PermissionName.manageDevice, patient.site) && hasDeviceOrdering
  const isAllowedToAssignDevice = hasPermission(PermissionName.manageDevice, patient.site) && hasDeviceAssigning
  const isAllowedToDeleteOrders = hasPermission(PermissionName.deleteSubmittedOrder, patient.site) && hasDeviceOrdering
  const existingDeviceTypes = devices.map((device) => device.deviceType)

  function handleAssignDeviceOpen(): void {
    trackEvent(TrackingEvent.deviceAssignClicked)
    setAssignDeviceModalVisibility(true)
  }

  function handleOrderDeviceOpen(): void {
    trackEvent(TrackingEvent.deviceOrderClicked)
    setOrderDeviceModalVisibility(hasAddress && hasPhone)
  }

  return (
    <>
      <ButtonWrapper>
        {isAllowedToAssignDevice && (
          <Button onClick={handleAssignDeviceOpen} type="primary">
            {t('widgets.devices.assignDevice')}
          </Button>
        )}
        {isAllowedToOrderDevice && (
          <Popconfirm
            title={hasAddress ? t('widgets.devices.phoneIncomplete') : t('widgets.devices.addressIncomplete')}
            disabled={hasAddress && hasPhone}
            okText={t('actions.edit')}
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ type: 'text' }}
            onConfirm={() => navigate(routes.patientDetails(patient.id, PatientDetailsTab.demographics))}
            icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
          >
            <Button onClick={handleOrderDeviceOpen} type="primary">
              {t('widgets.devices.orderDevice')}
            </Button>
          </Popconfirm>
        )}
      </ButtonWrapper>
      <DevicesWrapper>
        {orderBy(devices, ['creationDate'], ['desc']).map((device) => {
          return (
            <DeviceCard
              key={device.deviceId ?? device.orderId}
              device={device}
              onDeleteDevice={handleRemoveDevice}
              onDeleteOrder={handleRemoveOrder}
              isAllowedToOrderDevice={isAllowedToOrderDevice}
              isAllowedToAssignDevice={isAllowedToAssignDevice}
              isAllowedToDeleteOrders={isAllowedToDeleteOrders}
            />
          )
        })}
      </DevicesWrapper>
      <OrderDevice
        onOrderDevice={getDevices}
        isVisible={isOrderDeviceModalVisible}
        onCancel={() => setOrderDeviceModalVisibility(false)}
        existingDeviceTypes={existingDeviceTypes}
      />
      <AssignDevice
        onAssignDevice={getDevices}
        isVisible={isAssignDeviceModalVisible}
        onCancel={() => setAssignDeviceModalVisibility(false)}
      />
    </>
  )
}
