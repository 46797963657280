import { DatePicker, Segmented, Select, Space } from 'antd'
import React, { useEffect } from 'react'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { AnalyticsAggregationEnum } from '@vetahealth/tuna-can-api'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { useSitesStore } from '../../../stores/sites'
import { useUserStore } from '../../../stores/user'
import { getDateIntervalLabel, getDateRange } from './helpers'

export function Filter(): JSX.Element {
  const { t } = useTranslation()
  const [analyticsSettings, updateAnalyticsSettings, hasPermission] = useUserStore((state) => [
    state.analyticsSettings,
    state.updateAnalyticsSettings,
    state.hasPermission,
  ])
  const [sites, getSites] = useSitesStore((state) => [state.sites, state.getSites])
  const { site: siteKey, programs, aggregationLevel, dateRange } = analyticsSettings || {}
  const currentSite = sites?.find((site) => site.key === siteKey)
  const accessibleSites = sites?.filter((site) => hasPermission(PermissionName.accessAnalytics, site.key))

  function handleSetSiteKey(key: string): void {
    const newSite = sites?.find((site) => site.key === key)
    if (newSite) {
      updateAnalyticsSettings({ site: key, programs: [] })
    }
  }

  function handleSetProgram(programs: number[]): void {
    trackEvent(TrackingEvent.analyticsProgramSelected)
    updateAnalyticsSettings({ programs })
  }

  function handleSetAggregationLevel(aggregationLevel: AnalyticsAggregationEnum): void {
    trackEvent(TrackingEvent.analyticsAggregationLevelSelected, { aggregationLevel })
    updateAnalyticsSettings({
      dateRange: getDateRange(aggregationLevel, dayjs()),
      aggregationLevel,
    })
  }

  function handleSetDate(date: Dayjs): void {
    trackEvent(TrackingEvent.analyticsDateSelected, { date: date.toISOString() })
    if (!aggregationLevel) return
    updateAnalyticsSettings({
      dateRange: getDateRange(aggregationLevel, date),
    })
  }

  useEffect(() => {
    const isAllowedToAccessSite = accessibleSites?.some((site) => site.key === siteKey)
    if ((!isAllowedToAccessSite || !analyticsSettings?.site) && accessibleSites?.length) {
      handleSetSiteKey(accessibleSites[0].key)
    }
  }, [accessibleSites, analyticsSettings])

  void getSites()

  return (
    <Space align="start">
      {accessibleSites && accessibleSites.length > 1 && (
        <Select
          placeholder={t('placeholders.site')}
          value={siteKey}
          options={accessibleSites?.map((site) => ({
            label: site.shortName,
            value: site.key,
          }))}
          onChange={handleSetSiteKey}
          popupMatchSelectWidth={false}
        />
      )}
      {currentSite && (
        <>
          <Select
            value={programs}
            mode="multiple"
            style={{ minWidth: 150 }}
            options={currentSite.programs.map((program) => ({
              label: program.name,
              value: program.id,
            }))}
            placeholder={t('placeholders.programs')}
            onChange={handleSetProgram}
            popupMatchSelectWidth={false}
          />
          <Segmented
            options={Object.values(AnalyticsAggregationEnum).map((interval) => ({
              label: getDateIntervalLabel(interval),
              value: interval,
            }))}
            value={aggregationLevel}
            onChange={handleSetAggregationLevel}
          />
          {dateRange && aggregationLevel !== AnalyticsAggregationEnum.All && (
            <DatePicker picker={aggregationLevel} value={dayjs(dateRange[0])} onChange={handleSetDate} />
          )}
        </>
      )}
    </Space>
  )
}
