import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { faSirenOn, faUserCheck, faUserLargeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { Note as NoteType } from '@vetahealth/tuna-can-api'

import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Popconfirm, Select, Typography } from 'antd'

import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { getCareStateNames } from '../Widgets/helpers'
import { getNoteTypeName, noteTypes } from '../helpers'
import { TwoColumns } from './styles'
import { FormKeys } from './types'

const Section = styled.section<{ $hasMarginBottom?: boolean }>`
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: ${({ $hasMarginBottom }) => ($hasMarginBottom ? '20px' : '0')};
  }
`
const SubText = styled.span`
  display: block;
  font-size: 12px;
  color: ${Colors.gray600};
`
const Label = styled.div`
  font-weight: 600;
`
const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  > *.ant-form-item {
    margin-bottom: 0;
  }
  > *:not(:last-child) {
    margin-right: 10px;
  }
`

const Text = styled(Typography.Text)`
  white-space: pre-wrap;
`
interface NoteFormProps {
  loading: boolean
  note: NoteType
  isEditable: boolean
  isDeletable: boolean
  onDelete: () => void
  onDownload: () => void
  onSubmit: () => void
}

export function Note({
  loading,
  onDelete,
  onDownload,
  onSubmit,
  note,
  isEditable,
  isDeletable,
}: NoteFormProps): JSX.Element {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)

  function handleEditMode(): void {
    trackEvent(TrackingEvent.notesEditClicked)
    setIsEditMode((prevState) => !prevState)
  }

  function handleEditSave(): void {
    trackEvent(TrackingEvent.notesEditSaveClicked)
    setIsEditMode(false)
    onSubmit()
  }

  return (
    <>
      {note?.careState && (
        <Section>
          <Label>{t('form.careState')} </Label>
          <Text>{getCareStateNames()[note.careState]}</Text>
        </Section>
      )}
      <TwoColumns>
        {isEditMode ? (
          <Form.Item
            style={{ width: '100%' }}
            name={FormKeys.TIMESTAMP}
            validateFirst
            required={false}
            label={t('form.date')}
            rules={[
              { required: true, message: t('validations.enterDate') },
              {
                type: 'date',
                validator: (_, value: Dayjs) => (dayjs(value) < dayjs() ? Promise.resolve() : Promise.reject()),
                message: t('validations.noFutureDate'),
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} format="lll" showTime={{ format: t('dateFormats.time') }} showNow />
          </Form.Item>
        ) : (
          <Section>
            <Label>{t('form.date')}</Label>
            <Text>{dayjs(note.timestamp).format('lll')}</Text>
          </Section>
        )}
        {isEditMode ? (
          <Form.Item
            name={FormKeys.TIME_SPENT_IN_MIN}
            validateFirst
            required={false}
            label={t('placeholders.timeSpent')}
            rules={[{ required: true, message: t('validations.enterTimeSpent') }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              max={99}
              formatter={(i) => (i ? Number(i).toFixed() : '')}
              placeholder={t('placeholders.timeSpent')}
            />
          </Form.Item>
        ) : (
          <Section>
            <Label>{t('placeholders.timeSpent')}</Label>
            <Text>{note.timeSpentInMin}</Text>
          </Section>
        )}
      </TwoColumns>
      {isEditMode ? (
        <Form.Item
          style={{ width: '100%' }}
          name={FormKeys.NOTE_TYPE}
          label={t('form.type')}
          required={false}
          rules={[{ required: true, message: t('validations.enterNoteType') }]}
        >
          <Select
            showSearch
            options={noteTypes.map((noteTypeKey) => ({
              value: noteTypeKey,
              label: getNoteTypeName(noteTypeKey),
            }))}
            placeholder={t('placeholders.noteType')}
            optionFilterProp="label"
          />
        </Form.Item>
      ) : (
        <Section>
          <Label>{t('form.type')}</Label>
          <Text>{getNoteTypeName(note.type) || `${note.type} (deprecated)`}</Text>
        </Section>
      )}
      <TwoColumns>
        {isEditMode ? (
          <Form.Item style={{ marginBottom: '10px' }} name={FormKeys.PATIENT_INTERACTION} valuePropName="checked">
            <Checkbox style={{ borderRadius: '0px' }}>{t('form.patientCommunication')}</Checkbox>
          </Form.Item>
        ) : (
          <Section>
            <Label>{t('form.patientCommunication')}</Label>
            {note.patientInteraction ? (
              <>
                <Icon icon={faUserCheck} />
                <Text>{t('form.yes')}</Text>
              </>
            ) : (
              <>
                <Icon icon={faUserLargeSlash} />
                <Text>{t('form.no')}</Text>
              </>
            )}
          </Section>
        )}
        {note.hasEscalationReport && !isEditMode ? (
          <Section>
            <Label>{t('form.escalated')}</Label>
            <>
              <Icon icon={faSirenOn} />
              <Text>{t('form.yes')}</Text>
            </>
          </Section>
        ) : (
          <Section />
        )}
      </TwoColumns>
      {isEditMode ? (
        <Form.Item
          label={t('form.text')}
          name={FormKeys.TEXT}
          required={false}
          rules={[{ required: true, message: t('validations.enterNote') }]}
        >
          <Input.TextArea placeholder={t('placeholders.note')} allowClear autoSize={{ minRows: 5, maxRows: 20 }} />
        </Form.Item>
      ) : (
        <Section>
          <Label>{t('form.text')}</Label>
          <Text>{note.text}</Text>
        </Section>
      )}
      <Section $hasMarginBottom={isEditable || note.hasEscalationReport}>
        {note.createdByName !== 'Unknown' && !isEditMode && (
          <SubText>{t('widgets.notes.createdBy', { name: note.createdByName })}</SubText>
        )}
        {note.createdById !== 'unknown' && !isEditMode && (
          <SubText>{t('widgets.notes.createdAt', { date: dayjs(note.createdAt).format('lll') })}</SubText>
        )}
        {note.lastModifiedAt && !isEditMode && (
          <SubText>{t('widgets.notes.lastModifiedAt', { date: dayjs(note.lastModifiedAt).format('lll') })}</SubText>
        )}
      </Section>
      {(isEditable || isDeletable || (note.hasEscalationReport && !isEditMode)) && (
        <ButtonWrap>
          {(isEditMode || (isDeletable && !isEditable)) && (
            <Form.Item style={isEditMode ? { flexGrow: 1 } : undefined}>
              <Popconfirm
                title={t('widgets.notes.confirmDelete')}
                okText={t('actions.delete')}
                onConfirm={onDelete}
                okButtonProps={{ danger: true }}
                cancelText={t('actions.cancel')}
                cancelButtonProps={{ type: 'text' }}
                placement="topRight"
                icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
              >
                <Button danger disabled={loading}>
                  {t('actions.delete')}
                </Button>
              </Popconfirm>
            </Form.Item>
          )}
          {(isEditable || (note.hasEscalationReport && !isEditMode)) && (
            <>
              {isEditable && (
                <Form.Item>
                  <Button onClick={handleEditMode}>
                    {isEditMode ? t('actions.discardChanges') : t('actions.edit')}
                  </Button>
                </Form.Item>
              )}
              {isEditMode && (
                <Form.Item>
                  <Button onClick={handleEditSave} htmlType="submit" type="primary" loading={loading}>
                    {t('actions.save')}
                  </Button>
                </Form.Item>
              )}
              {note.hasEscalationReport && !isEditMode && (
                <Form.Item>
                  <Button onClick={onDownload}>{t('actions.downloadEscalationReport')}</Button>
                </Form.Item>
              )}
            </>
          )}
        </ButtonWrap>
      )}
    </>
  )
}
