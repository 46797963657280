import { Mutex } from 'async-mutex'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ValidUnits, defaultUnits } from '@vetahealth/fishing-gear/conversions'
import { AlertDigestFrequencyEnum, AlertDigestSetting, ThemeEnum } from '@vetahealth/tuna-can-api'

import { Select, TimePicker, Typography, message } from 'antd'

import { SupportedLocale } from '../../lib/i18n'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { useUserStore } from '../../stores/user'
import { labelFromLocale } from '../Forms/helpers'
import { Spinner } from '../Spinner'
import { Card, SubTitle } from './styles'

const SettingsSubTitle = styled(Typography.Text)`
  display: block;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 4px;
`
const AlertDigestWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`

const updateMutex = new Mutex()

export function AppSettings(): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(true)
  const [updateLocale, updateUnits, updateAlertDigest, locale, units, alertDigest, getSettings] = useUserStore(
    (state) => [
      state.updateLocale,
      state.updateUnits,
      state.updateAlertDigest,
      state.locale,
      Object.assign({}, defaultUnits, state.units),
      state.alertDigest,
      state.getSettings,
    ],
  )
  const [theme, updateTheme] = useUserStore((state) => [state.theme, state.updateTheme])

  const isTimePickerDisabled = alertDigest.frequency === 'never' || alertDigest.frequency === 'realtime'

  useEffect(() => {
    getSettings().finally(() => setLoading(false))
  }, [])

  async function handleLanguageChange(newLocale: SupportedLocale): Promise<void> {
    if (newLocale !== locale) {
      const messageKey = 'languageUpdate'

      trackEvent(TrackingEvent.settingsLanguageChangeClicked, { language: newLocale })

      const releaseMutex = await updateMutex.acquire()

      message.loading({ content: t('message.updating'), key: messageKey, duration: 0 })
      const success = await updateLocale(newLocale)

      if (success) {
        message.success({ content: t('message.languageChanged'), key: messageKey })
      } else {
        message.destroy(messageKey)
      }

      releaseMutex()
    }
  }

  async function handleAlertDigestChange(newAlertDigest: Partial<AlertDigestSetting>): Promise<void> {
    const messageKey = 'alertDigestUpdate'
    const alertDigestUpdate = { ...alertDigest, ...newAlertDigest }

    trackEvent(TrackingEvent.settingsDigestChanged, alertDigestUpdate)

    const releaseMutex = await updateMutex.acquire()
    message.loading({ content: t('message.updating'), key: messageKey, duration: 0 })
    const success = await updateAlertDigest(alertDigestUpdate)

    if (success) {
      message.success({ content: t('message.alertDigestChanged'), key: messageKey })
    } else {
      message.destroy(messageKey)
    }

    releaseMutex()
  }

  function handleUnitChange<T>(key: keyof ValidUnits): (unit: T) => void {
    return async (unit) => {
      const messageKey = 'unitUpdate'

      trackEvent(TrackingEvent.settingsUnitChangeClicked, { type: key, unit })

      const releaseMutex = await updateMutex.acquire()

      message.loading({ content: t('message.updating'), key: messageKey, duration: 0 })
      const updatedUnits = { ...units, [key]: unit }
      const success = await updateUnits(updatedUnits)

      if (success) {
        message.success({ content: t('message.unitChanged'), key: messageKey })
      } else {
        message.destroy(messageKey)
      }

      releaseMutex()
    }
  }

  function handleThemeChange(newTheme: ThemeEnum): void {
    if (newTheme !== theme) {
      trackEvent(TrackingEvent.settingsThemeChangeClicked, { theme: newTheme })
      updateTheme(newTheme)
    }
  }

  return (
    <Card title={t('widgets.appSettings.title')}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SubTitle>{t('widgets.appSettings.language')}</SubTitle>
          <Select
            onChange={handleLanguageChange}
            defaultValue={locale}
            style={{ width: '100%' }}
            options={Object.values(SupportedLocale).map((locale) => ({
              value: locale,
              label: labelFromLocale[locale],
            }))}
          />
          <SubTitle>{t('widgets.appSettings.alertDigest.title')}</SubTitle>
          <AlertDigestWrapper>
            <Select
              style={{ flex: 1 }}
              onChange={(frequency) => handleAlertDigestChange({ frequency })}
              defaultValue={alertDigest.frequency}
              popupMatchSelectWidth={false}
              options={[
                { value: AlertDigestFrequencyEnum.Never, label: t('widgets.appSettings.alertDigest.never') },
                { value: AlertDigestFrequencyEnum.Realtime, label: t('widgets.appSettings.alertDigest.realtime') },
                { value: AlertDigestFrequencyEnum.Daily, label: t('widgets.appSettings.alertDigest.daily') },
                { value: AlertDigestFrequencyEnum.Weekly, label: t('widgets.appSettings.alertDigest.weekly') },
              ]}
            />
            {alertDigest.frequency === 'weekly' && (
              <Select
                defaultValue={alertDigest.weekday}
                onChange={(weekday) => handleAlertDigestChange({ weekday })}
                popupMatchSelectWidth={false}
                options={dayjs.weekdays().map((label, index) => ({ value: index, label }))}
              />
            )}
            <TimePicker
              inputReadOnly
              disabled={isTimePickerDisabled}
              defaultValue={dayjs().set('hour', alertDigest.hourToSend).set('minute', 0).set('second', 0)}
              format={t('dateFormats.time')}
              showNow={false}
              allowClear={false}
              placeholder={t('widgets.appSettings.alertDigest.hourToSend')}
              onChange={(dayjsTime) => dayjsTime && handleAlertDigestChange({ hourToSend: dayjsTime.get('hour') })}
            />
          </AlertDigestWrapper>
          <SubTitle>{t('widgets.appSettings.units.title')}</SubTitle>
          <SettingsSubTitle>{t('widgets.appSettings.units.bloodPressure')}</SettingsSubTitle>
          <Select
            onChange={handleUnitChange('bloodPressure')}
            defaultValue={units.bloodPressure}
            popupMatchSelectWidth={false}
            style={{ width: '100%' }}
            options={[
              { value: 'mmHg', label: 'mmHg' },
              { value: 'kPa', label: 'kPa' },
            ]}
          />
          <SettingsSubTitle>{t('widgets.appSettings.units.bloodGlucose')}</SettingsSubTitle>
          <Select
            onChange={handleUnitChange('bloodGlucose')}
            defaultValue={units.bloodGlucose}
            popupMatchSelectWidth={false}
            style={{ width: '100%' }}
            options={[
              { value: 'mmol/l', label: 'mmol/l' },
              { value: 'mg/dl', label: 'mg/dl' },
            ]}
          />
          <SettingsSubTitle>{t('widgets.appSettings.units.hbA1c')}</SettingsSubTitle>
          <Select
            onChange={handleUnitChange('hbA1c')}
            defaultValue={units.hbA1c}
            popupMatchSelectWidth={false}
            style={{ width: '100%' }}
            options={[
              { value: 'mmol/mol', label: 'mmol/mol' },
              { value: '%', label: '%' },
            ]}
          />
          <SettingsSubTitle>{t('widgets.appSettings.units.weight')}</SettingsSubTitle>
          <Select
            onChange={handleUnitChange('weight')}
            defaultValue={units.weight}
            popupMatchSelectWidth={false}
            style={{ width: '100%' }}
            options={[
              { value: 'kg', label: 'kg' },
              { value: 'lbs', label: 'lbs' },
              { value: 'lb_oz', label: 'lb oz' },
              { value: 'st_lb', label: 'st lb' },
            ]}
          />
          <SettingsSubTitle>{t('widgets.appSettings.units.temperature')}</SettingsSubTitle>
          <Select
            onChange={handleUnitChange('bodyTemperature')}
            defaultValue={units.bodyTemperature}
            popupMatchSelectWidth={false}
            style={{ width: '100%' }}
            options={[
              { value: '°C', label: '°C' },
              { value: '°F', label: '°F' },
            ]}
          />
          <SettingsSubTitle>{t('widgets.appSettings.units.length')}</SettingsSubTitle>
          <Select
            onChange={handleUnitChange('height')}
            defaultValue={units.height}
            popupMatchSelectWidth={false}
            style={{ width: '100%' }}
            options={[
              { value: 'm', label: 'm' },
              { value: 'cm', label: 'cm' },
              { value: 'ft', label: 'ft' },
              { value: 'ft_in', label: 'ft in' },
            ]}
          />
          <SubTitle>{t('widgets.appSettings.colorScheme.title')}</SubTitle>
          <Select
            onChange={handleThemeChange}
            defaultValue={'system'}
            style={{ width: '100%' }}
            value={theme}
            options={[
              { value: 'light', label: t('widgets.appSettings.colorScheme.light') },
              { value: 'dark', label: t('widgets.appSettings.colorScheme.dark') },
              { value: 'system', label: t('widgets.appSettings.colorScheme.system') },
            ]}
          />
        </>
      )}
    </Card>
  )
}
