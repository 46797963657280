import { produce } from 'immer'
import { keyBy } from 'lodash-es'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { API } from '../../lib/api'
import { takeLeading } from '../../lib/takeLeading'
import { ChatState } from './types'

const initialState: Pick<
  ChatState,
  'chatMessages' | 'unreadChatMessages' | 'isChatOpen' | 'isChatMinimized' | 'currentlyViewedChats'
> = {
  unreadChatMessages: [],
  chatMessages: {},
  isChatOpen: false,
  isChatMinimized: false,
  currentlyViewedChats: {},
}

export const DATA_FETCH_LIMIT = 30
export const useChatStore = createWithEqualityFn<ChatState>(
  (set, get) => ({
    ...initialState,
    getChatMessages: takeLeading(async (id, offset) => {
      const newChatMessages = await API.getChatMessages(id, DATA_FETCH_LIMIT, offset ? offset : 0)
      if (newChatMessages?.length) {
        set(
          produce<ChatState>(({ chatMessages }) => {
            newChatMessages.forEach((newMessage) => {
              chatMessages[newMessage.id] = newMessage
            })
            chatMessages = { ...chatMessages, ...keyBy(newChatMessages, 'id') }
          }),
        )
        return newChatMessages
      }
    }),
    addChatMessage: async (id, message) => {
      const { chatMessages } = get()

      const newMessage = await API.addChatMessage(id, { content: message })
      if (newMessage) set({ chatMessages: { ...chatMessages, [newMessage.id]: newMessage } })
    },
    markChatMessagesAsRead: async (id, messageIds) => {
      const updatedMessages = await API.markChatMessagesAsRead(id, messageIds)
      if (updatedMessages?.length) {
        set(
          produce<ChatState>(({ chatMessages }) => {
            updatedMessages.forEach((updatedMessage) => {
              chatMessages[updatedMessage.id] = updatedMessage
            })
          }),
        )
      }
    },
    getUnreadChatMessages: takeLeading(async () => {
      const unreadChatMessages = await API.getUnreadChatMessages()
      if (unreadChatMessages) set({ unreadChatMessages })
    }),
    setIsChatOpen: (isOpen) => {
      set({ isChatOpen: isOpen, isChatMinimized: false })
    },
    setIsChatMinimized: (isMinimized) => {
      set({ isChatMinimized: isMinimized })
    },
    resetChatMessages: () =>
      set({
        chatMessages: {},
      }),
  }),
  shallow,
)
