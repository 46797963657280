import axios from 'axios'

import { env } from '../../lib/env'

interface ExtensionEndpoint {
  valueUri: string
  url: string
}

interface NestedExtension {
  url: string
  extension: Array<ExtensionEndpoint | NestedExtension>
}

interface MetadataResponseRestDescriptor {
  security: {
    extension: Array<ExtensionEndpoint | NestedExtension>
  }
}

interface MetadataResponse {
  rest: MetadataResponseRestDescriptor[]
}

interface AuthorizationRequest {
  response_type: 'code'
  client_id: string
  redirect_uri: string
  scope: string
  launch: string
  aud: string
  state?: string
}

interface TokenRequest {
  grant_type: 'authorization_code'
  code: string
  redirect_uri: string
  client_id: string
}

interface TokenResponse {
  access_token: string
  token_type: 'bearer'
  expires_in: number
  scope: string
  id_token?: string
  patient?: string // internal fhir id
  'epic.dstu2.patient'?: string
  encounter?: string
  location?: string
  appointment?: string
  loginDepartment: string
  state?: string
  userEmail?: string // Launch-Context: email
  userId?: string // Launch-Context: epic user-id
  patientId?: string // Launch-Context: mrn
}

export interface EpicState {
  tokenUrl: string
  api: string
}

export async function getEpicCodeRedirect({ iss, launch }: { iss: string; launch: string }): Promise<string> {
  const { data: metaData } = await axios.get<MetadataResponse>(`${iss}/metadata`, {
    headers: { Accept: 'application/fhir+json' },
  })
  const oauthExtensions = metaData.rest[0]?.security.extension.find(
    (o) => o.url === 'http://fhir-registry.smarthealthit.org/StructureDefinition/oauth-uris',
  )
  if (!oauthExtensions || !('extension' in oauthExtensions)) {
    throw new Error('[Oauth] unexpected metadata response format')
  }
  const authorizeEndpoint = oauthExtensions.extension.find((o) => o.url === 'authorize')
  const tokenEndpoint = oauthExtensions.extension.find((o) => o.url === 'token')
  if (!authorizeEndpoint || !('valueUri' in authorizeEndpoint) || !tokenEndpoint || !('valueUri' in tokenEndpoint)) {
    throw new Error('[Oauth] unexpected metadata response format for token endpoint')
  }

  const epicState: EpicState = { api: iss, tokenUrl: tokenEndpoint.valueUri }

  const authRequestParams: AuthorizationRequest = {
    response_type: 'code',
    client_id: env.PUBLIC_EPIC_CLIENT_ID,
    redirect_uri: window.location.origin,
    scope: 'openid fhirUser launch',
    aud: iss,
    launch,
    state: JSON.stringify(epicState),
  }

  return `${authorizeEndpoint.valueUri}?${new URLSearchParams(Object.entries(authRequestParams)).toString()}`
}

export async function getEpicAccessToken({
  code,
  tokenUrl,
}: {
  code: string
  tokenUrl: string
}): Promise<TokenResponse> {
  const tokenRequest: TokenRequest = {
    grant_type: 'authorization_code',
    code,
    client_id: env.PUBLIC_EPIC_CLIENT_ID,
    redirect_uri: window.location.origin,
  }
  const { data } = await axios.post<TokenResponse>(tokenUrl, new URLSearchParams(Object.entries(tokenRequest)))
  return data
}
