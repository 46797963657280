import { Modal } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { AnalyticsAggregationEnum, AnalyticsTypeEnum } from '@vetahealth/tuna-can-api'
import { isEmpty } from 'lodash-es'
import styled from 'styled-components'
import { AggregatedAnalytics, useAnalyticsStore } from '../../../stores/analytics'
import { useUserStore } from '../../../stores/user'
import { Spinner } from '../../Spinner'
import { Card } from './Card'
import { getChartAggregationLevel, isAnalyticsStoredForTypes } from './helpers'

const Wrap = styled.div`
  display: flex;
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 12px;
`
const ListItem = styled.div<{ $isActive: boolean }>`
  padding: 9px 12px;
  cursor: pointer;
  background-color: ${({ theme, $isActive }) => $isActive && theme.secondaryBackground};
  border-radius: 6px;
  margin-bottom: 8px;
`
const Selection = styled.div`
  background-color: ${({ theme }) => theme.secondaryBackground};
  display: flex;
  gap: 12px;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  padding: 24px;
  border-radius: 6px;
`
const Widget = styled.div`
  flex: 0.5;
  cursor: pointer;
  align-self: flex-start;
`

export function Add({
  isVisible,
  types,
  onAdd,
  onCancel,
}: {
  isVisible: boolean
  types: AnalyticsTypeEnum[]
  onAdd: (displayFormat: 'compact' | 'chart', item: AggregatedAnalytics) => void
  onCancel: () => void
}) {
  const [analyticsSettings] = useUserStore((state) => [state.analyticsSettings])
  const [aggregatedAnalytics, getAggregatedAnalytics] = useAnalyticsStore((state) => [
    state.aggregatedAnalytics,
    state.getAggregatedAnalytics,
  ])
  const [selectedAnalytics, setSelectedAnalytics] = useState<AggregatedAnalytics>()
  const [displayFormat, setDisplayFormat] = useState<'compact' | 'chart'>('compact')
  const addableAnalytics = useMemo(
    () => types.map((type) => aggregatedAnalytics?.[type]).filter(isDefined),
    [aggregatedAnalytics, types],
  )

  async function getAddableAnalyticsData(
    siteKey: string,
    types: AnalyticsTypeEnum[],
    programs: number[],
    aggregationLevel: AnalyticsAggregationEnum,
    start: string,
    end: string,
  ): Promise<void> {
    const chartAggregationLevel = getChartAggregationLevel(aggregationLevel)
    if (!isAnalyticsStoredForTypes(types, aggregatedAnalytics)) {
      await getAggregatedAnalytics(siteKey, types, programs, chartAggregationLevel, start, end)
    }
  }

  function handleAdd(displayFormat: 'compact' | 'chart', item?: AggregatedAnalytics): void {
    if (!item) return
    onAdd(displayFormat, item)
  }

  useEffect(() => {
    if (!isEmpty(addableAnalytics)) setSelectedAnalytics(addableAnalytics[0])
  }, [addableAnalytics])

  useEffect(() => {
    const { site, programs, aggregationLevel, dateRange } = analyticsSettings || {}
    if (isVisible && site && programs && aggregationLevel && dateRange) {
      getAddableAnalyticsData(site, types, programs, aggregationLevel, dateRange[0], dateRange[1])
    }
  }, [isVisible, analyticsSettings])

  return (
    <Modal
      title="Add"
      open={isVisible}
      onOk={() => handleAdd(displayFormat, selectedAnalytics)}
      onCancel={onCancel}
      width={1240}
    >
      {!isEmpty(addableAnalytics) ? (
        <Wrap>
          <List>
            {addableAnalytics.map((item) => (
              <ListItem
                key={item.type}
                $isActive={item.id === selectedAnalytics?.id}
                onClick={() => setSelectedAnalytics(item)}
              >
                {item.label}
              </ListItem>
            ))}
          </List>
          <Selection>
            {selectedAnalytics && (
              <>
                <Widget onClick={() => setDisplayFormat('compact')}>
                  <Card isCompact item={selectedAnalytics} isActive={displayFormat === 'compact'} />
                </Widget>
                {selectedAnalytics.chartType && (
                  <Widget onClick={() => setDisplayFormat('chart')}>
                    <Card item={selectedAnalytics} isActive={displayFormat === 'chart'} />
                  </Widget>
                )}
              </>
            )}
          </Selection>
        </Wrap>
      ) : (
        <Spinner />
      )}
    </Modal>
  )
}
