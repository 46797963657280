import { faPen, faSirenOn, faUserCheck, faUserLargeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { CareStateEnum, Note } from '@vetahealth/tuna-can-api'

import { useUserStore } from '../../../stores/user'
import { getNoteTypeName } from '../../helpers'
import { getCareStateNames } from '../helpers'

const StyledCard = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.noteBorder};
  background-color: ${({ theme }) => theme.noteBackground};
  border-radius: 6px;
  padding: 8px 12px;
  margin-bottom: 12px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.noteHover};
  }
  &:last-child {
    margin-bottom: 0;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`
const Timestamp = styled.span`
  font-size: 12px;
`
const TimeSpent = styled(Timestamp)``

const Sub = styled.span`
  font-size: 12px;
  font-weight: 600;
`
const Text = styled.div`
  margin-top: 6px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const Type = styled(Sub)`
  flex: 1;
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
`
const CareState = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.apricot400};
  margin-bottom: 3px;
`
const EditWrap = styled.div`
  margin-top: 6px;
  color: ${Colors.green500};
`
const Edit = styled(Sub)`
  margin-left: 6px;
`

export function Card({
  onClick,
  note,
  careState,
}: {
  onClick: () => void
  note: Note
  careState?: CareStateEnum
}): JSX.Element {
  const { t } = useTranslation()
  const [userId] = useUserStore((state) => [state.userId])
  const hourDiff = dayjs().diff(dayjs(note.createdAt), 'hour')
  // Note can only be edited withing the last 24 hours by the creator
  const isEditable = hourDiff < 24 && note.createdById === userId

  return (
    <StyledCard key={note.id} onClick={onClick}>
      <Row>
        <Timestamp>{dayjs(note.timestamp).format('lll')}</Timestamp>
        <TimeSpent>{`${note.timeSpentInMin} ${t('table.timeSpent')}`}</TimeSpent>
      </Row>
      <Row>
        <Type>{getNoteTypeName(note.type) || `${note.type} (deprecated)`}</Type>
        {note.hasEscalationReport && <Icon icon={faSirenOn} />}
        {note.patientInteraction ? <Icon icon={faUserCheck} /> : <Icon icon={faUserLargeSlash} />}
      </Row>
      {careState && careState !== CareStateEnum.Onboarded && <CareState>{getCareStateNames()[careState]}</CareState>}
      <Text>{note.text}</Text>
      {isEditable && (
        <EditWrap>
          <FontAwesomeIcon icon={faPen} />
          <Edit>{t('widgets.notes.editable', { duration: dayjs.duration(24 - hourDiff, 'hours').humanize() })}</Edit>
        </EditWrap>
      )}
    </StyledCard>
  )
}
