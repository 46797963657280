import { faCircleInfo, faDownload, faLink, faPencil, faTrashCan, faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { FileInfo, Site } from '@vetahealth/tuna-can-api'
import { Button, Flex, Input, Modal, Popconfirm, Table, Tooltip, Upload, UploadProps, message } from 'antd'
import axios, { AxiosError } from 'axios'
import copy from 'copy-to-clipboard'
import dayjs from 'dayjs'
import jsDownload from 'js-file-download'
import { pickBy } from 'lodash-es'
import React, { useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { routes } from '../../../../Router/routes'
import { API } from '../../../../lib/api'
import { useDebounce } from '../../../../lib/hooks/useDebounce'
import { useUserStore } from '../../../../stores/user'
import { Field, getTableData } from '../../../helpers'

interface FilesState {
  fileList: FileInfo[]
  editDescriptionIndex: number
  editedDescription: string
  uploadCount: number
}

const initialState: FilesState = {
  fileList: [],
  editDescriptionIndex: -1,
  editedDescription: '',
  uploadCount: 0,
}

const UploadWrap = styled.div`
  margin-bottom: 40px;
`

function toMiB(bytes: number): number {
  return bytes / 1024 / 1024
}

function humanFileSize(size: number): string {
  if (size < 1024) return `${size} B`
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / 1024 ** i).toFixed(1)} ${['B', 'kiB', 'MiB', 'GiB', 'TiB'][i]}`
}

export function Files({ site }: { site?: Site }): JSX.Element {
  const { t } = useTranslation()
  const [hasPermission, userId] = useUserStore((state) => [state.hasPermission, state.userId])
  const [{ fileList, editDescriptionIndex, editedDescription, uploadCount }, set] = useReducer(
    (state: FilesState, update: Partial<FilesState>) => ({ ...state, ...update }),
    initialState,
  )
  const uploadControllers = useRef<Record<string, AbortController>>({})

  useDebounce(
    () => {
      // update file list
      if (!site) return
      API.getSharedFilesList(site.key).then((sharedFiles) => {
        if (!sharedFiles) return
        set({ fileList: sharedFiles })
      })
    },
    500,
    [uploadCount, site?.key],
  )

  const uploadProps: UploadProps = {
    progress: { strokeColor: Colors.mauve600, strokeWidth: 4 },
    beforeUpload: (file) => {
      if (toMiB(file.size) > 20) {
        message.error(t('widgets.siteManagement.files.tooLarge', { filename: file.name }))
        return false
      }
    },
    customRequest: async ({ file, onProgress, onSuccess, onError }) => {
      if (!site || typeof file === 'string' || !('uid' in file)) return
      const headers = pickBy(await API.getProtectedHeaders())
      headers['Content-Type'] = 'application/octet-stream'
      const onUploadProgress = ({ loaded, total }: { loaded: number; total?: number }) => {
        if (!total || !onProgress) return
        onProgress({ percent: Math.round((100 * loaded) / total) })
      }
      uploadControllers.current[file.uid] = new AbortController()
      axios
        .post(API.getUploadSharedFileUrl(site.key, file.name), file, {
          headers,
          onUploadProgress,
          signal: uploadControllers.current[file.uid].signal,
        })
        .then(() => {
          onSuccess?.({})
        })
        .catch((axiosError: AxiosError) => {
          onError?.(axiosError)
        })
    },
    multiple: true,
    onChange(info) {
      if (info.file.status === 'done') {
        set({ uploadCount: uploadCount + 1 })
        message.success(t('widgets.siteManagement.files.uploadSuccess', { filename: info.file.name }))
      } else if (info.file.status === 'error') {
        message.error(t('widgets.siteManagement.files.uploadError', { filename: info.file.name }))
      }
    },
    onRemove(file) {
      uploadControllers.current[file.uid]?.abort()
    },
  }

  const canDownload = hasPermission(PermissionName.downloadFile, site?.key)
  const canUpload = hasPermission(PermissionName.uploadFile, site?.key)
  const canEditOthers = hasPermission(PermissionName.manageUser, site?.key)

  async function handleDelete(id: number): Promise<void> {
    if (!site) return
    const success = await API.deleteSharedFile(site.key, id)
    if (!success) return
    const index = fileList.findIndex((item) => item.id === id)
    if (index < 0) return
    message.success(t('widgets.siteManagement.files.deleteSuccess', { filename: fileList[index].name }))
    fileList.splice(index, 1)
    set({ fileList: [...fileList] })
  }

  async function handleUpdateDescription(): Promise<void> {
    if (!site) return
    const index = editDescriptionIndex
    set({ editDescriptionIndex: -1 })
    await API.updateSharedFile(site.key, fileList[index].id, editedDescription)
    fileList[index].label = editedDescription
    set({ fileList: [...fileList] })
  }

  function handleEditDescription(id: number): void {
    const fileIndex = fileList.findIndex((item) => item.id === id)
    set({ editDescriptionIndex: fileIndex })
    if (fileIndex < 0) return
    set({ editedDescription: fileList[fileIndex].label ?? '' })
  }

  async function handleDownload(id: number): Promise<void> {
    const file = fileList.find((item) => item.id === id)
    if (!site || !file) return
    const fileData = await API.downloadSharedFile(site.key, id)
    if (fileData) {
      jsDownload(fileData, file.name)
    }
  }

  async function shareLink(id: number): Promise<void> {
    const file = fileList.find((item) => item.id === id)
    if (!site || !file) return

    copy(window.location.origin + routes.fileDownload(site.key, id.toString(), file.name))
    message.success(t('message.copied'))
  }

  const fields: Field<FileInfo>[] = [
    { key: 'name', title: t('table.fileName') },
    {
      key: 'label',
      title: t('table.description'),
    },
    {
      key: 'uploadedAt',
      title: t('table.uploaded'),
      format: (uploadedAt: string) => (
        <Tooltip title={dayjs(uploadedAt).format('LLL')}>{dayjs(uploadedAt).fromNow()}</Tooltip>
      ),
      defaultSortOrder: 'descend',
    },
    { key: 'size', title: t('table.fileSize'), format: humanFileSize },
    {
      key: 'id',
      noSorting: true,
      title: t('table.actions'),
      width: 100,
      format: (id: number, item: FileInfo) => {
        const isOwnFile = item.uploadedBy === userId
        return (
          <Flex gap="small">
            <Tooltip title={t('actions.download')}>
              <Button
                shape="circle"
                icon={<FontAwesomeIcon icon={faDownload} />}
                disabled={!canDownload && !isOwnFile}
                onClick={() => handleDownload(item.id)}
              />
            </Tooltip>
            <Tooltip title={t('actions.copyLink')}>
              <Button
                shape="circle"
                icon={<FontAwesomeIcon icon={faLink} />}
                disabled={!canDownload && !isOwnFile}
                onClick={() => shareLink(item.id)}
              />
            </Tooltip>
            <Tooltip title={t('actions.editDescription')}>
              <Button
                style={{ marginLeft: 16 }}
                shape="circle"
                icon={<FontAwesomeIcon icon={faPencil} />}
                disabled={!canEditOthers && !isOwnFile}
                onClick={() => handleEditDescription(item.id)}
              />
            </Tooltip>
            <Tooltip title={t('actions.delete')}>
              <Popconfirm
                title={t('widgets.siteManagement.files.confirmDelete')}
                onConfirm={() => handleDelete(id)}
                icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
              >
                <Button
                  shape="circle"
                  icon={<FontAwesomeIcon icon={faTrashCan} />}
                  danger
                  disabled={!canEditOthers && !isOwnFile}
                />
              </Popconfirm>
            </Tooltip>
          </Flex>
        )
      },
    },
  ]

  const { dataSource, columns } = getTableData({
    fields,
    data: fileList,
  })
  return (
    <div>
      {canUpload && (
        <UploadWrap>
          <Upload {...uploadProps}>
            <Button icon={<FontAwesomeIcon icon={faUpload} />}>{t('widgets.siteManagement.files.uploadButton')}</Button>
          </Upload>
        </UploadWrap>
      )}
      <Table dataSource={dataSource} columns={columns} pagination={{ pageSize: 8 }} />
      <Modal
        open={editDescriptionIndex > -1}
        title={t('actions.editDescription')}
        onOk={handleUpdateDescription}
        onCancel={() => set({ editDescriptionIndex: -1 })}
        centered
      >
        <Input type="text" value={editedDescription} onChange={(e) => set({ editedDescription: e.target.value })} />
      </Modal>
    </div>
  )
}
