import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { Site } from '@vetahealth/tuna-can-api'
import { Button, Flex, Form, FormInstance, Tabs, message } from 'antd'
import { Mutex } from 'async-mutex'
import { debounce } from 'lodash-es'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SiteManagementTab } from '../../../Router/routes/SiteManagement'
import { useSitesStore } from '../../../stores/sites'
import { useUserStore } from '../../../stores/user'
import { defaultSorting } from '../../helpers'
import { Card } from '../styles'
import { TabProps } from '../types'
import { AddSite } from './AddSite'
import { Files } from './Files'
import { Ordering } from './Ordering'
import { Programs } from './Programs'
import { Settings } from './Settings'
import { Snapshots } from './Snapshots'

export interface SiteUpdateProps {
  site?: Site
  form: FormInstance
  handleSiteUpdate: () => void
}

const SitesList = styled.div`
  min-width: 180px;
  padding-right: 30px;
  border-right: 1px solid ${({ theme }) => theme.secondaryBackground};
`

const SiteButton = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin-bottom: 2px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.badgeActive : 'transparent')};
  &:hover {
    background-color: ${({ $isActive, theme }) => ($isActive ? theme.badgeActive : theme.secondaryBackground)};
  }
`

const SiteTabs = styled(Tabs)`
  padding-left: 30px;
  flex-grow: 1;
  min-height: calc(100vh - 132px);
  .ant-tabs-content-holder {
    padding-top: 16px;
  }
`

const updateMutex = new Mutex()

export function SiteManagement({ tab, onTabChange }: TabProps): JSX.Element {
  const [sites, managedSiteKeys, getSites] = useSitesStore((state) => [
    state.sites,
    state.managedSiteKeys,
    state.getSites,
  ])
  const [hasPermission] = useUserStore((state) => [state.hasPermission])
  const [siteKey, setSiteKey] = useState<string | undefined>()
  const [isAddSiteVisible, setIsAddSiteVisible] = useState<boolean>(false)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [formUpdate, setFormUpdate] = useState(0)
  const [updateSite] = useSitesStore((state) => [state.updateSite])

  const currentSite = sites?.find((site) => site.key === siteKey)

  useEffect(() => {
    if (!currentSite) return
    form.resetFields()
    form.setFieldsValue(currentSite)
    setFormUpdate(formUpdate + 1)
  }, [currentSite])

  const handleSiteUpdate = useCallback(
    debounce(async () => {
      const formValues = form.getFieldsValue()

      if (
        !currentSite ||
        !Object.values(formValues).length ||
        form.getFieldsError().some(({ errors }) => !!errors?.length)
      )
        return

      const updatedSite = { ...formValues, key: currentSite.key }
      const releaseMutex = await updateMutex.acquire()
      const messageKey = 'siteUpdate'
      message.loading({ content: t('message.updating'), key: messageKey, duration: 0 })

      const success = await updateSite(updatedSite)
      if (success) {
        message.success({ content: t('message.siteUpdate'), key: messageKey })
      } else {
        message.destroy(messageKey)
      }
      releaseMutex()
    }, 750),
    [currentSite],
  )

  useEffect(() => {
    if (sites && managedSiteKeys && !siteKey) {
      const sitesWithPermission = sites.filter((site) => managedSiteKeys.includes(site.key))
      if (sitesWithPermission.length) setSiteKey(sitesWithPermission[0].key)
    }
  }, [sites, managedSiteKeys, siteKey])

  void getSites()

  const isEditable =
    hasPermission(PermissionName.manageSite, siteKey) && hasPermission(PermissionName.manageUser, siteKey)
  const isTester = hasPermission(PermissionName.testFeatures, siteKey)

  const tabItems = [
    {
      key: SiteManagementTab.programs,
      label: t('widgets.siteManagement.tabs.programs'),
      children: tab === SiteManagementTab.programs && <Programs site={currentSite} isEditable={isEditable} />,
    },
    {
      key: SiteManagementTab.settings,
      label: t('widgets.siteManagement.tabs.settings'),
      children: tab === SiteManagementTab.settings && !!formUpdate && (
        <Settings
          key={formUpdate}
          site={currentSite}
          isEditable={isEditable}
          form={form}
          handleSiteUpdate={handleSiteUpdate}
        />
      ),
    },
    {
      key: SiteManagementTab.ordering,
      label: t('widgets.siteManagement.tabs.ordering'),
      children: tab === SiteManagementTab.ordering && !!formUpdate && (
        <Ordering key={formUpdate} site={currentSite} form={form} handleSiteUpdate={handleSiteUpdate} />
      ),
      disabled: !isEditable,
    },
    {
      key: SiteManagementTab.files,
      label: t('widgets.siteManagement.tabs.files'),
      children: tab === SiteManagementTab.files && <Files site={currentSite} />,
      disabled:
        !hasPermission(PermissionName.uploadFile, siteKey) && !hasPermission(PermissionName.downloadFile, siteKey),
    },
    {
      key: SiteManagementTab.snapshots,
      label: t('widgets.siteManagement.tabs.snapshots'),
      children: tab === SiteManagementTab.snapshots && <Snapshots site={currentSite} />,
      disabled: !isTester || !currentSite?.isDemo,
    },
  ]

  useEffect(() => {
    if (!siteKey) return
    const tabIndex = tabItems.findIndex(({ key, disabled }) => key === tab && disabled)
    if (tabIndex > -1) onTabChange(tabItems[tabIndex - 1].key)
  }, [siteKey])

  return (
    <Card>
      <Flex>
        <SitesList>
          {isEditable && (
            <Button type="primary" onClick={() => setIsAddSiteVisible(true)} style={{ marginBottom: '40px' }}>
              {t('actions.add')}
            </Button>
          )}
          {sites
            ?.filter((site) => managedSiteKeys?.includes(site.key))
            .sort((a, b) => defaultSorting(a, b, 'key', 'ascend'))
            .map((site) => (
              <SiteButton key={site.key} onClick={() => setSiteKey(site.key)} $isActive={site.key === siteKey}>
                {site.key}
              </SiteButton>
            ))}
        </SitesList>
        <SiteTabs activeKey={tab} onChange={onTabChange} size="small" items={tabItems} />
      </Flex>
      {isEditable && (
        <AddSite
          onAdd={setSiteKey}
          open={isAddSiteVisible}
          managedSiteKeys={managedSiteKeys}
          onCancel={() => {
            setIsAddSiteVisible(false)
          }}
        />
      )}
    </Card>
  )
}
