import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import * as React from 'react'
import styled from 'styled-components'

export const Wrap = styled.div`
  overflow-y: hidden;
  .ant-table table th { font-size: 12px; }
`

const Check = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${Colors.green500};
`

export const Checkmark: React.FC = () => (
  <Check>
    <FontAwesomeIcon icon={faCheck} color={Colors.white} />
  </Check>
)

export const Flex = styled.div`
  display: flex;
  gap: 8px;
`
