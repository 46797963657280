import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

export const Wrap = styled.div<{ $hasOpacity?: boolean }>`
  ${({ $hasOpacity }) => $hasOpacity && 'opacity: 0.3;'}
  padding-top: 12px;
  .ant-timeline .ant-timeline-item .ant-timeline-item-label {
    width: calc(20% - 16px);
  }
  .ant-timeline .ant-timeline-item .ant-timeline-item-tail {
    left: 20%;
  }
  .ant-timeline .ant-timeline-item .ant-timeline-item-head {
    left: 20%;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(20% - 8px);
    width: calc(80% - 18px);
  }
`

export const Card = styled.div<{ $colors: Record<string, Colors>; $isEditable?: boolean }>`
  background-color: ${({ $colors }) => $colors.background};
  border-radius: 6px;
  padding: 3px 6px;
  margin: 2px 0 5px;
  ${({ $isEditable, $colors }) =>
    $isEditable &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${$colors.hover};
    }
  `}
`

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Label = styled.div`
  flex: 1;
  margin-right: 20px;
`

export const TimingItem = styled.div<{ $isOnlyIcon?: boolean }>`
  min-width: ${({ $isOnlyIcon }) => ($isOnlyIcon ? '26px' : '30px')};
  margin-right: 8px;
  display: inline-block;
  &:last-child {
    margin-right: 0;
  }
`

export const ToDoTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  padding-top: 2px;
`

export const Timing = styled.div`
  opacity: 0.6;
`

export const LabelWrap = styled.div`
  margin-top: 3px;
`

export const Name = styled.span`
  white-space: nowrap;
  padding-right: 4px;
  font-size: 13px;
  &::after {
    content: '|';
    display: inline-block;
    padding-left: 4px;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`

export const Time = styled.div`
  font-size: 12px;
`
export const Value = styled.span`
  font-size: 13px;
  margin-left: 3px;
`

export const Dot = styled.div<{ $color?: string }>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${({ $color }) => $color};
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
`

export const DotMultiple = styled.div<{ $colors: string[] }>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-size:
    100% 100%,
    100% 50%,
    100% 100%,
    100% 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(360deg, ${({ $colors }) => $colors[0]} 100%, transparent 100%),
    linear-gradient(360deg, ${({ $colors }) => $colors[1]} 100%, transparent 100%);
  background-position:
    center center,
    left top,
    right top,
    left bottom,
    right bottom;
  background-origin: content-box, border-box, border-box, border-box, border-box;
  background-clip: content-box, border-box, border-box, border-box, border-box;
  transform: rotate(90deg);
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
`
