import { faShieldCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button, Form, Input } from 'antd'

import { useLoading } from '../../lib/hooks/useLoading'
import { AuthStep, useAuthStore } from '../../stores/auth'
import { Action, ButtonWrapper, Description, FormWrapper } from './styles'
import { FormValues } from './types'

export function TwoFactorSignin(): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()
  const [handleTwoFactorSignin, setStep, errorStatus] = useAuthStore((state) => [
    state.handleTwoFactorSignin,
    state.setStep,
    state.errorStatus,
  ])

  function handleSubmit(values: Pick<FormValues, 'code'>): void {
    withLoading(handleTwoFactorSignin(values.code))
  }

  return (
    <>
      <Action>{t('auth.twoFactor')}</Action>
      <Description>{t('auth.instructionsTwoFactor')}</Description>
      {errorStatus && <Alert type="error" message={t('error.twoFactor')} style={{ marginLeft: '30px' }} />}
      <FormWrapper>
        <Form onFinish={handleSubmit} validateTrigger="onSubmit">
          <Form.Item name="code" rules={[{ required: true, message: t('validations.enterTwoFactor') }]}>
            <Input
              autoFocus
              size="large"
              placeholder={t('placeholders.twoFactor')}
              prefix={<FontAwesomeIcon icon={faShieldCheck} />}
            />
          </Form.Item>
          <Form.Item>
            <Button block disabled={isLoading} loading={isLoading} type="primary" htmlType="submit" size="large">
              {t('actions.continue')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
      <ButtonWrapper>
        <Button onClick={() => setStep(AuthStep.SIGNIN)} type="link">
          {t('actions.back')}
        </Button>
      </ButtonWrapper>
    </>
  )
}
