import { Button, Spin, Table } from 'antd'
import React, { useEffect, useMemo } from 'react'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { AnalyticsAggregationEnum, AnalyticsTypeEnum } from '@vetahealth/tuna-can-api'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { PropertyPath, get, isNumber } from 'lodash-es'
import { unparse } from 'papaparse'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../Router/routes'
import { useLoading } from '../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { useAnalyticsStore } from '../../../stores/analytics'
import { usePatientsStore } from '../../../stores/patients'
import { useUserStore } from '../../../stores/user'
import { getTableData } from '../../helpers'
import { Chart } from './Chart'
import { Filter } from './Filter'
import { getChartAggregationLevel, getFields } from './helpers'
import { Actions, Bar, Description, DetailsCard, Header, TableWrap, Title, Value } from './styles'

export function Details(): JSX.Element | null {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoadingAggregatedAnalytics, withLoadingAggregatedAnalytics] = useLoading()
  const [isLoadingRawAnalytics, withLoadingRawAnalytics] = useLoading()
  const [analyticsSettings] = useUserStore((state) => [state.analyticsSettings])
  const [aggregatedAnalytics, rawAnalytics, getRawAnalytics, getAggregatedAnalytics] = useAnalyticsStore((state) => [
    state.aggregatedAnalytics,
    state.rawAnalytics,
    state.getRawAnalytics,
    state.getAggregatedAnalytics,
  ])
  const [patients] = usePatientsStore((state) => [state.patients])
  const { id: type } = useParams<{ id: AnalyticsTypeEnum }>()
  const item = type && aggregatedAnalytics?.[type]
  const patientsIdAndName: Array<{ id: string; name: string }> = useMemo(
    () => patients.map(({ id, firstName, lastName }) => ({ name: `${firstName} ${lastName}`, id })),
    [patients],
  )

  async function getAnalyticsData(
    siteKey: string,
    type: AnalyticsTypeEnum,
    programs: number[],
    aggregationLevel: AnalyticsAggregationEnum,
    start: string,
    end: string,
  ): Promise<void> {
    const chartAggregationLevel = getChartAggregationLevel(aggregationLevel)
    withLoadingRawAnalytics(getRawAnalytics(siteKey, type, programs, start, end))
    if (!aggregatedAnalytics?.[type]) {
      // Only fetch when aggregatedAnalytics is not already stored for type
      // Note: On filter change, aggregatedAnalytics and rawAnalytics are reset
      withLoadingAggregatedAnalytics(
        getAggregatedAnalytics(siteKey, [type], programs, chartAggregationLevel, start, end),
      )
    }
  }

  function handleBack(): void {
    trackEvent(TrackingEvent.analyticsBackClicked)
    navigate(routes.analytics())
  }

  function handleCSVDownload(): void {
    trackEvent(TrackingEvent.analyticsCsvDownloadClicked)
    const tableData = {
      fields: columns.map(({ title }) => (title ?? '') as string),
      data: dataSource.map((item) =>
        columns.map(({ dataIndex }) => (dataIndex ? get(item, dataIndex as PropertyPath) : undefined)),
      ),
    }
    const csv = unparse(tableData, { quotes: (value) => !isNumber(value) })
    fileDownload(csv, 'data-export.csv')
  }

  const { dataSource, columns } = getTableData({
    fields: getFields(patientsIdAndName, type),
    data: rawAnalytics,
  })

  useEffect(() => {
    const { site, programs, aggregationLevel, dateRange } = analyticsSettings || {}
    if (type && site && programs && aggregationLevel && dateRange) {
      getAnalyticsData(site, type, programs, aggregationLevel, dateRange[0], dateRange[1])
    }
  }, [analyticsSettings, type])

  return (
    <>
      <Bar>
        <Filter />
        <Actions>
          <Button onClick={handleCSVDownload}>{t('actions.downloadCsv')}</Button>
          <Button type="primary" onClick={handleBack}>
            {t('actions.backToDashboard')}
          </Button>
        </Actions>
      </Bar>

      <DetailsCard>
        <Header>
          <Title>{item?.label}</Title>
        </Header>
        <Value $color={item?.color || Colors.mauve600}>
          {isLoadingAggregatedAnalytics && !aggregatedAnalytics ? <Spin spinning /> : item?.aggregationValue}
        </Value>
        <Description>{item?.description}</Description>
        {item?.chartType && <Chart item={item} height={350} />}
        <TableWrap>
          <Table dataSource={dataSource} columns={columns} loading={isLoadingRawAnalytics} />
        </TableWrap>
      </DetailsCard>
    </>
  )
}
