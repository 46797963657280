import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import {
  AnalyticsAggregationEnum,
  AnalyticsTypeEnum,
  DeviceTypeEnum,
  OrderKitTypeEnum,
  QuestionTypeEnum,
} from '@vetahealth/tuna-can-api'
import { Button, Spin } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import i18next from 'i18next'
import { isEmpty, sortBy } from 'lodash-es'
import React from 'react'
import { routes } from '../../../Router/routes'
import { PatientDetailsTab } from '../../../Router/routes/PatientDetails'
import { FilterDropdown, SearchDropdown, searchIcon } from '../../../lib/antdFilters'
import {
  AggregatedAnalytics,
  ChartAggregationLevel,
  GroupedAggregatedAnalytics,
  RawAnalytics,
  SurveyResponse,
} from '../../../stores/analytics'
import { config } from '../../../stores/analytics/config'
import { getDisplayValueWithUnit } from '../../../stores/analytics/helpers'
import { Field } from '../../helpers'
import { getDeviceName } from '../PatientDetails/Devices/helpers'

export function isAnalyticsStoredForTypes(
  types: AnalyticsTypeEnum[],
  aggregatedAnalytics?: GroupedAggregatedAnalytics,
): boolean {
  if (!aggregatedAnalytics) return false
  return types.every((type) => type in aggregatedAnalytics)
}

export function getDateIntervalLabel(interval: AnalyticsAggregationEnum): string {
  if (interval === AnalyticsAggregationEnum.Month) return i18next.t('dateFormats.month')
  if (interval === AnalyticsAggregationEnum.Year) return i18next.t('dateFormats.year')
  return i18next.t('dateFormats.allTime')
}

export function getQuestionTypeLabel(questionType: QuestionTypeEnum): string {
  if (questionType === QuestionTypeEnum.Slider) return i18next.t('widgets.analytics.slider')
  if (questionType === QuestionTypeEnum.CheckboxGroup) return i18next.t('widgets.analytics.checkbox')
  if (questionType === QuestionTypeEnum.RadioButtons) return i18next.t('widgets.analytics.radio')
  return i18next.t('widgets.analytics.freeText')
}

export function getCardItems(types: AnalyticsTypeEnum[], analytics: GroupedAggregatedAnalytics): AggregatedAnalytics[] {
  return types.map((type) => analytics[type]).filter(isDefined)
}

export function getDateRange(aggregationLevel: AnalyticsAggregationEnum, date: Dayjs): string[] {
  if (aggregationLevel === AnalyticsAggregationEnum.Year) {
    return [dayjs(date).startOf('year').toISOString(), dayjs(date).endOf('year').toISOString()]
  }
  if (aggregationLevel === AnalyticsAggregationEnum.Month) {
    return [dayjs(date).startOf('month').toISOString(), dayjs(date).endOf('month').toISOString()]
  }

  return [dayjs('2000/01').startOf('year').toISOString(), dayjs(date).endOf('year').toISOString()]
}

export function getChartAggregationLevel(aggregationLevel?: AnalyticsAggregationEnum): ChartAggregationLevel {
  if (aggregationLevel === AnalyticsAggregationEnum.Month) {
    return ChartAggregationLevel.day
  }
  if (aggregationLevel === AnalyticsAggregationEnum.Year) {
    return ChartAggregationLevel.month
  }
  return ChartAggregationLevel.year
}

export function getFields(
  patients: Array<{ id: string; name: string }>,
  type?: AnalyticsTypeEnum,
): Field<RawAnalytics>[] {
  const commonTableFields = [
    {
      key: 'timestamp',
      title: i18next.t('table.date'),
      format: (timestamp: string) => dayjs(timestamp).format('lll'),
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      onFilter: (query: string, record: RawAnalytics) => record.timestamp.toLowerCase().includes(query.toLowerCase()),
    },
    {
      key: 'userId',
      title: i18next.t('table.user'),
      noSorting: true,
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      onFilter: (query: string, record: RawAnalytics) => {
        const patient = patients?.find((patient) => patient.id === record.userId)
        return patient?.name.toLowerCase().includes(query.toLowerCase()) ?? false
      },
      format: (id: string) => {
        const patient = patients.find((patient) => patient.id === id)
        if (isEmpty(patients)) return <Spin size="small" />
        return (
          <>
            {patient?.name ? <span>{patient.name}</span> : <i>{i18next.t('status.archived')}</i>}
            <Button
              shape="circle"
              type="link"
              href={routes.patientDetails(id, PatientDetailsTab.results)}
              icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              onClick={(e) => e.stopPropagation()}
            />
          </>
        )
      },
    },
    {
      key: 'program',
      title: i18next.t('table.program'),
    },
  ]

  if (type === AnalyticsTypeEnum.TotalDeviceCost) {
    return [
      ...commonTableFields,
      {
        key: 'orderType',
        title: i18next.t('table.deviceType'),
        format: (orderType: DeviceTypeEnum | OrderKitTypeEnum) => getDeviceName(orderType),
        noSorting: true,
      },
      {
        key: 'orderPrice',
        title: i18next.t('table.orderPrice'),
        format: (value: number) => getDisplayValueWithUnit(value, config[type].rawUnit),
      },
    ]
  }
  if (type === AnalyticsTypeEnum.DevicesOrdered)
    return [
      ...commonTableFields,
      {
        key: 'orderType',
        title: i18next.t('table.deviceType'),
        format: (orderType: DeviceTypeEnum | OrderKitTypeEnum) => getDeviceName(orderType),
        noSorting: true,
      },
      {
        key: 'orderPrice',
        title: i18next.t('table.orderPrice'),
        format: (value: number) => getDisplayValueWithUnit(value, config[type].rawUnit),
      },
    ]
  if (type === AnalyticsTypeEnum.DeliveryTime) {
    return [
      ...commonTableFields,
      {
        key: 'deliveredTimestamp',
        title: i18next.t('table.deliveredTimestamp'),
        format: (timestamp: string) => dayjs(timestamp).format('lll'),
      },
      {
        key: 'daysToDelivered',
        title: i18next.t('table.daysToDelivery'),
        format: (value: number) => getDisplayValueWithUnit(value, config[type].rawUnit),
      },
      {
        key: 'carrier',
        title: i18next.t('table.courier'),
      },
      {
        key: 'trackingNumber',
        title: i18next.t('table.trackingId'),
      },
    ]
  }
  if (type === AnalyticsTypeEnum.NpsScore) {
    return [
      ...commonTableFields,
      {
        key: 'score',
        title: i18next.t('table.score'),
      },
    ]
  }
  if (type === AnalyticsTypeEnum.FirstMeasurementTime) {
    return [
      ...commonTableFields,
      {
        key: 'firstMeasurementTimestamp',
        title: i18next.t('table.firstMeasurementTimestamp'),
        format: (timestamp: string) => dayjs(timestamp).format('lll'),
      },
      {
        key: 'daysToFirstMeasurement',
        title: i18next.t('table.daysToFirstMeasurement'),
        format: (value: number) => getDisplayValueWithUnit(value, config[type].rawUnit),
      },
    ]
  }
  if (type === AnalyticsTypeEnum.ParkviewSurveyOne || type === AnalyticsTypeEnum.ParkviewSurveyTwo) {
    return [
      ...commonTableFields,
      {
        key: 'questionType',
        title: i18next.t('table.questionType'),
        format: (questionType: QuestionTypeEnum) => getQuestionTypeLabel(questionType),
        noSorting: true,
        filterMultiple: true,
        filters: sortBy(
          Object.values<QuestionTypeEnum>(QuestionTypeEnum).map((questionType) => ({
            text: getQuestionTypeLabel(questionType),
            value: questionType,
          })),
          'text',
        ),
        onFilter: (query: string, record: SurveyResponse) =>
          record.questionType?.toLowerCase().includes(query.toLowerCase()),
        filterDropdown: FilterDropdown,
      },
      {
        key: 'question',
        title: i18next.t('table.question'),
      },
      {
        key: 'response',
        title: i18next.t('table.response'),
      },
    ]
  }
  return [...commonTableFields]
}
