import i18next from 'i18next'
import React from 'react'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { PatientTask } from '@vetahealth/tuna-can-api'

import { BarChart } from '../Chart/BarChart'
import { TaskTable } from '../Table'
import { ResultsConfig } from './types'

export function getTaskConfig(): ResultsConfig {
  const unit = 'min'

  return {
    key: 'task',
    title: i18next.t('tracking.task.title'),
    category: 'task',
    format: (event) => event.value?.toString() ?? '-',
    card: {
      formatTime: () => i18next.t('tracking.task.range'),
    },
    unit,
    actions: { reviewBulk: true },
    chart: {
      component: BarChart,
      intervals: ['week', 'month', 'all'],
      dataKeys: [{ key: 'value', color: Colors.plum500 }],
      getData: (data: PatientTask[]) =>
        data.filter(({ status }) => status === 'answered').map((item) => ({ ...item, value: 1, id: item.responseId })),
    },
    render: () => <TaskTable type="task" key="task" />,
  }
}
