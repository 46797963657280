import { faLock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Button, Checkbox, Form, Input, Typography } from 'antd'

const CheckboxWrap = styled.div`
  margin-bottom: 20px;
`
const DescriptionWrap = styled.div`
  margin-bottom: 16px;
`
const PasswordRequirementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 20px;
`
const PasswordRequirement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PasswordRequirementIndicator = styled(Typography.Text)`
  font-weight: bold;
  font-size: 13px;
`
const PasswordRequirementLabel = styled(Typography.Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.quaternaryBackground};
`

function isValidPassword(password: string): boolean {
  // minlength (8)
  return /^.{8,}$/.test(password)
}

export function SetPdfPassword({ isLoading }: { isLoading: boolean }): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const [password, setPassword] = useState<string>('')
  const [isPasswordOptionVisible, setPasswordOption] = useState(true)

  const requirement: { indicator: string; label: string; isValid: boolean } = {
    indicator: '8+',
    label: t('auth.passwordLength'),
    isValid: password.length >= 8,
  }

  return (
    <>
      <CheckboxWrap>
        <Checkbox defaultChecked onChange={(event) => setPasswordOption(event.target.checked)}>
          {t('form.protectWithPassword')}
        </Checkbox>
      </CheckboxWrap>
      {isPasswordOptionVisible && (
        <>
          <DescriptionWrap>
            <Typography.Text>{t('form.passwordDescription')}</Typography.Text>
          </DescriptionWrap>
          <Form.Item name="password" rules={[{ required: true, message: t('validations.enterNewPassword') }]}>
            <Input.Password
              size="large"
              placeholder={t('placeholders.password')}
              type="password"
              prefix={<FontAwesomeIcon icon={faLock} size="sm" />}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: t('validations.enterConfirmPassword') },
              ({ getFieldValue }) => ({
                validator(_, confirmPassword) {
                  return !confirmPassword || getFieldValue('password') === confirmPassword
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('validations.confirmPassword')))
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t('placeholders.confirmPassword')}
              type="password"
              prefix={<FontAwesomeIcon icon={faLock} size="sm" />}
            />
          </Form.Item>
          <PasswordRequirementWrapper>
            <PasswordRequirement key={requirement.label}>
              <PasswordRequirementIndicator
                style={{ color: requirement.isValid ? Colors.green500 : theme.quaternaryBackground }}
              >
                {requirement.indicator}
              </PasswordRequirementIndicator>
              <PasswordRequirementLabel>{requirement.label}</PasswordRequirementLabel>
            </PasswordRequirement>
          </PasswordRequirementWrapper>
        </>
      )}
      <Form.Item>
        <Button
          block
          disabled={(isPasswordOptionVisible && !isValidPassword(password)) || isLoading}
          loading={isLoading}
          type="primary"
          htmlType="submit"
          size="large"
        >
          {t('actions.download')}
        </Button>
      </Form.Item>
    </>
  )
}
