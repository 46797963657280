import React from 'react'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

const Wrap = styled.div<{ $size: number }>`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`

const Circle = styled.div<{ $color: string; $size: number }>`
  position: absolute;
  width: ${({ $size }) => $size / 2}px;
  height: ${({ $size }) => $size / 2}px;
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  pointer-events: none;
`

const Ring = styled.div<{ $color: string; $size: number }>`
  position: absolute;
  border: ${({ $size }) => $size / 12}px solid ${({ $color }) => $color};
  border-radius: 30px;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  animation: pulsate 1.2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  pointer-events: none;

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
`

export function PulsatingDot({
  color = Colors.green300,
  size = 24,
  ...props
}: {
  color?: string
  size?: number
}): JSX.Element {
  return (
    <Wrap $size={size} {...props}>
      <Ring $color={color} $size={size} />
      <Circle $color={color} $size={size} />
    </Wrap>
  )
}
