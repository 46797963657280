import React from 'react'
import styled from 'styled-components'

import { Typography } from 'antd'

import { usePatientStore } from '../../../../stores/patient'
import { usePatientsStore } from '../../../../stores/patients'
import { useSitesStore } from '../../../../stores/sites'
import { useUserStore } from '../../../../stores/user'
import { getQuickInfoLabel, getQuickInfoValue } from './helpers'

const InfoGrid = styled.div`
  align-self: flex-start;
  display: flex;
  gap: 22px;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
`
const Label = styled(Typography.Text)`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
`
const Value = styled(Typography.Text)`
  font-size: 14px;
  line-height: 1.2;
`

export function QuickInfo(): JSX.Element | null {
  const [patient] = usePatientStore((state) => [state.patient])
  const [patients] = usePatientsStore((state) => [state.patients])
  const [sites] = useSitesStore((state) => [state.sites])
  const [patientQuickInfo] = useUserStore((state) => [state.patientQuickInfo])
  const lastOnboardedAt = patients.find((patients) => patients.id === patient?.id)?.lastOnboardedAt
  const site = patient && sites?.find((site) => site.key === patient.site)

  if (!patient) return null
  return (
    <InfoGrid>
      {patientQuickInfo.map((label) => (
        <Info key={label}>
          <Label>{getQuickInfoLabel(label)}</Label>
          <Value>{getQuickInfoValue(patient, label, site, lastOnboardedAt)}</Value>
        </Info>
      ))}
    </InfoGrid>
  )
}
