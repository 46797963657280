import {
  CardDetails,
  GenericValueEvent,
  RiskAlertConfig,
  SummaryIntervalEnum,
  SummaryItem,
  TrackingAlertConfig,
  TrackingTypeEnum,
} from '@vetahealth/tuna-can-api'

export enum CallbackType {
  onTokenExpired = 'onTokenExpired',
  onTokenRetrieved = 'onTokenRetrieved',
  onInfoRetrieved = 'onInfoRetrieved',
}

export type Callback<Payload = unknown> = (payload: Payload) => void

export interface UpdateRequest<Values> {
  id: string
  values: Values
}

export interface ApiError {
  status: number
  errorName: string
  error?: unknown
}

export interface AssignIdConflictError extends ApiError {
  error: {
    userId: string
  }
}

export interface AddEventRequest {
  id: string
  genericValueEvent: GenericValueEvent
}

export interface GetPatientResultDetailsRequest {
  id: string
  type?: string
  since?: string
}

export interface GetPatientResultSummaryRequest {
  id: string
  summaryInterval: SummaryIntervalEnum
}

export interface DeleteEventRequest {
  id: string
  eventId: number
}

export interface GetBillingRequest {
  site: string
  month: number
  year: number
  publish: boolean
  outputFormat?: string
}

export interface Conflict {
  field: string
  value: string
}

export interface GetPatientActivityRequest {
  id: string
  limit: number
  offset: number
}

export type SummaryDetail = Omit<SummaryItem, 'type'> & { type: TrackingTypeEnum }

export const isSummaryDetail = (item: CardDetails | SummaryItem): item is SummaryDetail =>
  Object.values<string>(TrackingTypeEnum).includes(item.type)

export type AlertConfig = TrackingAlertConfig | RiskAlertConfig
