import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { message } from 'antd'

import { Spinner } from '../../components/Spinner'
import { API } from '../../lib/api'
import { routes } from './index'

export function ReportDownload(): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(true)
  const { year, month, site, format } = useParams<{ year: string; month: string; site: string; format: string }>()
  const { t } = useTranslation()

  useEffect(() => {
    if (year !== undefined && month !== undefined && site !== undefined) {
      API.getBilling({
        site: site,
        month: +month,
        year: +year,
        publish: false,
        outputFormat: format,
      }).then((response) => {
        if (response instanceof ArrayBuffer) {
          const reportDate = dayjs(new Date(+year, +month - 1))
          const filename = [site, reportDate.format('YYYY'), reportDate.format('MMMM')].join(' - ')
          fileDownload(response, `${filename}.zip`)
          void message.success(t('message.downloadTriggered'))
        } else {
          void message.error({ content: t('error.generic'), key: 'errorGeneric' })
        }
        setLoading(false)
      })
    }
  }, [])

  return isLoading ? <Spinner /> : <Navigate to={routes.root()} />
}
