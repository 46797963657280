import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { Site } from '@vetahealth/tuna-can-api'
import { Button, Flex, Input, Modal, Popconfirm, Select, message } from 'antd'
import dayjs from 'dayjs'
import { orderBy } from 'lodash-es'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { usePatientStore } from '../../../../stores/patient'
import { usePatientsStore } from '../../../../stores/patients'
import { useSitesStore } from '../../../../stores/sites'
import { ThreeColumns } from '../../../Forms/styles'
import { SubTitle } from '../../styles'

export function Snapshots({ site }: { site?: Site }): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()
  const [isConfirmFreezeOpen, setIsConfirmFreezeOpen] = useState(false)
  const [snapshotLabel, setSnapshotLabel] = useState('')
  const [selectedSnapshot, setSelectedSnapshot] = useState<string>()
  const [freezeSite, unfreezeSite, deleteSnapshot] = useSitesStore((state) => [
    state.freezeSite,
    state.unfreezeSite,
    state.deleteSnapshot,
  ])
  const [resetPatients] = usePatientsStore((state) => [state.reset])
  const [resetPatient] = usePatientStore((state) => [state.reset])

  const snapshotOptions = orderBy(site?.snapshots ?? [], 'createdAt', 'desc').map((snapshot) => ({
    value: snapshot.id,
    label: t('widgets.siteManagement.snapshots.snapshotLabel', {
      id: snapshot.id,
      name: snapshot.name,
      date: dayjs(snapshot.createdAt).format('L LT'),
      count: snapshot.numPatients,
    }),
  }))

  const handleFreezeSite = async (): Promise<void> => {
    setIsConfirmFreezeOpen(false)
    if (!site) return
    const success = await withLoading(freezeSite(site.key, snapshotLabel || undefined, selectedSnapshot || undefined))
    if (success) {
      message.success(t('message.siteFreeze'))
    }
    setSnapshotLabel('')
    setSelectedSnapshot(undefined)
  }

  const handleUnfreezeSite = async (): Promise<void> => {
    if (!site || !selectedSnapshot) return
    const success = await withLoading(unfreezeSite(site.key, selectedSnapshot))
    if (success) {
      message.success(t('message.siteUnfreeze'))
      resetPatients()
      resetPatient()
      setSelectedSnapshot(undefined)
    }
  }

  const handleDeleteSnapshot = async (): Promise<void> => {
    if (!site || !selectedSnapshot) return
    const success = await deleteSnapshot(site.key, selectedSnapshot)
    if (success) {
      message.success(t('message.snapshotDelete'))
    }
    setSelectedSnapshot(undefined)
  }
  return (
    <Flex gap="middle" vertical align="start">
      <Button
        onClick={() => setIsConfirmFreezeOpen(true)}
        loading={isLoading && !selectedSnapshot}
        disabled={isLoading}
      >
        {t('widgets.siteManagement.snapshots.createSnapshot')}
      </Button>
      <ThreeColumns style={{ marginTop: 12 }}>
        <Select
          value={selectedSnapshot}
          options={snapshotOptions}
          onSelect={setSelectedSnapshot}
          placeholder={t('widgets.siteManagement.snapshots.selectPlaceholder')}
        />
        <Flex gap="middle">
          <Popconfirm
            title={t('widgets.siteManagement.snapshots.confirmUpdate')}
            onConfirm={handleFreezeSite}
            icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
          >
            <Button disabled={!selectedSnapshot || isLoading}>{t('actions.update')}</Button>
          </Popconfirm>
          <Popconfirm
            title={t('widgets.siteManagement.snapshots.confirmUnfreeze')}
            onConfirm={handleUnfreezeSite}
            icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
          >
            <Button disabled={!selectedSnapshot || isLoading}>{t('actions.restore')}</Button>
          </Popconfirm>
          <Popconfirm
            title={t('widgets.siteManagement.snapshots.confirmDelete')}
            onConfirm={handleDeleteSnapshot}
            icon={<FontAwesomeIcon icon={faCircleInfo} color={Colors.apricot600} />}
          >
            <Button danger disabled={!selectedSnapshot || isLoading}>
              {t('actions.delete')}
            </Button>
          </Popconfirm>
          <Modal
            open={isConfirmFreezeOpen}
            onOk={handleFreezeSite}
            onCancel={() => setIsConfirmFreezeOpen(false)}
            closeIcon={false}
            centered
          >
            <SubTitle>{t('widgets.siteManagement.snapshots.createSnapshot')}</SubTitle>
            <Input
              value={snapshotLabel}
              onChange={(e) => setSnapshotLabel(e.target.value)}
              placeholder={t('widgets.siteManagement.snapshots.optionalLabel')}
            />
          </Modal>
        </Flex>
      </ThreeColumns>
    </Flex>
  )
}
