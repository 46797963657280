import { Button, Modal, Typography, message } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '../../../lib/hooks/useLoading'
import { usePatientsStore } from '../../../stores/patients'

interface ConfirmDeIdentifyModalProps {
  patientIds: string[]
  hasSelection: boolean
  isVisible: boolean
  onCancel: () => void
}

export function ConfirmDeIdentifyModal({
  patientIds,
  hasSelection,
  isVisible,
  onCancel,
}: ConfirmDeIdentifyModalProps): JSX.Element {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const deIdentifyPatients = usePatientsStore((state) => state.deIdentifyPatients)

  const handleSubmit = async (): Promise<void> => {
    const success = await withLoading(deIdentifyPatients(patientIds))
    if (success) message.success(t('message.deIdentified'))
  }

  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      title={t('menus.main.helpModal.subTitle')}
      destroyOnClose
      centered
      footer={
        <Button danger loading={loading} disabled={loading} onClick={handleSubmit}>
          {t('actions.deIdentify', {
            context: hasSelection && patientIds.length ? 'selected' : 'unselected',
            count: patientIds.length,
          })}
        </Button>
      }
    >
      <Typography.Paragraph>{t('widgets.patient.confirmDeIdentify')}</Typography.Paragraph>
    </Modal>
  )
}
