import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import {
  DomainTuple,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory'

import { ToolTip } from './ToolTip'
import { getAggregationLabel, getBarIntervalData, hasUnreviewedAlerts } from './helpers'
import { HoverLine, Wrapper, chartPadding } from './styles'
import { getTheme } from './theme'
import { ChartProps, LabelFormatterParams } from './types'

export function BarChart({
  data,
  dataKeys,
  intervalDates,
  intervalType,
  alerts,
  onChangeAggregationLabel,
  getToolTipData,
}: ChartProps): JSX.Element {
  const theme = useTheme()
  const [width, setWidth] = useState<number | undefined>()
  const ref = useRef<HTMLDivElement>(null)

  const { intervalData, ticks, formatter, y, aggregationInterval } = useMemo(() => {
    return getBarIntervalData({ data, dataKeys, intervalDates, intervalType })
  }, [data, dataKeys, intervalDates, intervalType])

  function handleChartWidth(): void {
    if (ref) setWidth(ref.current?.getBoundingClientRect().width)
  }

  function getDomain(): { y: DomainTuple } {
    if (Object.values(intervalData).length === 0) {
      return y.all[0] === y.all[1] ? { y: [Number(y.all[0]) * 0.9, Number(y.all[1]) * 1.1] } : { y: y.all }
    }

    return y.interval[0] === y.interval[1]
      ? { y: [Number(y.interval[0]) * 0.9, Number(y.interval[1]) * 1.1] }
      : { y: y.interval }
  }

  useEffect(() => {
    onChangeAggregationLabel(getAggregationLabel(aggregationInterval))
  }, [aggregationInterval])

  useEffect(() => {
    handleChartWidth()
    window.addEventListener('resize', handleChartWidth)
    return () => window.removeEventListener('resize', handleChartWidth)
  }, [])

  return (
    <Wrapper ref={ref}>
      <VictoryChart
        padding={{ ...chartPadding, left: 40 }}
        domainPadding={{ x: 20, y: 10 }}
        width={width}
        theme={getTheme(theme)}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiBlacklist={intervalData[0]?.map((_, index) => `bar-${index}`)}
            voronoiDimension="x"
            labelComponent={
              <ToolTip
                getToolTipData={getToolTipData}
                aggregationInterval={aggregationInterval}
                alerts={alerts}
                chartType="bar"
              />
            }
            labels={() => ' '} // https://github.com/FormidableLabs/victory/issues/1805#issuecomment-802179487
          />
        }
      >
        <VictoryAxis tickValues={ticks} tickFormat={formatter} />

        <VictoryAxis
          dependentAxis
          domain={getDomain()}
          tickFormat={(value: number) =>
            Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits: 1,
            })
              .format(value)
              .toLowerCase()
          }
        />

        {dataKeys.map(({ color }, dataKeyIndex) => {
          return (intervalData[dataKeyIndex] || []).map((item, index) => {
            return (
              <VictoryGroup key={index} data={[item]}>
                <VictoryBar
                  name={`bar-${index}`}
                  barWidth={8}
                  cornerRadius={{ top: 4, bottom: 4 }}
                  style={{
                    data: {
                      fill: ({ datum: { ids } }: LabelFormatterParams) =>
                        hasUnreviewedAlerts(alerts, ids) ? theme.concerningActive : color,
                    },
                  }}
                />
                <VictoryScatter name={`scatterLine-${index}`} dataComponent={<HoverLine />} />
              </VictoryGroup>
            )
          })
        })}
      </VictoryChart>
    </Wrapper>
  )
}
