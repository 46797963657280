import { faSliders } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PatientQuickInfoEnum } from '@vetahealth/tuna-can-api'
import { Button, Dropdown, Modal, Tooltip, Typography, message } from 'antd'
import { isEqual } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useUserStore } from '../../../../stores/user'
import { SortableList } from '../../../SortableList'
import { SortableListItem } from '../../../SortableList/types'
import { getQuickInfoLabel } from './helpers'

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`

const Description = styled(Typography.Paragraph)`
  font-size: 13px;
  opacity: 0.5;
  margin-bottom: 12px;
`

export function EditQuickInfo(): JSX.Element | null {
  const { t } = useTranslation()
  const [patientQuickInfo, updateQuickInfo] = useUserStore((state) => [state.patientQuickInfo, state.updateQuickInfo])
  const [selectedQuickInfo, setSelectedQuickInfo] = useState<PatientQuickInfoEnum[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const sortableListItems: SortableListItem[] = selectedQuickInfo.map((label) => ({
    id: label,
    label: getQuickInfoLabel(label),
  }))
  const availableLabels = Object.values(PatientQuickInfoEnum)
    .filter((label) => !selectedQuickInfo.includes(label))
    .map((label) => ({
      key: label,
      label: getQuickInfoLabel(label),
    }))

  const handleSubmit = async (): Promise<void> => {
    const success = await updateQuickInfo(selectedQuickInfo)
    if (success) {
      setIsOpen(false)
      message.success(t('message.quickInfoUpdated'))
    }
  }

  const handleCancel = (): void => {
    setSelectedQuickInfo(patientQuickInfo)
    setIsOpen(false)
  }

  const handleAddLabel = ({ key: label }: { key: string }): void => {
    setSelectedQuickInfo([...selectedQuickInfo, label as PatientQuickInfoEnum])
  }

  const handleSortableListChange = (ids: string[]): void => {
    setSelectedQuickInfo(ids.map((id) => id as PatientQuickInfoEnum))
  }

  useEffect(() => {
    if (patientQuickInfo.length) setSelectedQuickInfo(patientQuickInfo)
  }, [patientQuickInfo])

  return (
    <>
      <Tooltip title={t('patient.manageQuickInfo')}>
        <Button shape="circle" onClick={() => setIsOpen(true)}>
          <FontAwesomeIcon icon={faSliders} />
        </Button>
      </Tooltip>
      <Modal
        title={t('patient.manageQuickInfo')}
        open={isOpen}
        onCancel={handleCancel}
        onOk={handleSubmit}
        okButtonProps={{ disabled: isEqual(patientQuickInfo, selectedQuickInfo) }}
        centered
      >
        <Description>{t('patient.quickInfoDescription')}</Description>
        <SortableList items={sortableListItems} onChange={handleSortableListChange} />
        <Dropdown
          menu={{
            items: availableLabels,
            onClick: handleAddLabel,
          }}
          trigger={['click']}
        >
          <ButtonWrap>
            <Button type="link" disabled={!availableLabels.length}>
              {t('patient.addQuickInfo')}
            </Button>
          </ButtonWrap>
        </Dropdown>
      </Modal>
    </>
  )
}
