import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TrackingTypeEnum } from '@vetahealth/tuna-can-api'

import { Form, Input, Select, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'

import { SupportedLocale } from '../../lib/i18n'
import { getTrackingTitle } from '../Widgets/helpers'
import { SubTitle } from '../Widgets/styles'
import { labelFromLocale, unitsFromTrackingType } from './helpers'
import { Badge, BadgeLabel, ThreeColumns } from './styles'
import { FormKeys } from './types'

const DefaultLocale = styled.div`
  grid-column: 1 / 2;
`
const DefaultUnits = DefaultLocale

const Features = styled.div`
  grid-column: 3 / 4;
`
const Language = styled.div`
  grid-column: 2 / 3;
`

const trackingTypes = Object.values(TrackingTypeEnum)

export function SiteGeneralSettings({ form }: { form: FormInstance }): JSX.Element {
  const { t } = useTranslation()

  const { defaultUnits, supportedLocales } = form.getFieldsValue([FormKeys.DEFAULT_UNITS, FormKeys.SUPPORTED_LOCALES])

  function handleUnitChange(key: TrackingTypeEnum, value: string): void {
    const unitUpdate = { ...defaultUnits, [key]: value }
    form.setFieldValue(FormKeys.DEFAULT_UNITS, unitUpdate)
    form.submit()
  }

  return (
    <>
      <ThreeColumns>
        <Form.Item
          name={FormKeys.SHORT_NAME}
          label={t('form.shortName')}
          rules={[{ required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={FormKeys.NAME} label={t('form.name')} rules={[{ required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name={FormKeys.ARCHIVE_AFTER_INACTIVE_DAYS}
          label={t('form.archiveInactive')}
          rules={[{ required: true }]}
        >
          <Input type="number" min="0" />
        </Form.Item>
      </ThreeColumns>
      <ThreeColumns>
        <DefaultUnits>
          <SubTitle>{t('widgets.siteManagement.settings.units')}</SubTitle>
          <Form.Item
            label={t('form.defaultUnits')}
            extra={t('form.defaultUnitsDescription')}
            style={{ flex: 1, marginBottom: 0 }}
          >
            {trackingTypes.map((type: TrackingTypeEnum) => {
              const isActive = defaultUnits[type]
              return (
                <Badge key={type} $isActive={isActive}>
                  <BadgeLabel $isActive={isActive}>{getTrackingTitle(type)}</BadgeLabel>
                  <Form.Item name={[FormKeys.DEFAULT_UNITS, type]} style={{ flex: 1, marginBottom: 0 }}>
                    {defaultUnits[type]?.length ? (
                      <Select
                        onChange={(value) => handleUnitChange(type, value)}
                        value={defaultUnits[type]}
                        placeholder={t('validations.selectUnit')}
                        options={unitsFromTrackingType[type].map((unit) => ({
                          label: unit.replace('_', ' '),
                          value: unit,
                        }))}
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </Form.Item>
                </Badge>
              )
            })}
          </Form.Item>
        </DefaultUnits>
        <Language>
          <SubTitle>{t('widgets.siteManagement.settings.language')}</SubTitle>
          <Form.Item
            name={FormKeys.SUPPORTED_LOCALES}
            label={t('form.supportedLanguages')}
            rules={[{ required: true, message: t('validations.selectLanguage') }]}
          >
            <Select
              mode="multiple"
              placeholder={t('form.supportedLanguages')}
              options={Object.values(SupportedLocale)
                .filter((locale) => locale !== SupportedLocale.DE_DE)
                .map((locale) => ({
                  value: locale,
                  label: labelFromLocale[locale],
                }))}
            />
          </Form.Item>
          <DefaultLocale>
            <Form.Item name={FormKeys.DEFAULT_LOCALE} label={t('form.defaultLanguage')}>
              <Select
                placeholder={t('form.defaultLanguage')}
                options={supportedLocales.map((locale: SupportedLocale) => ({
                  value: locale,
                  label: labelFromLocale[locale],
                }))}
              />
            </Form.Item>
          </DefaultLocale>
        </Language>
        <Features>
          <SubTitle>{t('widgets.siteManagement.settings.features')}</SubTitle>
          <Form.Item
            name={FormKeys.IS_DEMO}
            label={t('form.demo')}
            extra={t('form.demoDescription')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name={FormKeys.HAS_MESSAGING}
            label={t('form.messaging')}
            extra={t('form.messagingDescription')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name={FormKeys.HAS_CUSTOMER_IO}
            label={t('form.customerIo')}
            extra={t('form.customerIoDescription')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name={FormKeys.IS_MANAGED_BY_VETA}
            label={t('form.managedByVeta')}
            extra={t('form.managedByVetaDescription')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Features>
      </ThreeColumns>
    </>
  )
}
