import React, { useEffect } from 'react'

import { PatientList as PopulationPatientList } from '../../components/Widgets/Population'
import { usePatientStore } from '../../stores/patient'
import { GenericWrapper } from './styles'

export function PatientList(): JSX.Element {
  const resetPatient = usePatientStore((state) => state.reset)

  useEffect(() => {
    resetPatient()
  }, [])

  return (
    <GenericWrapper>
      <PopulationPatientList />
    </GenericWrapper>
  )
}
