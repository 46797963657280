export const countryNames: Array<{ name: string; iso: string }> = [
  {
    name: 'Canada',
    iso: 'CA',
  },
  {
    name: 'Chile',
    iso: 'CL',
  },
  {
    name: 'Colombia',
    iso: 'CO',
  },
  {
    name: 'Finland',
    iso: 'FI',
  },
  {
    name: 'France',
    iso: 'FR',
  },
  {
    name: 'Germany',
    iso: 'DE',
  },
  {
    name: 'Ireland',
    iso: 'IE',
  },
  {
    name: 'Mexico',
    iso: 'MX',
  },
  {
    name: 'Netherlands',
    iso: 'NL',
  },
  {
    name: 'Switzerland',
    iso: 'CH',
  },
  {
    name: 'United Kingdom',
    iso: 'GB',
  },
  {
    name: 'United States of America',
    iso: 'US',
  },
]
