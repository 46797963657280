import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { PopulationView } from '@vetahealth/tuna-can-api'

import { Button, Tooltip, message } from 'antd'

import { PopulationViewState } from '../../../lib/PopulationViewState'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { usePatientsStore } from '../../../stores/patients'
import { useUserStore } from '../../../stores/user'
import { ViewModal } from './ViewModal'
import { ViewSelector } from './ViewSelector'
import { AlertLevel, evaluateView } from './views'

const Wrapper = styled.div`
  display: flex;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  > * {
    margin: 0 8px 8px 0;
  }
`

const defaultQueryString = new PopulationViewState('').toQueryString(true)

export function ViewBar({ currentSelectionCount }: { currentSelectionCount: number }): JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()
  const [showViewModal, setShowViewModal] = useState(false)
  const [selectedViewIndex, setSelectedViewIndex] = useState(-1)
  const { t } = useTranslation()
  const [views, updateViews] = useUserStore((state) => [state.views, state.updateViews])
  const patients = usePatientsStore((state) => state.patients)
  const currentQueryString = new PopulationViewState(location.search).toQueryString(true)

  const evaluatedViews = views.map((view) => evaluateView(patients, view))

  const viewIndex = evaluatedViews.findIndex(({ queryString }) => queryString === currentQueryString)
  if (viewIndex > -1 && viewIndex !== selectedViewIndex) setSelectedViewIndex(viewIndex)
  const isModified = viewIndex === -1 && selectedViewIndex > -1
  const isUnsaved = viewIndex === -1 && selectedViewIndex === -1 && currentQueryString !== defaultQueryString

  function handleClick(index: number): void {
    if (index === -1) {
      // all patients view
      setSelectedViewIndex(-1)
      navigate(location.pathname)
      return
    }

    if (evaluatedViews[index].queryString === currentQueryString) return

    navigate(location.pathname + evaluatedViews[index].queryString)
  }

  async function handleSubmit(view: PopulationView): Promise<void> {
    trackEvent(TrackingEvent.patientListFilterUpdateClicked)

    const updatedViews = [...views]

    if (selectedViewIndex === -1) {
      updatedViews.push(view)
    } else {
      updatedViews.splice(selectedViewIndex, 1, view)
    }

    const messageKey = 'upsertView'
    message.loading({ content: t('message.updating'), key: messageKey, duration: 0 })

    const success = await updateViews(updatedViews)

    if (success) {
      message.success({
        content: selectedViewIndex > -1 ? t('message.viewUpdated') : t('message.viewCreated'),
        key: messageKey,
      })
    } else {
      message.destroy(messageKey)
    }

    setShowViewModal(false)
  }

  async function handleRemove(): Promise<void> {
    if (selectedViewIndex === -1) return
    const messageKey = 'deleteView'
    message.loading({ content: t('message.deleting'), key: messageKey, duration: 0 })
    const updatedViews = [...views]
    updatedViews.splice(selectedViewIndex, 1)
    const success = await updateViews(updatedViews)
    if (success) {
      message.success({ content: t('message.viewDeleted'), key: messageKey })
      setSelectedViewIndex(-1)
    } else {
      message.destroy(messageKey)
    }
    setShowViewModal(false)
  }

  function handleClickAdd(): void {
    trackEvent(TrackingEvent.patientListFilterAddClicked)
    setSelectedViewIndex(-1)
    setShowViewModal(true)
  }

  return (
    <Wrapper>
      <ItemWrapper>
        {evaluatedViews.map(({ count, label, tooltip, alertLevel, queryString }, index) => (
          <ViewSelector
            key={queryString}
            count={index === selectedViewIndex && isModified ? currentSelectionCount : count}
            label={label}
            tooltip={tooltip}
            alertLevel={alertLevel}
            isActive={index === selectedViewIndex}
            isModified={isModified}
            onClick={() => handleClick(index)}
            onEdit={() => setShowViewModal(true)}
            onUpdate={() => handleSubmit({ ...views[index], queryString: currentQueryString })}
          />
        ))}
        <ViewSelector
          key={'all_patients'}
          count={patients.length}
          label={t('widgets.population.all')}
          tooltip={t('widgets.population.allDescription')}
          alertLevel={AlertLevel.default}
          isActive={currentQueryString === defaultQueryString}
          isModified={false}
          onClick={() => handleClick(-1)}
        />
        <Tooltip
          title={
            isModified || isUnsaved ? t('widgets.population.saveAsNewView') : t('widgets.population.addViewDescription')
          }
          mouseEnterDelay={0.5}
          open={showViewModal ? false : undefined}
        >
          <Button onClick={handleClickAdd} type="primary" disabled={!(isModified || isUnsaved)}>
            {t('actions.addView')}
          </Button>
        </Tooltip>
        <ViewModal
          view={selectedViewIndex > -1 ? views[selectedViewIndex] : undefined}
          isVisible={showViewModal}
          currentQueryString={currentQueryString}
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          onCancel={() => setShowViewModal(false)}
        />
      </ItemWrapper>
    </Wrapper>
  )
}
