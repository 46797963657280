import { Form } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useRolesStore } from '../../../stores/roles'
import { Card } from '../styles'
import { PermissionTable } from './PermissionTable'
import { convertToFormValues, convertToRole, isRoleUpdate } from './helpers'
import { Wrap } from './styles'
import { RoleTableFormValues } from './types'

export const Roles = () => {
  const [form] = Form.useForm()
  const [roles, getRoles, createRole, updateRole, deleteRole] = useRolesStore((state) => [
    state.roles,
    state.getRoles,
    state.createRole,
    state.updateRole,
    state.deleteRole,
  ])

  const formValues = useMemo(() => {
    return { roles: convertToFormValues(roles) }
  }, [roles])

  async function handleSubmit(formValues: RoleTableFormValues) {
    const role = convertToRole(formValues)
    if (isRoleUpdate(role)) {
      await updateRole(role)
    } else {
      await createRole(role)
    }
  }

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [form, formValues])

  getRoles()

  return (
    <Card>
      <Wrap>
        <Form form={form} initialValues={formValues}>
          <Form.List name="roles">
            {(roles, { add, remove }) => (
              <PermissionTable
                form={form}
                roles={roles}
                onAddRole={add}
                onRemoveRole={remove}
                onSubmit={handleSubmit}
                onDelete={deleteRole}
              />
            )}
          </Form.List>
        </Form>
      </Wrap>
    </Card>
  )
}
