import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'antd'

import { routes } from '../Router/routes'
import { NotFound as NotFoundSvg } from './Svg/NotFound'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`

export function NotFound(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Wrapper>
      <NotFoundSvg style={{ marginBottom: '20px' }} width="50%" height="50%" aria-label={t('images.notFound')} />
      <Button type="primary" onClick={() => navigate(routes.patientList())}>
        {t('actions.notFound')}
      </Button>
    </Wrapper>
  )
}
