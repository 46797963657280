import React, { useEffect } from 'react'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { useNavigate } from 'react-router'
import { routes } from '.'
import { Dashboard as AnalyticsComponent } from '../../components/Widgets/Analytics'
import { useUserStore } from '../../stores/user'

export function Analytics(): JSX.Element {
  const navigate = useNavigate()
  const [hasSomePermission] = useUserStore((state) => [state.hasSomePermission])

  useEffect(() => {
    if (!hasSomePermission(PermissionName.accessAnalytics)) {
      navigate(routes.patientList(), { replace: true })
      return
    }
  }, [])

  return <AnalyticsComponent />
}
