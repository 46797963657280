import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faWarning } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  CareStateEnum,
  CareStateTemplate,
  CareTaskTemplate,
  ConditionEnum,
  ProgramTemplate as IProgramTemplate,
  ToDoTemplate,
  TrackingTypeEnum,
} from '@vetahealth/tuna-can-api'
import { Button, Dropdown, Form, Popconfirm, Select, Switch, message } from 'antd'
import { keyBy } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AlertConfig } from '../../../../lib/api'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { useSitesStore } from '../../../../stores/sites'
import { Alerts, TrackingAlertWizard } from '../../../Alerts'
import { CareTasks } from '../../../CareTasks'
import { CareTaskTemplateWizard } from '../../../CareTasks/CareTaskTemplateWizard'
import { FormKeys } from '../../../Forms'
import { getConditionNames } from '../../../Forms/helpers'
import { LabelWithDescription, ThreeColumns } from '../../../Forms/styles'
import { SortableList } from '../../../SortableList'
import { ToDos } from '../../../ToDos'
import { ToDoTemplateWizard } from '../../../ToDos/Wizard'
import { getCareStateNames, getTrackingTitle } from '../../helpers'
import { HeadingFive, Line, SubTitle } from '../../styles'

const Flex = styled.div`
  display: flex;
  flex-direction: row;
`
const ProgramDescription = styled.p`
  opacity: 0.6;
  max-width: 60%;
  margin: 0 0 30px;
`
const Section = styled.div`
  padding-bottom: 16px;
  &:last-child {
      padding-bottom: 0;
    }
`
const Left = styled.div`
  flex: 50%;
  padding-right: 30px;
  border-right: 1px solid ${({ theme }) => theme.badgeBackground};
`
const Right = styled.div`
  flex: 50%;
  padding: 0 20px 0 30px;
`
const HeadingWrap = styled.div`
  display: flex;
  gap: 10px;
`
const CareTaskWrap = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  flex-wrap: wrap;
`
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`

type UpdateProgramTemplateFormValues = {
  [FormKeys.ENABLE_MEASUREMENT_RESPONSES]?: boolean
  [FormKeys.ENABLE_BILLING]?: boolean
  [FormKeys.DISPATCH_MESSAGING]?: boolean
  [FormKeys.DEFAULT_TRACKING_TYPES]?: TrackingTypeEnum[]
  [FormKeys.DEFAULT_CONDITIONS]?: ConditionEnum[]
  [FormKeys.CARE_STATE_TEMPLATES]?: CareStateTemplate[]
}

export function ProgramTemplate({
  program,
  siteKey,
  isEditable,
}: { program: IProgramTemplate; siteKey: string; isEditable: boolean }): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const conditionNames = getConditionNames()
  const [isLoadingProgramUpdate, withLoadingProgramUpdate] = useLoading()
  const careStateTemplatesByName = keyBy(program.careStateTemplates, 'name')
  const availableCareStates = Object.values(CareStateEnum)
    .filter((careState) => !careStateTemplatesByName[careState])
    .map((careState) => ({
      key: careState,
      label: getCareStateNames()[careState],
    }))

  const [
    updateProgramTemplate,
    deleteProgramTemplate,
    updateToDoTemplate,
    deleteToDoTemplate,
    updateAlertConfigTemplate,
    deleteAlertConfigTemplate,
  ] = useSitesStore((state) => [
    state.updateProgramTemplate,
    state.deleteProgramTemplate,
    state.updateToDoTemplate,
    state.deleteToDoTemplate,
    state.updateAlertConfigTemplate,
    state.deleteAlertConfigTemplate,
  ])

  const handleUpdateProgram = async (values: UpdateProgramTemplateFormValues): Promise<boolean> => {
    const success = await withLoadingProgramUpdate(updateProgramTemplate(siteKey, program.id, values))
    const messageKey = 'programUpdate'

    if (success) {
      message.success({ content: t('message.programTemplateUpdate'), key: messageKey })
      return true
    }
    return false
  }

  const handleDeleteProgram = async (): Promise<void> => {
    const success = await deleteProgramTemplate(siteKey, program.id)

    if (success) message.success(t('message.programDelete'))
  }

  const handleCreateToDoTemplate = async (toDoTemplate: ToDoTemplate): Promise<boolean> => {
    return await updateToDoTemplate({
      siteKey,
      programTemplateId: program.id,
      updateIndex: program.toDos?.length ?? 0,
      update: toDoTemplate,
    })
  }

  const handleUpdateToDoTemplate = async (updateIndex: number, updatedToDoTemplate: ToDoTemplate): Promise<boolean> => {
    return await updateToDoTemplate({
      siteKey,
      programTemplateId: program.id,
      updateIndex,
      update: updatedToDoTemplate,
    })
  }

  const handleDeleteToDoTemplate = async (deleteIndex: number): Promise<boolean> => {
    return await deleteToDoTemplate({
      siteKey,
      programTemplateId: program.id,
      deleteIndex,
    })
  }

  const handleCreateAlertConfigTemplate = async (
    alertConfig: AlertConfig,
    applyToEnrolled: boolean,
  ): Promise<boolean> => {
    return await updateAlertConfigTemplate({
      siteKey,
      programTemplateId: program.id,
      updateIndex: program.alertConfig?.length ?? 0,
      update: alertConfig,
      applyToEnrolled,
    })
  }

  const handleUpdateAlertConfigTemplate = async (
    updateIndex: number,
    updatedAlertConfig: AlertConfig,
    applyToEnrolled: boolean,
  ): Promise<boolean> => {
    return await updateAlertConfigTemplate({
      siteKey,
      programTemplateId: program.id,
      updateIndex,
      update: updatedAlertConfig,
      applyToEnrolled,
    })
  }

  const handleDeleteAlertConfigTemplate = async (deleteIndex: number, applyToEnrolled: boolean): Promise<boolean> => {
    return await deleteAlertConfigTemplate({
      siteKey,
      programTemplateId: program.id,
      deleteIndex,
      applyToEnrolled,
    })
  }

  const handleAddCareState = async ({ key }: { key: string }): Promise<void> => {
    handleUpdateProgram({
      careStateTemplates: [...program.careStateTemplates, { name: key as CareStateEnum, tasks: [] }],
    })
  }

  const handleSortableListChange = (items: string[]): void => {
    handleUpdateProgram({ careStateTemplates: items.map((item) => careStateTemplatesByName[item]) })
  }

  const handleUpdateCareTaskTemplate = async (
    updateIndex: number,
    updatedTemplate: CareTaskTemplate,
    careState: CareStateEnum,
  ): Promise<boolean> => {
    const updatedCareStateTemplates = program.careStateTemplates.map((template) => {
      if (template.name === careState) {
        return {
          ...template,
          tasks: template.tasks.map((task, index) => (index === updateIndex ? updatedTemplate : task)),
        }
      }
      return template
    })

    return handleUpdateProgram({ careStateTemplates: updatedCareStateTemplates })
  }

  const handleDeleteCareTaskTemplate = async (updateIndex: number, careState: CareStateEnum): Promise<boolean> => {
    const updatedCareStateTemplates = program.careStateTemplates.map((template) => {
      if (template.name === careState) {
        return {
          ...template,
          tasks: template.tasks.filter((_, index) => index !== updateIndex),
        }
      }
      return template
    })
    return handleUpdateProgram({ careStateTemplates: updatedCareStateTemplates })
  }

  const handleCreateCareTaskTemplate = async (
    newTemplate: CareTaskTemplate,
    careState: CareStateEnum,
  ): Promise<boolean> => {
    const updatedCareStateTemplates = program.careStateTemplates.map((template) => {
      if (template.name === careState) {
        return { ...template, tasks: [...template.tasks, newTemplate] }
      }
      return template
    })
    return handleUpdateProgram({ careStateTemplates: updatedCareStateTemplates })
  }

  return (
    <>
      {isEditable && (
        <>
          <Section>
            <SubTitle>{t('widgets.siteManagement.settings.generalTitle')}</SubTitle>
            <Form
              form={form}
              layout="vertical"
              validateTrigger="onChange"
              onValuesChange={handleUpdateProgram}
              onFinish={handleUpdateProgram}
              initialValues={program}
              scrollToFirstError
            >
              <ThreeColumns>
                <Form.Item
                  name={FormKeys.ENABLE_MEASUREMENT_RESPONSES}
                  label={t('form.measurementResponses')}
                  extra={t('form.measurementResponsesDescription')}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={FormKeys.DISPATCH_MESSAGING}
                  label={t('form.dispatchMessaging')}
                  extra={t('form.dispatchMessagingDescription')}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={FormKeys.ENABLE_BILLING}
                  label={t('form.includeInBillingReport')}
                  extra={t('form.includeInBillingReportDescription')}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </ThreeColumns>
              <ThreeColumns>
                <Form.Item
                  name={FormKeys.DEFAULT_TRACKING_TYPES}
                  label={t('form.defaultTrackingTypes')}
                  extra={t('form.defaultTrackingTypesDescription')}
                >
                  <Select
                    mode="multiple"
                    placeholder={t('form.defaultTrackingTypes')}
                    options={Object.values(TrackingTypeEnum).map((type) => ({
                      value: type,
                      label: getTrackingTitle(type),
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  name={FormKeys.DEFAULT_CONDITIONS}
                  label={t('form.defaultConditions')}
                  extra={t('form.defaultConditionsDescription')}
                >
                  <Select
                    mode="multiple"
                    placeholder={t('form.defaultConditions')}
                    options={Object.values(ConditionEnum).map((condition) => ({
                      value: condition,
                      label: conditionNames[condition],
                    }))}
                  />
                </Form.Item>
                <div>
                  <Popconfirm
                    title={t('widgets.programs.deleteProgramConfirmation')}
                    icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
                    cancelButtonProps={{ type: 'text' }}
                    cancelText={t('actions.cancel')}
                    okButtonProps={{ danger: true }}
                    okText={t('actions.delete')}
                    onConfirm={handleDeleteProgram}
                  >
                    <Button icon={<FontAwesomeIcon icon={faTrash} color={Colors.ruby600} size="sm" />}>
                      {t('actions.delete')}
                    </Button>
                  </Popconfirm>
                </div>
              </ThreeColumns>
            </Form>
          </Section>
          <Section>
            <LabelWithDescription label={t('form.careState')} description={t('form.careStateDescription')} width={50} />
            <SortableList
              items={program.careStateTemplates.map(({ name: careState }) => ({
                id: careState,
                label: getCareStateNames()[careState],
                extra: (
                  <CareTaskWrap>
                    <CareTasks
                      careTasks={careStateTemplatesByName[careState].tasks}
                      onUpdate={(updateIndex, careTaskTemplate) =>
                        handleUpdateCareTaskTemplate(updateIndex, careTaskTemplate, careState)
                      }
                      onDelete={(updateIndex) => handleDeleteCareTaskTemplate(updateIndex, careState)}
                    />
                    <CareTaskTemplateWizard
                      onCreate={(careTaskTemplate) => handleCreateCareTaskTemplate(careTaskTemplate, careState)}
                      render={(openToDoTemplateWizard) => (
                        <Button shape="circle" size="small" onClick={openToDoTemplateWizard}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      )}
                    />
                  </CareTaskWrap>
                ),
              }))}
              onChange={handleSortableListChange}
              isDisabled={isLoadingProgramUpdate}
            />
            <Dropdown
              overlayClassName="dropdown-scrollable"
              menu={{
                items: availableCareStates,
                onClick: handleAddCareState,
              }}
              trigger={['click']}
            >
              <ButtonWrap>
                <Button type="link" disabled={!availableCareStates.length}>
                  {t('widgets.siteManagement.programs.addCareState')}
                </Button>
              </ButtonWrap>
            </Dropdown>
          </Section>
          <Line />
        </>
      )}
      <Section>
        <SubTitle>{t('widgets.siteManagement.programs.initialPatientSettings')}</SubTitle>
        {isEditable && (
          <ProgramDescription>
            {t('widgets.siteManagement.programs.initialPatientSettingsDescription')}
          </ProgramDescription>
        )}
        <Flex>
          <Left>
            <Flex>
              <HeadingFive>{t('form.todos')}</HeadingFive>
              {isEditable && (
                <ToDoTemplateWizard
                  onCreate={handleCreateToDoTemplate}
                  render={(openToDoTemplateWizard) => (
                    <Button
                      shape="circle"
                      size="small"
                      onClick={openToDoTemplateWizard}
                      style={{ marginLeft: '8px', marginTop: '-2px' }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  )}
                />
              )}
            </Flex>
            <ToDos
              toDos={program.toDos}
              onUpdate={handleUpdateToDoTemplate}
              onDelete={handleDeleteToDoTemplate}
              isEditable={isEditable}
            />
          </Left>
          <Right>
            <HeadingWrap>
              <HeadingFive>{t('form.alerts')}</HeadingFive>
              {isEditable && (
                <TrackingAlertWizard
                  onCreate={handleCreateAlertConfigTemplate}
                  showApplyToEnrolled
                  render={(openTrackingAlertWizard) => (
                    <Button style={{ marginTop: '-2px' }} shape="circle" size="small" onClick={openTrackingAlertWizard}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  )}
                />
              )}
            </HeadingWrap>
            <Alerts
              showApplyToEnrolled
              alertConfigs={program.alertConfig}
              onUpdate={handleUpdateAlertConfigTemplate}
              onDelete={handleDeleteAlertConfigTemplate}
              isEditable={isEditable}
            />
          </Right>
        </Flex>
      </Section>
    </>
  )
}
