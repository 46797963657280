import { ChatMessage, PatientOverviewItem } from '@vetahealth/tuna-can-api'
import { Typography } from 'antd'
import { isEmpty, orderBy } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from '../../Router/routes'
import { PatientDetailsTab } from '../../Router/routes/PatientDetails'
import { useChatStore } from '../../stores/chat'
import { usePatientsStore } from '../../stores/patients'
import { useUserStore } from '../../stores/user'
import { Message } from './Message'

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
`

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
`

const UnreadMessageWrap = styled.section`
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const UnreadMessageTitle = styled.div`
  opacity: 0.5;
  color: ${({ theme }) => theme.quaternaryBackground};
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBackground};
`

const MessageWrap = styled.div`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.chatHover};
  }
`

function UnreadMessage({
  title,
  messages,
  patients,
  onClick,
}: {
  title: string
  messages: ChatMessage[]
  patients: PatientOverviewItem[]
  onClick: (userId: string) => void
}): JSX.Element {
  return (
    <UnreadMessageWrap>
      <UnreadMessageTitle>
        <Typography.Text>{title}</Typography.Text>
      </UnreadMessageTitle>
      {orderBy(messages, ['createdAt'], ['desc']).map((message) => {
        const patient = patients.find((patient) => patient.id === message.userId)
        return (
          <MessageWrap key={message.id} onClick={() => onClick(message.userId)}>
            <Message patient={patient} message={message} isOverview />
          </MessageWrap>
        )
      })}
    </UnreadMessageWrap>
  )
}

export function UnreadMessages({ onClose }: { onClose: () => void }): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [patients] = usePatientsStore((state) => [state.patients])
  const [unreadChatMessages, setIsChatOpen] = useChatStore((state) => [state.unreadChatMessages, state.setIsChatOpen])
  const [userId] = useUserStore((state) => [state.userId])
  const subscribedPatientIds = patients
    .map((patient) => userId && patient.subscribers.includes(userId) && patient.id)
    .filter(Boolean)
  const subscribedMessages = unreadChatMessages.filter((message) => subscribedPatientIds.includes(message.userId))
  const otherMessages = unreadChatMessages.filter((message) => !subscribedPatientIds.includes(message.userId))

  function handleOnMessageClick(userId: string): void {
    navigate({
      pathname: routes.patientDetails(userId, PatientDetailsTab.results),
      search: location.search,
    })
    setIsChatOpen(true)
    onClose()
  }

  return (
    <Wrap>
      {isEmpty(unreadChatMessages) ? (
        t('chat.noNotifications')
      ) : (
        <ScrollContainer>
          {!isEmpty(subscribedMessages) && (
            <UnreadMessage
              title={t('chat.myPatients')}
              messages={subscribedMessages}
              patients={patients}
              onClick={handleOnMessageClick}
            />
          )}
          {!isEmpty(otherMessages) && (
            <UnreadMessage
              title={t('chat.otherPatients')}
              messages={otherMessages}
              patients={patients}
              onClick={handleOnMessageClick}
            />
          )}
        </ScrollContainer>
      )}
    </Wrap>
  )
}
