import { faLock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Form, Input, Typography } from 'antd'

const PasswordRequirementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 20px;
`
const PasswordRequirement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PasswordRequirementIndicator = styled(Typography.Text)`
  font-weight: bold;
  font-size: 13px;
`
const PasswordRequirementLabel = styled(Typography.Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.quaternaryBackground};
`

// number, uppercase, lowercase, minlength (8), special character
export function isValidPassword(password: string): boolean {
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&.,]).{8,}$/.test(password)
}

export function ChangePassword({
  password = '',
  onPasswordChange,
  isInitial = false,
}: {
  password?: string
  onPasswordChange: (password: string) => void
  isInitial?: boolean
}): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()

  const requirements: Array<{ indicator: string; label: string; isValid: boolean }> = [
    {
      indicator: '8+',
      label: t('auth.passwordLength'),
      isValid: password.length >= 8,
    },
    {
      indicator: 'AA',
      label: t('auth.passwordUpperCase'),
      isValid: /[A-Z]/.test(password),
    },
    {
      indicator: 'aa',
      label: t('auth.passwordLowerCase'),
      isValid: /[a-z]/.test(password),
    },
    {
      indicator: '123',
      label: t('auth.passwordNumbers'),
      isValid: /\d/.test(password),
    },
    {
      indicator: '@$!%*?&.,',
      label: t('auth.passwordSpecial'),
      isValid: /[@$!%*?&.,]/.test(password),
    },
  ]

  return (
    <>
      <Form.Item name="newPassword" rules={[{ required: true, message: t('validations.enterNewPassword') }]}>
        <Input.Password
          size="large"
          placeholder={isInitial ? t('placeholders.password') : t('placeholders.newPassword')}
          type="password"
          prefix={<FontAwesomeIcon icon={faLock} size="sm" />}
          onChange={(event) => onPasswordChange(event.target.value)}
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[
          { required: true, message: t('validations.enterConfirmPassword') },
          ({ getFieldValue }) => ({
            validator(_, confirmPassword) {
              return !confirmPassword || getFieldValue('newPassword') === confirmPassword
                ? Promise.resolve()
                : Promise.reject(new Error(t('validations.confirmPassword')))
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          placeholder={t('placeholders.confirmPassword')}
          type="password"
          prefix={<FontAwesomeIcon icon={faLock} size="sm" />}
        />
      </Form.Item>
      <PasswordRequirementWrapper>
        {requirements.map(({ indicator, label, isValid }) => (
          <PasswordRequirement key={label}>
            <PasswordRequirementIndicator style={{ color: isValid ? Colors.green500 : theme.quaternaryBackground }}>
              {indicator}
            </PasswordRequirementIndicator>
            <PasswordRequirementLabel>{label}</PasswordRequirementLabel>
          </PasswordRequirement>
        ))}
      </PasswordRequirementWrapper>
    </>
  )
}
