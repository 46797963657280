import { faChartLine, faRotateLeft, faSparkles } from '@fortawesome/pro-light-svg-icons'
import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { Button, Form, FormInstance, InputNumber, Popover, Space, Tooltip, message } from 'antd'
import dayjs from 'dayjs'
import { orderBy } from 'lodash-es'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { API } from '../../../lib/api'
import { useLoading } from '../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { useNotesStore } from '../../../stores/notes'
import { usePatientStore } from '../../../stores/patient'
import { useUserStore } from '../../../stores/user'
import { FormKeys, NoteFormValues } from '../../Forms'
import { getInsertedMeasurements } from './helpers'

const Actions = styled(Space.Compact)`
  position: absolute;
  z-index: 1;
  right: 8px;
  bottom: 36px;
  border-radius: 6px;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.06),
    0 1px 15px rgba(0, 0, 0, 0.08);
`

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

type MeasurementTrackingType = 'sinceLastNote' | 'oneWeek' | 'twoWeeks' | 'custom'

/**
 * Calculates the start of the day (in a given timezone) for a period from the last X days to now. Today is understood
 * to be the first (fractional) day of this period, so getStartOfPeriod(1) will return the start of today.
 * @param days
 * @param timeZone
 */
function getStartOfPeriod(days: number, timeZone?: string) {
  if (days < 1) return dayjs()
  return dayjs()
    .tz(timeZone)
    .subtract(days - 1, 'days')
    .startOf('day')
}

export function NoteActions({
  form,
  isEnhanceTouched,
  onEnhanceTouch,
}: {
  form: FormInstance<NoteFormValues>
  isEnhanceTouched: boolean
  onEnhanceTouch: (isEnhanceTouched: boolean) => void
}): JSX.Element {
  const { t } = useTranslation()
  const [vitalsForm] = Form.useForm()
  const [hasPermission, preferredUnitsByType] = useUserStore((state) => [state.hasPermission, state.units])
  const [enhanceLoading, withEnhanceLoading] = useLoading()
  const [measurementsLoading, withMeasurementsLoading] = useLoading()
  const [notes, enhanceNote] = useNotesStore((state) => [state.notes, state.enhanceNote])
  const [patient] = usePatientStore((state) => [state.patient])
  const [isInsertVitalsOpen, setOpenInsertVitals] = useState<boolean>(false)
  const hasText = !!Form.useWatch(FormKeys.TEXT, form)
  const currentDays = Form.useWatch(FormKeys.DAYS, vitalsForm)
  const lastNoteTimestamp = orderBy(notes, ['timestamp'], ['desc'])[0]?.timestamp

  async function handleEnhanceNote(): Promise<void> {
    trackEvent(TrackingEvent.notesEnhanceClicked)
    if (!patient) return
    const noteType = form.getFieldValue(FormKeys.NOTE_TYPE)
    const text = form.getFieldValue(FormKeys.TEXT)
    form.setFieldValue(FormKeys.TEXT_ORIGINAL, text)
    const enhancedNote = await withEnhanceLoading(enhanceNote({ id: patient.id, values: { text, type: noteType } }))

    if (enhancedNote) {
      form.setFieldsValue({
        [FormKeys.TEXT]: enhancedNote.text,
        [FormKeys.TEXT_ENHANCED]: enhancedNote.text,
      })
      onEnhanceTouch(true)
      message.success(t('message.noteEnhanced'))
    }
  }

  function handleRedoEnhanceNote(): void {
    const preEnhancedText = form.getFieldValue(FormKeys.TEXT_ORIGINAL)
    form.setFieldsValue({
      [FormKeys.TEXT]: preEnhancedText,
      [FormKeys.TEXT_ENHANCED]: undefined,
    })
    onEnhanceTouch(false)
  }

  async function handleInsertMeasurements(since: dayjs.Dayjs, type: MeasurementTrackingType): Promise<void> {
    const days = dayjs().diff(dayjs(since), 'days', type === 'sinceLastNote')
    trackEvent(TrackingEvent.notesInsertMeasurementsClicked, { days, type })

    if (!patient) return
    setOpenInsertVitals(false)
    const response = await withMeasurementsLoading(
      API.getTrackingEventsForEnhancing({
        id: patient.id,
        since: since.toISOString(),
      }),
    )
    if (response?.length) {
      const measurements = getInsertedMeasurements(response, preferredUnitsByType)
      const text = form.getFieldValue(FormKeys.TEXT)
      form.setFieldValue(FormKeys.TEXT, text ? `${text}\n\n${measurements}` : measurements)
      onEnhanceTouch(false)
    } else {
      message.info(t('message.noVitals'))
    }
  }

  return (
    <Actions>
      {hasPermission(PermissionName.testFeatures, patient?.site) &&
        (hasPermission(PermissionName.testFeatures, patient?.site) && isEnhanceTouched ? (
          <Tooltip title={t('widgets.notes.undoEnhance')}>
            <Button icon={<FontAwesomeIcon icon={faRotateLeft} />} onClick={handleRedoEnhanceNote} />
          </Tooltip>
        ) : (
          <Tooltip title={t('widgets.notes.enhance')} placement="bottom">
            <Button
              icon={<FontAwesomeIcon icon={faSparkles} />}
              onClick={handleEnhanceNote}
              loading={enhanceLoading}
              disabled={!hasText || measurementsLoading}
            />
          </Tooltip>
        ))}
      <Popover
        placement="topLeft"
        open={isInsertVitalsOpen}
        content={
          <ButtonWrap>
            <Button
              onClick={() =>
                handleInsertMeasurements(dayjs(lastNoteTimestamp ?? '1970-01-01T00:00:00Z'), 'sinceLastNote')
              }
            >
              {t('widgets.notes.sinceLastNote')}
            </Button>
            <Button onClick={() => handleInsertMeasurements(getStartOfPeriod(14, patient?.timeZone), 'twoWeeks')}>
              {t('widgets.notes.lastTwoWeeks')}
            </Button>
            <Button onClick={() => handleInsertMeasurements(getStartOfPeriod(7, patient?.timeZone), 'oneWeek')}>
              {t('widgets.notes.lastWeek')}
            </Button>
            <Form
              onFinish={({ days }) => handleInsertMeasurements(getStartOfPeriod(days, patient?.timeZone), 'custom')}
              form={vitalsForm}
              validateTrigger="onSubmit"
              initialValues={{ days: 1 }}
            >
              <Space.Compact>
                <Form.Item name={FormKeys.DAYS} noStyle>
                  <InputNumber
                    min={1}
                    precision={0}
                    style={{ maxWidth: '200px' }}
                    formatter={(i) => (i ? Math.round(i).toString() : '0')}
                    addonBefore={t('dateFormats.last', { count: currentDays })}
                    addonAfter={t('dateFormats.days', { count: currentDays })}
                  />
                </Form.Item>
                <Button type="primary" htmlType="submit" icon={<FontAwesomeIcon icon={faCheck} />} />
              </Space.Compact>
            </Form>
          </ButtonWrap>
        }
      >
        <Tooltip title={t('widgets.notes.insertVitals')} placement="bottom">
          <Button
            icon={<FontAwesomeIcon icon={isInsertVitalsOpen ? faClose : faChartLine} />}
            onClick={() => setOpenInsertVitals(!isInsertVitalsOpen)}
            loading={measurementsLoading}
            disabled={enhanceLoading}
          />
        </Tooltip>
      </Popover>
    </Actions>
  )
}
