import { useState } from 'react'

type UseLoadingWrapperFunc = <T>(promise: Promise<T>) => Promise<T>

export function useLoading(): [boolean, UseLoadingWrapperFunc] {
  const [loading, setLoading] = useState(0)
  const useLoadingWrapper: UseLoadingWrapperFunc = async (promise) => {
    setLoading((loading) => loading + 1)
    try {
      return await promise
    } finally {
      setLoading((loading) => loading - 1)
    }
  }
  return [loading > 0, useLoadingWrapper]
}
