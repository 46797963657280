import { Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import * as React from 'react'
import { Checkmark } from './styles'

interface EditableCellProps extends FormItemProps {
  readonly editing: boolean
}

interface DummyProps {
  value?: any
  checked?: any
}

const Dummy = ({ value, checked }: DummyProps) => {
  if (typeof checked === 'boolean' && checked) return <Checkmark />
  return <div>{value}</div>
}

export const EditableCell = ({ editing, children, ...rest }: EditableCellProps) => {
  return (
    <Form.Item noStyle {...rest}>
      {editing ? children : <Dummy />}
    </Form.Item>
  )
}
