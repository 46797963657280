import i18next from 'i18next'
import { jwtDecode } from 'jwt-decode'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { message } from 'antd'

import { router } from '../../Router/router'
import { routes } from '../../Router/routes'
import { PatientDetailsTab } from '../../Router/routes/PatientDetails'
import { API, isAxiosError } from '../../lib/api'
import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { ModalName, useModalStore } from '../modal'
import { usePatientsStore } from '../patients'
import { useUserStore } from '../user'
import { AuthState, AuthStep, SigninType } from './types'

const launchQuery = new URLSearchParams(window.location.search)
const isPendingEpicLaunch = launchQuery.has('launch') || launchQuery.has('code')

export const useAuthStore = createWithEqualityFn<AuthState>((set, get) => ({
  step: AuthStep.SIGNIN,
  setStep: (step) => set({ step, errorStatus: undefined }),
  resetPasswordUserName: undefined,
  isAuthorized: false,
  isInitialized: false,
  isPendingEpicLaunch,
  twoFactorId: undefined,
  errorStatus: undefined,
  trustToken: undefined,
  trustChallenge: undefined,
  epicImportData: undefined,
  handleSignin: async (signinData) => {
    const response = await API.signin(signinData)

    if (!response) return

    if (signinData.signinType === SigninType.SSO && signinData.token) {
      const { picture } = jwtDecode<{ picture?: string }>(signinData.token)
      useUserStore.setState({ avatarImageUrl: picture })
    }

    if (isAxiosError(response)) {
      const status = response.response?.status
      if (status === 401 && signinData.signinType === SigninType.SSO) {
        message.error({ content: i18next.t('message.noSSOAccount'), key: 'noSSOAccount' })
      }
      if (status && [404, 423].includes(status)) set({ errorStatus: status })
      return
    }

    if ('twoFactorId' in response) {
      return set({ twoFactorId: response?.twoFactorId, errorStatus: undefined, step: AuthStep.TWO_FACTOR_SIGNIN })
    }

    set({ isAuthorized: true })
  },
  handleEpicLaunch: async (data) => {
    const launchResult = await API.requestEpicReconcile(data)
    if (!launchResult) return

    if ('error' in launchResult) {
      if (launchResult.error === 'reconcileFailed')
        message.error({ content: i18next.t('message.epicFail'), duration: 30, key: 'errorEpicFail' })
      if (launchResult.error === 'unknownAccount')
        message.error({ content: i18next.t('message.epicUnknownAccount'), duration: 30, key: 'epicUnknownAccount' })

      router?.navigate(routes.root(), { replace: true })

      return
    }

    const { patientId, patientData } = launchResult

    trackEvent(TrackingEvent.authEpicSSOSuccess, { patientId })

    router?.navigate(patientId ? routes.patientDetails(patientId, PatientDetailsTab.results) : routes.root(), {
      replace: true,
    })

    const { epicUrl, accessToken, fhirPatientId, medicalRecordNumber } = data
    set({
      isAuthorized: true,
      isPendingEpicLaunch: false,
      epicImportData: fhirPatientId
        ? {
            patientId,
            patient: patientData,
            importBody: { epicUrl, accessToken, fhirPatientId, medicalRecordNumber },
          }
        : undefined,
    })
    if (fhirPatientId && patientData) useModalStore.getState().openModal(ModalName.epicImport)
  },
  handleEpicImportOrLink: async () => {
    const { epicImportData } = get()
    if (!epicImportData) return false

    const { importBody, patientId } = epicImportData
    if (importBody && !patientId) {
      // import patient into prosper care
      const patientId = await API.epicImport(importBody)
      if (patientId) {
        usePatientsStore.getState().expirePatients()
        router?.navigate(routes.patientDetails(patientId, PatientDetailsTab.demographics), { replace: true })
      }
    } else if (patientId && importBody.fhirPatientId) {
      // link existing patient
      await API.linkExternal(patientId, importBody.fhirPatientId)
      router?.navigate(routes.patientDetails(patientId, PatientDetailsTab.results), { replace: true })
    } else {
      // something's wrong
      return false
    }
    return true
  },
  handleTwoFactorSignin: async (code) => {
    const { twoFactorId } = get()
    if (!twoFactorId) return

    const response = await API.twoFactor({ twoFactorId, code })

    if (!response) return

    if (isAxiosError(response)) {
      const status = response.response?.status
      if (status && [404, 421].includes(status)) set({ errorStatus: status })
      return
    }

    set({ isAuthorized: true })
  },
  handleForgotPassword: async (userName) => {
    set({ step: AuthStep.SIGNIN })
    await API.forgotPassword(userName)
    void message.success(i18next.t('message.passwordResetSent'))
  },
  handleResetPassword: async ({ resetCode, newPassword }) => {
    const { resetSoft, trustToken, trustChallenge } = get()
    const response = await API.resetPassword({ resetCode, newPassword, trustToken, trustChallenge })

    if (!response) return

    if (isAxiosError(response)) {
      set({ errorStatus: response.response?.status })
      return
    }

    if (response.oneTimePassword) {
      const responseAutoSignin = await API.signin({
        signinType: SigninType.CREDENTIALS,
        oneTimePassword: response.oneTimePassword,
      })

      if (!isAxiosError(responseAutoSignin)) {
        resetSoft()
        message.success(i18next.t('message.passwordReset'))
        return
      }
    }

    set({ step: AuthStep.SIGNIN })
  },
  getTrustToken: async ({ code, email }) => {
    const response = await API.getTrustToken({ code, email })

    if (response) {
      if (isAxiosError(response)) return response.response?.status

      set(response)
    }
  },
  handleChangePassword: async ({ password, newPassword, onSuccess }) => {
    const { trustToken, trustChallenge } = get()
    const response = await API.changePassword({ password, newPassword, trustToken, trustChallenge })

    if (!response) {
      message.error({ content: i18next.t('error.generic'), key: 'errorGeneric' })
      return
    }

    if (isAxiosError(response)) return response.response?.status

    if (response.oneTimePassword) {
      const responseAutoSignin = await API.signin({
        signinType: SigninType.CREDENTIALS,
        oneTimePassword: response.oneTimePassword,
      })

      if (!isAxiosError(responseAutoSignin)) {
        message.success(i18next.t('message.passwordChanged'))
        onSuccess()
        return
      }
    }

    API.signout()
  },
  handleSignout: () => {
    API.signout()
  },
  getTwoFactorSecret: async () => {
    const { secretBase32Encoded } = (await API.getTwoFactorSecret()) || {}
    return secretBase32Encoded
  },
  handleEnableTwoFactor: async ({ secretBase32Encoded, twoFactorId, code }) => {
    const response = await API.enableTwoFactor({ secretBase32Encoded, twoFactorId, code })

    if (isAxiosError(response)) {
      if (response?.response?.status === 406) {
        message.error({ content: i18next.t('message.enableTwoFactorFailed'), key: 'enableTwoFactorFailed' })
      }
      return false
    }

    message.success(i18next.t('message.enableTwoFactorSucceeded'))
    return true
  },
  handleDisableTwoFactor: async (code) => {
    const response = await API.disableTwoFactor(code)

    if (response?.status === 200) {
      message.success(i18next.t('message.disableTwoFactorSucceeded'))
      await API.refreshToken()
      return true
    }

    if (isAxiosError(response) && response?.response?.status === 406) {
      message.error({ content: i18next.t('message.disableTwoFactorFailed'), key: 'disableTwoFactorFailed' })
    }
    return false
  },
  setInitialized: () => set({ isInitialized: true }),
  setIsAuthorized: () => set({ isAuthorized: true }),
  setIsUnauthorized: () => set({ isAuthorized: false }),
  resetSoft: () =>
    set({
      step: AuthStep.SIGNIN,
      errorStatus: undefined,
      resetPasswordUserName: undefined,
      twoFactorId: undefined,
      trustToken: undefined,
      trustChallenge: undefined,
    }),
  shallow,
}))
