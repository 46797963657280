import { ConfigProvider, Typography } from 'antd'
import { ThemeConfig } from 'antd/lib/config-provider/context'
import { CallbackDataParams } from 'echarts/types/src/util/types'
import React from 'react'
import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import styled, { DefaultTheme, ThemeProvider } from 'styled-components'
import { ChartAggregationLevel } from '../../stores/analytics'
import { getDisplayValueWithUnit } from '../../stores/analytics/helpers'
import { formatDateLabel } from './helpers'

interface CreateToolTipProps {
  theme: DefaultTheme
  antTheme?: ThemeConfig
  aggregationLevel: ChartAggregationLevel
  categories: string[]
  unit?: string
}

type ToolTipProps = {
  title: string
  value: number
  unit: string
}

const Wrapper = styled.div<{ theme: DefaultTheme }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export function createToolTip({
  theme,
  antTheme,
  aggregationLevel,
  categories,
  unit = '',
}: CreateToolTipProps): (props: CallbackDataParams | CallbackDataParams[]) => HTMLElement | HTMLElement[] {
  return function formatTooltip(props: CallbackDataParams): HTMLElement | HTMLElement[] {
    const category = categories[props.dataIndex]

    if (!category) return []

    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
      root.render(
        <ConfigProvider theme={antTheme}>
          <ThemeProvider theme={theme}>
            <ToolTip title={formatDateLabel(category, aggregationLevel)} unit={unit} value={props.value as number} />
          </ThemeProvider>
        </ConfigProvider>,
      )
    })
    return div
  }
}

function ToolTip({ title, value, unit }: ToolTipProps): JSX.Element {
  return (
    <Wrapper>
      <Typography.Text strong>{title}</Typography.Text>
      <Typography.Text>{getDisplayValueWithUnit(value, unit)}</Typography.Text>
    </Wrapper>
  )
}
