import { Button, Modal, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { API } from '../../lib/api'
import { useLoading } from '../../lib/hooks/useLoading'
import { ModalProps } from './types'

export function ResetUserSettings({ isVisible, onCancel }: ModalProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()

  async function handleConfirm(): Promise<void> {
    const success = await withLoading(API.devResetUserSettings())
    if (success) window.location.reload()
  }

  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      title={t('menus.main.resetUserSettings')}
      centered
      footer={
        <Button disabled={isLoading} loading={isLoading} type="primary" onClick={handleConfirm}>
          {t('menus.main.resetUserSettingsModal.submit')}
        </Button>
      }
    >
      <Typography.Text>{t('menus.main.resetUserSettingsModal.text')}</Typography.Text>
    </Modal>
  )
}
