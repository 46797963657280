import { CustomError, CustomErrorIdEnum, DeviceTypeEnum } from '@vetahealth/tuna-can-api'
import { Button, Form, Input, Modal, Select, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { debounce } from 'lodash-es'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { routes } from '../../../../Router/routes'
import { PatientDetailsTab } from '../../../../Router/routes/PatientDetails'
import { API, ApiError } from '../../../../lib/api'
import { convertEmptyStringToUndefined, trimDeep } from '../../../../lib/normalizers'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { usePatientStore } from '../../../../stores/patient'
import { assignableDevices, getDeviceName, tenoviDevices } from './helpers'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

function isApiError(response: boolean | CustomError | ApiError | undefined): response is CustomError | ApiError {
  return !!response && typeof response !== 'boolean' && 'status' in response
}

export function AssignDevice({
  isVisible,
  onCancel,
  onAssignDevice,
}: {
  isVisible: boolean
  onCancel: () => void
  onAssignDevice: (patientId: string) => void
}): JSX.Element | null {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [patient] = usePatientStore((state) => [state.patient])
  const [form] = useForm()
  const selectedDevice = Form.useWatch('deviceType', form)
  const isTenoviDevice = tenoviDevices.includes(selectedDevice)

  const handleAssignDevice = useCallback(
    debounce((values: { id: string; deviceType: DeviceTypeEnum; hubId: string }): void => {
      if (!patient?.id) return

      const { id, hubId } = convertEmptyStringToUndefined(trimDeep(values))

      trackEvent(TrackingEvent.deviceAssignConfirmClicked, { type: values.deviceType })
      onCancel()

      API.assignDevice(patient.id, { id, deviceType: values.deviceType, hubId }).then((response) => {
        if (isApiError(response) && response.status === 409 && response.error) {
          const { errorId, userId: conflictingUserId } = (response as CustomError).error
          if (errorId === CustomErrorIdEnum.DeviceAlreadyAssigned) {
            message.error(
              <>
                {t('error.deviceIdAlreadyAssigned')}
                <Button
                  style={{ paddingLeft: '6px' }}
                  type="link"
                  onClick={() => {
                    message.destroy()
                    navigate(routes.patientDetails(conflictingUserId ?? '?', PatientDetailsTab.devices))
                  }}
                >
                  {t('error.unassignHere')}
                </Button>
              </>,
              6,
            )
          } else if (errorId === CustomErrorIdEnum.DuplicateTenoviDevice) {
            message.error(t('error.duplicateTenoviDevice'), 6)
          }
        } else if (isApiError(response) && response.status === 400) {
          message.error(t('error.noDashes'), 6)
        } else if (isApiError(response) && response.status === 404) {
          message.error(t('error.noDevice'), 6)
        } else {
          onAssignDevice(patient.id)
        }
      })
    }, 300),
    [],
  )

  return (
    <Modal
      title={t('widgets.devices.assignDevice')}
      open={isVisible}
      onCancel={onCancel}
      destroyOnClose
      centered
      footer={
        <Button form="assignDevice" htmlType="submit" type="primary">
          {t('widgets.devices.assignDevice')}
        </Button>
      }
    >
      <Form name="assignDevice" onFinish={handleAssignDevice} layout="vertical" form={form} preserve={false}>
        <Wrapper>
          <Form.Item
            name="deviceType"
            rules={[{ required: true, message: t('validations.enterDevice') }]}
            label={t('widgets.devices.selectDeviceType')}
          >
            <Select
              style={{ width: '100%' }}
              placeholder={t('placeholders.deviceType')}
              options={assignableDevices.map((device) => ({
                label: getDeviceName(device),
                value: device,
              }))}
            />
          </Form.Item>
        </Wrapper>
        <Wrapper>
          <Form.Item
            name="id"
            rules={[{ type: 'string', min: 1, required: !isTenoviDevice }]}
            label={t('widgets.devices.deviceId')}
          >
            <Input placeholder={t('placeholders.deviceId')} />
          </Form.Item>
          <Form.Item
            name="hubId"
            rules={[{ type: 'string', min: 1, required: isTenoviDevice }]}
            label={t('widgets.devices.hubId')}
          >
            <Input placeholder={t('placeholders.hubId')} />
          </Form.Item>
        </Wrapper>
      </Form>
    </Modal>
  )
}
