import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import React, { ReactNode } from 'react'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Tag, Tooltip } from 'antd'
import { SearchDropdown, searchIcon } from '../../../lib/antdFilters'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { CareManager, CareManagerRole, RoleAssignment } from '@vetahealth/tuna-can-api'
import { Field } from '../../helpers'

function formatCreationDate(isoDate: string): ReactNode {
  return <Tooltip title={dayjs(isoDate).format('llll')}>{dayjs(isoDate).fromNow()}</Tooltip>
}

function formatRoles(roles: RoleAssignment[], careManagerRoles: CareManagerRole[], t: TFunction): ReactNode {
  return (
    <>
      {roles.map(({ roleId, site }) => {
        const roleName = careManagerRoles.find((role) => role.id === roleId)?.name
        return <Tag key={`${roleId}:${site ?? 'all'}`}>{`${roleName}: ${site ?? t('widgets.users.allSites')}`}</Tag>
      })}
    </>
  )
}

function formatActive(active: boolean, t: TFunction): ReactNode {
  return active ? (
    ''
  ) : (
    <Tooltip title={t('widgets.users.locked')}>
      <FontAwesomeIcon icon={faLock} color={Colors.ruby600} />
    </Tooltip>
  )
}

export function getFields(t: TFunction, careManagerRoles: CareManagerRole[]): Field<CareManager>[] {
  return [
    {
      key: 'firstName',
      title: t('table.firstName'),
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      onFilter: (query: string, { firstName }) => firstName?.toLowerCase().includes(query.toLowerCase()),
    },
    {
      key: 'lastName',
      title: t('table.lastName'),
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      onFilter: (query: string, { lastName }) => lastName?.toLowerCase().includes(query.toLowerCase()),
    },
    {
      key: 'credentials',
      title: t('table.credentials'),
    },
    {
      key: 'username',
      title: t('table.epicId'),
    },
    {
      key: 'email',
      title: t('table.email'),
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      onFilter: (query: string, { email }) => email?.toLowerCase().includes(query.toLowerCase()),
    },
    {
      key: 'createdAt',
      title: t('table.creationDate'),
      format: formatCreationDate,
    },
    {
      key: 'roles',
      title: t('table.roles'),
      format: (roles: RoleAssignment[]) => formatRoles(roles, careManagerRoles, t),
      noSorting: true,
    },
    {
      key: 'active',
      title: '',
      format: (active: boolean) => formatActive(active, t),
      noSorting: true,
    },
  ]
}

export function getManageableUserSites(permissions: string[]): string[] {
  return permissions.reduce<string[]>((result, permission) => {
    const [permissionName, site] = permission.split(':')
    if (permissionName === PermissionName.manageUser && site !== '*') {
      result.push(site)
    }
    return result
  }, [])
}
