import { NoteTypeEnum } from '@vetahealth/tuna-can-api'
import { UserState } from '../../../stores/user'

import { Template } from './types'

export const replaceNameAndCredentials = (
  template: Template,
  { firstName, lastName, credentials }: Partial<UserState>,
): Template => {
  let adjustedTemplate = template

  if (firstName && lastName) {
    const adjustedText = adjustedTemplate.text
      .replace('<care_manager.first_name>', firstName)
      .replace('<care_manager.last_name>', lastName)
    adjustedTemplate = { ...adjustedTemplate, text: adjustedText }
  }

  const adjustedText = adjustedTemplate.text.replace('<credentials>', credentials || '')

  return { ...adjustedTemplate, text: adjustedText }
}

/* eslint-disable max-len */
export const templates: Template[] = [
  {
    title: 'Appointment scheduled',
    type: NoteTypeEnum.AppointmentScheduled,
    patientInteraction: true,
    timeSpentInMin: 0,
    text: `Appointment scheduled secondary to escalation
Appointment scheduled on: [DATE/TIME]

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Program Introduction and Verbal Consent Received',
    type: NoteTypeEnum.ConsentCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the RPM program overview was provided to the [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. Questions were answered to their satisfaction and teach-back was used to validate understanding. The [PATIENT, CAREGIVER, PATIENT/CAREGIVER] verbally consented to participate. They were informed to expect their device within the next ten business days, to follow the instructions for use, and begin using the device after it is set up. They were instructed to contact their primary care provider if they have any questions about the program or their values. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Program Introduction and Verbal Consent Not Received',
    type: NoteTypeEnum.ProgramOverviewAndConsentCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the RPM program overview was provided to the [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. Questions were answered to their satisfaction and teach-back was used to validate understanding. The [PATIENT, CAREGIVER, PATIENT/CAREGIVER] declined to participate in the program. They were instructed to contact their primary care provider to discuss the program further and our contact information was provided.  The clinical champion was notified that they did not consent to participate in the program.

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Care Manager Treatment Management Monthly Outreach',
    type: NoteTypeEnum.TreatmentManagementOutreachCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, [PHYSIOLOGIC VALUE(S)] were reviewed with the [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. Concerns were solicited and addressed regarding their measurements, care pathway, symptoms, medications adherence, and treatment plan adherence. [ADD PERTINENT FINDINGS/PLAN AND NOTIFICATION TO CLINICAL CHAMPION]. They were instructed to contact their primary care provider if they have any questions about the program, their values, or their treatment plan. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Care Manager Measurement Frequency Outreach',
    type: NoteTypeEnum.MeasurementFrequencyOutreachCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, measurement frequency expectations were discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. Concerns were solicited and addressed regarding how frequently to take their measurements. [ADD PERTINENT FINDINGS/PLAN AND NOTIFICATION TO CLINICAL CHAMPION]. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. They were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Care Manager Care Pathway Engagement Outreach',
    type: NoteTypeEnum.CarePathwayEngagementOutreachCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, care pathway engagement expectations were discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. Concerns were solicited and addressed regarding engagement with their care pathway. [ADD PERTINENT FINDINGS/PLAN AND NOTIFICATION TO CLINICAL CHAMPION]. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. They were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Care Manager Out of Range Values Outreach',
    type: NoteTypeEnum.OutOfRangeValuesOutreachCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the out-of-range [INSERT PHYSIOLOGIC VALUE] was discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. A repeat value was obtained and found to be [IN RANGE OR OUT-OF-RANGE]. [ADD PERTINENT FINDINGS/PLAN AND NOTIFICATION TO CLINICAL CHAMPION]. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. They were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Care Manager Care Pathway Concerning Answers to Questions/Surveys Outreach',
    type: NoteTypeEnum.CarePathwayConcernsOutreachCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the report of [INSERT CONCERN] was discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. [ADD PERTINENT FINDINGS/PLAN AND NOTIFICATION TO CLINICAL CHAMPION]. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. They were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Care Manager Insurance Non-Coverage (Verified) Outreach and Agreement to Continue Participation',
    type: NoteTypeEnum.InsuranceNonCoverageAndContinuedParticipationOutreachCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the program coverage by their insurance was discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. They were informed that their provider would like for them to continue participating in the program and how the program costs will be covered was also discussed. They agreed to continue participation and were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Care Manager Insurance Non-Coverage (Verified) Outreach and Declined to Continue Participation',
    type: NoteTypeEnum.InsuranceNonCoverageAndDeclineToParticipateOutreachCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the program coverage by their insurance was discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. They were informed that their provider would like for them to continue participating in the program and how the program costs will be covered was also discussed. They declined to continue participation and were instructed to contact their primary care provider to discuss further. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns.

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'The Patient Called for Device Troubleshooting - Problem Solved',
    type: NoteTypeEnum.DeviceTroubleshootingWithResolutionCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the problem with the device was discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. The device issue was corrected and they successfully obtained and transmitted a [INSERT MEASUREMENT]. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. They were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials> or

[CARE MANAGER ASSISTANT FIRST NAME, LAST NAME]`,
  },
  {
    title: 'The Patient Called for Device Troubleshooting - New Device Shipped',
    type: NoteTypeEnum.DeviceTroubleshootingWithNewDeviceShippedCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the problem with the device was discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. The device issue was unable to be corrected. A new device was shipped. They were told to expect their device within the next ten business days, to follow the instructions for use, and begin using the device after it is set up. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. They were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials> or

[CARE MANAGER ASSISTANT FIRST NAME, LAST NAME]`,
  },
  {
    title: 'The Patient Called with a Medical Question',
    type: NoteTypeEnum.PatientInquiryCall,
    patientInteraction: true,
    text: `After verifying that this Care Manager was speaking with the correct person, the reason for the call was discussed with [PATIENT, CAREGIVER, PATIENT/CAREGIVER]. The patient was concerned about [PHYSIOLOGIC MEASUREMENT, MEDICATIONS, CONDITION, SYMPTOMS, ETC.] [ADD PERTINENT FINDINGS/PLAN AND NOTIFICATION TO CLINICAL CHAMPION]. Education on [INSERT TOPIC(S)] was provided and teach-back was used to validate understanding. They were instructed to contact their primary care provider if they have any questions about the program, their values, and their treatment plan. Our contact information was provided and they were encouraged to reach out to us during normal business hours with any questions or concerns. The next appointment was set for [INSERT DATE AND TIME].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Data Review - Patient Outreach and Clinical Champion Escalation is Not Indicated',
    type: NoteTypeEnum.DataReviewWithoutEscalation,
    patientInteraction: false,
    text: `The patient’s data was reviewed and their values are within the established treatment parameters. No patient outreach or escalation is indicated at this time.

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Data Review - Patient Outreach and Clinical Champion Escalation is Indicated',
    type: NoteTypeEnum.DataReviewWithEscalation,
    patientInteraction: true,
    text: `The patient’s data was reviewed and their values are outside of the established treatment parameters. Patient outreach and escalation to the clinical champion are indicated.

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Data Review - Clinical Champion Escalation without Changes to Treatment Plan',
    type: NoteTypeEnum.ClinicalChampionEscalationWithoutTreatmentPlanChanges,
    patientInteraction: false,
    text: `The patient’s values are outside of the established treatment parameters. Escalation to the clinical champion was done. The clinical champion did not make any changes to the treatment plan.

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
  {
    title: 'Clinical Champion Escalation with Changes to Treatment Plan',
    type: NoteTypeEnum.ClinicalChampionEscalationWithTreatmentPlanChanges,
    patientInteraction: true,
    text: `The patient’s values are outside of the established treatment parameters. Escalation to the clinical champion was done. The clinical champion made changes to the treatment plan and will outreach to the [PATIENT, CAREGIVER, PATIENT/CAREGIVER] to discuss them. Changes included [INSERT].

<care_manager.first_name> <care_manager.last_name> <credentials>`,
  },
]
