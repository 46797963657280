import React from 'react'
import styled, { useTheme } from 'styled-components'
import { VictoryTooltipProps } from 'victory'

export const Wrapper = styled.div`
  position: relative;
`
export const chartPadding = {
  top: 30,
  bottom: 40,
  left: 50,
  right: 10,
}

export function HoverLine(props: VictoryTooltipProps): JSX.Element | null {
  const { x, y, active } = props
  const theme = useTheme()

  if (!active || !x || !y) return null

  return (
    <g>
      <line
        x1={x}
        y1={chartPadding.top}
        x2={x}
        y2={`calc(100% - ${chartPadding.bottom}px`}
        stroke={theme.quaternaryBackground}
        strokeDasharray={3}
      />
    </g>
  )
}
