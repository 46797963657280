import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { faBars, faClose } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popconfirm, Spin } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { AggregatedAnalytics } from '../../../stores/analytics'
import { Chart } from './Chart'
import { Actions, Description, Draggable, Header, Title, Value, Wrap } from './styles'

export function Card({
  item,
  isCompact,
  isEditable,
  isActive,
  isLoading,
  onDelete,
  onOpen,
}: {
  item: AggregatedAnalytics
  isCompact?: boolean
  isEditable?: boolean
  isActive?: boolean
  isLoading?: boolean
  onDelete?: (id: string) => void
  onOpen?: (chartId: string) => void
}) {
  const { t } = useTranslation()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })
  const theme = useTheme()

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Wrap
      style={style}
      $isCompact={isCompact}
      $isActive={isActive}
      $isEditable={isEditable}
      onClick={isEditable ? undefined : () => onOpen?.(item.type)}
    >
      <Header>
        <Title>{item.label}</Title>
        {isEditable && onDelete && (
          <Actions>
            <Draggable {...attributes} {...listeners} ref={setNodeRef}>
              <FontAwesomeIcon icon={faBars} color={theme.primary} />
            </Draggable>
            <Popconfirm title={t('actions.confirm')} onConfirm={() => onDelete(item.id)}>
              <Button shape="circle" type="text" size="small">
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </Popconfirm>
          </Actions>
        )}
      </Header>
      <Value $color={item.color}>{isLoading ? <Spin spinning /> : item.aggregationValue}</Value>
      <Description>{item.description}</Description>
      {!isCompact && <Chart item={item} />}
    </Wrap>
  )
}
